import React from 'react';
import '../../../css/queryResults.css';

function getTableHeaders(queryUrl){
    return (
        <thead>
            <table border="2" cellpadding="0" align="center" cellspacing="0" width="70%">
                <tr className="bg-gray-lighter">
                    <td align="center" colSpan={42} className="bg-gray-lighter query-title">World Port Index<br />Query Results</td>
                </tr>
                <tr align="left">
                    <td className="case-capitalize" colSpan={42}><b>Query - {getQueryHeader(queryUrl)}</b></td>
                </tr>
            </table>
        </thead>
    );
}

//Return subheader to display the customer query
function getQueryHeader(queryUrl){
    var regexUrl = queryUrl.replace(/.*?\?(.*?)&output=html/i, '$1');
    regexUrl = regexUrl.replace("regionName=", "Region Name: ");
    regexUrl = regexUrl.replace("harborSize=", "Harbor Size: ");
    regexUrl = regexUrl.replace(/&harborSize=/ig, ', ');
    regexUrl = regexUrl.replace("portName=", "Port Name: ");
    regexUrl = regexUrl.replace("countryName=", "Country Name: ");
    regexUrl = regexUrl.replace("&", " and ");
    regexUrl = regexUrl.replace(/%20/ig, ' ');
    //console.log("Got regexUrl: "+regexUrl);
    return regexUrl;
}

function getPortNameLinks(data){
    //console.log("In getPortNameLinks");
    //Get Port Name table
    return (
        <table className="port" align="center">{getMultiPortRows(data)}</table>
    );
}

function getMultiPortRows(portNameData){
    //Get Port Names from data
    var url = "publications/world-port-index";
    return portNameData['ports'].map(port => {
        return <tr><td><a href={"/queryBreakout?"+url+"?indexNumber="+port.portNumber+"&output=html"}>{port.portName + " - " + port.countryName + ", Port " + port.portNumber}<br /></a></td></tr>
    });
}

function getIndivPortInfo(data){
    //console.log("In getIndivPortInfo");
    //If there were no results returned from the database, return empty array
    if ((data['ports']).length === 0) {
        //console.log("In getIndivPortInfo - no data");
        return data['ports'];
    } else {
        //console.log("In getIndivPortInfo - should have some data");
        return buildPortBody(data['ports'][0]);
    }
}

function buildPortBody(portData) {
    //console.log("In buildPortBody");
    var portNumber = portData.portNumber;
    var portName = portData.portName;
	return (
        <tbody id="wpiPortInfo" align="center">
            <table className="port" align="center">
                <tr>
                    <td align="center" className="case-capitalize" colSpan={42}>Data for Port Name <b>{portName}</b>, Port No. {portNumber}
                        <br /><a onClick={()=>{window.open('/CodeKeys.html', '_blank', 'width=400,height=300'); return false;}}>(Code Keys)</a></td>
                </tr>
            </table>
            <table className="port">
                <tr className="bg-gray-lighter">
                    <td className="port" width="50%"><b>Country: </b>{portData.countryName}</td>
                    <td className="port" width="50%"><b>Latitude: </b>{portData.latitude}</td>
                </tr>
                <tr className="bg-gray-lighter">
                    <td className="port" width="50%"><b>Geographic Region: </b>{portData.regionName}, {portData.regionNumber}</td>
                    <td className="port" width="50%"><b>Longitude: </b>{portData.longitude}</td>
                </tr>
                    <tr className="bg-gray-lighter">
                        <td className="port" width="50%"><b>Alternate Port Name: </b>{portData.alternatePortName}</td>
                        <td className="port" width="50%"><b>Global ID: </b>{portData.globalId}</td>
                    </tr>
                    <tr className="bg-gray-lighter">
                        <td className="port" width="50%"><b>Publication: </b>{portData.publicationNumber}</td>
                        <td className="port" width="50%"><b>Digital Nautical Chart(s): </b>{portData.dnc.toUpperCase()}</td>
                    </tr>
                    <tr className="bg-gray-lighter">
                        <td className="port" width="50%"><b>World Water Body: </b>{portData.dodWaterBody}</td>
                        <td className="port" width="50%"></td>
                    </tr>
            </table>
            <table className="port">
				<tr>
					<td className="port"><b>IHO S-57 Electronic Navigational Chart:</b></td>
                    <td width="10%">{portData.s57Enc}</td>
                    <td className="port"><b>NAVAREA:</b></td>
                    <td width="10%">{portData.navArea}</td>
                    <td className="port"><b>Chart:</b></td>
                    <td width="5%">{portData.chartNumber}</td>
				</tr>
                <tr>
                    <td className="port"><b>IHO S-101 Electronic Navigational Chart:</b></td>
                    <td width="10%">{portData.s101Enc}</td>
                    <td className="port"><b>Tugs Salvage:</b></td>
                    <td width="10%">{portData.tugsSalvage}</td>
                    <td className="port"><b>Lifts 100+ Tons:</b></td>
                    <td width="5%">{portData.lifts100}</td>
                </tr>
                <tr>
                    <td className="port"><b>IHO S-130 Sea Area:</b></td>
                    <td width="10%">{portData.s121WaterBody}</td>
                    <td className="port"><b>Tugs Assist:</b></td>
                    <td width="10%">{portData.tugsAssist}</td>
                    <td className="port"><b>Lifts 50-100 Tons:</b></td>
                    <td width="5%">{portData.lifts50}</td>
                </tr>
                <tr>
                    <td className="port"><b>Port Security:</b></td>
                    <td width="10%">{portData.portSecurity}</td>
                    <td className="port"><b>Underkeel Clearance Management System</b></td>
                    <td width="10%">{portData.ukcMgmtSystem}</td>
                    <td className="port"><b>Lifts 25-49 Tons:</b></td>
                    <td width="5%">{portData.lifts50}</td>
                </tr>
                <tr>
                    <td className="port"><b>Search and Rescue:</b></td>
                    <td width="10%">{portData.searchAndRescue}</td>
                    <td className="port"><b>Maximum Vessel Length (m):</b></td>
                    <td width="10%">{portData.maxVesselLength}</td>
                    <td className="port"><b>Lifts 0-24 Tons:</b></td>
                    <td width="5%">{portData.lifts0}</td>
                </tr>
                <tr>
                    <td className="port"><b>Harbor Size:</b></td>
                    <td width="10%">{portData.harborSize}</td>
                    <td className="port"><b>Maximum Vessel Beam (m):</b></td>
                    <td width="10%">{portData.maxVesselBeam}</td>
                    <td className="port"><b>Services - Longshore:</b></td>
                    <td width="5%">{portData.srLongshore}</td>
                </tr>
                <tr>
                    <td className="port"><b>Harbor Type:</b></td>
                    <td width="10%">{portData.harborType}</td>
                    <td className="port"><b>Maximum Vessel Draft (m):</b></td>
                    <td width="10%">{portData.maxVesselDraft}</td>
                    <td className="port"><b>Services - Electrical:</b></td>
                    <td width="5%">{portData.srElectrical}</td>
                </tr>
                <tr>
                    <td className="port"><b>Harbor Use:</b></td>
                    <td width="10%">{portData.harborUse}</td>
                    <td className="port"><b>Offshore Maximum Vessel Length (m):</b></td>
                    <td width="10%">{portData.offMaxVesselLength}</td>
                    <td className="port"><b>Services - Steam:</b></td>
                    <td width="5%">{portData.srSteam}</td>
                </tr>
                <tr>
                    <td className="port"><b>Shelter Afforded:</b></td>
                    <td width="10%">{portData.shelter}</td>
                    <td className="port"><b>Offshore Maximum Vessel Beam (m):</b></td>
                    <td width="10%">{portData.offMaxVesselBeam}</td>
                    <td className="port"><b>Services - Navig Equip:</b></td>
                    <td width="5%">{portData.srNavigEquip}</td>
                </tr>
                <tr>
                    <td className="port"><b>Entrance Width (m):</b></td>
                    <td width="10%">{portData.entranceWidth}</td>
                    <td className="port"><b>Offshore Maximum Vessel Draft (m):</b></td>
                    <td width="10%">{portData.offMaxVesselDraft}</td>
                    <td className="port"><b>Services - Elect Repair:</b></td>
                    <td width="5%">{portData.srElectRepair}</td>
                </tr>
                <tr>
                    <td className="port"><b>Entrance Restriction - Tide:</b></td>
                    <td width="10%">{portData.erTide}</td>
                    <td className="port"><b>Quarantine - Pratique:</b></td>
                    <td width="10%">{portData.qtPratique}</td>
                    <td className="port"><b>Services - Ice Breaking:</b></td>
                    <td width="5%">{portData.srIceBreaking}</td>
                </tr>
                <tr>
                    <td className="port"><b>Entrance Restriction - Heavy Swell:</b></td>
                    <td width="10%">{portData.erSwell}</td>
                    <td className="port"><b>Quarantine - Sanitation:</b></td>
                    <td width="10%">{portData.qtSanitation}</td>
                    <td className="port"><b>Services - Diving:</b></td>
                    <td width="5%">{portData.srDiving}</td>
                </tr>
                <tr>
                    <td className="port"><b>Entrance Restriction - Ice:</b></td>
                    <td width="10%">{portData.erIce}</td>
                    <td className="port"><b>Quarantine - Other:</b></td>
                    <td width="10%">{portData.qtOther}</td>
                    <td className="port"><b>Communications - Telephone:</b></td>
                    <td width="10%">{portData.cmTelephone}</td>
                </tr>
                <tr>
                    <td className="port"><b>Entrance Restriction - Other:</b></td>
                    <td width="10%">{portData.erOther}</td>
                    <td className="port"><b>Tidal Range (m):</b></td>
                    <td width="10%">{portData.tide}</td>
                    <td className="port"><b>Communications - Telefax:</b></td>
                    <td width="10%">{portData.cmTelegraph}</td>
                </tr>
                <tr>
                    <td className="port"><b>Supplies - Potable Water:</b></td>
                    <td width="5%">{portData.suWater}</td>
                    <td className="port"><b>Channel Depth (m):</b></td>
                    <td width="10%">{portData.chDepth}</td>
                    <td className="port"><b>Communications - Radio:</b></td>
                    <td width="10%">{portData.cmRadio}</td>
                </tr>
                <tr>
                    <td className="port"><b>Supplies - Fuel Oil:</b></td>
                    <td width="5%">{portData.suFuel}</td>
                    <td className="port"><b>Anchorage Depth (m):</b></td>
                    <td width="10%">{portData.anDepth}</td>
                    <td className="port"><b>Communications - Radio Tel:</b></td>
                    <td width="10%">{portData.cmRadioTel}</td>
                </tr>
                <tr>
                    <td className="port"><b>Supplies - Diesel Oil:</b></td>
                    <td width="5%">{portData.suDiesel}</td>
                    <td className="port"><b>Cargo Pier Depth (m):</b></td>
                    <td width="10%">{portData.cpDepth}</td>
                    <td className="port"><b>Communications - Air:</b></td>
                    <td width="10%">{portData.cmAir}</td>
                </tr>
                <tr>
                    <td className="port"><b>Supplies - Aviation Fuel:</b></td>
                    <td width="10%">{portData.suAviationFuel}</td>
                    <td className="port"><b>Oil Terminal Depth (m):</b></td>
                    <td width="10%">{portData.otDepth}</td>
                    <td className="port"><b>Communications - Rail:</b></td>
                    <td width="10%">{portData.cmRail}</td>
                </tr>
                <tr>
                    <td className="port"><b>Supplies - Provisions:</b></td>
                    <td width="5%">{portData.suProvisions}</td>
                    <td className="port"><b>Liquified Natural Gas Terminal Depth (m):</b></td>
                    <td width="10%">{portData.lngTerminalDepth}</td>
                    <td className="port"><b>Chemical Holding Tank:</b></td>
                    <td width="10%">{portData.cht}</td>
                </tr>
                <tr>
                    <td className="port"><b>Supplies - Deck:</b></td>
                    <td width="5%">{portData.suDeck}</td>
                    <td className="port"><b>Facilities - Wharves:</b></td>
                    <td width="10%">{portData.loWharves}</td>
                    <td className="port"><b>Vessel Traffic Service:</b></td>
                    <td width="10%">{portData.vts}</td>
                </tr>
                <tr>
                    <td className="port"><b>Supplies - Engine:</b></td>
                    <td width="5%">{portData.suEngine}</td>
                    <td className="port"><b>Facilities - Container:</b></td>
                    <td width="5%">{portData.loContainer}</td>
                    <td className="port"><b>Good Holding Ground:</b></td>
                    <td width="10%">{portData.goodHoldingGround}</td>
                </tr>
                <tr>
                    <td className="port"><b>Facilities - Anchorage:</b></td>
                    <td width="10%">{portData.loAnchor}</td>
                    <td className="port"><b>Facilities - Breakbulk:</b></td>
                    <td width="5%">{portData.loBreakBulk}</td>
                    <td className="port"><b>Turning Area:</b></td>
                    <td width="10%">{portData.turningArea}</td>
                </tr>
                <tr>
                    <td className="port"><b>Facilities - Med Mooring:</b></td>
                    <td width="10%">{portData.loMedMoor}</td>
                    <td className="port"><b>Facilities - Oil Terminal:</b></td>
                    <td width="5%">{portData.loOilTerm}</td>
                    <td className="port"><b>First Port of Entry:</b></td>
                    <td width="10%">{portData.firstPortOfEntry}</td>
                </tr>
                <tr>
                    <td className="port"><b>Facilities - Beach Mooring:</b></td>
                    <td width="10%">{portData.loBeachMoor}</td>
                    <td className="port"><b>Facilities - LNG Terminal:</b></td>
                    <td width="5%">{portData.loLngTerm}</td>
                    <td className="port"><b>U.S. Representative:</b></td>
                    <td width="10%">{portData.usRep}</td>
                </tr>
                <tr>
                    <td className="port"><b>Facilities - Ice Mooring:</b></td>
                    <td width="10%">{portData.loIceMoor}</td>
                    <td className="port"><b>Facilities - RoRo:</b></td>
                    <td width="5%">{portData.loRoRo}</td>
                    <td className="port"><b>Degaussing:</b></td>
                    <td width="10%">{portData.degauss}</td>
                </tr>
                <tr>
                    <td className="port"><b>Facilities - Dangerous Cargo Anchorage:</b></td>
                    <td width="5%">{portData.loDangCargo}</td>
                    <td className="port"><b>Facilities - Other:</b></td>
                    <td width="5%">{portData.loOther}</td>
                    <td className="port"><b>Repair:</b></td>
                    <td width="5%">{portData.repairCode}</td>
                </tr>
                <tr>
                    <td className="port"><b>Facilities - Liquid Bulk:</b></td>
                    <td width="5%">{portData.loLiquidBulk}</td>
                    <td className="port"><b>Medical Facilities:</b></td>
                    <td width="10%">{portData.medFacilities}</td>
                    <td className="port"><b>Dry Dock:</b></td>
                    <td width="5%">{portData.drydock}</td>
                </tr>
                <tr>
                    <td className="port"><b>Facilities - Solid Bulk:</b></td>
                    <td width="5%">{portData.loSolidBulk}</td>
                    <td className="port"><b>Overhead Limits:</b></td>
                    <td width="10%">{portData.overheadLimits}</td>
                    <td className="port"><b>Railway:</b></td>
                    <td width="5%">{portData.railway}</td>
                </tr>
                <tr>
                    <td className="port"><b>ETA Message:</b></td>
                    <td width="10%">{portData.etaMessage}</td>
                    <td className="port"><b>Cranes Fixed:</b></td>
                    <td width="10%">{portData.crFixed}</td>
                    <td className="port"><b>Garbage Disposal:</b></td>
                    <td width="10%">{portData.garbageDisposal}</td>
                </tr>
                <tr>
                    <td className="port"><b>Traffic Separation Scheme:</b></td>
                    <td width="10%">{portData.tss}</td>
                    <td className="port"><b>Cranes Mobile:</b></td>
                    <td width="10%">{portData.crMobile}</td>
                    <td className="port"><b>Dirty Ballast Disposal:</b></td>
                    <td width="10%">{portData.dirtyBallast}</td>
                </tr>
                <tr>
                    <td className="port"><b>Pilotage Compulsory:</b></td>
                    <td width="10%">{portData.ptCompulsory}</td>
                    <td className="port"><b>Cranes Floating:</b></td>
                    <td width="10%">{portData.crFloating}</td>
                    <td className="port"></td>
                    <td width="5%"></td>
                </tr>
                <tr>
                    <td className="port"><b>Pilotage Available:</b></td>
                    <td width="10%">{portData.ptAvailable}</td>
                    <td className="port"><b>Cranes Container:</b></td>
                    <td width="10%">{portData.cranesContainer}</td>
                    <td className="port"></td>
                    <td width="5%"></td>
                </tr>
                <tr>
                    <td className="port"><b>Pilotage Local Assist:</b></td>
                    <td width="10%">{portData.ptLocalAssist}</td>
                    <td className="port"><b>UN/LOCODE:</b></td>
                    <td width="10%">{portData.unloCode}</td>
                    <td className="port"></td>
                    <td width="5%"></td>
				</tr>
                <tr>
                    <td className="port"><b>Pilotage Advisable:</b></td>
                    <td width="10%">{portData.ptAdvisable}</td>
                    <td className="port"></td>
                    <td width="10%"></td>
                    <td className="port"></td>
                    <td width="5%"></td>
                </tr>
            </table>
        </tbody>
    )
}

function getErrorMessage(){
    //Return error message
    return (
        <table className="port" align="center">
            <tr>
                <td><b>Error: This port selection attempted to retrieve multiple ports, please try again.</b></td>
            </tr>
        </table>
    );
}

export {getTableHeaders, getPortNameLinks, getIndivPortInfo, getErrorMessage}
