import React, { Component } from 'react';
import PageContent from '../PageContent';

class NauticalCalc extends Component {
    render() {
        return (
        <div className="NauticalCalc">
            <div className="container left-align">
                <div id="nau-calc">
                    <PageContent divid="nau-calc" />
                </div>
               
                <div className="col-xs-3 p0">
                    <ul className="nav nav-pills nav-stacked">
                        <li className="active"><a href="#tab1" data-toggle="pill" className="calc-link">Celestial Navigation Calculators</a></li>
                        <li><a href="#tab2" data-toggle="pill" className="calc-link">Conversions Calculators</a></li>
                        <li><a href="#tab3" data-toggle="pill" className="calc-link">Distance Calculators</a></li>
                        <li><a href="#tab4" data-toggle="pill" className="calc-link">Log and Trig Calculators</a></li>
                        <li><a href="#tab5" data-toggle="pill" className="calc-link">Sailings Calculators</a></li>
                        <li><a href="#tab6" data-toggle="pill" className="calc-link">Time Zone Table</a></li>
                        <li><a href="#tab7" data-toggle="pill" className="calc-link">Weather Data Calculators</a></li>
                    </ul>
                </div>
                <div className="col-xs-9">
                    <div className="tab-content">
                        <div className="tab-pane active" id="tab1">
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/correctamp.html&type=view', '_blank', 'width=600,height=600'); return false;}}>Compass Error from Amplitudes Observed on the Visible Horizon</a><br/>
                            <a className="calc-body-link"  onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/airtemp.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Altitude Correction for Air Temperature</a><br/>
                            <a className="calc-body-link"  onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/offsets.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Table of Offsets</a><br/>
                            <a className="calc-body-link"  onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/latlong.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Latitude and Longitude Factors</a><br/>
                            <a className="calc-body-link"  onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/pressure.htm&type=view', '_blank', 'width=600,height=600'); return false; }}>Altitude Correction for Atmospheric Pressure</a><br/>
                            <a className="calc-body-link"  onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/factors.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Altitude Factors & Change of Altitude</a><br/>
                            <a className="calc-body-link"  onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/srt1.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Pub 229</a><br/>
                            <a className="calc-body-link"  onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/amplitudes.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Compass Error from Amplitudes Observed on the Celestial Horizon</a><br/>
                            <a className="calc-body-link"  onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/meridian.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Meridian Angle and Altitude of a Body on the Prime Vertical Circle</a><br/>
                        </div>
                        <div className="tab-pane" id="tab2">
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/scale.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Chart Scales and Conversion for Nautical and Statute Miles</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/meters.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Conversion for Meters, Feet and Fathoms</a><br/>
                        </div>
                        <div className="tab-pane" id="tab3">
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/degree.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Length of a Degree of Latitude and Longitude</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/speed.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Speed for Measured Mile and Speed, Time, and Distance</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/bearings.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Distance of an Object by Two Bearings</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/horizon.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Distance of the Horizon</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/vertical1.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Distance by Vertical Angle Measured Between Sea Horizon and Top of Object Beyond Sea Horizon</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/traverse.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Traverse Table</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/range.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Geographic Range</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/vertical2.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Distance by Vertical Angle Measured Between Waterline at Object and Top of Object</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/dip.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Dip of Sea Short of the Horizon</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/vertical3.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Distance by Vertical Angle Measured Between Waterline at Object and Sea Horizon Beyond Object</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/parts.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Meridional Parts</a><br/>
                        </div>
                        <div className="tab-pane" id="tab4">
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/trig.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Logarithmic and Trigonometric Functions</a><br/>
                        </div>
                        <div className="tab-pane" id="tab5">
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/gcsail.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Great Circle Sailing</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/msail.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Mercator Sailing</a><br/>
                        </div>
                        <div className="tab-pane" id="tab6">
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/zones.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Time Zones, Zone Descriptions, and Suffixes</a><br/>
                        </div>
                        <div className="tab-pane" id="tab7">
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/wind.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Direction and Speed of True Wind</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/baroheight.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Correction of Barometer Reading for Height Above Sea Level</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/barogravity.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Correction of Barometer Reading for Gravity</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/temp.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Temperature Conversions</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/humidity.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Relative Humidity and Dew Point</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/barotemp.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Correction of Barometer Reading for Temperature</a><br/>
                            <a className="calc-body-link" onClick={()=>{window.open('/api/publications/download?key=16920949/SFH00000/Calculators/mercury.html&type=view', '_blank', 'width=600,height=600'); return false; }}>Barometer Measurement Conversions</a><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                        
        );
    }
}

export default NauticalCalc;
