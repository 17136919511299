import React, { Component } from "react";
import QueryPanel from "../QueryPanel";
import { LgModal } from "../../utils/LgModal";
import { SmModal } from "../../utils/SmModal";
import "../../css/BroadcastWarnings.css";
import PageContent from "../PageContent";
import { deconvertTime } from "../../utils/FormatDate";

const calcFileSize = require("../../utils/FileSize").calcFileSize;
const fmt = require("../../utils/fmtAreas");
const msiFetch = require("../../controllers/MsiFetch");
const limits =
  "/api/publications/download?key=16920956/SFH00000/navwarnings.jpg&type=view";
const TableRow = (props) => {
  //takes in an array containing navarea, warning number, warning year, subregion, text, issueDate
  //["A", "12", "2018", "(12)", "blahblah", "(232019Z DEC 2016)"]
  var areaName = "";
  var warningNum = "";
  //convert the nav area number to the actual name if its not null
  if (props.area[0]) {
    areaName = fmt.convertNavArea(props.area[0]);
  }
  //concatenate the warning number & year if they arent null
  if (props.area[1]) {
    warningNum = props.area[1] + "/" + props.area[2];
  }

  return (
    <tr id={areaName}>
      <td>
        <a onClick={props.updateArea.bind(props, props.area)}>{areaName}</a>
      </td>
      <td>{warningNum}</td>
    </tr>
  );
};

class BroadcastWarnings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSurvey: false,
      showWarning: false,
      currentWarnings: [],
      dbDate: "",
      dailyMemLinks: [],
      network: props.network,
    };
    this.area = [];
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.updateArea = this.updateArea.bind(this);
  }

  async componentDidMount() {
    //grab the current warning messages from the database
    let url = "publications/broadcast-warn/current-warnings";
    await msiFetch
      .fetchData(url)
      .then((data) => this.setState({ currentWarnings: data }))
      .catch((error) => {
        this.setState({ currentWarnings: [] });
      });

    url = "publications/broadcast-warn/latest-warning";
    await msiFetch.fetchData(url).then((data) => {
      if (data) {
        var currWarnDate = deconvertTime(data.date);
        if (currWarnDate === "Invalid Date") {
          currWarnDate = data.endTime;
        }
        this.setState({ dbDate: currWarnDate });
      }
    });

    url = "media?type=Nav Warnings";
    await msiFetch.fetchData(url).then((data) => {
      if (data) {
        this.setState({ dailyMemLinks: data });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.network !== this.props.network) {
      this.setState({ network: this.props.network });
    }
  }

  showModal(modalType, areaName) {
    if (modalType === "survey") {
      this.setState({ showSurvey: true });
    } else if (modalType === "warning") {
      this.areaName = areaName;
      this.setState({ showWarning: true });
    }
  }

  hideModal() {
    //when called, change the state to hide the modal
    this.setState({ showSurvey: false });
    this.setState({ showWarning: false });
  }

  updateArea(area) {
    this.area = area;
    this.showModal("warning", fmt.convertNavArea(area[0]));
  }

  requestBroadcast() {
    if (this.state.network.substring(0, 7) === "unclass") {
      return [
        <a
          className="btn"
          key="request"
          onClick={this.showModal.bind(this, "survey", null)}
        >
          Request a Warning Broadcast
        </a>,
        <br key="br" />,
        <a className="btn" href="/subscribe?type=navWarnings">
          Subscribe to Navigational Warnings
        </a>,
      ];
    } else return null;
  }

  render() {
    return (
      <div id="nav-warnings">
        <div className="row container">
          <div id="BroadcastWarnings" className="left-align">
            <PageContent divid="BroadcastWarnings" />
          </div>
          <br />
          <div>
            <a className="btn" href={limits} target="_blank">
              View Navigational Warning Limits
            </a>
          </div>
        </div>
        <section className="current-warnings pub-download-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h6>Actions</h6>
                <div>
                  {this.requestBroadcast()}

                  <br />
                </div>
                <br />
              </div>
              <div className="col-md-6">
                <h6>Daily Memorandum</h6>
                <ul className="bullets list left-align">
                  <li>
                    A summary of all in-force Navigational Warnings by
                    navigation area
                  </li>
                  <li>Issued daily, excluding weekends and federal holidays</li>
                </ul>
                <div className="row">
                  {this.state.dailyMemLinks.length > 0
                    ? this.state.dailyMemLinks.map((link) => {
                        if (link.fileExtension.toLowerCase() !== "kmz") {
                          return (
                            <div className="col-sm-6" key={link.filenameBase}>
                              <a
                                href={
                                  "/api/publications/download?type=view&key=" +
                                  link.s3Key
                                }
                                target="_blank"
                                className="btn btn-xs"
                              >
                                {link.displayName} (
                                {calcFileSize(link.fileSize)})
                              </a>
                            </div>
                          );
                        } else return null;
                      })
                    : null}
                </div>
              </div>
              {/* <div className="col-md-6">
                <h6>Current Warnings</h6>
                Latest Warnings uploaded to online database. Last Update:{" "}
                {this.state.dbDate}
                <table className="table table-striped">
                  <thead className="uppercase bg-gray-light">
                    <tr>
                      <th className="h5 center-align">Warning Type</th>
                      <th className="h5 center-align">Current #</th>
                    </tr>
                  </thead>
                  <tbody className="warnings-table">
                    {this.state.currentWarnings.length > 0
                      ? this.state.currentWarnings.map((area) => (
                          <TableRow
                            area={area}
                            key={area}
                            updateArea={this.updateArea}
                          />
                        ))
                      : null}
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </section>
        <LgModal
          show={this.state.showSurvey}
          handleClose={this.hideModal}
          title="Worldwide Navigational Warning Service"
        >
          <p className="left-align">
            The Worldwide Navigational Warning Service (WWNWS) is a global radio
            and satellite broadcast system for the dissemination of Maritime
            Safety Information that provides timely long-range and coastal
            warning messages, promoting the safety of life and property at sea,
            and Special Warnings that advise mariners of potential political or
            military hazards that may affect the safety of US shipping.
          </p>
          <p className="left-align">
            The broadcast desk is staffed 24 hours per day, 365 days per year.
            To submit information for inclusion in a Broadcast Warning message,
            contact the Worldwide Navigational Warning Service broadcast desk
            at:
          </p>
          <p className="left-align">
            Phone: 571-557-5455 <br />
            Toll-free: 800-362-6289 <br />
            DSN: 547-5455 <br />
            Internet e-mail: NavSafety@nga.mil
          </p>
        </LgModal>
        <SmModal
          show={this.state.showWarning}
          handleClose={this.hideModal}
          title={this.areaName}
        >
          <pre className="left-align bwarn-data">
            {fmt.convertNavArea(this.area[0])} {this.area[1]}/{this.area[2]}{" "}
            {this.area[3]}
            <br />
            <br />
            {this.area[4]}
            <br />
            {this.area[5]}
          </pre>
        </SmModal>
        <h5>NAVIGATIONAL WARNING QUERY</h5>
        <QueryPanel category="Smaps" network = {this.state.network} />
        <h5>NAVIGATIONAL WARNING ARCHIVED QUERY (PRIOR TO APRIL 2024)</h5>
        <QueryPanel category="Warning" />
      </div>
    );
  }
}

export default BroadcastWarnings;
