import React, {Component} from 'react'
const msiFetch = require('../controllers/MsiFetch');

class QueryDirections extends Component {
    constructor(props){
        super(props);
        this.state = {
            directions: ""
        }
    }

    componentDidMount(){
        //call api for query directions, pass queryType
        const url = "query-directions?queryType=" + (window.location.search.split("=")[1]);
        msiFetch.fetchData(url)
        .then(data => {
            this.setState({
                directions: data['queryDirections'][0]['directions']
            });
        })
        .catch(error => {
            this.setState({directions: ''});
        });
    }

    render(){
        return(
            <div id="queryDirections" className="left-align container" dangerouslySetInnerHTML={{ __html: this.state.directions}}>
                
            </div>
        );
    }
}

export default QueryDirections;