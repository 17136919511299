import React, {Component} from 'react';
import { Route } from 'react-router-dom'

import EmbeddedHTML from './Publications/EmbeddedHTML';
import NotFound from './NotFound'

const File = (props) => {
    //return our specific file type
    if (props.match.params.pub === "HTML") { return <EmbeddedHTML /> }
    else return null
  }

class ViewFile extends Component {
    render(){
        return(
        <div id="ViewFile">
            <Route path={`${this.props.match.url}/:pub`} render={(props) => <File match={props.match} />} />
            <Route
                exact
                path={this.props.match.url}
                render={() => <NotFound />}
            />
        </div>
        );
    }
}

export default ViewFile;