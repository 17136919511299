import React, {Component} from 'react';
import { ngalolGeoAreas, ngalolVolAreas } from '../tmp/pubConstants';
const QueryBuilder = require('./NGALOLQueryBuilder');
const lightsUtil = require('../LightsUtils');

const OptionValue = (props) => {
    //option items for country filter
    if (props.data.substring(0, 4) === "PUB "){
        return <option disabled value={props.data}>{props.data}</option>
    }
    return <option value={props.data}>{props.data}</option>
}

class NGALOLQueryPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {},
            volobject: {},
            volume: 0,
            geoHDRName: '',
            geoHDR: [],
            geoSHDR: [],
            aidType: "lights-buoys"
        }
        this.validateFields = this.validateFields.bind(this);
        this.updateHDRAreas = this.updateHDRAreas.bind(this);
        this.hideRangeFilter = this.hideRangeFilter.bind(this);
    }

    async componentDidMount(){
        //get list of geo hdr and shdr areas. shdr only used for lighted aids query
        /* 
        JG 20190110 currently commenting out the fetch from database - takes too long to return response
        const geoareas = await MsiFetch.fetchData('publications/ngalol/areasByVolume');
        const subgeoareas = await MsiFetch.fetchData('publications/ngalol/areas');
        */
        const geoareas = ngalolVolAreas["lights-buoys"];

        //transform list to an object structure
        const volobject = lightsUtil.transformArray(geoareas);
        const areaobject = lightsUtil.transformArray(ngalolGeoAreas);

        //set state
        await this.setState({volobject : volobject, geoSHDR : areaobject});
        this.updateHDRAreas();
    }

    getHDRAreas(){
        let optionsList = [];
        const options = this.state.geoHDR;
        //if statement because options could be undefined (async fetch has not run yet)
        if (options){
            optionsList = options.map((area, index) => {return <OptionValue data={area} key={index}/>});
        }
        return optionsList;
    }

    updateHDRAreas(){
        //when user changes volume, update state
        let volobject = this.state.volobject;
        const volume = document.getElementById("sel4").value;
        const aidType = document.getElementById("ngalolReturn").value;

        if(aidType !== this.state.aidType){
            const geoareas = ngalolVolAreas[aidType];
            //transform list to an object structure
            volobject = lightsUtil.transformArray(geoareas);
            this.setState({volobject, aidType});
        }

        //get the list of areas from the state array corresponding to that volume
        const geoHDR = volobject[lightsUtil.numToPub(volume)];
        this.setState({geoHDR});
    }

    updateSHDRAreas(){
        const geoHDRName = document.getElementById("geoHDR").value;
        this.setState({geoHDRName});
    }

    getSubHDRAreas(){
        // per the geohdr you selected, get the <option> values for the sub hdr areas
        let optionsList = [];
        let options = this.state.geoSHDR[this.state.geoHDRName];
        //if statement because options could be undefined (async fetch has not run yet)
        if (options) {
            options = options.sort();
            optionsList = options.map(area => {return <OptionValue data={area} key={area}/>});
        }
        return optionsList;
    }

    hideRangeFilter(){
        const dataType = document.getElementById("ngalolReturn").value;
        //if datatype is lights-buoys, show the range filter
        //if not, hide it
        if(dataType !== 'lights-buoys'){
            document.getElementById("label8").className = "no-display";
            document.getElementById("rangeArea").className = "no-display";
        }
        else {
            document.getElementById("label8").className = "query-label";
            document.getElementById("rangeArea").className = "";
        }
    }

    updateAidType(){
        //when user udates the aid type, we need to update the geohdr areas
        //and show/hide the range filters.
        this.updateHDRAreas();
        this.updateFinalFilter();
        this.hideRangeFilter();
    }

    validateFields(){
        var filterType = document.getElementById("sel5").value;
        if (filterType === "geographicArea"){
            const alertMessage = lightsUtil.validateFields();

            //if we have a message to show the user, show it
            if (alertMessage !== ""){
                window.alert(alertMessage);
            }
            else{
                //if everything is valid, move on to query
                this.getNGALOL();
            }
        }
        else {
            //if not doing a geographic query, move on 
            this.getNGALOL();
        }
    }
    getNGALOL(){
        // variables include the select boxes & input fields
        const dataType = document.getElementById("ngalolReturn").value
        let url = "publications/ngalol/";
        url += dataType + "?";
        
        url = QueryBuilder.getNGALOL(url);

        // open the query results window. send the api url as Request Params
        // so that we don't have to send data. Actual fetch will be done by QueryResults window
        window.open('/queryResults?'+url, '_blank');
    }

    updateFinalFilter(){
        // variables are for input & select boxes related to ngalol"
        const filter = document.getElementById("sel5").value;
        const aidType = document.getElementById("ngalolReturn").value;
        const label = document.getElementById("label6");
        const aidNumberStartInput = document.getElementById("startRangeInput6");
        const aidNumberEndInput = document.getElementById("endRangeInput6");
        const geoHDR = document.getElementById("geoHDR");
        const geoSHDRLabel = document.getElementById("labelSHDR");
        const geoSHDR = document.getElementById("geoSHDR");
        const latLong = document.getElementById("latLong");
        
        // display classNames that we will be setting
        const display = "form-control";
        const nodisplay = "form-control no-display";
        const displayHalf = "form-control half-width";
        const dirdisplay = "display p0";
        const dirnodisplay = "no-display p0";

        // depending on what user is filtering by, show or do not show certain inputs
        switch(filter) {
            case "featureNumber":
                label.innerHTML = "Enter Aid Number";
                aidNumberStartInput.placeholder = "";
                aidNumberStartInput.className = displayHalf;
                aidNumberEndInput.className = nodisplay;
                geoHDR.className = nodisplay;
                geoHDR.value = "null"
                geoSHDRLabel.innerHTML = "";
                geoSHDR.className = nodisplay;
                geoSHDR.value = "null";
                latLong.className = dirnodisplay;
                break;
            case "featureNumberRange":
                label.innerHTML = "Enter Aid Number Range"
                aidNumberStartInput.placeholder = "Starting Number";
                aidNumberStartInput.className = displayHalf;
                aidNumberEndInput.className = displayHalf;
                geoHDR.className = nodisplay;
                geoHDR.value = "null"
                geoSHDRLabel.innerHTML = "";
                geoSHDR.className = nodisplay;
                geoSHDR.value = "null";
                latLong.className = dirnodisplay;
                break;
            case "geoHDR":
                label.innerHTML = "Geographic Header";
                aidNumberStartInput.className = nodisplay;
                aidNumberEndInput.className = nodisplay;
                geoHDR.className = display;
                if(aidType === 'lights-buoys'){
                    geoSHDRLabel.innerHTML = "Geographic Subheader";
                    geoSHDR.className = display;
                } else {
                    geoSHDRLabel.innerHTML = "";
                    geoSHDR.className = nodisplay;
                    geoSHDR.value = "null";
                }
                latLong.className = dirnodisplay;
                break;
            case "geographicArea":
                label.innerHTML = "Enter Corner Coordinates Of Search Area"
                aidNumberStartInput.className = nodisplay;
                aidNumberEndInput.className = nodisplay;
                geoHDR.className = nodisplay;
                geoHDR.value = "null"
                geoSHDRLabel.innerHTML = "";
                geoSHDR.className = nodisplay;
                geoSHDR.value = "null";
                latLong.className = dirdisplay;
                break;
            default:
                label.innerHTML = "Enter Aid Number";
                aidNumberStartInput.className = nodisplay;
                aidNumberEndInput.className = nodisplay;
                geoHDR.className = nodisplay;
                geoHDR.value = "null"
                geoSHDRLabel.innerHTML = "";
                geoSHDR.className = nodisplay;
                geoSHDR.value = "null";
                latLong.className = dirnodisplay;
        }
    }

    clearFilters(){
        const volume = document.getElementById("sel4");
        const filter = document.getElementById("sel5");
        const label = document.getElementById("label6");
        const aidNumberStartInput = document.getElementById("startRangeInput6");
        const aidNumberEndInput = document.getElementById("endRangeInput6");
        const geoHDR = document.getElementById("geoHDR");
        const geoSHDRLabel = document.getElementById("labelSHDR");
        const geoSHDR = document.getElementById("geoSHDR");
        const latLong = document.getElementById("latLong");
        const range = document.getElementById("range");

        volume.value = "0";
        filter.value = "null";
        label.innerHTML = "";
        range.value = "";

        //reset aid range fields
        aidNumberStartInput.value = "";
        aidNumberStartInput.className = "form-control no-display half-width";
        aidNumberEndInput.value = "";
        aidNumberEndInput.className = "form-control no-display half-width";

        //reset geoHDR fields
        geoHDR.value = "null";
        geoHDR.className = "form-control no-display";
        geoSHDRLabel.innerHTML = "";
        geoSHDR.value = "null";
        geoSHDR.className = "form-control no-display";
        
        //reset lat/long fields
        latLong.className = "no-display p0";
        document.getElementById("leftLatDegree").value = "";
        document.getElementById("leftLatMinute").value = "";
        document.getElementById("leftLatDirection").value = "";
        document.getElementById("leftLonDegree").value = "";
        document.getElementById("leftLonMinute").value = "";
        document.getElementById("leftLonDirection").value = "";

        document.getElementById("rightLatDegree").value = "";
        document.getElementById("rightLatMinute").value = "";
        document.getElementById("rightLatDirection").value = "null";
        document.getElementById("rightLonDegree").value = "";
        document.getElementById("rightLonMinute").value = "";
        document.getElementById("rightLonDirection").value = "null";
    }

    openQueryDirections(){
        //open the window in a specific width/height
        window.open('/queryDirections?queryType=ngalol', '_blank', 'toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600');
    }

    render(){
        return(
            <div className="row">
                <div className="col-sm-12 query-dir">
                    <a onClick={this.openQueryDirections.bind(this)}>Query Directions</a>
                </div>
                <div className="col-sm-6" id="selector-col4">
                    <label htmlFor="sel4" className="query-label">Select Publication</label>
                    <select defaultValue={110} className="form-control" id="sel4" onChange={this.updateHDRAreas.bind(this)}>
                        <option value={110}>Publication 110</option>
                        <option value={111}>Publication 111</option>
                        <option value={112}>Publication 112</option>
                        <option value={113}>Publication 113</option>
                        <option value={114}>Publication 114</option>
                        <option value={115}>Publication 115</option>
                        <option value={116}>Publication 116</option>
                    </select>
                </div>
                <div className="col-sm-6" id="selector-col5">
                    <label htmlFor="sel5" className="query-label">Search Aids</label>
                    <select defaultValue='null' className="form-control" id="sel5" onChange={this.updateFinalFilter.bind(this)}>
                        <option disabled value='null'>Select a Filter</option>
                        <option value="featureNumber">By Specific Aid Number</option>
                        <option value="featureNumberRange">By Aid Number Range</option>
                        <option value="geoHDR">By Geographic Header</option>
                        <option value="geographicArea">By Geographic Area</option>
                    </select>
                </div>
                <div className="col-sm-12 p15" id="selector-col6">
                    <label htmlFor="geoHDR" className="query-label" id="label6"></label>
                    <input className="form-control no-display half-width" id="startRangeInput6" placeholder="Starting Number" />
                    <input className="form-control no-display half-width" id="endRangeInput6" placeholder="Ending Number" />
                    <select defaultValue="null" className="form-control no-display" id="geoHDR" onChange={this.updateSHDRAreas.bind(this)}>
                        <option disabled value="null">Select a Geographic Header</option>
                        {this.getHDRAreas()}
                    </select>
                    <label htmlFor="geoSHDR" className="query-label" id="labelSHDR"></label>
                    <select defaultValue="null" className="form-control no-display" id="geoSHDR">
                        <option disabled value="null">Optional: Select a Geographic Sub-header</option>
                        {this.getSubHDRAreas()}
                    </select>
                    {lightsUtil.latLong()}
                </div>
                <div className="col-sm-6">
                    <label htmlFor="ngalolReturn" className="query-label" id="label7">Type to Return</label>
                    <select defaultValue="lights-buoys" className="form-control" id="ngalolReturn" onChange={this.updateAidType.bind(this)}>
                        <option value="lights-buoys">Lighted Aids</option>
                        <option value="radiobeacons">Radiobeacons</option>
                        <option value="dgpsstations">DGPS Stations</option>
                    </select>
                    <br/>
                    <label htmlFor="chk1" className="query-label" id="label8">Optional</label><br />
                    <p id="rangeArea">Limit results to lights with a range exceeding &nbsp;
                        <input className="form-control quarter-width inline p0" id="range" maxLength={2} />
                        &nbsp; nautical miles.
                    </p> 
                </div>
                <div className="col-sm-6">
                    <label htmlFor="outputSel" className="query-label" id="label9">Output Format</label>
                    <select defaultValue="html" className="form-control" id="outputSel">
                        <option value="html">HTML</option>
                        <option value="xml">XML</option>
                        <option value="csv">CSV (Delimited text)</option>
                    </select>
                </div>
                <div className="col-sm-6">
                    <div className="filter-buttons">
                        <a className="btn vol-btn" title="Search" id="btnApplyQuery" onClick={this.validateFields}>Search</a>
                        <a className="btn vol-btn" title="Clear Filters" id="btnClearQuery" onClick={this.clearFilters}>Clear</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default NGALOLQueryPanel;
