function sailingDirEnroutes(){
    return([
        {value: "123", text: "Publication 123"},
        {value: "124", text: "Publication 124"},
        {value: "125", text: "Publication 125"},
        {value: "126", text: "Publication 126"},
        {value: "127", text: "Publication 127"},
        {value: "131", text: "Publication 131"},
        {value: "132", text: "Publication 132"},
        {value: "141", text: "Publication 141"},
        {value: "142", text: "Publication 142"},
        {value: "143", text: "Publication 143"},
        {value: "145", text: "Publication 145"},
        {value: "146", text: "Publication 146"},
        {value: "147", text: "Publication 147"},
        {value: "148", text: "Publication 148"},
        {value: "153", text: "Publication 153"},
        {value: "154", text: "Publication 154"},
        {value: "155", text: "Publication 155"},
        {value: "157", text: "Publication 157"},
        {value: "158", text: "Publication 158"},
        {value: "159", text: "Publication 159"},
        {value: "161", text: "Publication 161"},
        {value: "162", text: "Publication 162"},
        {value: "163", text: "Publication 163"},
        {value: "164", text: "Publication 164"},
        {value: "171", text: "Publication 171"},
        {value: "172", text: "Publication 172"},
        {value: "173", text: "Publication 173"},
        {value: "174", text: "Publication 174"},
        {value: "175", text: "Publication 175"},
        {value: "181", text: "Publication 181"},
        {value: "182", text: "Publication 182"},
        {value: "183", text: "Publication 183"},
        {value: "191", text: "Publication 191"},
        {value: "192", text: "Publication 192"},
        {value: "193", text: "Publication 193"},
        {value: "194", text: "Publication 194"},
        {value: "195", text: "Publication 195"},
        {value: "all", text: "All Enroutes"}   
    ]);
}

function sailingDirPlanning(){
    return([
        {value: "120", text: "Publication 120"},
        {value: "140", text: "Publication 140"},
        {value: "160", text: "Publication 160"},
        {value: "180", text: "Publication 180"},
        {value: "200", text: "Publication 200"},
        {value: "all", text: "All Planning Guides"}
    ]);
}

function ngaListofLights(){
    return([
        {value: "110", text: "Publication 110"},
        {value: "111", text: "Publication 111"},
        {value: "112", text: "Publication 112"},
        {value: "113", text: "Publication 113"},
        {value: "114", text: "Publication 114"},
        {value: "115", text: "Publication 115"},
        {value: "116", text: "Publication 116"},
        {value: "all", text: "All List of Lights"}
    ]);
}

function uscgLightLists(){
    return([
        {value: "I", text: "Volume I"},
        {value: "II", text: "Volume II"},
        {value: "III", text: "Volume III"},
        {value: "IV", text: "Volume IV"},
        {value: "V", text: "Volume V"},
        {value: "VI", text: "Volume VI"},
        {value: "VII", text: "Volume VII"},
        {value: "all", text: "All Light Lists"}
    ]);
}

function fleetGuides(){
    return([
        {value: "195", text: "Publication 940"},
        {value: "195", text: "Publication 941"},
        {value: "all", text: "All Fleet Guides"}
    ]);
}

function sightRedAir(){
    return([
        {value: "1", text: "Volume 1"},
        {value: "2", text: "Volume 2"},
        {value: "3", text: "Volume 3"},
        {value: "all", text: "All Sight Reduction Tables"}
    ]);
}

function sightRedMar(){
    return([
        {value: "1", text: "Volume 1"},
        {value: "2", text: "Volume 2"},
        {value: "3", text: "Volume 3"},
        {value: "4", text: "Volume 4"},
        {value: "5", text: "Volume 5"},
        {value: "6", text: "Volume 6"},
        {value: "all", text: "All Sight Reduction Tables"}
    ]);
}

function coastPilots(){
    return ([
        {value: "1", text: "Coast Pilot 1"},
        {value: "2", text: "Coast Pilot 2"},
        {value: "3", text: "Coast Pilot 3"},
        {value: "4", text: "Coast Pilot 4"},
        {value: "5", text: "Coast Pilot 5"},
        {value: "6", text: "Coast Pilot 6"},
        {value: "7", text: "Coast Pilot 7"},
        {value: "8", text: "Coast Pilot 8"},
        {value: "9", text: "Coast Pilot 9"},
        {value: "all", text: "All Coast Pilots"}
    ]);
}

function tideTables(){
    return ([
        {value: "Tide Tables (East Pacific)", text: "Tide Tables (East Pacific): Tide Tables: West Coast of North and South America including the Hawaiian Islands"},
        {value: "Tide Tables (Europe/West Africa)", text: "Tide Tables (Europe/West Africa): Tide Tables: Europe and West Coast of Africa including the Mediterranean Sea"},
        {value: "Tide Tables (West Atlantic)", text: "Tide Tables (West Atlantic): Tide Tables: East Coast of North and South America including Greenland"},
        {value: "Tide Tables (West Pacific/Indian Ocean)", text: "Tide Tables (West Pacific/Indian Ocean): Tide Tables: Central and Western Pacific Ocean and Indian Ocean"},
        {value: "all", text: "All Tide Tables"}
    ]);
}

function tidalCurrentTables(){
    return ([
        {value: "Tidal Current Tables (Atlantic)", text: "Tidal Current Tables (Atlantic): Tidal Current Tables: Atlantic Coast of North America"},
        {value: "Tidal Current Tables (Pacific)", text: "Tidal Current Tables (Pacific): Tidal Current Tables: Pacific Coast of North America and Asia"},
        {value: "all", text: "All Tidal Current Tables"}
    ]);
}

function atlasPilotCharts(){
    return ([
        {value: "105", text: "Publication 105"},
        {value: "106", text: "Publication 106"},
        {value: "107", text: "Publication 107"},
        {value: "107", text: "Publication 108"},
        {value: "109", text: "Publication 109"},
        {value: "all", text: "All Atlases of Pilot Charts"}
    ]);
}

function otherPubs(){
    return ([
        {value: "American Practical Navigator", text: "American Practical Navigator"},
        {value: "Chart No. 1", text: "Chart No. 1"},
        {value: "Distances Between Ports", text: "Distances Between Ports"},
        {value: "International Code of Signals", text: "International Code of Signals"},
        {value: "Radar Navigation and Maneuvering", text: "Radar Navigation and Maneuvering Board Manual"},
        {value: "Radio Navigational Aids", text: "Radio Navigational Aids"},
        {value: "World Port Index", text: "World Port Index"},
        {value: "Navigation Rules", text: "USCG Navigation Rules"},
        {value: "CATA", text: "NOS Catalog A"},
        {value: "CATG", text: "NOS Catalog G"},
        {value: "CATK", text: "NOS Catalog K"},
        {value: "CATL", text: "NOS Catalog L"},
        {value: "CATP", text: "NOS Catalog P"},
        {value: "Distances Between US Ports", text: "Distances Between US Ports"},
        {value: "Almanacs: Edition 4", text: "Air Almanac"},
        {value: "Nautical Almanac", text: "Nautical Almanac"},
        {value: "all", text: "All Other Publications"},
    ]);
}


export { sailingDirEnroutes, sailingDirPlanning, ngaListofLights, 
         uscgLightLists, fleetGuides, sightRedAir, sightRedMar, 
         coastPilots, tideTables, tidalCurrentTables, atlasPilotCharts,
         otherPubs };