import React, {Component} from 'react';
import { SmModal } from '../../utils/SmModal';
import PageContent from '../PageContent';
import Recaptcha from './Recaptcha';
import Config from '../../utils/Config';

const msiFetch = require('../../controllers/MsiFetch');
const LatLongConv = require('../../utils/LatLongConv');
const convDate = require('../../utils/FormatDate').convertDate;

class MODU extends Component {
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            captchaDone: false,
            renderCaptcha: true,
            captchaToken: ''
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    async componentDidMount(){
        var conf = new Config();
        await conf.setAppConfig();
        if(conf.network !== "unclassified"){
            this.setState({captchaDone: true, renderCaptcha: false})
        }
    }

    showModal(){
        //when called, change state to show the modal
        this.setState({showModal: true});

    }

    hideModal(){
        //when called, change the state to hide the modal
        this.setState({showModal: false});
    }

    changeStatus(){
        const status = document.getElementById("ss").value;
        if(status === "Specific Berth Requested"){
            document.getElementById("berthDist").className = "";
            document.getElementById("sb").disabled = false;
        } else {
            document.getElementById("sb").disabled = true;
            document.getElementById("berthDist").className = "disabled-text";
        }
    }

    getArrLatAndLon(){
        const latDegree = document.getElementById("lat_deg").value; 
        const latMin = document.getElementById("lat_min").value;
        const latSec = document.getElementById("lat_sec").value;
        const latDir = document.getElementById("lat_dir").value;

        const lonDegree = document.getElementById("lon_deg").value;
        const lonMin = document.getElementById("lon_min").value;
        const lonSec = document.getElementById("lon_sec").value;
        const lonDir = document.getElementById("lon_dir").value;

        if((latDegree != null || latDegree !== "") && (lonDegree != null || lonDegree !== "")){
            //concat degree min sec 
            return LatLongConv.convertToDec(latDegree, latMin, latSec, latDir, lonDegree, lonMin, lonSec, lonDir)
        }
        return null;
        
    }

    async sendTransaction(){
        const arrLatLonObj = this.getArrLatAndLon();
        const deptDate = document.getElementById("deptDate").value;
        const arrDate = document.getElementById("arrDate").value;

        const formData = {
            "poc": document.getElementById("poc").value,
            "org": document.getElementById("org").value,
            "email": document.getElementById("email").value,
            "phone": document.getElementById("phone").value,
            "unitName": document.getElementById("rigName").value,
            "unitType": document.getElementById("type").value,
            "departureDate": (deptDate !== "" ? convDate(deptDate + "T04:00:00") : null),
            "arrivalDate": (arrDate !== "" ? convDate(arrDate + "T04:00:00") : null),
            "arrivalLatitude": (arrLatLonObj !== null ? arrLatLonObj["latitude"] : arrLatLonObj),
            "arrivalLongitude": (arrLatLonObj !== null ? arrLatLonObj["longitude"] : arrLatLonObj),
            "specialStatus": document.getElementById("ss").value,
            "berthDistance": document.getElementById("sb").value,
            "rigRemoved": document.getElementById("rigRmvd").checked,
            "comments": document.getElementById("cmts").value,
            "recaptchaToken": this.state.captchaToken
        }
        const url = "reports/modu-reports";

        await msiFetch.postRawData(url, formData, 'application/json');
    }

    verifyCaptcha(response){
        this.setState({captchaDone: true, captchaToken: response});
    }

    expiredCaptcha(){
        this.setState({captchaDone: false, captchaToken: ''});
    }

    validateFields(){
        let alertMessage = "";
        const poc = document.getElementById("poc").value;
        const org = document.getElementById("org").value;
        const rigName = document.getElementById("rigName").value;
        const type = document.getElementById("type").value;
        const deptDate = document.getElementById("deptDate").value;
        const arrDate = document.getElementById("arrDate").value;
        const rigRmvd = document.getElementById("rigRmvd").checked;
        const specificBerthSS = (document.getElementById("ss").value === "Specific Berth Requested" ? true : false );
        const specificBerth = document.getElementById("sb").value;
        const latDegree = document.getElementById("lat_deg").value; 
        const lonDegree = document.getElementById("lon_deg").value;
        const email = document.getElementById("email").value;

        const latLong = (latDegree !== "" && lonDegree !== "" ? true : false);


        if(poc === null || poc === ""){
            alertMessage += "Observer information is required.\n";
            document.getElementById("lblPoc").className = "form-alert";
        } else {
            document.getElementById("lblPoc").className = "";
        }

        if(org === null || org === ""){
            alertMessage += "Organization is required.\n";
            document.getElementById("lblOrg").className = "form-alert";
        } else {
            document.getElementById("lblOrg").className = "";
        }
        if(email === null || email === ""){
            alertMessage += "Email Address is required.\n";
            document.getElementById("lblEmail").className = "form-alert";
        } else {
            document.getElementById("lblEmail").className = "";
        }
        if(rigName === null || rigName === ""){
            alertMessage += "Equipment Name is required.\n";
            document.getElementById("lblRigName").className = "form-alert";
        } else {
            document.getElementById("lblRigName").className = "";
        }

        if(type === "null"){
            alertMessage += "Equipment type is required.\n";
            document.getElementById("lblType").className = "form-alert";
        } else {
            document.getElementById("lblType").className = "";
        }

        if(rigRmvd && (deptDate === null || deptDate === "")){
            alertMessage += "Expected date of departure is required when equipment removed.\n";
            document.getElementById("lblDeptDate").className = "form-alert";
        } else {
            document.getElementById("lblDeptDate").className = "";
        }

        if(!rigRmvd && (arrDate === null || arrDate === "")){
            alertMessage += "Anticipated date of arrival is required.\n";
            document.getElementById("lblArrDate").className = "form-alert";
        } else {
            document.getElementById("lblArrDate").className = "";
        }

        if(!rigRmvd && !latLong){
            alertMessage += "Geographic Location is required.\n";
            document.getElementById("geoArea").className = "form-alert"; 
        } else {
            document.getElementById("geoArea").className = "";
        }

        if(specificBerthSS && (specificBerth === null || specificBerth === "")){
            alertMessage += "Specific Berth Distance is required when Special Status of Specific Berth Requested selected.\n";
            document.getElementById("berthDist").className = "form-alert";
        } else {
            document.getElementById("berthDist").className = "";
        }

        if(!this.state.captchaDone){
            alertMessage += "Please complete the Captcha Verification.";
        }
     
        //if we have a message to show the user, show it
        if (alertMessage !== ""){
            window.alert(alertMessage);
        }
        else{
            //if everything is valid, move on to store data in the database
            //send some kind of confirmation that this is been submitted
            this.sendTransaction();
            this.showModal();
            this.clearForm();
        }
    }

    clearForm(){
        //all the elements that need to be set to empty
        let elementList = ["poc", "org", "email",  "phone", "deptDate", "rigName", "lat_deg", "lat_min", "lat_sec", "lon_deg", "lon_min", "lon_sec",
             "arrDate", "sb", "cmts"];

        //set selection boxes to null
        document.getElementById("ss").value = "None";
        document.getElementById("lat_dir").value = "None";
        document.getElementById("lon_dir").value = "None";
        document.getElementById("type").value = "null";

        //empty out that elementlist
        for(var i=0; i<elementList.length;i++){
            document.getElementById(elementList[i]).value = "";
        }

        elementList = ["lblPoc", "lblDeptDate", "lblOrg", "lblLat", "lblLon", "lblArrDate", "lblRigName", "lblType"];

        //remove checkbox on rig removed
        document.getElementById("rigRmvd").checked = false;
        //change font color back to default
        for(var j=0; j<elementList.length;j++){
            document.getElementById(elementList[j]).className = "";
        }
    }


    render() {
      return (
        <div id="MODU" className="left-align">
           <PageContent divid="modu-report" />
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <table className="col-xs-12 left-align">
                    <tbody>
                        <tr>
                            <td><span id="lblPoc">Observer*</span><br/>
                            <input type="text" id="poc" className="form-control" /></td> 
                        </tr>
                        <tr>
                            <td><span id="lblOrg">Organization*</span><br/>
                            <input type="text" id="org" className="form-control" /></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="col-xs-5 p0">Contact Information</div>
                                <div className="col-xs-2"><span id="lblPhone">Phone&nbsp;</span></div>
                                <div className="col-xs-5 p0">
                                    <input type="text" id="phone" className="form-control" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><div className="col-xs-2 p0"><span id="lblEmail">Email*&nbsp;</span></div>
                            <div className="col-xs-10 p0"><input type="text" id="email" className="form-control" /></div></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="col-xs-5 p0">Equipment Information</div>
                                <div className="col-xs-2"><span id="lblType">Type*&nbsp;</span></div>
                                <div className="col-xs-5 p0">
                                    <select id="type" className="form-control" defaultValue="null">
                                        <option value="null" disabled>Select Type...</option>
                                        <option value="Jack-up">Jack-up</option>
                                        <option value="SemiSubmersible">Semi Submersible</option>
                                        <option value="Submersible">Submersible</option>
                                        <option value="Drillship">Drillship</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="col-xs-2 p0"><span id="lblRigName">Name*</span></div>
                                <div className="col-xs-10 p0"><input type="text" id="rigName" className="form-control" /></div>
                            </td>
                        </tr>
                        <tr>
                            <td><span id="lblArrDate">Expected Date of Arrival <i>(Required unless equipment removed)</i></span><br/>
                            <input type="date" id="arrDate" className="form-control" /></td> 
                        </tr>
                        <tr>
                            <td><span id="geoArea">Geographic Location <i>(Required unless equipment removed)</i></span></td>
                        </tr>
                        <tr>
                            <td>
                                <div className="col-xs-2 col-xs-offset-3 p0 right-align"><span id="lblLat">Latitude </span></div>
                                <div className="col-xs-7 p0">
                                    <input type="number" max="9999" id="lat_deg" className="form-control quarter-width inline" maxLength={3} />&deg;
                                    <input type="number" max="9999" id="lat_min" className="form-control quarter-width inline" maxLength={4} />'
                                    <input type="number" max="9999" id="lat_sec" className="form-control quarter-width inline" maxLength={4} />"
                                    <select id="lat_dir" defaultValue="None" className="form-control tenth-width p0 inline">
                                        <option value="None"> </option>
                                        <option value="N">N</option>
                                        <option value="S">S</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>
                                <div className="col-xs-2 col-xs-offset-3 p0 right-align"><span id="lblLon">Longitude </span></div>
                                <div className="col-xs-7 p0">
                                    <input type="number" max="9999" id="lon_deg" className="form-control quarter-width inline" maxLength={3} />&deg;
                                    <input type="number" max="9999" id="lon_min" className="form-control quarter-width inline" maxLength={4} />'
                                    <input type="number" max="9999" id="lon_sec" className="form-control quarter-width inline" maxLength={4} />"
                                    <select id="lon_dir" defaultValue="None" className="form-control tenth-width p0 inline">
                                        <option value="None"> </option>
                                        <option value="W">W</option>
                                        <option value="E">E</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="col-xs-7 p0">Special Status </div>
                                <div className="col-xs-5 pr0"><span id="berthDist" className="disabled-text">Specific Berth Distance*:</span></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="col-xs-7 p0">
                                    <select id="ss" defaultValue="None" className="form-control" onChange={this.changeStatus.bind(this)}>
                                        <option value="None">None</option>
                                        <option value="Adjacent to Charted Platform">Adjacent to Charted Platform</option>
                                        <option value="Wide Berth Requested">Wide Berth Requested</option>
                                        <option value="Specific Berth Requested">Specific Berth Requested</option>
                                    </select>
                                </div>
                                <div className="col-xs-5 pr0 disabled">
                                    <input type="number" id="sb" className="form-control" disabled />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><span id="lblRigRmvd">Equipment Removed</span> &nbsp; &nbsp;
                            <input type="checkbox" id="rigRmvd" /></td> 
                        </tr>
                        <tr>
                            <td><span id="lblDeptDate">Expected Date of Departure <i>(Required if equipment removed)</i></span><br/>
                            <input type="date" id="deptDate" className="form-control" /></td> 
                        </tr>                                      
                        <tr>
                            <td ><span id="lblCmts">Additional Information</span><br/>
                            <textarea id="cmts" className="form-control" rows="3" ></textarea></td>
                        </tr>
                        {this.state.renderCaptcha ? <Recaptcha
                            verifyCallback={this.verifyCaptcha.bind(this)} expiredCallback={this.expiredCaptcha.bind(this)}
                        /> : null}
                        <tr>
                            <td className="center-align"><input type="button" value="Submit" onClick={this.validateFields.bind(this)} />
                            <input type="reset" value="Clear form" onClick={this.clearForm.bind(this)} /></td>
                        </tr>
                    </tbody>                      
                </table>
                <SmModal show={this.state.showModal} handleClose={this.hideModal} title="Submit Confirmation">
                    Thank you, your response has been submitted!
                </SmModal>
            </div>
            <div className="col-md-3"></div>
        </div>
        );
    }
}

export default MODU;