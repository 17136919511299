const pubs = require('./NavPubs');

//the DropDown with Specific Charts/Within Region/Within Subregion/Within Port of Call/ETC for Chart Corrections
function searchChartCorrBy(){
    var searchType = document.getElementById("selChartCorrFilters").value;
    var chartList = document.getElementById("chartList");
    var chartAreaFilterGroup = document.getElementById("chartAreaFilters");
    var chartGeoAreaDiv = document.getElementById("latLongDiv");
    var chartGeoArea = document.getElementById("latLong");
    var chartAllNote = document.getElementById("chartAllNote");
    var label = document.getElementById("lblChartRegion");

    //areaFilterGroup
    var chartRegion = document.getElementById("selChartRegion");
    var chartSubregion = document.getElementById("selChartSubregion");
    var chartPorts = document.getElementById("selChartPorts");
    var chartDNC = document.getElementById("selChartDNC");
    var chartType = document.getElementById("selChartType");

    var listDisplay = "col-sm-6";
    var areaFilterGroupDisplay = "col-sm-4";
    var geoAreaDisplay = "";
    var ctlDisplay = "form-control";
    var noDisplay = "no-display";

    switch(searchType){
        case "specifcCharts":
            //show chartList, hide everything else
            chartList.className = listDisplay;
            chartAreaFilterGroup.className = noDisplay;
            chartGeoAreaDiv.className = noDisplay;
            chartAllNote.className = noDisplay;
            label.innerHTML = "";
            break;
        case "byRegion":
            //show areafilterGroup and chartRegion, hide everything else
            chartList.className = noDisplay;
            chartAreaFilterGroup.className = areaFilterGroupDisplay;
            chartGeoAreaDiv.className = noDisplay;
            chartAllNote.className = noDisplay;
            //show/hide additional filters under areafiltergroup
            chartRegion.className = ctlDisplay;
            chartSubregion.className = noDisplay;
            chartPorts.className = noDisplay;
            chartDNC.className = noDisplay;
            chartType.className = noDisplay;
            label.innerHTML = "SELECT &nbsp;&nbsp;&nbsp;<a target='_blank' href='/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=view'>(View Geographic Locator)</a>";
            break;
        case "bySubregion":
            //show areafilterGroup and chartSubregion, hide everything else
            chartList.className = noDisplay;
            chartAreaFilterGroup.className = areaFilterGroupDisplay;
            chartGeoAreaDiv.className = noDisplay;
            chartAllNote.className = noDisplay;
            //show/hide additional filters under areafiltergroup
            chartRegion.className = noDisplay;
            chartSubregion.className = ctlDisplay;
            chartPorts.className = noDisplay;
            chartDNC.className = noDisplay;
            chartType.className = noDisplay;
            label.innerHTML = "SELECT &nbsp;&nbsp;&nbsp;<a target='_blank' href='/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=view'>(View Geographic Locator)</a>";
            break;
        case "withinPort":
            //show areafilterGroup and chartPorts, hide everything else
            chartList.className = noDisplay;
            chartAreaFilterGroup.className = areaFilterGroupDisplay;
            chartGeoAreaDiv.className = noDisplay;
            chartAllNote.className = noDisplay;
            //show/hide additional filters under areafiltergroup
            chartRegion.className = noDisplay;
            chartSubregion.className = noDisplay;
            chartPorts.className = ctlDisplay;
            chartDNC.className = noDisplay;
            chartType.className = noDisplay;
            label.innerHTML = "SELECT";
            break;
        case "withinDNC":
            //show areafilterGroup and chartDNC, hide everything else
            chartList.className = noDisplay;
            chartAreaFilterGroup.className = areaFilterGroupDisplay;
            chartGeoAreaDiv.className = noDisplay;
            chartAllNote.className = noDisplay;
            //show/hide additional filters under areafiltergroup
            chartRegion.className = noDisplay;
            chartSubregion.className = noDisplay;
            chartPorts.className = noDisplay;
            chartDNC.className = ctlDisplay;
            chartType.className = noDisplay;
            label.innerHTML = "SELECT";
            break;
        case "byChartType":
            //show areafilterGroup and chartType, hide everything else
            chartList.className = noDisplay;
            chartAreaFilterGroup.className = areaFilterGroupDisplay;
            chartGeoAreaDiv.className = noDisplay;
            chartAllNote.className = noDisplay;
            //show/hide additional filters under areafiltergroup
            chartRegion.className = noDisplay;
            chartSubregion.className = noDisplay;
            chartPorts.className = noDisplay;
            chartDNC.className = noDisplay;
            chartType.className = ctlDisplay;
            label.innerHTML = "SELECT";
            break;
        case "geographicArea":
            //show geoarea hide everything else
            chartList.className = noDisplay;
            chartAreaFilterGroup.className = noDisplay;
            chartGeoAreaDiv.className = geoAreaDisplay;
            chartGeoArea.className = "display p0";
            chartAllNote.className = noDisplay;
            label.innerHTML = "";
            break;
        case "all":
            //show areaNote hide everything else
            chartList.className = noDisplay;
            chartAreaFilterGroup.className = noDisplay;
            chartGeoAreaDiv.className = noDisplay;
            chartAllNote.className = listDisplay;
            label.innerHTML = "";
            break;
        default:
            break;
    }
}

function searchChartletsBy(){
    const searchType = document.getElementById("selChartletFilters").value;
    let chartList = document.getElementById("chartList");
    let chartSubregion = document.getElementById("chartletSubreg");
    let chartType = document.getElementById("chartletChartType");

    const noDisplay = "no-display";
    const display6 = "col-sm-6";
    const display4 = "col-sm-4";

    switch(searchType){
        case "specifcCharts":
            //display chartlist
            chartList.className = display6;
            chartSubregion.className = noDisplay;
            chartType.className = noDisplay;
            break;
        case "subregion":
            //display subregion list
            chartSubregion.className = display6;
            chartList.className = noDisplay;
            chartType.className = noDisplay;
            break;
        case "chartType":
            //display subregion list
            chartSubregion.className = noDisplay;
            chartList.className = noDisplay;
            chartType.className = display4;
            break;
        case "All":
            //hide chartlist
            chartList.className = noDisplay;
            chartSubregion.className = noDisplay;
            chartType.className = noDisplay;
            break;
        default:
            break;
    }
}

function searchBWBy(){
    //disable/hide Area Filter for all other options besides charts
    var productType = document.getElementById("selBWFilters").value;
    var areaFiltersDiv = document.getElementById("BWAdditionalFilters");
    
    switch(productType){
        case "charts":
            areaFiltersDiv.className = "row left-align";
            break;
        default:
            areaFiltersDiv.className = "no-display";
            break;
    }
}

function searchBWAreaBy(){
    //when user selects to change the area filter, show/hide the relevant drop downs
    var areaType = document.getElementById("selBWAreaFilters").value;
    var selRegion = document.getElementById("selChartRegion");
    var selSubregion = document.getElementById("selChartSubregion");
    var regionLabel = document.getElementById("regionlabel");

    var noDisplay = "no-display";
    var formCtl = "form-control";
    switch(areaType){
        case "byRegion":
            //show region drop down
            selRegion.className = formCtl;
            selSubregion.className = noDisplay;
            regionLabel.innerHTML = "SELECT &nbsp;&nbsp;&nbsp;<a target='_blank' href='/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=view'>(View Geographic Locator)</a>";
            break;
        case "bySubregion":
            //show subregion drop down
            selRegion.className = noDisplay;
            selSubregion.className = formCtl;
            regionLabel.innerHTML = "SELECT &nbsp;&nbsp;&nbsp;<a target='_blank' href='/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=view'>(View Geographic Locator)</a>";
            break;
        default:
            //do not show anything
            selRegion.className = noDisplay;
            selSubregion.className = noDisplay;
            regionLabel.innerHTML = "";
            break;
    }
}

function searchNavPubBy(){
    //when user switches which nav pub they want to search by, update the 
    //small or large options lists accordingly
    var pub = document.getElementById("selNavProduct").value;
    var smallDiv = document.getElementById("smallOptionsList");
    var smallSel = document.getElementById("selSmallNavPub");
    var largeDiv = document.getElementById("largeOptionsList");
    var largeSel = document.getElementById("selLargeNavPub");

    var noDisplay = "no-display";
    var optionsList = [];

    //Remove all previous children in select divs
    while(smallSel.firstChild) { smallSel.removeChild(smallSel.firstChild); }
    while(largeSel.firstChild) { largeSel.removeChild(largeSel.firstChild); }

    // display the div with the Select Box and add data to it
    /* Not all cases have instructions about the div. Once we match our case, if we matched to a case
       that doesnt have instructions it continues down the list until it reaches
       a case with a "break". small divs for everything at the top of the list and large
       divs for everything towards the bottom of the list. */
    switch(pub){
        case "none":
            smallDiv.className = noDisplay;
            largeDiv.className = noDisplay;
            break;
        case "Sailing Directions - Enroute":
            if(optionsList.length < 1) { optionsList = pubs.sailingDirEnroutes(); }
            showSmallDiv(optionsList);
            break;
        case "Sailing Directions - Planning Guides":
            if(optionsList.length < 1) { optionsList = pubs.sailingDirPlanning(); }
            showSmallDiv(optionsList);
            break;
        case "NGA List of Lights":
            if(optionsList.length < 1) { optionsList = pubs.ngaListofLights(); }
            showSmallDiv(optionsList);
            break;
        case "USCG Light List":
            if(optionsList.length < 1) { optionsList = pubs.uscgLightLists(); }
            showSmallDiv(optionsList);
            break;
        case "Fleet Guide":
            if(optionsList.length < 1) { optionsList = pubs.fleetGuides(); }
            showSmallDiv(optionsList);
            break;
        case "Sight Reduction Tables for Air Navigation":
            if(optionsList.length < 1) { optionsList = pubs.sightRedAir(); }
            showSmallDiv(optionsList);
            break;
        case "Sight Reduction Tables for Marine Navigation":
            if(optionsList.length < 1) { optionsList = pubs.sightRedMar(); }
            showSmallDiv(optionsList);
            break;
        case "Coast Pilot":
            if(optionsList.length < 1) { optionsList = pubs.coastPilots(); }
            showSmallDiv(optionsList);
            break;
        case "Tide Tables":
            if(optionsList.length < 1) { optionsList = pubs.tideTables(); }
            showLargeDiv(optionsList);
            break;
        case "Tidal Current Tables":
            if(optionsList.length < 1) { optionsList = pubs.tidalCurrentTables(); }
            showLargeDiv(optionsList);
            break;
        case "Atlas of Pilot Charts":
            if(optionsList.length < 1) { optionsList = pubs.atlasPilotCharts(); }
            showLargeDiv(optionsList);
            break;
        case "other":
            if(optionsList.length < 1) { optionsList = pubs.otherPubs(); }
            showLargeDiv(optionsList);
            break;
        default:
            break;
    }
}

function showSmallDiv(optionsList){
    //based on user selection, show and populate the smaller select box
    var smallDiv = document.getElementById("smallOptionsList");
    var smallSel = document.getElementById("selSmallNavPub");
    var largeDiv = document.getElementById("largeOptionsList");

    var displaySmallDiv = "col-sm-4";
    var noDisplay = "no-display";

    //fill select with the appropriate items
    for (var i=0; optionsList.length > i; i++){
        var option = document.createElement("option");
        option.text = optionsList[i]['text'];
        option.value = optionsList[i]['value'];
        smallSel.add(option);
    }

    //hide/unhide divs
    smallDiv.className = displaySmallDiv;
    largeDiv.className = noDisplay;
}

function showLargeDiv(optionsList){
    var smallDiv = document.getElementById("smallOptionsList");
    var largeDiv = document.getElementById("largeOptionsList");
    var largeSel = document.getElementById("selLargeNavPub");

    var displayLargeDiv = "col-sm-8";
    var noDisplay = "no-display";

    for (var x=0; optionsList.length > x; x++){
        var option2 = document.createElement("option");
        option2.text = optionsList[x]['text'];
        option2.value = optionsList[x]['value'];
        largeSel.add(option2);
    }

    //hide/unhide divs
    largeDiv.className = displayLargeDiv;
    smallDiv.className = noDisplay;
}

function chartCorrSubregions(){
    return(['11','12','13','14','15','16','17','18','19','21','22','23','24','25','26','27','28',
            '35','36','37','38','41','42','43','44','50','51','52','53','54','55','56','57',
            '61','62','63','71','72','73','74','75','76','81','82','83','91','92','93','94','95','96','97']
            );
    //from database: 
    //' 9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29',
    //'30','31','33','35','36','37','38','39','40','41','42','43','44','50','51','52','53','54','55','56','57',
    //'58','59','60','61','62','63','70','71','72','73','74','75','76','77','78','80','81','82','83','90','91',
    //'92','93','94','95','96','97','B3','E0','E1','E2','E3','E5','E6','E7','E8'
}

function chartCorrDNC(){
    return([
        {value: "201", text: "DNC 1 - South Atlantic Ocean"},
        {value: "210", text: "DNC 10 - Middle East"},
        {value: "211", text: "DNC 11 - South China Sea"},
        {value: "212", text: "DNC 12 - Japan/North Pacific"},
        {value: "213", text: "DNC 13 - North America West"},
        {value: "214", text: "DNC 14 - Central America/Caribbean Sea"},
        {value: "215", text: "DNC 15 - Gulf of Mexico/Straits of Florida"},
        {value: "216", text: "DNC 16 - Bahamas/Bermuda"},
        {value: "217", text: "DNC 17 - Eastern United States"},
        {value: "218", text: "DNC 18 - Northeastern United States/Canada"},
        {value: "219", text: "DNC 19 - Greenland/Iceland/United Kingdom"},
        {value: "202", text: "DNC 2 - South Atlantic Ocean"},
        {value: "220", text: "DNC 20 - United Kingdom"},
        {value: "221", text: "DNC 21 - Norway"},
        {value: "222", text: "DNC 22 - Barents Sea"},
        {value: "223", text: "DNC 23 - Northern Asia"},
        {value: "224", text: "DNC 24 - Sea of Okhotsk"},
        {value: "225", text: "DNC 25 - Gulf of Alaska"},
        {value: "226", text: "DNC 26 - Bristish Columbia"},
        {value: "227", text: "DNC 27 - Arctic Ocean"},
        {value: "228", text: "DNC 28 - Canada"},
        {value: "229", text: "DNC 29 - Antarctica"},
        {value: "203", text: "DNC 3 - Indian Ocean"},
        {value: "204", text: "DNC 4 - Western Australia"},
        {value: "205", text: "DNC 5 - Eastern Australia"},
        {value: "206", text: "DNC 6 - South Pacific Ocean"},
        {value: "207", text: "DNC 7 - South America"},
        {value: "208", text: "DNC 8 - Southern Europe"},
        {value: "209", text: "DNC 9 - Central Mediterranean"}
    ]);
}

function chartCorrPorts(){
    return ([
        {value: "99", text: "AAA Japan Support"},
        {value: "63", text: "Andros Islands, Bahamas"},
        {value: "84", text: "Argentia, New Foundland"},
        {value: "72", text: "Azores"},
        {value: "98", text: "Baltimore"},
        {value: "34", text: "Bangor, WA."},
        {value: "83", text: "Bath, ME"},
        {value: "20", text: "Beaumont, TX &amp; Orange, LA"},
        {value: "77", text: "Bergen, Norway"},
        {value: "49", text: "Bermuda"},
        {value: "47", text: "Bremerhaven, Germany"},
        {value: "11", text: "Bremerton, WA."},
        {value: "79", text: "Brest, France"},
        {value: "31", text: "Cape Cod to Monhogan Island"},
        {value: "30", text: "Cape May to Newport"},
        {value: "3", text: "Charleston, SC."},
        {value: "29", text: "Chesapeake Bay"},
        {value: "48", text: "Chinhae, South Korea"},
        {value: "22", text: "Corpus Christi/Port Ingleside,"},
        {value: "32", text: "DelMarVa"},
        {value: "71", text: "Delaware River Area"},
        {value: "80", text: "Den Helder, Netherlands"},
        {value: "36", text: "Diego Garcia, UK"},
        {value: "5", text: "East Coast, USA."},
        {value: "64", text: "English Channel"},
        {value: "76", text: "Freeport, Bahamas"},
        {value: "21", text: "Galveston Bay/Houston Bay, TX."},
        {value: "61", text: "Gibraltar/Strait of Gibraltar"},
        {value: "40", text: "Guam, U.S."},
        {value: "75", text: "Guantanamo Bay, Cuba"},
        {value: "16", text: "Gulfport, MS."},
        {value: "89", text: "Haifa, Israel"},
        {value: "45", text: "Halifax, Nova Scotia"},
        {value: "57", text: "Hamburg, Germany"},
        {value: "39", text: "Holy Loch/Faslane, Scotland"},
        {value: "54", text: "Hong Kong, U.K."},
        {value: "28", text: "Honolulu, HI."},
        {value: "95", text: "Jacksonville, FL"},
        {value: "4", text: "Kings Bay, GA."},
        {value: "87", text: "Kingston, Jamaica"},
        {value: "43", text: "LaMaddalena, Sardina"},
        {value: "19", text: "Lake Charles, LA."},
        {value: "51", text: "Lisbon, Portugal"},
        {value: "91", text: "Little Creek, VA."},
        {value: "9", text: "Long Beach, CA."},
        {value: "26", text: "Los Angeles, CA."},
        {value: "92", text: "Lower Chesapeake Bay"},
        {value: "58", text: "Melbourne, Australia"},
        {value: "14", text: "Mobile, AL."},
        {value: "67", text: "Morehead City/Beaufort, NC"},
        {value: "35", text: "Naples, Italy"},
        {value: "86", text: "Nassau"},
        {value: "1", text: "New London, CT."},
        {value: "17", text: "New Orleans-Gulf Outlet"},
        {value: "18", text: "New Orleans-Miss. River"},
        {value: "68", text: "New York City Area"},
        {value: "33", text: "Newport to Cape Cod"},
        {value: "94", text: "Newport, RI"},
        {value: "2", text: "Norfolk, VA."},
        {value: "24", text: "Palm Beach, FL."},
        {value: "23", text: "Panama Canal Zone"},
        {value: "15", text: "Pascagoula, MS."},
        {value: "27", text: "Pearl Harbor, HI."},
        {value: "13", text: "Pensacola, FL."},
        {value: "52", text: "Perth, Australia"},
        {value: "93", text: "Philadelphia, PA."},
        {value: "74", text: "Plymouth, UK"},
        {value: "96", text: "Port Arthur/Sabine, TX"},
        {value: "6", text: "Port Canaveral, FL."},
        {value: "7", text: "Port Everglades, FL."},
        {value: "97", text: "Portland, OR"},
        {value: "66", text: "Portsmouth, NH"},
        {value: "41", text: "Portsmouth, U.K."},
        {value: "46", text: "Pusan, South Korea"},
        {value: "70", text: "Raritan Bay Area"},
        {value: "88", text: "Rio De Janeiro, Brazil"},
        {value: "69", text: "Roosevelt Roads, PR"},
        {value: "59", text: "Rosyth, Scotland"},
        {value: "37", text: "Rota, Spain"},
        {value: "81", text: "Rotterdam, Netherlands"},
        {value: "8", text: "San Diego, CA."},
        {value: "10", text: "San Francisco/Sacramento, CA."},
        {value: "44", text: "Sasebo, Japan"},
        {value: "25", text: "Seattle, WA/Puget Sound."},
        {value: "56", text: "Seward, AK"},
        {value: "60", text: "Sidney, Australia"},
        {value: "50", text: "Singapore"},
        {value: "53", text: "St. Croix, USVI"},
        {value: "85", text: "St. Lawrence Seaway"},
        {value: "73", text: "St. Thomas, USVI"},
        {value: "38", text: "Subic Bay, R.P."},
        {value: "12", text: "Tampa, FL."},
        {value: "82", text: "Toulon, France"},
        {value: "78", text: "Trondheim, Norway"},
        {value: "65", text: "Vestfjorden, Norway"},
        {value: "62", text: "Victoria/Esquimalt, Canada"},
        {value: "55", text: "Wilhelmshaven, Germany"},
        {value: "42", text: "Yokosuka, Japan"},
        {value: "90", text: "Yorktown, VA."}
    ]);
}

export {
    searchChartCorrBy, searchChartletsBy,
    searchBWBy, searchBWAreaBy,
    searchNavPubBy,
    chartCorrSubregions, chartCorrDNC, chartCorrPorts
}
