// controllers/geoaxis.js
import Config from '../utils/Config';

// ******************************************
//  Using the GeoAxis Module:
//    Three methods are exported from the
//    module for use virtually anywhere in
//    the applciation: login(), getUserInfo(),
//    and getToken().
//
//  loggedIn() -  Checks for the existance
//    and validity of a geoaxis authentication
//    token in local storage.  On not finding
//    a valid token, checks for the existance
//    of an auth code as a parameter in the
//    window's URL.  If a code is found,
//    the code is passed to the SSO API in
//    exchange for an authentication token.
//    Stores authentication tokens in local
//    storage.  Returns true if a valid token
//    exists at the end of the workflow, and
//    false if the user needs to authenticate.
//
//  getUserInfo() - Uses a valid authentication
//    token to retrieve the user information
//    of the logged in user from the SSO API's
//    /userInfo endpoint.  Generally consists
//    of an email address, unique user ID,
//    and a First and Last name, badly parsed
//    from the user's email address (a
//    contractor will generally have .ctr
//    appended to his last name).
//
//  getToken() - returns the users' gx token
//    from localstorage.
//
//  getRedirectUrl() - return's the SSO
//    redirectUrl that the sender should
//    navigate to to log in.
//
//  parseCodeFromUrl() - return the authCode 
//     given by the sso service provider. App
//     should use this to get an auth token
//
//   exchangeCodeForToken() - use the authCode
//     to get an authToken from the service provider.
// ******************************************
export {
    getUserInfo,
    getToken,
    removeToken
};

/** authentication ENDPOINTS */
const ENDPOINTS = {
    token: '/api/auth/token',
    userinfo: '/api/user',
};

// return Config file
async function getSettings() {
    return new Config();
}

//finds UserID within user attributes DN
function findAndStoreUserId(dnArray){
    //dn assumed value (NOT IN ANY ORDER)
    //["OU=NSS,C=US,OU=NGA,OU=DoD,CN=[userid],O=U.S. Government"]
    let found = false;
    let userId = "";
    let index = 0;
    dnArray = dnArray.split(',');
    while(!found && index < dnArray.length){
        if(dnArray[index].substring(0, 3) === "CN="){
            found = true;
            userId = dnArray[index].substring(3);
        } else {
            index++;
        }
    }
    sessionStorage.setItem("userId", userId);
}

// store token in localstorage
function storeToken(token) {
    sessionStorage.setItem('access_token', token);
    //if (!stored) console.error('gx token not saved to localstorage');
}

// returns the gx token from localstorage
function getToken() {
    return sessionStorage.getItem('access_token');
}
// deletes gx token from localstorage
function removeToken() {
    return sessionStorage.removeItem('access_token');
}
// pull user name and email address
async function getUserInfo() {
    const config = await getSettings();
    const savedAccessToken = await getAccessToken();
    const URL = ENDPOINTS.userinfo;
    const header = {
        headers: { Authorization: "Bearer " + savedAccessToken }
    };
    const userInfo = await fetch(URL, header)
    .then((response) => {
        if(response.ok){
            return response.json()  
        }
        else { 
            return false;
        } 
    }).then((data) => {
        //test for user attributes (may not exist in certain pcf environments)
        var userAttr = data['user_attributes'];
        if(userAttr && config.network === "unclassified"){
            if(JSON.stringify(userAttr) === JSON.stringify({}) ||  userAttr['personaTypeCode'][0] === "CIV" || userAttr['personaTypeCode'][0] === "CTR" || userAttr['personaTypeCode'][0] === "MIL"){
                return data;
            } else { return false; }
        }
        return data;
    }).catch(error => {
        removeToken();
        return false;
    });
    if (userInfo) {
        console.log(userInfo);
        const dnArray = userInfo.dnValue[0];
        findAndStoreUserId(dnArray);
    }
    return userInfo;
}

/**
* attempt to get a bearer token. oauth session required.
* endpoint does not accept tokens.
*/
const getAccessToken = async() => {
    try {
        const url = ENDPOINTS.token;
        const options = {
            headers: { accepts: 'application/json' },
        };
        const tokenResp = await fetch(url, options)
        .then((response) => {
            if(response.ok){
                return response.json()  
            }
            else { 
                return false;
            } 
        }).then((data) => {
            if (typeof data === 'string') data = JSON.parse(data);
            return data.access_token ? data.access_token : false;
        });
        storeToken(tokenResp);
        return tokenResp;
    } catch (e) {
        return false;
    }
};
