import React, {Component} from 'react'
const msiFetch = require('../../controllers/MsiFetch');

class AvailableCorr extends Component {
    constructor(props){
        super(props);
        this.state = {
            corr: []
        }
        this.queryType = window.location.search.split('&')[0].split('=')[1];
        this.currNotice = window.location.search.split('&')[1].split('=')[1];
    }

    componentDidMount(){
        let pubTypeId = 0;
        if(this.queryType === 'uscgLLCorr') {
            pubTypeId = 16;
        } else if(this.queryType === 'ngaLoLCorr') {
            pubTypeId = 9;
        }
        //call api for query directions, pass queryType
        const url = "publications/ntm/ntm-available-corr?pubTypeId=" + pubTypeId;
        msiFetch.fetchData(url)
        .then(data => {
            this.setState({
                corr: data['availCorr']
            });
        })
        .catch(error => {
            this.setState({corr: []});
        });
    }

    render(){
        let type = '';
        if(this.state.corr.length > 0){
            type = this.state.corr[0]['pubName']
        }
        let title = type + ' - List of Available NTM Corrections';
        let pubOrVol = '';
        if(type === "USCG Light List"){ pubOrVol = 'Vol Num'; }
        else { pubOrVol = "Pub Num"; }

        return(
            <div id="availableCorr" className="left-align container">
                <table>
                    <thead>
                        <tr><th colSpan={42}>{title}</th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{pubOrVol}</td>
                            <td>Title</td>
                            <td>Current Edition</td>
                            <td>NTM Corrections</td>
                        </tr>
                        {this.state.corr.map(corr => { 
                            return (
                            <tr>
                                <td>{corr['volumeNumber']}</td>
                                <td>{corr['volumeName']}</td>
                                <td>{corr['editionYear']}</td>
                                <td>{corr['lastNotice']} - {this.currNotice}</td>
                            </tr> );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AvailableCorr;