import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HomeUC extends Component {
    render(){
        const styles = {
            width:"85%"   
        };
        let displayWarn = (this.props.network === "unclassified" ? "" : "no-display");
        let columns = (this.props.network === "unclassified" ? "col-sm-4" : "col-sm-6");

        return(
            <div className="container" >
                <div className={"row"}>
                    <br />
                    <br />
                </div>
                <div className={"row"}> </div>
                <div className="row">
                    <div className="col-md-6 col-md-push-3">
                        <div className="gray-light-box">
                            <h3>Maritime Safety Office</h3><br/>
                            <h6 className="text-normal"><b>Our Mission</b></h6>
                            <p className="pl10 pr10">Provide global maritime geospatial intelligence in support of national 
                            security objectives, including safety of navigation, international obligations, and joint military operations.</p>
                            <br /><br />
                        </div>
                    </div>
                    <div className="col-md-3 col-md-pull-6">
                        <div className="gray-light-box">
                            <b>Customer Outreach</b><br /><br />
                            <Link to="/whats-new" className="btn btn-home" style={styles}>What's New...</Link><br />
                            <Link to="/submit-report"  className="btn btn-home" style={styles}>Submit a Report...</Link><br />
                            <Link to="/did-you-know"  className="btn btn-home" style={styles}>Did You Know...</Link>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="gray-light-box">
                            <b>Subscription Services</b><br /><br />
                            <Link to="/subscribe?type=ntmUpdates" className="btn btn-home" style={styles}>Notice to Mariners</Link><br />
                            <Link to="/subscribe?type=navWarnings" className="btn btn-home" style={styles}>Navigation Warnings</Link><br />
                            <Link to="/subscribe?type=pubUpdates" className="btn btn-home" style={styles}>Publication Updates</Link>
                        </div>
                    </div>                            
                </div>
                <div className="row"><br /></div>
                    <div className="col-md-12">
                        <div className="gray-light-box">
                            <div className={columns}>
                                <Link to="/browser-support" >Browser Support</Link>
                            </div>
                        <div className={columns}>
                            <Link to="/legal-sec" >Legal Disclaimer & Security Policy</Link>
                        </div>
                        <div className={"col-md-4 " + displayWarn}>
                            <Link to="/commercial-use" >Commercial Use Warning</Link>
                        </div>
                        </div>
                </div>
                <div className="row"><br /><br /></div>
                </div>
        );
    }
}

export default HomeUC;