import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class MsiFooter extends Component {
    constructor(props){
        super(props);
        this.state = {
            network: props.network
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.network !== this.props.network){
            this.setState({network: this.props.network});
        }
    }

    render(){
        let dncURL = "";
        if(this.state.network === "unclassified"){
            dncURL = "https://dnc.nga.mil/";
        } else if(this.state.network === "unclassified//limdis"){
            dncURL = "https://dnc.geo.nga.mil/";
        } else if(this.state.network === "secret"){
            dncURL = "http://dnc.nga.smil.mil/";
        } else {
            dncURL = "https://dnc.nga.ic.gov/";
        }

        const network = "Dynamic content classified up to " + this.state.network.toUpperCase();  
        return(
            <div id="bottom">
                <footer className="msifooter" > 
                    {this.state.network.substring(0,7) !== "unclass" ? <div className="col-xs-2 ngafooterlogo" id="logo-div"><img id="footer-logo" src="/webgraphics/ngaglobe.png" alt="NGA Logo" height="45px"></img> NGA</div> : null}
                    <div className="col-xs-2 msifootertxt"><Link className="link-white" to="/contact-info">Contact Information</Link></div>
                    <div className="col-xs-2 msifootertxt"><Link className="link-white" to="/faq">FAQ</Link></div>
                    <div className="col-xs-2 msifootertxt"><Link className="link-white" to="/api/swagger-ui.html" target="_blank">Developers</Link></div>
                    {this.state.network.substring(0,7) === "unclass" ? <div className="col-xs-2 msifootertxt"><a className="link-white" href="https://www.nga.mil/resources/FOIA_Information.html" target="_blank">FOIA&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;</a><a className="link-white" href="https://www.nga.mil/resources/Privacy_Policy.html" target="_blank">Privacy Policy</a></div> : null}
                    <div className="col-xs-2 msifootertxt"><Link className="link-white" to="/maritime-partners">Mission Partners</Link></div> 
                </footer>


                <div className="classificationblock">
                    <div  id="classification">
                        {network}
                    </div>
                </div>    
            </div>
        );
    }
}

export default MsiFooter;
