import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';

class Chart1 extends Component {
    render(){
        return(
            <div id="chart-no1">
                <div id="chart-no1-descrip">
                <PageContent divid="chart-no1-descrip" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="Chart1" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default Chart1;