import React from 'react';
import groupBy from 'lodash/groupBy';
import '../../../css/queryResults.css';

const tableHeaders = ["No", "Name & Location", "Position", "Characteristic", "Height", "Range", "Structure", "Remarks"];
const Vol5tableHeaders = ["No", "Name & Location", "Mile", "Bank", "Characteristic", "Structure/Dayboard Up&emsp;&emsp;&emsp;&emsp;&emsp;Down", "Remarks"];

function getTableHeaders(queryUrl){
    return (
        <thead className="center-align">
            <tr>
                <td colSpan={42} className="bg-gray-lighter query-title">USCG Light List<br />Query Results</td>
            </tr>
            <tr align="left">
                    <td className="case-capitalize" colSpan={42} id="searchTerms"><b>Query Search Terms: </b><br/>{getQueryHeader(queryUrl)}</td>
                </tr>
        </thead>
    );
}

//Return subheader to display the customer query
function getQueryHeader(queryUrl){
    let regexUrl = queryUrl.replace(/.*?\?(.*?)&output=html/i, '$1');
    regexUrl = regexUrl.replace("volume=", "Volume Number: ");
    regexUrl = regexUrl.replace("featureNumber=", "Aid Number: ");
    regexUrl = regexUrl.replace("featureNumberStart=", "Aid Number Start: ");
    regexUrl = regexUrl.replace("featureNumberEnd=", "Aid Number End: ");
    regexUrl = regexUrl.replace("geoHDRName=", "Geographic Header: ");
    regexUrl = regexUrl.replace("geoSHDRName=", "Geographic Sub Header: ");
    regexUrl = regexUrl.replace("range=", "Range Exceeding: ");
    regexUrl = regexUrl.replace("latitudeLeft=", "Lower Left Latitude: ");
    regexUrl = regexUrl.replace("longitudeLeft=", "Lower Left Longitude: ");
    regexUrl = regexUrl.replace("latitudeRight=", "Upper Right Latitude: ");
    regexUrl = regexUrl.replace("longitudeRight=", "Upper Right Longitude: ");
    regexUrl = regexUrl.replace("&includeRemovals=true", "");
    regexUrl = regexUrl.replace("&includeRemovals=false", "");
    regexUrl = regexUrl.replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n");
    regexUrl = regexUrl.replace(/%20/ig, ' ');
    return regexUrl;
}

function getVolumes(data){
    let queryData;
    if(data['uscg-llV5']){ queryData = data['uscg-llV5']; }
    else { queryData = data['uscg-ll'] }

    if (queryData.length === 0) {
        return queryData;
    }
    else {
        //group data by volumes {creates object with volumes as keys}
        const listByVolume = groupBy(queryData, 'volumeNumber');
        const volumes = Object.keys(listByVolume);
        return volumes;
    }
}

function formatData(data){
    let queryData;
    let type;
    //set type and queryData
    if(data['uscg-llV5']){ 
        queryData = data['uscg-llV5'];
        type = 'vol5';
    }
    else if(data['ntm-uscg-llV5']){
        queryData = data['ntm-uscg-llV5'];
        type = 'ntmVol5';
    }
    else if(data['ntm-uscg-ll']){ 
        queryData = data['ntm-uscg-ll'];
        type = 'ntmReg';
    }
    else { 
        queryData = data['uscg-ll'];
        type = 'reg';
    }

    if (queryData.length === 0) {
        return queryData;
    }
    else {
        var formattedData = {}
        var key, array = null
        //group data by volume {creates object with volumes as keys}
        var listByVolume = groupBy(queryData, 'volumeNumber');
        var volumes = Object.keys(listByVolume);

        if (volumes.length > 1) {
            //user queried data that returned more than 1 volume. show results as linked list
            for (key in listByVolume) {
                if (key !== null){
                    array = listByVolume[key]
                    formattedData[key] = showGeoAreasofVolume(array)
                }
            }
            return Object.keys(formattedData).map(volume => { return <LinkedList volume={volume} key={volume} data={formattedData[volume]} /> });
        } else {
            //results list only concerns one volume, display data directly
            if (volumes[0] === "VOLUME V"){
                formattedData = concatDupesV5(queryData, type);
            } else {
                formattedData = concatDupes(queryData, type);
            }
            
            return <tbody><VolumeData data={formattedData} /></tbody>          
        }
    }
}

function showGeoAreasofVolume(array){
    var listByGeoArea = groupBy(array, 'geoHDRName');
    return listByGeoArea;
}

function concatDupes(array, type){
    var formattedArray = []
    var object;
    //iterate over list backwards to concatenate values that are duplicated
    for (var i = array.length-1; i>0; i--){   
        object = {}
        if(array[i].precedingNote){
            object.precedingNote = array[i].precedingNote.replace(/#|!|\$/g,''); // get rid of # or ! /
        }
        if(array[i].postNote){
            object.postNote = array[i].postNote.replace(/#|!|\$/g,''); // get rid of # or ! /
        }
       
        object.aidNumber = array[i].featureNumber;
        object.name = (array[i].name ? textReplacements(array[i].name) : '');
        object.latlong = array[i].position;
        object.characteristicText = (array[i].characteristic ? textReplacements(array[i].characteristic) : '');
        object.heightFeet = array[i].height; 

        object.range = (array[i].range ? array[i].range : '');
        object.structureText = (array[i].structureText ? array[i].structureText.replace(/#|!|\$/g,'') : '');
        object.characteristicRemarks = (array[i].remarks ? textReplacements(array[i].remarks) : '');

        if (!array[i].regionHeading){ array[i].regionHeading = '' }
        if (!array[i].subregionHeading){ array[i].subregionHeading = '' }
        if (!array[i].localHeading){ array[i].localHeading = '' }

        object.geoHDRName = array[i].geopoliticalHeading;
        object.geoSHDRName = array[i].regionHeading;
        object.geoUHDRName = array[i].subregionHeading;
        object.geoBHDRName = array[i].localHeading;
        if(type === 'ntmReg'){
            object.noticeNumber = array[i].noticeWeek + "/" + array[i].noticeYear;
        }

        //save to new array *unshift adds to beginning of array*
        formattedArray.unshift(object);
    }
    //first record in the array
    object = {}
    
    if(array[0].precedingNote){
        object.precedingNote = array[0].precedingNote.replace(/#|!|\$/g,''); // get rid of # or ! /
    }
    if(array[0].postNote){
        object.postNote = array[0].postNote.replace(/#|!|\$/g,''); // get rid of # or ! /
    }
    
    object.aidNumber = array[0].featureNumber;
    object.name = (array[0].name ? textReplacements(array[0].name) : '');
    object.latlong = array[0].position;
    object.characteristicText = (array[0].characteristic ? textReplacements(array[0].characteristic) : '');
    object.height = array[0].height; 

    object.range = (array[0].range ? array[0].range : '');
    object.structureText = (array[0].structureText ? array[0].structureText.replace(/#|!|\$/g,'') : '');
    object.characteristicRemarks = (array[0].remarks ? textReplacements(array[0].remarks) : '');

    if (!array[0].regionHeading){ array[0].regionHeading = '' }
    if (!array[0].subregionHeading){ array[0].subregionHeading = '' }
    if (!array[0].localHeading){ array[0].localHeading = '' }

    object.geoHDRName = array[0].geopoliticalHeading;
    object.geoSHDRName = array[0].regionHeading;
    object.geoUHDRName = array[0].subregionHeading;
    object.geoBHDRName = array[0].localHeading;

    if(type === 'ntmReg'){
        object.noticeNumber = array[0].noticeWeek + "/" + array[0].noticeYear;
    }
    //save to new array *unshift adds to beginning of array*
    formattedArray.unshift(object);
    return formattedArray;
}

function concatDupesV5(array, type){
    var formattedArray = []
    var object;
    for (var i = 0; i< array.length; i++){
        object = {};
        //features in vol 5 with no name seem to be the preceding notes for the area.
        if (array[i].name){
            if(array[i].precedingNote){
                object.precedingNote = array[i].precedingNote.replace(/#|!|\$/g,''); // get rid of # or ! /
            }
            if(array[i].postNote){
                object.postNote = array[i].postNote.replace(/#|!|\$/g,''); // get rid of # or ! /
            }

            object.aidNumber = array[i].featureNumber;
            
            object.name = (array[i].name ? textReplacements(array[i].name) : '');
            object.mile = (array[i].mile ? textReplacements(array[i].mile) : '');            
            if(array[i].bank) { 
                object.bank = (array[i].bank === 'L' ? 'Left' : (array[i].bank === 'R' ? 'Right' : '')) }
            else { object.bank = '' }

            object.characteristicText = (array[i].characteristicText ? array[i].characteristicText.replace(/#|!|\$/g,'') : '');
            object.structure = array[i].structure;           
            object.V5characteristicRemarks = (array[i].remarks ? textReplacements(array[i].remarks) : '');

            object.geoHDRName = (array[i].geopoliticalHeading ? array[i].geopoliticalHeading : '');
            object.geoSHDRName = (array[i].regionHeading ? array[i].regionHeading : '');
            object.geoUHDRName = (array[i].subregionHeading ? array[i].subregionHeading : '');
            object.geoBHDRName = (array[i].localHeading ? array[i].localHeading : '');
            if(type === 'ntmVol5'){
                object.noticeNumber = array[i].noticeWeek + "/" + array[i].noticeYear;
            }
            //save to new array *unshift adds to beginning of array*
            formattedArray.push(object);
        }
        //if we encountered a feature that has no name, possibly a preceding note feature
        else {
            if (array[i].precedingNote){
                object.precedingNote = array[i].precedingNote.replace(/#|!|\$/g,'').replace('null', '');
                object.geoHDRName = (array[i].geopoliticalHeading ? array[i].geopoliticalHeading : '');
                object.geoSHDRName = (array[i].regionHeading ? array[i].regionHeading : '');
                object.geoUHDRName = (array[i].subregionHeading ? array[i].subregionHeading : '');
                object.geoBHDRName = (array[i].localHeading ? array[i].localHeading : '');
                formattedArray.push(object);
            }
        }
    }
    return formattedArray;
}

function textReplacements(text){
    //replaces common chars in text
    if(text && (typeof text === 'string' || text instanceof String)){
        return text.replace(/#|!|\$/g,'').replace(/\^/g,'°').replace(/@/g, '• ').replace(/_/g, '- ').replace('null', '');
    } else { return text }
}

function romanToInt(volumeNumberString){
    var volNumInt = null;
    switch(volumeNumberString){
        case "VOLUME I":
            volNumInt = 1;
            break;
        case "VOLUME II":
            volNumInt = 2;
            break;
        case "VOLUME III":
            volNumInt = 3;
            break;
        case "VOLUME IV":
            volNumInt = 4;
            break;
        case "VOLUME V":
            volNumInt = 5;
            break
        case "VOLUME VI":
            volNumInt = 6;
            break;
        case "VOLUME VII":
            volNumInt = 7;
            break;
        default:
            break;
    }
    return volNumInt;
}

const LinkedList = (props) => {
    //List of links that will show the GeoHDR value when multiple volumes are returned
    //DOM XSS Fix
    var url = encodeURIComponent('/queryBreakout?' + window.location.search.substring(1));
    url.replace("/[^a-zA-Z0-9]+/", "");
    //END DOM XSS Fix

    var volNum = romanToInt(props.volume);
    return (
        <tbody id={props.volume}>
            <tr>
                <td colSpan={42} align="center" className="bg-gray-lighter text-bold">{props.volume}</td>
            </tr>
            {Object.keys(props.data).sort().map(area => <tr><td><a href={url+'&volume='+volNum+'&geoHDRName='+area} target='_blank'>{area}</a></td></tr>)}
        </tbody>
    );
}

const TableData = (props) => {
    //Returns the actual table rows for each row in the table.
    //Excludes certain elements (that will be taken care of via another method (like pre/post note))
    return (
        <tr>
            {Object.keys(props.data).map(function(element) { 
                var dataValue = props.data[element];
                // list of elements that should NOT be displayed the normal way
                const nonDisplay = ['featureId', 'geoHDRName', 'geoSHDRName', 'geoUHDRName', 'geoBHDRName', 'precedingNote', 'postNote'];
                if(nonDisplay.indexOf(element) < 0){
                    return <td key={props.data["aidNumber"] + element} id={element} className="valignTop">{dataValue}</td>
                } 
                else if(element === "structureUp"){
                    //volume V structure Up, Down & Text
                    return <td key={props.data["aidNumber"] + element} id={element} className="valignTop"><span className="uscgll">{dataValue}</span><span className="uscgll">{props.data["structureDown"]}</span><br/>{props.data["structure"]}</td>
                }
                else { return false }
            })}
        </tr>
    );
}

const VolumeData = (props) => {
    var tableData = [];
    var displayGeoBHDR, displayGeoSHDR, displayGeoHDR, displayGeoUHDR, displayPreNote, displayPostNote;

    var geoHDR = "";
    var geoSHDR = "";
    var geoUHDR = "";
    var geoBHDR = "";

    for(var i=0; i<props.data.length; i++){
        //test display of area header names
        displayGeoHDR = (props.data[i].geoHDRName === geoHDR ? false : true);
        displayGeoSHDR = (displayGeoHDR ? (props.data[i].geoSHDRName !== '' ? true : false) : (props.data[i].geoSHDRName === geoSHDR ? false : (props.data[i].geoSHDRName !== '' ? true : false)));
        displayGeoUHDR = (props.data[i].geoUHDRName === geoUHDR ? false : (props.data[i].geoUHDRName !== '' ? true : false));
        displayGeoBHDR = (props.data[i].geoBHDRName === geoBHDR ? false : (props.data[i].geoBHDRName !== '' ? true : false));

        //test the display of the prenote and postnote
        displayPreNote = (props.data[i].precedingNote ? true : false);
        displayPostNote = (props.data[i].postNote ? true : false)

        //we created an array of reactjs components that will display the table rows
        //for each header, if true (meaning display it) add it to the array
        if (displayGeoHDR){ tableData.push(<DisplayGeoHDR areaName={props.data[i].geoHDRName} />) }
        if (displayGeoSHDR){ tableData.push(<DisplayGeoSHDR areaName={props.data[i].geoSHDRName} />) }
        if (displayGeoUHDR){ tableData.push(<DisplayGeoUHDR areaName={props.data[i].geoUHDRName} />) }
        if (displayGeoBHDR){ tableData.push(<DisplayGeoBHDR areaName={props.data[i].geoBHDRName} />) }

        //display prenote (if exists), table data (always), then postnote (if exists)
        if (displayPreNote){ tableData.push(<DisplayNote note={props.data[i].precedingNote} type="preNote" />) }
        tableData.push(<TableData data={props.data[i]} key={i} />);
        if (displayPostNote){ tableData.push(<DisplayNote note={props.data[i].postNote} type="postNote" />) }
            
        //update vars if necessary
        if (props.data[i].geoHDRName !== geoHDR) { geoHDR = props.data[i].geoHDRName }
        if (props.data[i].geoSHDRName !== geoSHDR) { geoSHDR = props.data[i].geoSHDRName }
        if (props.data[i].geoUHDRName !== geoUHDR) { geoUHDR = props.data[i].geoUHDRName }
        if (props.data[i].geoBHDRName !== geoBHDR) { geoBHDR = props.data[i].geoBHDRName }
    }
    
    return tableData;
}

const DisplayGeoHDR = (props) => {
    return (
        <tr id={props.areaName}>
            <td colSpan={42} align="center" className="bg-gray-lighter text-bold">{props.areaName}</td>
        </tr>
    );
}

const DisplayGeoSHDR = (props) => {
    return (
        <tr id={props.areaName}>
            <td colSpan={42} align="left" className="text-bold" id="sub-area">{props.areaName}</td>
        </tr>
    );
}

const DisplayGeoUHDR = (props) => {
    return (
        <tr id={props.areaName}>
            <td colSpan={42} align="left" className="text-bold" id="sub-u-area">{props.areaName}</td>
        </tr>
    );
}

const DisplayGeoBHDR = (props) => {
    return (
        <tr id={props.areaName}>
            <td colSpan={42} align="left" className="text-bold" id="sub-b-area">{props.areaName}</td>
        </tr>
    );
}

const DisplayNote = (props) => {
    if(props.type === "preNote"){
        return (
            <tr>
                <td colSpan={42} align="left" id="preceedingNote">{props.note}</td>
            </tr>
        );
    }
    else {
        return (
            <tr>
                <td></td><td colSpan={42} align="left" id="postNote">{props.note}</td>
            </tr>
        );
    }
}

export {getTableHeaders, tableHeaders, Vol5tableHeaders,formatData, getVolumes};
