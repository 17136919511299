function calcFileSize(bytes){
    var megabytes = bytes/1000000
    if (megabytes < 1) {
        // turn to KB
        return Math.ceil(megabytes * 1000) + " KB";
    }else {
        return Math.ceil(megabytes) + " MB";
    }
}

export {calcFileSize}