import React, {Component} from 'react';

const MsiFetch = require('../../controllers/MsiFetch');
const filters = require('./NTMQueryFilters');
const lightsUtil = require('../Publications/LightsUtils');
const pubConstants = require('../Publications/tmp/pubConstants');
const validate = require('./ValidateNTM');
const queryBuilder = require('./NTMQueryBuilder');

const OptionValue = (props) => {
    //option items for country filter
    if (props.data.substring(0, 4) === "PUB "){
        return <option disabled value={props.data}>{props.data}</option>
    }
    return <option value={props.data}>{props.data}</option>
}

class NTMQueryPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            network: props.network,
            data: {},
            volobject: {},
            volume: 0,
            geoHDRName: '',
            geoHDR: [],
            geoSHDR: [],
            geoHDROptions: [],
            geoSHDROptions: [],
            queryType: "chartCorr",
            currentUnclassNotice: "00/0000",
            currentClassNotice: "00/0000",
            pubType: "untm"
        }
        this.setSearchNTMNumber = this.setSearchNTMNumber.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentUnclassNotice !== prevProps.currentUnclassNotice) {
            this.setState({currentUnclassNotice: this.props.currentUnclassNotice});
            this.setSearchNTMNumber(this.props.currentUnclassNotice);
        }

        if(this.props.network !== prevProps.network){
            this.setState({network: this.props.network});
        }

        if(this.props.currentClassNotice !== prevProps.currentClassNotice){
            this.setState({currentClassNotice: this.props.currentClassNotice});
        }
    }

    setSearchNTMNumber(noticeNumber) {
        //takes notice number in 33/2019 format and sets it as input week and year in query panel
       document.getElementById("endInputNTMWk").value = noticeNumber.split("/")[0];
       document.getElementById("endInputNTMYr").value = noticeNumber.split("/")[1];
    }

    changeSearchNTMNumber(){
        const pubType = document.getElementById("pubTypeSel").value;
        let op;
        if(pubType === "untm"){
            this.setSearchNTMNumber(this.state.currentUnclassNotice);
            document.getElementById("lblEndInputNTMWk").innerHTML = "Week Number";
            document.getElementById("lblStartInputNTMWk").innerHTML = "Week Number";
            //enable all select options
            op = document.getElementById("queryType").getElementsByTagName("option");
            for (let i = 0; i < op.length; i++) {
                op[i].disabled = false; 
            }
        } else {
            this.setSearchNTMNumber(this.state.currentClassNotice);
            document.getElementById("lblStartInputNTMWk").innerHTML = "Month Number";
            document.getElementById("lblEndInputNTMWk").innerHTML = "Month Number";
            //disable certain select options 
            const enabled = ["chartCorr", "chartletsDepthtabsNotes", "chartsAffected", "hydrographic", "navPubCorr"];
            op = document.getElementById("queryType").getElementsByTagName("option");
            for (let i = 0; i < op.length; i++) {
                if (enabled.indexOf(op[i].value) < 0) { op[i].disabled = true; }
            }
        }
        this.setState({pubType});
    }

    changeQueryType(){
        const queryType = document.getElementById("queryType").value;
        const rangeDiv = document.getElementById("lightListRange");
        const outputSel = document.getElementById("outputOptions");
        const noticeNumDiv = document.getElementById("searchNTMs");
        const availableCorr = document.getElementById("availCorrLink");

        const noDisplay = 'no-display';
        this.setState({queryType});
        /* if(queryType === "uscgLLCorr"){
            //unhide the range selector for the lights
            rangeDiv.className = 'col-sm-6';
            //unhide list of available corrections link
            availableCorr.className = 'col-sm-12';
            //fetch the geoHDR data from the database
            this.getUscgllGeohdr();
        } else */{
            rangeDiv.className = noDisplay;
            availableCorr.className = noDisplay;
        }

        //hide notice number selection if doing pubs aff query
        if(queryType === "pubsAffected") {
            noticeNumDiv.className = noDisplay;
        } else { noticeNumDiv.className = "row left-align"; }

        //for ngalol, get the geo headers
        if (queryType === "ngaLoLCorr") {
            //unhide list of available corrections link
            availableCorr.className = 'col-sm-12';
            this.getNgalolGeohdr();
        }

        //hide output selection (html/csv/xml) if we are doing a graphics query
        if(queryType === "chartletsDepthtabsNotes" || queryType === "navPubCorr"){
            outputSel.className = "no-display";
        } else { outputSel.className = "col-sm-4"; }
        
    }

    /* async getUscgllGeohdr(){
        //get list of geo hdr and shdr areas
        const geoareas = await MsiFetch.fetchData('publications/uscgll/areasByVolume');
        const subgeoareas = await MsiFetch.fetchData('publications/uscgll/areas')
        
        //transform list to an object structure
        this.setgeoareas(geoareas['uscg-ll'], subgeoareas['uscg-ll']);
    } */

    async getNgalolGeohdr(){
        //get list of geo hdr and shdr areas
        //const geoareas = await MsiFetch.fetchData('publications/ngalol/areasByVolume');
        //const subgeoareas = await MsiFetch.fetchData('publications/ngalol/areas');
        const geoareas = pubConstants.ngalolVolAreas;
        const subgeoareas = pubConstants.ngalolGeoAreas; 
        this.setNgalolGeoAreas(geoareas, subgeoareas);
    }

    setNgalolGeoAreas(geoareas, subgeoareas){
        //get current ngalol datatype
        let datatype = "lights-buoys"; 
        //original datatype should be lights-buoys, but can change per user input
        try {
            datatype = document.getElementById("ngalolReturn").value
        } catch(e) {}
        //transform list to an object structure
        this.setgeoareas(geoareas[datatype], subgeoareas);
    }

    async setgeoareas(geoareas, subgeoareas){
        //transforms lists to an object structure
        const volobject = lightsUtil.transformArray(geoareas);
        const areaobject = lightsUtil.transformArray(subgeoareas);
        //set state
        await this.setState({volobject : volobject, geoSHDR : areaobject});
        this.getHDRAreas();
    }

    updateHDRAreas(volobject, queryType){
        //when user changes volume, update state
        let geoHDR;
        let volume = "110";
        try {
            volume = document.getElementById("sel4").value;
        }
        catch (e){ }

        //get the list of areas from the state array corresponding to that volume
        /*if(queryType === "uscgLLCorr"){
            geoHDR = volobject[lightsUtil.volToRoman(volume)];
        } else */if (queryType === "ngaLoLCorr"){
            geoHDR = volobject[lightsUtil.numToPub(volume)];
        }
        return geoHDR;
    }

    async getHDRAreas(){
        const geoareas = this.updateHDRAreas(this.state.volobject, this.state.queryType);
        let optionsList = [];
        let options = geoareas;
        //if statement because options could be undefined (async fetch has not run yet)
        if (options){
            optionsList = options.map((area, index) => {return <OptionValue data={area} key={index}/>});
        }
        await this.setState({geoHDROptions: optionsList});
        this.updateSHDRAreas();

        //also will need to send geoareas to subhdr... cannot set state
    }

    async updateSHDRAreas(){
        const geoHDRName = document.getElementById("geoHDR").value;
        await this.setState({geoHDRName});
        this.getSubHDRAreas();
    }

    getSubHDRAreas(){
        // per the geohdr you selected, get the <option> values for the sub hdr areas
        let optionsList = [];
        let options = this.state.geoSHDR[this.state.geoHDRName];
        //if statement because options could be undefined (async fetch has not run yet)
        if (options) {
            options = options.sort();
            optionsList = options.map(area => {return <OptionValue data={area} key={area}/>});
        }
        document.getElementById("geoSHDR").value = 'null';
        this.setState({geoSHDROptions: optionsList});
    }

    switchSearchNTMS(){
        //based upon what user selected on how they would like to search
        //ntms, switch which input boxes show
        const searchType = document.getElementById("selSearchNtms").value;
        const startRange = document.getElementById("startInputNTM");
        const endRange = document.getElementById("endInputNTM");

        switch (searchType){
            case "bySpecificNTM":
                //show start range only
                startRange.className = "no-display";
                endRange.className = "";
                break;
            case "byNTMRange":
                //show start & end ranges
                startRange.className = "";
                endRange.className = "";
                break;
            case "all":
                //show neither start nor end range
                startRange.className = "no-display";
                endRange.className = "no-display";
                break;
            default:
                break;
        }
    }

    async validateFields(){
        //valid starts as a boolean 1, if any method returns invalid
        //we will minus 1 and end up with 0 or -1 which is falsy
        let isValid = 1;

        //validate NTM Range values first
        isValid-= validate.validateNTMRange(this.state.pubType);
        if(isValid){
            //validate specific fields for each query type 
            switch (this.state.queryType){
                case "chartsAffected": 
                    //fall through to charCorr (same fields)
                case "chartCorr":
                    isValid-= validate.validateChartCorr();
                    break;
                case "chartletsDepthtabsNotes": 
                    isValid-= validate.validateChartlets();
                    break;
                case "hydrographic": 
                    isValid-= validate.validateHydrographic();
                    break;
                case "navPubCorr": 
                    isValid-= validate.validateNavPubCorr();
                    break;
                /* case "uscgLLCorr": 
                    isValid-= await validate.validateUscgLLCorr("16");
                    break; */
                case "ngaLoLCorr": 
                    isValid-= await validate.validateNgaLolCorr("9");
                    break;
                default:
                    break;
            }

            //validate NTM Range values first
            if(isValid){
                this.buildNTMQuery();
            } 
        }        
    }

    buildNTMQuery(){
        let url = "publications/";
        switch (this.state.queryType){
            case "chartCorr":
                url += queryBuilder.buildChartCorrQuery();
                break;
            case "chartletsDepthtabsNotes": 
                url += queryBuilder.buildChartletsQuery();
                break;
            case "chartsAffected": 
                url += queryBuilder.buildChartsAffQuery();
                break;
            case "hydrographic": 
                url += queryBuilder.buildHydroCorrQuery();
                break;
            case "navPubCorr": 
                url += queryBuilder.buildNavPubCorrQuery();
                break;
            /* case "uscgLLCorr": 
                url += queryBuilder.buildUSCGLLQuery();
                break; */
            case "ngaLoLCorr": 
                url += queryBuilder.buildNGALOLQuery();
                break;
            case "pubsAffected":
                url += queryBuilder.buildPubsAffQuery();
                break;
            default:
                break;
        }

        // open the query results window. send the api url as Request Params
        // so that we don't have to send data. Actual fetch will be done by QueryResults window
        window.open('/queryResults?'+url, '_blank');
    }

    openQueryDirections(){
        //open the window in a specific width/height
        window.open('/queryDirections?queryType=' + this.state.queryType, '_blank', 'toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600');
    }

    openAvailCorr(){
        //open window in a specific width/height
        window.open('/availableCorrections?queryType=' + this.state.queryType + "&currNotice=" + this.state.currentUnclassNotice, '_blank', 'toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=400');
    }

    renderQueryTypeSelector(){
        if(this.state.network.substring(0,7) === "unclass"){
            return(
                <div className="col-sm-6 col-sm-offset-3">
                    <select id="queryType" className="form-control" defaultValue="chartCorr" onChange={this.changeQueryType.bind(this)}>
                        <option value="chartCorr">Chart Corrections</option>
                        <option value="chartletsDepthtabsNotes">Chartlets/Depth Tabulations/Notes</option>
                        <option value="chartsAffected">Charts Affected by NTM</option>
		    	{/*<option value="hydrographic">Hydrographic Products Catalog Corrections</option>
                        <option value="navPubCorr">Navigation Publication Corrections</option>
                        <option value="uscgLLCorr">USCG Light List Corrections</option>
                        <option value="ngaLoLCorr">NGA List of Lights Corrections</option>
                        <option value="pubsAffected">Publications Affected by NTM</option>*/}
                    </select>
                </div>
            );
        } else {
            return([
                <div className="col-sm-3" key={1}>
                    <select id="pubTypeSel" className="form-control" defaultValue="untm" onChange={this.changeSearchNTMNumber.bind(this)}>
                        <option value="untm">Search Unclassified NTM</option>
                        <option value="cntm">Search Classified NTM</option>
                    </select>
                </div>,
                <div className="col-sm-6" key={2}>
                    <select id="queryType" className="form-control" defaultValue="chartCorr" onChange={this.changeQueryType.bind(this)}>
                        <option value="chartCorr">Chart Corrections</option>
                        <option value="chartletsDepthtabsNotes">Chartlets/Depth Tabulations/Notes</option>
                        <option value="chartsAffected">Charts Affected by NTM</option>
		    	{/*<option value="hydrographic">Hydrographic Products Catalog Corrections</option>
                        <option value="navPubCorr">Navigation Publication Corrections</option>
                        <option value="uscgLLCorr">USCG Light List Corrections</option>
                        <option value="ngaLoLCorr">NGA List of Lights Corrections</option>
                        <option value="pubsAffected">Publications Affected by NTM</option>*/}
                    </select>
                </div>
            ]);
        }
    }

    render(){
        return([
            <div className="row left-align mb24" key={1}>
                {this.renderQueryTypeSelector()}
                <div className="col-sm-3 query-dir">
                    <a onClick={this.openQueryDirections.bind(this)}>Query Directions</a>
                </div>
            </div>,
            <div id="availCorrLink" className="col-sm-12 no-display" key={2}>
                <a onClick={this.openAvailCorr.bind(this)}>View List of Available NTM Corrections</a>
            </div>,
            <div className="row left-align" id="searchNTMs" key={3}>
                {/*BEGIN OF SEARCH NTMS ROW*/}
                <div className="col-sm-4">
                    <label htmlFor="selSearchNtms" className="query-label">Search NTMs</label>
                    <select defaultValue="bySpecificNTM" className="form-control" id="selSearchNtms" onChange={this.switchSearchNTMS.bind(this)}>
                        <option value="bySpecificNTM">{this.state.pubType === "untm" ? "by Specific NTM" : "by Specific CNTM"}</option>
                        <option value="byNTMRange">{this.state.pubType === "untm" ? "by NTM Range" : "by CNTM Range"}</option>
                        {this.state.queryType === "pubsAffected" ? null : <option value="all">All</option>}
                    </select>
                </div>
                <div id="startInputNTM"  className="no-display">
                    <div className="col-sm-2">
                        <label htmlFor="startInputNTMWk" id="lblStartInputNTMWk" className="query-label">Week Number</label>
                        <input className="form-control" id="startInputNTMWk" type="number" min="1" max="52"/>
                    </div>
                    <div className="col-sm-2">
                        <label htmlFor="startInputNTMYr" className="query-label">Year (YYYY)</label>
                        <input className="form-control" id="startInputNTMYr" type="number" min="1981"/>
                    </div>
                </div>
                <div id="endInputNTM">
                    <div className="col-sm-2">
                        <label id="lblEndInputNTMWk" htmlFor="endInputNTMWk" className="query-label">Week Number</label>
                        <input className="form-control" id="endInputNTMWk" type="number" min="1" max="52" />
                    </div>
                    <div className="col-sm-2">
                        <label htmlFor="endInputNTMYr" className="query-label">Year (YYYY)</label>
                        <input className="form-control" id="endInputNTMYr" type="number" min="1981" />
                    </div>
                </div>
                {/*END OF SEARCH NTMS ROW*/}
            </div>,
            (this.state.queryType === "chartCorr" ? filters.showChartCorrFilters(this) : ""),
            (this.state.queryType === "chartletsDepthtabsNotes" ? filters.showChartletDepthTabFilters(this) : ""),
            (this.state.queryType === "chartsAffected" ? filters.showChartCorrFilters(this) : ""),
            (this.state.queryType === "hydrographic" ? filters.showHydrographicFilters(this) : ""),
            (this.state.queryType === "navPubCorr" ? filters.showNavPubFilters() : ""),
            (this.state.queryType === "pubsAffected" ? filters.showPubsAffFilters() : ""),
            //(this.state.queryType === "uscgLLCorr" ? filters.showUscgLLFilters(this) : ""),
            (this.state.queryType === "ngaLoLCorr" ? filters.showNgaLolFilters(this) : ""),
            <div className="row left-align" key={11}>
                {/*BEGINNING OF OUTPUT OPTIONS ROW*/}
                <div className="col-sm-4" id="outputOptions">
                    <label htmlFor="outputSel" className="query-label" id="label9">Output Format</label>
                    <select defaultValue="html" className="form-control" id="outputSel">
                        <option value="html">HTML</option>
                        <option value="xml">XML</option>
                        <option value="csv">CSV (Delimited text)</option>
                    </select>
                </div>
                <div className="col-sm-6 no-display" id="lightListRange">
                    <label htmlFor="chk1" className="query-label" id="label8">Optional</label><br />
                    <p className="mb0">Limit results to lights with a range exceeding &nbsp;
                        <input className="form-control width70 inline p0" id="range" maxLength={2} />
                        &nbsp; nautical miles.
                    </p> 
                </div>
                {/*END OF OUTPUT OPTIONS ROW*/}
            </div>,
            <div className="col-sm-12" key={12}>
                <div className="col-sm-offset-9 filter-buttons">
                    <a className="btn vol-btn" title="Search" id="btnApplyQuery" onClick={this.validateFields.bind(this)}>Search</a>
                    <a className="btn vol-btn" title="Clear Filters" id="btnClearQuery" onClick={this.clearFilters}>Clear</a>
                </div>
            </div>
        ]);
    }
}

export default NTMQueryPanel;
