import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';

class RNMB extends Component {
    render(){
        return(
            <div id="radar-nav-man-board">
                <div id="rnmb-descrip">
                    <PageContent divid="rnmb-descrip" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="RNMB" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default RNMB;