import React, {Component} from 'react';
import PubDownload from './../PubDownload';
//import QueryPanel from '../../QueryPanel';
import PageContent from '../../PageContent';



class USCGLL extends Component {
    render(){
        return(
            <div id="uscg-ll">
                <div id="uscgll-descrip">
                    <PageContent divid="uscgll-descrip" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="USCGLL" pubTypeId={this.props.pubTypeId} />
                </div>
                {/*<h5>Search Online Database</h5>
                <div id="pub-query">
                    <QueryPanel category="USCGLL" />
                </div>*/}
            </div>
        );
    }
}

export default USCGLL;