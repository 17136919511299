import React, { Component } from 'react';
import Select from 'react-select';
const lightsUtil = require('../Publications/LightsUtils');

const LatLongConv = require('../../utils/LatLongConv');
const msiFetch = require('../../controllers/MsiFetch');

const options = [
    { value: '3', label: 'Symbols, Abbreviations and Terms used on Paper and Electronic Navigation Charts - Chart No. 1' },
    { value: '44', label: 'Digital Nautical Publications - Quarterly Update' },
    { value: '29', label: 'NGA/DLIS Catalog of Maps Charts and Related Products' },
    { value: '2', label: 'The American Practical Navigator - Pub. 9' },
    { value: '7', label: 'International Code of Signals - For Visual, Sound and Radio Communications - U.S.Edition, 1969 (Revised 2003) - Pub. 102' },
    { value: '11', label: 'Radio Navigational Aids - Pub. 117' },
    { value: '17', label: 'World Port Index - Pub. 150' },
    { value: '5', label: 'Distances Between Ports, 2001 - Pub. 151' },
    { value: '10', label: 'Radar Navigation and Maneuvering Board Manual, 2001 - Pub. 1310' },
    { value: '28', label: 'USCG Navigational Rules and Regulations Handbook' }
];

const nganos_subregions = [
    11, 12, 13, 14, 15, 16, 17, 18, 19,
    21, 22, 23, 24, 25, 26, 27, 28, 29,
    35, 36, 37, 38,
    41, 42, 43, 44,
    51, 52, 53, 54, 55, 56, 57,
    61, 62, 63,
    71, 72, 73, 74, 75, 76,
    81, 82, 83,
    91, 92, 93, 94, 95, 96, 97
]

class ProdCatQueryPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: [],
        }
        this.cntmEnabled = (props.network.substring(0,7) !== "unclass" && props.network !== "" ? true : false);
    }

    async componentDidMount() {
        var dncRegionSelect= document.getElementById("dnc-region");
        var todNumberSelect = document.getElementById("tod-number");
        var todLevelSelect = document.getElementById("tod-level");
        var ngaNosSubregionSelect = document.getElementById("nganos-subregion");

        //Returns filters for each chart type
        const dncRegions = await msiFetch.fetchData("publications/products/dnc/regions");
        var sortedDncRegions = dncRegions.sort(function(a,b){return a-b});
        const todNumbers = await msiFetch.fetchData("publications/products/tod/numbers");
        var sortedTodNumbers = todNumbers.sort(function(a,b){return a-b});
        const todLevels = await msiFetch.fetchData("publications/products/tod/levels");
        var sortedTodLevels = todLevels.sort(function(a,b){return a-b});
                 
        var optAll = new Option('All', 'all');
        dncRegionSelect.appendChild(optAll);

        //populate the various select boxes on the site from these values
        this.populateSelect(sortedDncRegions, dncRegionSelect);
        this.populateSelect(sortedTodNumbers, todNumberSelect);
        this.populateSelect(sortedTodLevels, todLevelSelect);
        this.populateSelect(nganos_subregions, ngaNosSubregionSelect);
        
        //replace 0 with VDU; 30 with Updated Libraries
        for (var i= 0; i<dncRegionSelect.length; i++) {
            if (dncRegionSelect.options[i].value==='0') {
                dncRegionSelect.options[i].text = 'VDU';
            }
            if (dncRegionSelect.options[i].value==='30') {
                dncRegionSelect.options[i].text = 'Updated Libraries';
            }
        }
   
    }
    populateSelect(filterList, select){
        for (var i = 0; i < filterList.length; i++) {
            var opt = filterList[i];
            var el= document.createElement("option");
            el.textContent = opt;
            el.value = opt;
            select.appendChild(el);
         }  
    }

    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    }

    //show/hide the charts panel when a user clicks the charts button
    showCharts() {
        var chartsPanel = document.getElementById("charts-panel");
        var pubsPanel = document.getElementById("pubs-panel");

        if(chartsPanel.classList.contains("display")){
            chartsPanel.className = "no-display";
            pubsPanel.className = "no-display";
        } else {
            chartsPanel.className = "display";
            pubsPanel.className = "no-display";
            this.updateCharts();
        }
    }

    //show/hide the publications panel when a user clicks the pubs button
    showPubs() {
        var chartsPanel = document.getElementById("charts-panel");
        var pubsPanel = document.getElementById("pubs-panel");

        if(pubsPanel.classList.contains("display")){
            chartsPanel.className = "no-display";
            pubsPanel.className = "no-display";
        } else {
            chartsPanel.className = "no-display";
            pubsPanel.className = "display";
            this.updatePubs();
        }
    }

    updateCharts() {
        var chartSelected = document.getElementById("chart-select").value;
        var ngaNosFilter = document.getElementById("nganos-filter");
        var dncFilter = document.getElementById("dnc-filter");
        var todFilter = document.getElementById("tod-filter");
        var classFilter = document.getElementById("class-filter");

        var display = "display";
        var nodisplay = "no-display";

        //switch case for chart list, filters, and directions
        switch (chartSelected) {
            case "dnc":
                ngaNosFilter.className = nodisplay;
                dncFilter.className = display;
                todFilter.className = nodisplay;
                classFilter.className = nodisplay;
                break;

            case "tod":
                ngaNosFilter.className = nodisplay;
                dncFilter.className = nodisplay;
                todFilter.className = display;
                classFilter.className = nodisplay;
                break;
            case "class":
                ngaNosFilter.className = nodisplay;
                dncFilter.className = nodisplay;
                todFilter.className = nodisplay;
                classFilter.className = display;
                break;
            case "nganos":
            default:
                ngaNosFilter.className = display;
                dncFilter.className = nodisplay;
                todFilter.className = nodisplay;
                classFilter.className = nodisplay;
                break;
        

        }
    }

    updateNgaNosFilters() {
        var querySelected = document.getElementById("nganos-query-type").value;
        var subregionSelect = document.getElementById("subregion-div");
        var regionSelect = document.getElementById("region-div");
        var geoAreaSelect = document.getElementById("geoarea-div");
        var specificChartInput = document.getElementById("specific-charts-div");
        document.getElementById("latLong").className="display p0";
        var displayWithAttributes = "display col-sm-4";
        var display = "col-sm-10";
        var nodisplay = "no-display";

        
        switch (querySelected) {
            case "subregion":
                subregionSelect.className = displayWithAttributes;
                regionSelect.className = nodisplay;
                geoAreaSelect.className = nodisplay;
                specificChartInput.className = nodisplay;
                break;

            case "region":
                subregionSelect.className = nodisplay;
                regionSelect.className = displayWithAttributes;
                geoAreaSelect.className = nodisplay;
                specificChartInput.className = nodisplay;
                break;

            case "geoarea":
                subregionSelect.className = nodisplay;
                regionSelect.className = nodisplay;
                geoAreaSelect.className = "col-sm-12";
                specificChartInput.className = nodisplay;
                break;

            case "specific-charts":
                subregionSelect.className = nodisplay;
                regionSelect.className = nodisplay;
                geoAreaSelect.className = nodisplay;
                specificChartInput.className = display;
                break;

            default:
                subregionSelect.className = nodisplay;
                regionSelect.className = nodisplay;
                geoAreaSelect.className = nodisplay;
                specificChartInput.className = display;
                break;
        }
    }
    async updateTodFilters() {
        var todRetrieveSelected = document.getElementById("tod-retrieve").value;
        var numberSelect = document.getElementById("tod-number-cell");
        var levelSelect = document.getElementById("tod-level-cell");

        var display = "col-sm-3";
        var nodisplay = "no-display";

        switch (todRetrieveSelected) {
            case "all":
                numberSelect.className = nodisplay;
                levelSelect.className = nodisplay;
                break;

            case "num":
                numberSelect.className = display;
                levelSelect.className = nodisplay;
                break;

            case "lvl":
                numberSelect.className = nodisplay;
                levelSelect.className = display;
                break;

            case "num-lvl":
                numberSelect.className = display;
                levelSelect.className = display;
                break;

            default:
                numberSelect.className = nodisplay;
                levelSelect.className = nodisplay;
                break;
        }

    }
    updateClassFilters() {
        var querySelected = document.getElementById("class-query-type").value;
        var chartTypeSelect = document.getElementById("charttype-div");
        var geoAreaSelect = document.getElementById("cls-geoarea-div");
        var specificChartInput = document.getElementById("cls-specific-charts-div");
        document.getElementById("cls-geoarea-div").querySelector("#latLong").className="display p0";
        var displayWithAttributes = "display col-sm-8";
        var display = "col-sm-10";
        var nodisplay = "no-display";

        
        switch (querySelected) {
            case "chartType":
                chartTypeSelect.className = displayWithAttributes;
                geoAreaSelect.className = nodisplay;
                specificChartInput.className = nodisplay;
                break;

            case "geoarea":
                chartTypeSelect.className = nodisplay;
                geoAreaSelect.className = "col-sm-12";
                specificChartInput.className = nodisplay;
                break;

            case "specific-charts":
                chartTypeSelect.className = nodisplay;
                geoAreaSelect.className = nodisplay;
                specificChartInput.className = display;
                break;

            default:
                chartTypeSelect.className = nodisplay;
                geoAreaSelect.className = nodisplay;
                specificChartInput.className = display;
                break;
        }
    }
    async updatePubs() {
        var pubSelected = document.getElementById("pub-select").value;
        var singleSelect = document.getElementById("volume-select-single");
        var singleSelectDiv = document.getElementById("volume-single-div");
        var multiSelectDiv = document.getElementById("volume-multi-div");
        
        var display = "display";
        var nodisplay = "no-display";

        if(pubSelected === "single"){
            singleSelectDiv.className = nodisplay;
            multiSelectDiv.className = display;
        }
        else
        {
                //Returns volumes for the selected pub type
                var url = "publications/products/publications/volumes?publicationTypeId=";
                const volumes = await msiFetch.fetchData(url + pubSelected);

                //Clear the volumes currently in dropdown
                if (singleSelect.options.length > 0) {
                    while (singleSelect.options.length)
                    singleSelect.remove(0);
                }
                
                //Adds each volume to the now blank dropdown
                for (var i = 0; i < volumes.length; i++) {
                    var opt = volumes[i];
                    var el= document.createElement("option");
                    el.textContent = opt['volumeName'];
                    el.value = opt['volumeId'];
                    singleSelect.appendChild(el);
                }

                singleSelectDiv.className = display;
                multiSelectDiv.className = nodisplay;
            
            }
    }
    getProdCatPubs(){
        // variables include the select boxes & input fields
        var pubSelected = document.getElementById("pub-select").value;
        var output = document.getElementById("output-select-pubs").value
        //build the api url to fetch with the prod-cat filter
        var url = "publications/products/publications";

        if(pubSelected === "single"){
        var multiSelected = this.state.selectedOptions.map(option => option.value);
            url += "?publicationTypeId=" + multiSelected + "&output=" + output;
        }
        else {
        var singleSelected = document.getElementById("volume-select-single").value;
            url += "?publicationTypeId=" + pubSelected + "&volumeId=" + singleSelected + "&output=" + output;
        }

        // open the query results window. send the api url as Request Params
        // so that we don't have to send data. Actual fetch will be done by QueryResults window
        window.open('/queryResults?'+url, '_blank');
    }
    getCharts(){
        // variables include the select boxes & input fields
        var chartSelected = document.getElementById("chart-select").value;
        //build the api url to fetch with the prod-cat filter
        var url = "publications/products/";
        switch (chartSelected) {
            case "nganos":
                url = this.prepareNgaNosUrl(url);
                break;
            case "dnc":
                url = this.prepareDncUrl(url);
                break;
            case "tod":
                url = this.prepareTodUrl(url);
                break;
            case "class":
                url = this.prepareClassUrl(url);
                break;
            default:
                break;
        }
        // open the query results window. send the api url as Request Params
        // so that we don't have to send data. Actual fetch will be done by QueryResults window
        window.open('/queryResults?'+url, '_blank');
    }
    prepareDncUrl(url){
        var regionSelected = document.getElementById("dnc-region").value;
        var output = document.getElementById("output-select-charts").value
        if (regionSelected === "all"){
            url += "dnc?output=" + output;
        }
        else{
            url += "dnc?region=" + regionSelected + "&output=" + output;
        }
        return url; 
    }
    prepareTodUrl(url){
        var todRetrieveSelected = document.getElementById("tod-retrieve").value;
        var output = document.getElementById("output-select-charts").value
        var numberSelected;
        var levelSelected;
        switch (todRetrieveSelected) {
            case "num":
                numberSelected = document.getElementById("tod-number").value;
                url += "tod?number=" + numberSelected + "&output=" + output;
                break;
            case "lvl":
                levelSelected = document.getElementById("tod-level").value;
                url += "tod?level=" + levelSelected + "&output=" + output;
                break;
            case "num-lvl":
                numberSelected = document.getElementById("tod-number").value;
                levelSelected = document.getElementById("tod-level").value;
                url += "tod?number=" + numberSelected + "&level=" + levelSelected + "&output=" + output;
                break;
            case "all":
            default:
                url += "tod?output=" + output;
                break;
        }
        return url;
    }
    prepareNgaNosUrl(url){
        var querySelected = document.getElementById("nganos-query-type").value;
        var scaleSelected = document.getElementById("filter-select").value;
        var output = document.getElementById("output-select-charts").value;

        url += "nganos?";

        switch (querySelected) {
            case "subregion":
                var subregionSelected = document.getElementById("nganos-subregion").value;
                url += "subregion=" + subregionSelected;
                break;
            case "region":
                var regionSelected = document.getElementById("nganos-region").value;
                url += "region=" + regionSelected;
                break;
            case "geoarea":
                var leftLatDegree = document.getElementById("geoarea-div").querySelector("#leftLatDegree").value;
                var leftLatMinute = document.getElementById("geoarea-div").querySelector("#leftLatMinute").value;
                var leftLatDirection = document.getElementById("geoarea-div").querySelector("#leftLatDirection").value;
                var rightLatDegree = document.getElementById("geoarea-div").querySelector("#rightLatDegree").value;
                var rightLatMinute = document.getElementById("geoarea-div").querySelector("#rightLatMinute").value;
                var rightLatDirection = document.getElementById("geoarea-div").querySelector("#rightLatDirection").value;
                var leftLonDegree = document.getElementById("geoarea-div").querySelector("#leftLonDegree").value;
                var leftLonMinute = document.getElementById("geoarea-div").querySelector("#leftLonMinute").value;
                var leftLonDirection = document.getElementById("geoarea-div").querySelector("#leftLonDirection").value;
                var rightLonDegree = document.getElementById("geoarea-div").querySelector("#rightLonDegree").value;
                var rightLonMinute = document.getElementById("geoarea-div").querySelector("#rightLonMinute").value;
                var rightLonDirection = document.getElementById("geoarea-div").querySelector("#rightLonDirection").value;
                var lowerCoords = LatLongConv.convertToDec(leftLatDegree, leftLatMinute, 0, leftLatDirection, leftLonDegree, leftLonMinute, 0, leftLonDirection);
                var upperCoords = LatLongConv.convertToDec(rightLatDegree, rightLatMinute, 0, rightLatDirection, rightLonDegree, rightLonMinute, 0, rightLonDirection);

                url+= "latitudeMin=" + lowerCoords["latitude"] + "&longitudeMin=" + lowerCoords["longitude"] 
                        + "&latitudeMax=" + upperCoords["latitude"] + "&longitudeMax=" + upperCoords["longitude"];
                break;
            case "specific-charts":
                var specificCharts = document.getElementById("specific-charts").value;
                url += "chartNumber=" + specificCharts;
                break;
            default:
                break;
        }
        //add inset if exists
        if(scaleSelected !== "none"){
            url += "&insetScale=" + scaleSelected;
        }
        //add output field
        url+= "&output=" + output;
        return url;
    }
    
    prepareClassUrl(url){
        var querySelected = document.getElementById("class-query-type").value;
        var scaleSelected = document.getElementById("filter-select").value;
        var output = document.getElementById("output-select-charts").value;

        url += "nganos?";

        switch (querySelected) {
            case "chartType":
                var chartType = document.getElementById("selChartType").value;
                url += "chartType=" + chartType;
                break;
            case "geoarea":
                var leftLatDegree = document.getElementById("cls-geoarea-div").querySelector("#leftLatDegree").value;
                var leftLatMinute = document.getElementById("cls-geoarea-div").querySelector("#leftLatMinute").value;
                var leftLatDirection = document.getElementById("cls-geoarea-div").querySelector("#leftLatDirection").value;
                var rightLatDegree = document.getElementById("cls-geoarea-div").querySelector("#rightLatDegree").value;
                var rightLatMinute = document.getElementById("cls-geoarea-div").querySelector("#rightLatMinute").value;
                var rightLatDirection = document.getElementById("cls-geoarea-div").querySelector("#rightLatDirection").value;
                var leftLonDegree = document.getElementById("cls-geoarea-div").querySelector("#leftLonDegree").value;
                var leftLonMinute = document.getElementById("cls-geoarea-div").querySelector("#leftLonMinute").value;
                var leftLonDirection = document.getElementById("cls-geoarea-div").querySelector("#leftLonDirection").value;
                var rightLonDegree = document.getElementById("cls-geoarea-div").querySelector("#rightLonDegree").value;
                var rightLonMinute = document.getElementById("cls-geoarea-div").querySelector("#rightLonMinute").value;
                var rightLonDirection = document.getElementById("cls-geoarea-div").querySelector("#rightLonDirection").value;
                var lowerCoords = LatLongConv.convertToDec(leftLatDegree, leftLatMinute, 0, leftLatDirection, leftLonDegree, leftLonMinute, 0, leftLonDirection);
                var upperCoords = LatLongConv.convertToDec(rightLatDegree, rightLatMinute, 0, rightLatDirection, rightLonDegree, rightLonMinute, 0, rightLonDirection);

                url+= "latitudeMin=" + lowerCoords["latitude"] + "&longitudeMin=" + lowerCoords["longitude"] 
                        + "&latitudeMax=" + upperCoords["latitude"] + "&longitudeMax=" + upperCoords["longitude"];
                break;
            case "specific-charts":
                var specificCharts = document.getElementById("cls-specific-charts").value;
                url += "chartNumber=" + specificCharts;
                break;
            default:
                break;
        }
        //add inset if exists
        if(scaleSelected !== "none"){
            url += "&insetScale=" + scaleSelected;
        }
        //add output field
        url+= "&chartClassification=classified&output=" + output;
        return url;
    }

    openQueryDirections(type){
        let queryType = "";
        if(type === "charts"){
            queryType = document.getElementById("chart-select").value;
        } else if(type === "pubs"){
            queryType = "prodCatPubs";
        }
        //open the window in a specific width/height
        window.open('/queryDirections?queryType='+queryType, '_blank', 'toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600');
    }

    render() {
        return (
            <div>
                <div align="center" id="product-buttons">
                    <table width="50%">
                        <tbody>
                            <tr>
                                <td>
                                    <a className="btn vol-btn quarter-width float-left" title="Charts" id="btnCharts" onClick={this.showCharts.bind(this)}>Charts</a>
                                    <a className="btn vol-btn quarter-width float-right" title="Publications" id="btnPublications" onClick={this.showPubs.bind(this)}>Publications</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="no-display" id="charts-panel">
                    <div className="left-align col-sm-12 mb10">
                        <h5>Product Catalog - Charts Query</h5>
                        <div className="col-sm-6 col-sm-offset-3">
                            <select className="form-control" id="chart-select" onChange={this.updateCharts.bind(this)}>
                                <option value="nganos">NGA/NOS Charts</option>
				{/*<option value="dnc">Digital Nautical Charts (DNC®)</option>
                                <option value="tod">Tactical Ocean Data (TOD™)</option>*/}
                                {this.cntmEnabled ? <option value="class">Classified Charts</option> : null }
                            </select>
                        </div>
                        <div className="col-sm-3 query-dir">
                            <a onClick={this.openQueryDirections.bind(this, "charts")}>Query Directions</a>
                        </div>
                    </div>
                    <div id="nganos-filter">
                        <div id="nganos-table" className="left-align">
                            <div id="nganos-query-type-cell" className="col-sm-4">
                                <label htmlFor="nganos-query-type" className="query-label">Query for Charts</label>
                                <select defaultValue="specificCharts" className="form-control" id="nganos-query-type" onChange={this.updateNgaNosFilters.bind(this)}>
                                    <option value="specific-charts">Specific Charts</option>
                                    <option value="subregion">within Subregion</option>
                                    <option value="region">within Region</option>
                                    <option value="geoarea">within Geographic Area</option>
                                </select>
                            </div>
                            <div id="nganos-filter-cell" className="col-sm-8">
                                <div className="display col-sm-10" id="specific-charts-div">
                                    <label className="query-label">Enter Comma Separated Chart Numbers</label>
                                    <textarea className="form-control" id="specific-charts" />
                                </div>
                                <div className="no-display" id="subregion-div">
                                    <label className="query-label">Subregion</label>
                                    <select className="form-control" id="nganos-subregion">
                                    </select>
                                </div>
                                <div className="no-display" id="region-div">
                                    <label className="query-label">Region</label>
                                    <select className="form-control" id="nganos-region">
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>World and Military Charts</option>
                                    </select>
                                </div>
                                <div className="no-display" id="geoarea-div">
                                    {lightsUtil.latLong()} 
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <label className="query-label">Scale Filter</label>
                                <select width="50%" defaultValue="" className="form-control" id="filter-select">
                                    <option value="">No Filter</option>
                                    <option value="25000">&lt;= 25,000</option>
                                    <option value="50000">&lt;= 50,000</option>
                                    <option value="100000">&lt;= 100,000</option>
                                    <option value="250000">&lt;= 250,000</option>
                                    <option value="500000">&lt;= 500,000</option>
                                    <option value="1000000">&lt;= 1,000,000</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div id="dnc-filter">
                        <div className="left-align">
                            <div className="col-sm-4">
                                <label className="query-label">DNC Region</label>
                                <select className="form-control" id="dnc-region">
                                </select>
                            </div>    
                        </div>
                    </div>
                    <div id="tod-filter">
                        <div className="left-align" id="tod-table">
                            <div className="col-sm-4">
                                <label className="query-label">Retrieve:</label>
                                <select defaultValue="all" className="form-control" id="tod-retrieve" onChange={this.updateTodFilters.bind(this)}>
                                    <option value="all">All</option>
                                    <option value="num">by Number</option>
                                    <option value="lvl">by Level</option>
                                    <option value="num-lvl">by Number and Level</option>
                                </select>
                            </div>
                            <div className="no-display" id="tod-number-cell">
                                <label className="query-label">Number:</label>
                                <select className="form-control" id="tod-number">
                                </select>
                            </div>
                            <div className="no-display" id="tod-level-cell">
                                <label className="query-label">Level:</label>
                                <select className="form-control" id="tod-level">
                                </select>
                            </div>
                        </div>
                    </div>
                    <div id="class-filter">
                        <div id="class-table" className="left-align">
                            <div id="class-query-type-cell" className="col-sm-4">
                                <label htmlFor="class-query-type" className="query-label">Query for Charts</label>
                                <select defaultValue="specificCharts" className="form-control" id="class-query-type" onChange={this.updateClassFilters.bind(this)}>
                                    <option value="specific-charts">Specific Charts</option>
                                    <option value="chartType">by Chart Type</option>
                                    <option value="geoarea">within Geographic Area</option>
                                </select>
                            </div>
                            <div id="class-filter-cell" className="col-sm-8">
                                <div className="display col-sm-10" id="cls-specific-charts-div">
                                    <label className="query-label">Enter Comma Separated Chart Numbers</label>
                                    <textarea className="form-control" id="cls-specific-charts" />
                                </div>
                                <div className="no-display" id="charttype-div">
                                    <label className="query-label">Chart Type</label>
                                    <select className="form-control" id="selChartType">
                                        {/*<option>Bottom Contour</option>*/}
                                        <option>Combat/Littoral</option>
                                        <option>Standard Nautical</option>
		    			{/*<option>Bathymetric Navigation Planning</option>
                                        <option>Hull Integrity Test Site</option>
                                        <option>Bathymetric Navigation</option>*/}
                                    </select>
                                </div>
                                <div className="no-display" id="cls-geoarea-div">
                                    {lightsUtil.latLong()} 
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <label className="query-label">Scale Filter</label>
                                <select width="50%" defaultValue="none" className="form-control" id="filter-select">
                                    <option value="none">No Filter</option>
                                    <option value="25000">&lt;= 25,000</option>
                                    <option value="50000">&lt;= 50,000</option>
                                    <option value="100000">&lt;= 100,000</option>
                                    <option value="250000">&lt;= 250,000</option>
                                    <option value="500000">&lt;= 500,000</option>
                                    <option value="1000000">&lt;= 1,000,000</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12" style={{ padding: "0" }}>
                        <div className="col-sm-4 mb10 left-align">
                            <label className="query-label">Output Format</label>
                            <select width="50%" defaultValue="html" className="form-control" id="output-select-charts">
                                <option value="html">HTML</option>
                                <option value="xml">XML</option>
                                <option value="csv">Tab Delimited (CSV)</option>
                            </select>
                        </div>
                        <div className="col-sm-12 left-align">
                            <a className="btn vol-btn" title="Search" id="btnApplyQuery" onClick={this.getCharts.bind(this)}>Search</a>
                        </div>
                    </div>
                </div>
                <div className="no-display" id="pubs-panel">
                    <div className="left-align col-sm-12 mb10">
                        <h5>Product Catalog - Publications Query</h5>
                        <div className="col-sm-6 col-sm-offset-3">
                            <select className="form-control" id="pub-select" onChange={this.updatePubs.bind(this)}>
				{/*<option value="35">Almanacs</option>
                                <option value="30">Atlas of Pilot Charts</option>
                                <option value="40">Black Sea Pilot</option>
                                <option value="25">Coast Pilot</option>
                                <option value="6">Fleet Guides</option>*/}
                                <option value="9">NGA List of Lights</option>
				{/*<option value="34">NOS Chart Catalog</option>
                                <option value="21,22">Sailing Directions</option>*/}
                                <option value="15">Summary of Corrections</option>
				{/*<option value="13,14">Sight Reduction Tables</option>
                                <option value="27">Tidal Current Tables</option>
                                <option value="26">Tide Tables</option>
                                <option value="16">USCG Light List</option>
                                <option value="single">Single Volume Publications</option>*/}
                            </select>
                        </div>
                        <div className="col-sm-3 query-dir">
                            <a onClick={this.openQueryDirections.bind(this, "pubs")}>Query Directions</a>
                        </div>
                    </div>
                    <div className="left-align col-sm-12">
                        <div className="col-md-8">
                            <label htmlFor="sel5" className="query-label">Select Volume(s)</label>
                            <div id="volume-multi-div" className="no-display">
                            <Select id="volume-select-multi" isMulti="true" closeMenuOnSelect={true} value={this.state.selectedOptions} onChange={this.handleChange} options={options} />
                            </div>
                            <div id="volume-single-div" className="display">
                                <select id="volume-select-single" className="form-control">
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-12" style={{ padding: "0" }}>
                            <div className="col-sm-4 mb10 left-align">
                                <label className="query-label">Output Format</label>
                                <select width="50%" defaultValue="html" className="form-control" id="output-select-pubs">
                                    <option value="html">HTML</option>
                                    <option value="xml">XML</option>
                                    <option value="csv">Tab Delimited (CSV)</option>
                                </select>
                            </div>
                            <div className="col-sm-12 left-align">
                                <a className="btn vol-btn" title="Search" id="btnApplyQuery" onClick={this.getProdCatPubs.bind(this)}>Search</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ProdCatQueryPanel;
