import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import QueryPanel from '../../QueryPanel';
import PageContent from '../../PageContent';


class WPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            network: props.network
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.network !== this.props.network){
            this.setState({network: this.props.network});
        }
    }

    render(){
        return(
            <div id="wpi">
                <div id="wpi-descrip">
                    <PageContent divid="wpi-descrip" />
                </div>
                <br />
                <div id="wpi-descrip-ext">
                  {this.state.network === "unclassified" ? <PageContent divid="www-wpi-descrip-ext" />
                    : <PageContent divid="wpi-descrip-ext" /> }
                </div>
                <br />
                <h5>Search Online Database</h5>
                <div id="pub-query">
                    <QueryPanel category="WPI" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="WPI" pubTypeId={this.props.pubTypeId} />
                </div>
                <br />
            </div>
        );
    }
}

export default WPI;
