import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import QueryPanel from '../../QueryPanel';
import PageContent from '../../PageContent';

class NGALOL extends Component {
    render(){
        return(
            <div id="ngalol">
                <div id="ngalol-descrip">
                    <PageContent divid="ngalol-descrip" />
                </div>
                <br /><br /><br />
                <div id="pub-download">
                    <PubDownload category="NGALOL" pubTypeId={this.props.pubTypeId} />
                </div>
                <h5>Search Online Database</h5>
                <div id="pub-query">
                    <QueryPanel category="NGALOL" />
                </div>
            </div>
        );
    }
}

export default NGALOL;