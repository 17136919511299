import React, {Component} from 'react';
const msiFetch = require('../../controllers/MsiFetch');
const lightUtils = require('../Publications/LightsUtils');
const LatLongConv = require('../../utils/LatLongConv');


class MODUQueryPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            subregions: []
        }
        this.clearFilters = this.clearFilters.bind(this);
        this.showFilterOptions = this.showFilterOptions.bind(this);
        this.showFilterDates = this.showFilterDates.bind(this);
        this.getMODU = this.getMODU.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    async componentDidMount(){
        //api call to get the nav areas and subregions
        const url = "publications/modu/areas"
        await msiFetch.fetchData(url)
        .then(data => {
            this.setState({subregions: data["subregions"]});
        })
        .catch(error => {
            this.setState({subregions: []});
        });
    }

    showFilterOptions(){
        const filterType = document.getElementById("selFilter").value;
        const label = document.getElementById("filterLabel");
        const name = document.getElementById("name");
        const navArea = document.getElementById("selNavArea");
        const navRegion = document.getElementById("selNavRegion");
        const subreg = document.getElementById("selSubregion");
        const latLong = document.getElementById("latLong");
        const latLongDiv = document.getElementById("latLongDiv");

        const displaySel = "form-control";
        const noDisplay = "no-display";

        switch(filterType) {
            case "name":
                label.innerHTML = "NAME";
                name.className = displaySel;
                navArea.className = noDisplay;
                navRegion.className = noDisplay;
                subreg.className = noDisplay;
                latLong.className = noDisplay;
                latLongDiv.className = noDisplay;
                break;
            case "navArea":
                label.innerHTML = "SELECT";
                name.className = noDisplay;
                navArea.className = displaySel;
                navRegion.className = noDisplay;
                subreg.className = noDisplay;
                latLong.className = noDisplay;
                latLongDiv.className = noDisplay;
                break;
            case "navRegion":
                label.innerHTML = "SELECT &nbsp;&nbsp;&nbsp;<a target='_blank' href='/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=view'>(View Geographic Locator)</a>";
                name.className = noDisplay;
                navArea.className = noDisplay;
                navRegion.className = displaySel;
                subreg.className = noDisplay;
                latLong.className = noDisplay;
                latLongDiv.className = noDisplay;
                break;
            case "subreg":
                label.innerHTML = "SELECT &nbsp;&nbsp;&nbsp;<a target='_blank' href='/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=view'>(View Geographic Locator)</a>";
                name.className = noDisplay;
                navArea.className = noDisplay;
                navRegion.className = noDisplay;
                subreg.className = displaySel;
                latLong.className = noDisplay;
                latLongDiv.className = noDisplay;
                break;
            case "geoArea":
                label.innerHTML = "Enter Corner Coordinates of Search Area";
                name.className = noDisplay;
                navArea.className = noDisplay;
                navRegion.className = noDisplay;
                subreg.className = noDisplay;
                latLong.className = "col-sm-8";
                latLongDiv.className = "";
                break;
            default: //display nothing
                label.innerHTML = "";
                name.className = noDisplay;
                navArea.className = noDisplay;
                navRegion.className = noDisplay;
                subreg.className = noDisplay;
                latLong.className = noDisplay;
                latLongDiv.className = noDisplay;
                break;
        }
    }

    showFilterDates(){
        const startDate = document.getElementById("selector-col5");
        const endDate = document.getElementById("selector-col6");
        const year = document.getElementById("selector-col10");
        const filterType = document.getElementById("sel5").value;
        
        const display = "col-sm-3";
        const noDisplay = "no-display";

        switch(filterType){
            case "date":
                startDate.className = display;
                endDate.className = noDisplay;
                year.className = noDisplay;
                break;
            case "dateRange":
                startDate.className = display;
                endDate.className = display;
                year.className = noDisplay;
                break;
            case "year":
                startDate.className = noDisplay;
                endDate.className = noDisplay;
                year.className = display;
                break
            default:
                startDate.className = noDisplay;
                endDate.className = noDisplay;
                year.className = noDisplay;
                break;
        }
    }

    validateFields(){
        var dateType = document.getElementById("sel5").value;
        var alertMessage = "";

        //check dates
        if(dateType === "date"){
            if(document.getElementById("startDate").value === null || document.getElementById("startDate").value === ""){
                alertMessage += "You must enter/select a valid date."
            }
        }
        if(dateType === "dateRange"){
            if(document.getElementById("startDate").value === null || document.getElementById("startDate").value === ""){
                alertMessage += "You must enter/select a valid starting date.\n"
            }
            if(document.getElementById("endDate").value === null || document.getElementById("endDate").value === ""){
                alertMessage += "You must enter/select a valid ending date.\n"
            }
        }
        if(dateType === "year"){
            if(document.getElementById("year").value === null || document.getElementById("year").value === ""){
                alertMessage += "You must enter a valid year.\n"
            }
            else if(document.getElementById("year").value < 1000){
                alertMessage += "You must enter a valid 4 digit year.\n"
            }
        }

        //check geographic area
        const filterType = document.getElementById("selFilter").value;
        if(filterType === "geoArea"){
            alertMessage += lightUtils.validateFields();
        }

        if (alertMessage !== ""){
            window.alert(alertMessage);
        }
        else{
            //if everything is valid, move on to query
            this.getMODU();
        }
    }

    getMODU(){
        //query builder
        const filterType = document.getElementById("selFilter").value;
        const dateType = document.getElementById("sel5").value;
        const name = document.getElementById("name").value;
        const navArea = document.getElementById("selNavArea").value;
        const navRegion = document.getElementById("selNavRegion").value;
        const subreg = document.getElementById("selSubregion").value;
        const startDate = document.getElementById("startDate").value;
        const endDate = document.getElementById("endDate").value;
        const year = document.getElementById("year").value;
        const status = document.getElementById("selStatus").value;
        var url = "publications/modu?";

        //add the initial filter type to the url
        switch(filterType){
            case "name":
                url += "name=" + name;
                break;
            case "navArea":
                url += "navArea=" + navArea;
                break;
            case "navRegion":
                url += "region=" + navRegion;
                break;
            case "subreg":
                url += "subreg=" + subreg;
                break;
            case "geoArea":
                let leftLatDegree, leftLatMin, leftLatDir;
                let leftLonDegree, leftLonMin, leftLonDir;
                let rightLatDegree, rightLatMin, rightLatDir;
                let rightLonDegree, rightLonMin, rightLonDir;
                let leftCoords, rightCoords;

                leftLatDegree = parseFloat(document.getElementById("leftLatDegree").value);
                leftLatMin = parseFloat(document.getElementById("leftLatMinute").value);
                leftLatDir = document.getElementById("leftLatDirection").value;
                leftLonDegree = parseFloat(document.getElementById("leftLonDegree").value);
                leftLonMin = parseFloat(document.getElementById("leftLonMinute").value);
                leftLonDir = document.getElementById("leftLonDirection").value
                rightLatDegree = parseFloat(document.getElementById("rightLatDegree").value);
                rightLatMin = parseFloat(document.getElementById("rightLatMinute").value);
                rightLatDir = document.getElementById("rightLatDirection").value;
                rightLonDegree = parseFloat(document.getElementById("rightLonDegree").value);
                rightLonMin = parseFloat(document.getElementById("rightLonMinute").value);
                rightLonDir = document.getElementById("rightLonDirection").value;
    
                leftCoords = LatLongConv.convertToDec(leftLatDegree, leftLatMin, 0, leftLatDir, leftLonDegree, leftLonMin, 0, leftLonDir);
                rightCoords = LatLongConv.convertToDec(rightLatDegree, rightLatMin, 0, rightLatDir, rightLonDegree, rightLonMin, 0, rightLonDir);
                url = url + 'latitudeLeft=' + leftCoords['latitude'] + '&longitudeLeft=' + leftCoords['longitude'] + '&latitudeRight=' + rightCoords['latitude'] + '&longitudeRight=' + rightCoords['longitude'];
                break;
            default:
                url += "filter=none";
                break;
        }

        //add the date filter to the url
        switch(dateType){
            case "date":
                url += "&sourceDate=" + startDate;
                break;
            case "dateRange":
                url += "&minSourceDate=" + startDate + "&maxSourceDate=" + endDate;
                break;
            case "year": 
                url += "&year=" + year;
                break
            default:
                break;
        }

        //add status to the url
        if(status !== "null"){
            url += "&status=" + status;
        }

        //add output to the url
            url += "&output=" + document.getElementById("selOutput").value;
        
        // open the query results window. send the api url as Request Params
        // so that we don't have to send data. Actual fetch will be done by QueryResults window
        window.open('/queryResults?'+url, '_blank');
    }

    clearFilters(){
        //all the elements that need to be set to empty
        //TODO Add lat,long inputs
        const elementList = ["name", "startDate", "endDate", "year"];

        //set selection boxes to null
        document.getElementById("selFilter").value = "null";
        document.getElementById("sel5").value = "null";
        document.getElementById("selStatus").value = "null";

        //empty out that elementlist
        for(var i=0; i<elementList.length;i++){
            document.getElementById(elementList[i]).value = "";
        }

        //hide all the filtering boxes
        this.showFilterDates();
        this.showFilterOptions();

    }

    openQueryDirections(){
        //open the window in a specific width/height
        window.open('/queryDirections?queryType=modu', '_blank', 'toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600');
    }

    render(){
        return([
            <div className="row" key={1}>
                <div className="col-sm-12 query-dir left-align">
                    <a onClick={this.openQueryDirections.bind(this)}>Query Directions</a>
                </div>
                <div className="col-sm-4" id="selector-col1" align="left">
                    <label htmlFor="selFilter" className="query-label">Retrieve</label>
                    <select className="form-control" id="selFilter" defaultValue="null" onChange={this.showFilterOptions}>
                        <option value="null">All</option>
                        <option value="name">by Name</option>
                        <option value="navArea">by Nav Area</option>
                        <option value="navRegion">by Region</option>
                        <option value="subreg">by Subregion</option>
                        <option value="geoArea">by Geographic Area</option>
                    </select>
                </div>
                <div className="col-sm-3" id="selector-col2" align="left">
                    <label htmlFor="name" className="query-label" id="filterLabel"></label><br/>
                    <input className="no-display" id="name" /> 
                    <select className="no-display" id="selNavArea">
                        <option value="NAVAREA IV">NAVAREA IV</option>
                        <option value="NAVAREA XII">NAVAREA XII</option>
                        <option value="HYDROLANT">HYDROLANT</option>
                        <option value="HYDROPAC">HYDROPAC</option>
                        <option value="HYDROARC">HYDROARC</option>
                    </select>
                    <select className="no-display" id="selNavRegion">
                        <option value={1}>Region 1</option>
                        <option value={2}>Region 2</option>
                        <option value={3}>Region 3</option>
                        <option value={4}>Region 4</option>
                        <option value={5}>Region 5</option>
                        <option value={6}>Region 6</option>
                        <option value={7}>Region 7</option>
                        <option value={8}>Region 8</option>
                        <option value={9}>Region 9</option>
                    </select>
                    <select className="no-display" id="selSubregion">
                        {this.state.subregions && this.state.subregions.length > 0 ? this.state.subregions.map(subreg => <option key={subreg} value={subreg}>{subreg}</option>) : null}
                    </select>
                </div>
                <div id="latLongDiv" className="no-display">
                    {lightUtils.latLong()}
                </div>
            </div>,
            <div className="row" key={2}>
                <div className="col-sm-4" id="selector-col4" align="left">
                    <label htmlFor="selDateFilter" className="query-label">Date Filter</label>
                    <select defaultValue='null' className="form-control" id="sel5" onChange={this.showFilterDates}>
                        <option value='null'>None</option>
                        <option value="year">Specific Year</option>
                        <option value="date">Specific Date</option>
                        <option value="dateRange">Specific Date Range</option>
                    </select>
                </div>
                <div className="col-sm-3 no-display" id="selector-col5" align="left">
                    <label htmlFor="startDate" className="query-label" id="startDateLbl">Start Date</label>
                    <input className="form-control" id="startDate" type="date" placeholder="YYYY-MM-DD" />
                </div>
                <div className="col-sm-3 no-display" id="selector-col6" align="left">
                    <label htmlFor="endDate" className="query-label" id="endDateLbl">Ending Date</label>
                    <input className="form-control" id="endDate" type="date" placeholder="YYYY-MM-DD" />
                </div>
                <div className="col-sm-3 no-display" id="selector-col10" align="left">
                    <label htmlFor="year" className="query-label" id="yearLbl">Year</label>
                    <input className="form-control" id="year" type="number" maxLength={4} />
                </div>
            </div>,
            <div className="row" key={3}>
                <div className="col-sm-4" id="selector-col7" align="left">
                    <label htmlFor="selStatus" className="query-label">Status</label>
                    <select defaultValue={1} className="form-control" id="selStatus">
                        <option value="null">All</option>
                        <option value={1}>Active</option>
                        <option value={2}>Inactive</option>
                    </select>
                </div>
                <div className="col-sm-3" id="selector-col8" align="left">
                    <label htmlFor="selOutput" className="query-label">Output Format</label>
                    <select defaultValue="html" className="form-control" id="selOutput">
                        <option value="html">HTML</option>
                        <option value="xml">XML</option>
                        <option value="csv">CSV (Delimited text)</option>
                    </select>
                </div>
                <div className="col-sm-6 pb32" id="selector-col-9" align="left">
                    <div className="filter-buttons">
                        <a className="btn vol-btn float-left" title="Search" id="btnApplyQuery" onClick={this.validateFields}>Search</a>
                        <a className="btn vol-btn float-left" title="Clear Filters" id="btnClearQuery" onClick={this.clearFilters}>Clear</a>
                    </div>
                </div>
            </div>
        ]);
    }
}

export default MODUQueryPanel;