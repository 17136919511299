import React from 'react';

const LgModal = ({ handleClose, show, children, title }) => {
    const showHideClassName = show ? "modal display" : "modal";

    return (
        <div id="modal" className={showHideClassName} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <a className="btn vol-btn m0" onClick={handleClose}>Close</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {LgModal};