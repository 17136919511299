import React, {Component} from 'react';
import RNAQueryPanel from './Publications/RNA/RNAQueryPanel';
import BroadcastQueryPanel from './BroadcastWarnings/BroadcastQueryPanel';
//import USCGLLQueryPanel from './Publications/USCGLL/USCGLLQueryPanel';
import WPIQueryPanel from './Publications/WPI/WPIQueryPanel';
import NGALOLQueryPanel from './Publications/NGALOL/NGALOLQueryPanel';
import NTMQueryPanel from './NoticeToMariners/NTMQueryPanel';
import ASAMQueryPanel from './Piracy/ASAMQueryPanel';
import MODUQueryPanel from './MODU/MODUQueryPanel';
import ProdCatQueryPanel from './Products/ProdCatQueryPanel';
import SmapsQueryPanel from './BroadcastWarnings/SmapsQueryPanel';

class QueryPanel extends Component {
    showQueryPanel(){
        if(this.props.category === "Warning"){ return <BroadcastQueryPanel category="Warning"/> }
        if(this.props.category === "RNA"){ return <RNAQueryPanel category="RNA"/> }
        //if(this.props.category === "USCGLL") { return <USCGLLQueryPanel category="USCGLL"/> }
        if(this.props.category === "WPI") { return <WPIQueryPanel category="WPI"/> }
        if(this.props.category === "NGALOL") { return <NGALOLQueryPanel category="NGALOL"/> }
        if(this.props.category === "NTM") { return <NTMQueryPanel category="NTM" 
            currentUnclassNotice={this.props.currentUnclassNotice} currentClassNotice={this.props.currentClassNotice} network={this.props.network} /> }
        if(this.props.category === "ASAM") { return <ASAMQueryPanel category="ASAM" /> }
        if(this.props.category === "MODU") { return <MODUQueryPanel category="MODU" /> }
        if(this.props.category === "ProdCat") { return <ProdCatQueryPanel category="ProdCat" network={this.props.network} /> }
    
        if(this.props.category === 'Smaps') {
             console.log(`the network is ${this.props.network}`);
             if(this.props.network !== "secret" || this.props.network !== "top secret"){
                return <SmapsQueryPanel category="Smaps" />}
        }
    }

    render() {
        return (
            <section className="query-panel p15 select-overflow pb32">
                <form>
                    <div>
                        {this.showQueryPanel()}
                    </div>
                </form>
            </section>
        );
    }
}

export default QueryPanel;