import React, {Component} from 'react';
import { SmModal } from '../../utils/SmModal';
import PageContent from '../PageContent';
import Recaptcha from './Recaptcha';
import Config from '../../utils/Config';

const msiFetch = require('../../controllers/MsiFetch');
const convDate = require('../../utils/FormatDate').convertDate;

class MIReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            captchaDone: false,
            renderCaptcha: true,
            captchaToken: ''
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.selectFileHandler = this.selectFileHandler.bind(this);
        this.file = null;
    }

    async componentDidMount(){
        var conf = new Config();
        await conf.setAppConfig();
        if(conf.network !== "unclassified"){
            this.setState({captchaDone: true, renderCaptcha: false})
        };
    }

    showModal(){
        //when called, change state to show the modal
        this.setState({showModal: true});

    }

    hideModal(){
        //when called, change the state to hide the modal
        this.setState({showModal: false});
    }

    selectFileHandler(event) {
        this.file = event.target.files[0];
    }

    verifyCaptcha(response){
        this.setState({captchaDone: true, captchaToken: response});
    }

    expiredCaptcha(){
        this.setState({captchaDone: false, captchaToken: ''});
    }

    validateFields(){
        let alertMessage = "";
        const name = document.getElementById("name").value;
        const org = document.getElementById("org").value;
        const date = document.getElementById("date").value;
        const contactyes = document.getElementById("contactyes");
        const contactno = document.getElementById("contactno");
        const phone = document.getElementById("phone").value;
        const email = document.getElementById("email").value;



    
        if(name === null || name === ""){
            alertMessage += "Observer Name is required.\n";
            document.getElementById("lblName").className = "form-alert";
        } else {
            document.getElementById("lblName").className = "";
        }

        if(org === null || org === ""){
            alertMessage += "Vessel/Organization is required.\n";
            document.getElementById("lblOrg").className = "form-alert";
        } else {
            document.getElementById("lblOrg").className = "";
        }

        if(date === null || date === ""){
            alertMessage += "Date of Observation is required.\n";
            document.getElementById("lblDate").className = "form-alert";
        } else {
            document.getElementById("lblDate").className = "";
        }

        if(!contactyes.checked && !contactno.checked){
            alertMessage += "Yes or No is required.\n";
            document.getElementById("lblContact").className = "form-alert";
        } else {
            document.getElementById("lblContact").className = "";

        }
        if(contactyes.checked){
            if( (phone === null || phone === "") && (email === null || email === "") ){
                alertMessage += "Phone Number or Email is required.\n";
                document.getElementById("lblPhone").className = "form-alert";
                document.getElementById("lblEmail").className = "form-alert";
            } else {
                document.getElementById("lblPhone").className = "";
                document.getElementById("lblEmail").className = "";
            }
        }
        
        if(!this.state.captchaDone){
            alertMessage += "Please complete the Captcha Verification.";
        }
       
        //if we have a message to show the user, show it
        if (alertMessage !== ""){
            window.alert(alertMessage);
        }
        else{
            //if everything is valid, move on to store data in the database
            //send some kind of confirmation that this is been submitted
            this.sendTransaction();
            this.showModal();
            this.clearForm();
        }
    }

    async sendTransaction(){
        let formData = new FormData();
        let data = {
            "poc":  document.getElementById("name").value,
            "org": document.getElementById("org").value,
            "email": document.getElementById("email").value,
            "phone": document.getElementById("phone").value,
            "canContact": document.getElementById("contactyes").checked,
            "occurDate": convDate(document.getElementById("date").value + "T04:00:00"),
            "productAffected": document.getElementById("products").value,
            "details": document.getElementById("details").value,
            "recaptchaToken": this.state.captchaToken
        };
        formData.append('data', JSON.stringify(data));
        formData.append('file', this.file);
        const url = "reports/observ-reports";

        await msiFetch.postFormData(url, formData);
    }


    clearForm(){
        //all the elements that need to be set to empty
        let elementList = ["name", "org","email", "date","products", "phone", "details"];
        document.getElementById("contactyes").checked=false;
        document.getElementById("contactno").checked=false;


        
        //empty out that elementlist
        for(var i=0; i<elementList.length;i++){
            document.getElementById(elementList[i]).value = "";
        }

        elementList = ["lblName", "lblDate", "lblOrg", "lblEmail","lblPhone", "lblDetails"];

        //change font color back to default
        for(var j=0; j<elementList.length;j++){
            document.getElementById(elementList[j]).className = "";
        }

        //clear out attachment
        document.getElementById("file").value = null;
    }

    render() {
      return (  
        <div id="MIReport" className="left-align">
            <PageContent divid="observ-report" />
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <table className="col-sm-12 left-align">
                    <tbody>
                        <tr>
                            <td><span id="lblName">Observer*</span><br/>
                            <input type="text" id="name" className="form-control" /></td> 
                        </tr>
                        <tr>
                            <td><span id="lblOrg"> Vessel/Organization*</span><br/>
                            <input type="text" id="org" className="form-control" /></td>
                        </tr>
                        
                        <tr>
                            <td>
                                <span id="lblContact">Would you like to be contacted by the Maritime Safety Office?*</span><br/>
                                <div className="col-sm-2"><input type="radio"  name="contact" value="0" id="contactyes" />&nbsp;Yes&nbsp;&nbsp;</div>
                                <div className="col-sm-2"><input type="radio" name="contact" value="0" id="contactno" />&nbsp;No&nbsp;&nbsp; </div>
                                <div className="col-sm-2"><span id="lblPhone">Phone&nbsp;</span></div>
                                <div className="col-sm-6">
                                    <input type="text" id="phone" className="form-control" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><span id="lblEmail">Email &nbsp;</span>
                            <input type="text" id="email" className="form-control" /></td>
                        </tr>             
                        <tr>
                            <td><span id="lblDate">Date of Observation*</span><br/>
                        <input type="date" id="date" className="form-control" /> </td>
                        </tr>
                        <tr>
                            <td>Affected NGA Products <br/>
                        <textarea id="products" className="form-control" rows="4" ></textarea></td>
                        </tr>
                        <tr>
                            <td><span id="lblDetails">Details of Observation* </span><br/>
                            <textarea id="details" className="form-control" rows="4" ></textarea></td>
                        </tr>   
                        <tr>
                            <td>Indicate datum of geographic coordinates if other than WGS-84.<br/>
                            When providing sounding information, describe methods used to measure depth and indicate if soundings are corrected for draft.</td>
                        </tr>            
                        <tr>
                            <td><span><b>Attach Form  </b></span> 
                            <input type="file" id="file" className="form-control" onChange={this.selectFileHandler}  /></td>
                        </tr>
                        {this.state.renderCaptcha ? <Recaptcha
                            verifyCallback={this.verifyCaptcha.bind(this)} expiredCallback={this.expiredCaptcha.bind(this)}
                        /> : null}
                        <tr>
                            <td className="center-align"><input type="button" value="Submit" onClick={this.validateFields.bind(this)} />
                            <input type="reset" value="Clear form" onClick={this.clearForm.bind(this)} /></td>
                        </tr>
                    </tbody>
                </table>
                <SmModal show={this.state.showModal} handleClose={this.hideModal} title="Submit Confirmation">
                    Thank you, your response has been submitted!
                </SmModal>
            </div>
            <div className="col-md-3"></div>
        </div>
     );
    }
   }

export default MIReport;