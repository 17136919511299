import React, {Component} from 'react';
import { Route, Link } from 'react-router-dom'
import '../../css/publications.css';
import Overview from './Overview';
import RNA from './RNA/RNA';
import USCGLL from './USCGLL/USCGLL';
import APC from './APC/APC';
import Chart1 from './Chart1/Chart1';
import APN from './APN/APN';
import DistBetweenPorts from './DBP/DistBetweenPorts';
import ICOS from './ICOS/ICOS';
import NGALOL from './NGALOL/NGALOL';
import RNMB from './RNMB/RNMB';
import SRTAir from './SRTAir/SRTAir';
import WPI from './WPI/WPI';
import SDPG from './SDPGuides/SDPG';
import SDE from './SDEnroute/SDE';
import SRTMar from './SRTMar/SRTMar';
import EmbeddedHTML from './EmbeddedHTML';
import FleetGuides from './FleetGd/FleetGuides';
import Login from './../User/Login';
import NOAATT from './NOAATT/NOAA';
import NotFound from '../NotFound';
const pubConstants = require('../Publications/tmp/pubConstants');

const Publication = (props) => {
    const networkCheck = props.network !== "unclassified" ? true : false;

    //return our specific pub
    if (props.match.params.pub === "APN") { return <APN pubTypeId={pubConstants.pubType.APN} /> }
    if (props.match.params.pub === "APC") { return <APC pubTypeId={pubConstants.pubType.APC} /> }
    if (props.match.params.pub === "Chart1") { return <Chart1 pubTypeId={pubConstants.pubType.Chart1} /> }
    if (props.match.params.pub === "DBP") { return <DistBetweenPorts pubTypeId={pubConstants.pubType.DBP} /> }
    if (props.match.params.pub === "ICOS") { return <ICOS pubTypeId={pubConstants.pubType.ICOS} /> }
    if (props.match.params.pub === "NGALOL") { return <NGALOL pubTypeId={pubConstants.pubType.NGALOL} /> }
    if (props.match.params.pub === "RNMB") { return <RNMB pubTypeId={pubConstants.pubType.RNMB} /> }
    if (props.match.params.pub === "RNA") { return <RNA pubTypeId={pubConstants.pubType.RNA} /> }
    if (props.match.params.pub === "SDEnroute") { return <SDE pubTypeId={pubConstants.pubType.SDE} network={props.network} /> }
    if (props.match.params.pub === "SDPGuides") { return <SDPG pubTypeId={pubConstants.pubType.SDPG} /> }
    if (props.match.params.pub === "SRTAir") { return <SRTAir pubTypeId={pubConstants.pubType.SRTAir} /> }
    if (props.match.params.pub === "SRTMar") { return <SRTMar pubTypeId={pubConstants.pubType.SRTMar} /> }
    if (props.match.params.pub === "USCGLL") { return <USCGLL pubTypeId={pubConstants.pubType.USCGLL} /> }
    if (props.match.params.pub === "WPI") { return <WPI pubTypeId={pubConstants.pubType.WPI} network={props.network} /> }
    if (props.match.params.pub === "FleetGuides") {return networkCheck ? (props.loggedIn ? <FleetGuides pubTypeId={pubConstants.pubType.FleetGuides} /> : <Login /> ) : <NotFound /> }
    if (props.match.params.pub === "HTML") { return <EmbeddedHTML /> }
    if (props.match.params.pub === "NOAATT") {return networkCheck ? (props.loggedIn ? <NOAATT pubTypeId={pubConstants.pubType.NOAATT} /> : <Login /> ) : <NotFound /> }

    else return null
  }

class Publications extends Component {
    constructor(props){
        super(props)
        this.state = {
            pubList: null,
        }
    }

    networkCheck(){
        if(this.props.network !== "unclassified"){
            return true;
        } 
        return false;
    }

    navlist(passedClass){
        return(
            <ul className={passedClass}>
                <li className="text-bold">Select a Publication to View:</li><br />
                <li><Link to={`${this.props.match.url}/APN`}>American Practical Navigator </Link></li><br />
                <li><Link to={`${this.props.match.url}/APC`}>Atlas of Pilot Charts</Link></li><br />
                <li><Link to={`${this.props.match.url}/Chart1`}>Chart No. 1 </Link></li><br />
                <li><Link to={`${this.props.match.url}/DBP`}>Distances Between Ports </Link></li><br />
                {this.props.loggedIn && this.networkCheck() ? <li><Link to={`${this.props.match.url}/FleetGuides`}>Fleet Guides </Link></li> : null}{this.props.loggedIn ? <br/> : null}
                <li><Link to={`${this.props.match.url}/ICOS`}>International Code of Signals </Link></li><br />
                <li><Link to={`${this.props.match.url}/NGALOL`}>NGA List of Lights </Link></li><br />
                {this.props.loggedIn && this.networkCheck() ? <li><Link to={`${this.props.match.url}/NOAATT`}>NOAA Tide Publications </Link></li> : null}{this.props.loggedIn ? <br/> : null}
                <li><Link to={`${this.props.match.url}/RNMB`}>Radar Navigation and Maneuvering Board Manual </Link></li><br />
                <li><Link to={`${this.props.match.url}/RNA`}>Radio Navigation Aids</Link></li><br />
                <li><Link to={`${this.props.match.url}/SDEnroute`}>Sailing Directions Enroute </Link></li><br />
                <li><Link to={`${this.props.match.url}/SDPGuides`}>Sailing Directions Planning Guides </Link></li><br />
                <li><Link to={`${this.props.match.url}/SRTAir`}>Sight Reduction Tables for Air Navigation </Link></li><br />
                <li><Link to={`${this.props.match.url}/SRTMar`}>Sight Reduction Tables for Marine Navigation </Link></li><br />
                <li><Link to={`${this.props.match.url}/USCGLL`}>USCG Light List </Link></li><br />
                <li><Link to={`${this.props.match.url}/WPI`}>World Port Index</Link> </li><br />
            </ul>
        );
    }

    render(){
        return(
        <div id="Publications">
            <nav className="hidden-md hidden-lg">
                <div className="menu-toggle">
                    <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    {this.navlist("menu")}
                </div>
            </nav>
            <section>
                <div className="container">
                <h5>Nautical Publications</h5>
                    <div className="row border-between">
                        <div className="col-md-3 p24 left-align hidden-xs hidden-sm">
                            {this.navlist("none")}
                        </div>
                        <div className="col-md-9 p24 left-align">
                            <Route path={`${this.props.match.url}/:pub`} render={(props) => <Publication pubList={this.state.pubList} match={props.match} loggedIn={this.props.loggedIn} network={this.props.network} />} />
                            <Route
                                exact
                                path={this.props.match.url}
                                render={() => <Overview />}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        );
    }
}

export default Publications;