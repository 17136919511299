import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';

class DistBetweenPorts extends Component {
    render(){
        return(
            <div id="dist-between-ports">
                <div id="dbp-descrip">
                    <PageContent divid="dbp-descrip" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="DBP" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default DistBetweenPorts;