import React from 'react';
import groupBy from 'lodash/groupBy';
import '../../css/queryResults.css';
const sizeCalc = require('../../utils/FileSize');
const graphicsS3URL = "api/publications/download?key=16920957/SFH00000/";
const uscgllformatter = require('../Publications/USCGLL/USCGLLQueryResults');
const ngalolformatter = require('../Publications/NGALOL/NGALOLQueryResults');
const searchBy = require('../NoticeToMariners/SearchBy');

//table column headers for each querytype
const chartCorrHeaders = ["", "", "", "", ""];
const ntmGraphicHeaders = ["Chart Number", "NTM Number", "View/Download File"];
const chartsAffHeaders = ["Chart Number", "Edition Number", "Notice to Mariners Number"];
const hydroCorrHeaders = ["NTM", "Stock No. (National Stk No.)", "Title", "Scale = 1:", "Ed. No", "Ed. Date", "Price Category"];
const navPubCorrHeaders = ["Publication/Volume Number", "Publication Name", "Edition", "Date", "NTM"];
const pubsAffHeaders = ["Publication/Volume Name", "Edition", "NTM"];
const dgpstableHeaders = ["No", "Name & Location", "Position", "Station ID", "Range", "Frequency", "Transfer Rate", "Remarks", "Notice Number"];
const rbcntableHeaders = ["No", "Name & Location", "Position", "Characteristic", "Range", "Sequence", "Frequency", "Remarks", "Notice Number"];
const lightstableHeaders = ["No", "Name & Location", "Position", "Characteristic", "Height", "Range", "Structure", "Remarks", "Notice Number"];
const uscgllHeaders = ["No", "Name & Location", "Position", "Characteristic", "Height", "Range", "Structure", "Remarks", "Notice Number"];
const uscgllVol5Headers = ["No", "Name & Location", "Mile", "Bank", "Characteristic", "Structure/Dayboard Up&emsp;&emsp;&emsp;&emsp;&emsp;Down", "Remarks", "Notice Number"];

function getTableHeaders(queryUrl, type, addNoticeNumber){
    let hdrLabel = "";
    switch(type){
        case "chartCorr":
            hdrLabel = "Chart Corrections";
            break;
        case "ntmGraphics":
            hdrLabel = "Charlets/Depth Tabulations/Notes";
            break;
        case "chartsAff":
            hdrLabel = "Charts Affected By\nNotice to Mariners";
            break;
        case "hydroCorr":
            hdrLabel = "NGA Hydrogrophic Products Catalog Corrections";
            break;
        case "navPubCorr":
            hdrLabel = "Navigation Publication Corrections";
            break;
        case "noticeNumbers":
            hdrLabel = "Publications Affected";
            break;
        case "ntm-uscg-llV5":
        case "ntm-uscg-ll":
            hdrLabel = "NTM Corrections USCG Light List";
            break;
        case "ntm-ngalol":
            hdrLabel = "NTM Corrections NGA List of Lights";
            break;
        default:
            break;
    }

    //call method to retrieve notice number if it wasn't specific in query
    const noticeNumRow = addNoticeNumber();
    return (
        <thead className="center-align" id="table-header">
            <tr>
                <td colSpan={42} className="bg-gray-lighter query-title">{hdrLabel} Query Results</td>
            </tr>
            <tr align="left">
                <td className="case-capitalize" colSpan={42} id="searchTerms"><b>Query Search Terms: </b><br/>{getQueryHeader(queryUrl)}</td>
            </tr>
            {noticeNumRow}
            {type === "ntmGraphics" ? 
                <tr>
                    <td colSpan={42} id="printInstr">
                        <a onClick={()=>{window.open('/PrintingInstr.html', '_blank', 'width=400,height=300'); return false;}}>Printing Instructions</a>
                    </td> 
                </tr>
             : null }
        </thead>
    );
}

function getResultHeaders(type){
    //depending on the type of data, return a specific header list
    switch(type) {
        case "chartCorr":
            return chartCorrHeaders;
        case "ntmGraphics":
            return ntmGraphicHeaders;
        case "chartsAff":
            return chartsAffHeaders;
        case "hydroCorr":
            return hydroCorrHeaders;
        case "navPubCorr":
            return navPubCorrHeaders;
        case "pubsAff":
            return pubsAffHeaders;
        case "ntm-uscg-ll":
            return uscgllHeaders;
        case "ntm-uscg-llV5":
            return uscgllVol5Headers;
        case "dgpsstations":
            return dgpstableHeaders;
        case "radiobeacons":
            return rbcntableHeaders;
        case "lights-buoys":
            return lightstableHeaders;
        default:
            break;
    }
}

function formatData(type, data){
    switch(type){
        case "chartCorr":
            return formatChartCorrData(data["chartCorr"]);
        case "ntmGraphics":
            return formatNtmGraphicsData(data["ntmGraphics"]);
        case "chartsAff":
            return formatChartsAffData(data["chartsAff"]);
        case "hydroCorr":
            return formatHydroCorrData(data["hydroCorr"]);
        case "navPubCorr":
            return formatNavPubCorrData(data["navPubCorr"]);
        case "pubsAff":
            return formatPubsAffData(data["pubsAff"]);
        case "ntm-uscg-ll":
        case "ntm-uscg-llV5":
            return uscgllformatter.formatData(data);
        case "dgpsstations":
        case "radiobeacons":
        case "lights-buoys":
            return ngalolformatter.formatData(type, data);
        default:
            break;
    }
}

//CorrectionList from chartCorrections
const CorrectionList = (props) => {
    return props.data.map(item => {
        if(item != null){
            return (
                <tr className="no-border"><td className="no-border"></td>
                <td className="no-border">{item.action}</td>
                <td className="no-border content" colSpan={3}>{item.text}</td></tr>
            );
        } else return null;
    });        
}

//for Chart Corrections
const CorrectionElement = (props) => {
    const correctionList = props.data.correctionText.correction;
    const showMarking = (window.location.search.indexOf("pubType") > 0 ? true : false);
    //per TDoh, show "LAST NM" for UNTM, "LAST CN" for CNTM
    const ntmLabel = (showMarking ? "LAST CN: " : "LAST NM: " );
    return ([
        <tr id={props.data.id}>
            <td align="left">
                {showMarking ? transformClassMarking(props.data.priceCategory) : ""}
                {props.data.starred ? "*" : ""}
                {props.data.correctionType ? props.data.correctionType.substring(0,1) + " " : ""}
                {props.data.chartNumber}
            </td>
            <td align="left">{props.data.intlNumber ? "(INT " + props.data.intlNumber + ")" : ""}</td>
            <td align="left">{props.data.editionNumber} Ed. {props.data.editionDate}</td>
            <td align="left">{!props.data.noticeAction ? ntmLabel + props.data.lastNoticeNum : props.data.noticeAction}</td>
            <td align="right">{showMarking ? "CN " : (props.data.limDist ? "N" : "")}{props.data.currNoticeNum}</td>
        </tr>,
        <CorrectionList data={correctionList}/>,
        <tr className="no-top-border">
            <td className="no-top-border"></td>
            <td colSpan={42} className="no-top-border" align="left">({props.data.authority})</td>
        </tr>
    ]);
}

//for Chart Corrections
function formatChartCorrData(data){
    if(data && data.length > 0){
        //data is currently an array of objects. 
        return <tbody>{data.map(correction => <CorrectionElement key={correction.chartId} data={correction} />)}</tbody>
    } else { return data; }
}

//for NTM Graphics
const NTMGraphicRow = (props) => {
    let ntmList = [];
    let fileNameList = [];
    let type = "";
    let href = "";
    const showMarking = (window.location.search.indexOf("pubType") > 0 ? true : false);

    for(let i=0; i<props.data.length; i++){
        //label before list of files for each row
        if (type !== props.data[i].graphicType){
            if(type !== "") { 
                fileNameList.push(<br/>)
                ntmList.push(<br/>);
            }
            type = props.data[i].graphicType;
            fileNameList.push(type);
        }
        
        //retransform notice number
        let ntmNum = props.data[i].noticeNumber;
        if(showMarking){
            ntmNum = ntmNum + 60;
        }
        
        fileNameList.push(<br/>);
        ntmList.push(<br/>);
        if(type === 'Chartlet'){ href = "/chartlets/"; }
        if(type === 'Depth Tab') { href = "/depthtabs/"; }
        if(type === 'Note') { href = "/notes/" }
        
        const folder = (showMarking ? "CNTM/": "UNTM/");
        fileNameList.push(<a target="_blank" href={graphicsS3URL + folder + ntmNum + href + props.data[i].fileName + "&type=view"}>
            {showMarking ?  transformClassMarking(props.data[i].priceCategory) : null}{props.data[i].fileName}</a>);
        fileNameList.push(" (" + sizeCalc.calcFileSize(props.data[i].fileSize) + ")");
        ntmList.push(`${showMarking ? "CN " : ''}${props.data[i].noticeWeek}/${props.data[i].noticeYear}`);
    }
    return ([
        <tr><td className="valignTop bg-gray-lighter" colSpan="3">{props.data[0].chartNumber}</td></tr>,
        <tr>
            <td className="valignTop"></td>
            <td className="valignTop">{ntmList}</td>
            <td className="valignTop">{fileNameList}</td>
        </tr>,
    ]);
}

//for NTM Graphics
function formatNtmGraphicsData(data){
    if(data && data.length > 0){
        //data is an array of objects {"chartNumber": 12331,"noticeNumber": 201847,"noticeYear": 2018,"noticeWeek": 47,"chartType": "depth tab","seqNum": 1,"fileName": "D12331_00_0_20181030164153_U.jpg"}
        //group the data by the chart number, b/c each row is a unique chart number
        data = groupBy(data, "chartNumber");
        //for each chart number, make one row with our data
        const rowArray = Object.keys(data).map(chartNumber => {
            if(chartNumber !== "undefined"){ return <NTMGraphicRow key={chartNumber} data={data[chartNumber]} /> }
            else { return null; }
        });
        return <tbody>{rowArray}</tbody>
    } else { return data; }
}

//for Charts Affected
const ChartAffectedRow = (props) => {
    const noticeArray = groupBy(props.data, "noticeNumber");
    const showMarking = (window.location.search.indexOf("pubType") > 0 ? true : false);
    if(Object.keys(noticeArray).length > 1) {
        let list = [];
        list.push(Object.keys(noticeArray).map((noticeNumber, index) => {
            const dataArray = noticeArray[noticeNumber];
            return(
                <tr key={""+noticeNumber+index}>
                    <td className="valignTop">
                        {showMarking ? transformClassMarking(dataArray[0].priceCategory) : null}
                        {dataArray[0].correctionType ? dataArray[0].correctionType.substring(0,1) + " " : ""}
                        {dataArray[0].chartNumber}
                    </td>
                    <td className="valignTop">{dataArray[0].edition}</td>
                    <td className="valignTop">
                        {dataArray[0].priceCategory === "DS" || dataArray[0].priceCategory === "CHS" ? "N" : "" }
                        {dataArray[0].noticeWeek}/{dataArray[0].noticeYear}
                        {dataArray[0].actionCode === "NEW EDITION" || dataArray[0].actionCode === "NEW CHART"? "*" : ""}
                        {(dataArray[0].actionCode === "DELETE CHART" || dataArray[0].actionCode === "CANCELED CHART") ? "**" : ""}
                    </td>
                </tr>
            );
        }));
        return list;
    } else {
        return (
            <tr>
                <td className="valignTop">
                    {showMarking ? transformClassMarking(props.data[0].priceCategory) : null}
                    {props.data[0].correctionType ? props.data[0].correctionType.substring(0,1) + " " : ""}
                    {props.data[0].chartNumber}
                </td>
                <td className="valignTop">{props.data[0].edition}</td>
                <td className="valignTop">
                    {showMarking ? "CN " : (props.data[0].priceCategory === "DS" || props.data[0].priceCategory === "CHS" ? "N" : "" )}
                    {props.data[0].noticeWeek}/{props.data[0].noticeYear}
                    {props.data[0].actionCode === "NEW EDITION" || props.data[0].actionCode === "NEW CHART"? "*" : ""}
                    {(props.data[0].actionCode === "DELETE CHART" || props.data[0].actionCode === "CANCELED CHART") ? "**" : ""}
                </td>
            </tr>
        );
    }
}

//for Charts Affected
function formatChartsAffData(data){
    const classData = (window.location.search.indexOf("pubType") > 0 ? true : false);
    if(data && data.length > 0){
        //data is an array of objects {"chartId": 21566, "chartNumber": "12200","noticeNumber": 201847,"edition": "  53","region": "1","subregion": "12","portCode": 217,"actionCode": "NEW EDITION","priceCategory": "NOS"},
        //group the data by chart number, b/c each row is a unique chart number
        data = groupBy(data, "chartNumber");
        //for each chartNumber, make one row for each notice number
        const rowArray = Object.keys(data).map(chartNumber => <ChartAffectedRow key={chartNumber} data={data[chartNumber]} />);
        const queryNote = (
            <tr>
                <td colSpan={42} className="bg-gray-lighter">
                    <b>Note: </b>
                    {classData ? null : <span>
                        <b>N</b> indicates Not For Sale; &nbsp;
                        <b>P</b> indicates Preliminary; &nbsp;
                        <b>T</b> indicates Temporary; &nbsp;</span> }
                    <b>*</b> indicates New Edition/New Chart; &nbsp;
                    <b>**</b> indicates Chart Cancelled</td></tr>)
        return <tbody>{rowArray}{queryNote}</tbody>
    } else { return data; }
}

//for Hydrographic Corrections
function formatHydroCorrData(data){
    const showMarking = (window.location.search.indexOf("pubType") > 0 ? true : false);
    if(data && data.length > 0){
        return <tbody>{data.map(corr => {
            const titleLength = corr["title"].length;
            let rows = [];

            //decide on underlining of columns
            let col1Class = (corr["title"][0]["changeIndicator"]["col1Updated"] ? "underline" : "");
            let col2Class = (corr["title"][0]["changeIndicator"]["col2Updated"] ? "underline" : "");
            let col3Class = (corr["title"][0]["changeIndicator"]["col3Updated"] ? "underline" : "");
            let col4Class = (corr["title"][0]["changeIndicator"]["col4Updated"] ? "underline" : "");
            let col5Class = (corr["title"][0]["changeIndicator"]["col5Updated"] ? "underline" : "");
            let col6Class = (corr["title"][0]["changeIndicator"]["col6Updated"] ? "underline" : "");

            //push the first row w/ all the data
            rows.push(
                <tr>
                    <td>{showMarking ? "CN " : null}{corr["noticeWeek"]}/{corr["noticeYear"]}</td>
                    <td className={col1Class}>{corr["seriesStockNumber"]}<br/>{(corr["nationalStockNumber"] ? "(" + corr["nationalStockNumber"] + ")" : "")}</td>
                    <td id="hydro-title" className={col2Class}>{corr["title"][0]["insetTitle"]}</td>
                    <td className={col3Class}>{corr["title"][0]["insetScale"]}</td>
                    <td className={col4Class}>{corr["edition"]}</td>
                    <td className={col5Class}>{corr["editionDate"]}</td>
                    <td className={col6Class}>{corr["priceCategory"]}</td>
                </tr>
            )

            //if we have more titles, push additional rows
            if(titleLength > 1){
                for(let i = 1; i < titleLength; i++){
                    let col2Class = (corr["title"][i]["changeIndicator"]["col2Updated"] ? "underline no-top-border" : "no-top-border");
                    let col3Class = (corr["title"][i]["changeIndicator"]["col3Updated"] ? "underline no-top-border" : "no-top-border");

                    rows.push(
                        <tr>
                            <td className="no-top-border"></td><td className="no-top-border"></td>
                            <td id="hydro-title" className={col2Class}>{corr["title"][i]["insetTitle"]}</td>
                            <td className={col3Class}>{corr["title"][i]["insetScale"]}</td>
                            <td className="no-top-border"></td><td className="no-top-border"></td><td className="no-top-border"></td>
                        </tr>
                    )
                }
            }

            return rows;
        })}</tbody>
    } else { return data; }

}

//for Navigation Publication Corrections
function formatNavPubCorrData(data){
    const classData = (window.location.search.indexOf("pubType") > 0 ? true : false);
    if (data && data.length > 0){
        return <tbody>{data.map(corr => {
            let noticeWeek = corr["noticeWeek"];
            if(classData){
                noticeWeek = (noticeWeek - 60);
            }
            return <tr>
                <td>{(corr["publicationNumber"] ? corr["publicationNumber"] : corr["volumeNumber"])}</td>
                <td>{corr["publicationName"]}</td>
                <td>{corr["editionNumber"]}</td>
                <td>{corr["editionYear"]}</td>
                <td><a target="_blank" href={graphicsS3URL + "ECC/" + corr["filename"] + ".pdf&type=view"}>{classData ? "CN " : null}{noticeWeek}/{corr["noticeYear"]}</a></td>
            </tr>
        })}</tbody>
    } else { return data; }
}

//for publications affected query
function formatPubsAffData(data){
    if (data && data.length > 0){
        let dataList = [];
        let pubGroup = "";
        for(let i=0; i<data.length; i++){
            let pub = data[i];
            if(pub.pubGroup !== pubGroup){
                //add row for pubgroup
                pubGroup = pub.pubGroup;
                dataList.push(
                    <tr id={pubGroup} key={pubGroup}>
                        <td colSpan={42} align="left" className="bg-gray-lighter text-bold">{pubGroup}</td>
                    </tr>
                );
            }
            //add row for pub
            dataList.push(
                <tr key={i}>
                    <td className="quarter-width">{pub.pubOrVolNumber}</td>
                    <td>{pub.edition}</td>
                    <td>{pub.noticeNumbers}</td>
                </tr>
            );
        }
        return <tbody>{dataList}</tbody>
    } else { return data; }
}

function transformPorts(regexUrl){
    let portInfoRegex = /portCode=\d*/;
    var found = regexUrl.match(portInfoRegex)
    if(found){
        const code = found[0].replace("portCode=", "");
        const ports = searchBy.chartCorrPorts();
        const result = ports.filter(port => port.value === code);
        if(result.length > 0){
            return "Port of Call: " + result[0].text;
        } else {return null}
    } else { return null}
}

//Tranform the CNTM numbers (-60)
function tranformCNTMnumbers(regexUrl){
    //look for single noticeNumber
    let noticeNumberRegex = /oticeNumber=(\d)*/g;
    var found = [...regexUrl.matchAll(noticeNumberRegex)]
    if(found){
        let ntmNumber, newNumber;
        for(let i=0; i<found.length; i++){
            //slice off the notice number, subtract 60, then replace it in the query string
            ntmNumber = found[i][0].slice(found[i][0].length - 6);
            newNumber = ntmNumber - 60;
            regexUrl = regexUrl.replace(ntmNumber, newNumber);
        }
    }
    return regexUrl
}

//Transform the CNTM Price Category into a Classification Marking
function transformClassMarking(priceCategory){
    if(priceCategory){
        //using 1st letter, determine classification unless "DS"
        if(priceCategory.substring(0,1) === "S" || priceCategory.substring(0,1) === "C"){
            return "(" + priceCategory.substring(0,1) + ") "; 
        } else {
            return "(U) "
        }
    } else return "";
}

//Return subheader to display the customer query
function getQueryHeader(queryUrl){
    const hasCNTM = (queryUrl.indexOf("pubType=cntm") > -1 ? true : false);

    let noticeNumLbl = "Corrected through U.S. Notice to Mariners No. ";
    let noticeRangeLbl = "NTM Range: ";
    if(hasCNTM){
        noticeNumLbl =  "Corrected through Classified NTM No. "
        noticeRangeLbl = "Classified NTM Range: "

        //if noticeNumbers exist, transform them
        queryUrl = tranformCNTMnumbers(queryUrl);
    }
    let regexUrl = queryUrl.replace(/.*?\?(.*?)&output=html/i, '$1');
    regexUrl = regexUrl.replace("noticeNumber=", noticeNumLbl);
    regexUrl = regexUrl.replace("minNoticeNumber=", noticeRangeLbl);
    regexUrl = regexUrl.replace("&maxNoticeNumber=", " - ");
    regexUrl = regexUrl.replace("subregion=", "Subregion: ");
    regexUrl = regexUrl.replace("region=", "Region: ");
    regexUrl = regexUrl.replace("chartNumber=", "Specific Charts: ");
    regexUrl = regexUrl.replace("graphicType=", "Graphic Type: ");
    regexUrl = regexUrl.replace("chartType=", "Chart Type: ");
    regexUrl = regexUrl.replace("productType=", "Type of Product: ");
    regexUrl = regexUrl.replace("publicationTypeGroup=", "Product Name: ");
    regexUrl = regexUrl.replace("&publicationTypeSubGroup=", " : ");
    regexUrl = regexUrl.replace("&pubVolNumber=", " : Publication/Volume ");
    regexUrl = regexUrl.replace("&pubOrVolumeNumber=", "Publication/Volume Number: ");
    regexUrl = regexUrl.replace("volume=", "Volume Number: ");
    regexUrl = regexUrl.replace("featureNumber=", "Aid Number: ");
    regexUrl = regexUrl.replace("featureNumberStart=", "Aid Number Start: ");
    regexUrl = regexUrl.replace("featureNumberEnd=", "Aid Number End: ");
    regexUrl = regexUrl.replace("geoHDRName=", "Geographic Header: ");
    regexUrl = regexUrl.replace("geoSHDRName=", "Geographic Sub Header: ");
    regexUrl = regexUrl.replace("range=", "Range Exceeding: ");
    regexUrl = regexUrl.replace("latitudeLeft=", "Lower Left Latitude: ");
    regexUrl = regexUrl.replace("longitudeLeft=", "Lower Left Longitude: ");
    regexUrl = regexUrl.replace("latitudeRight=", "Upper Right Latitude: ");
    regexUrl = regexUrl.replace("longitudeRight=", "Upper Right Longitude: ");
    regexUrl = regexUrl.replace("pubGroup=", "Product: ");
    regexUrl = regexUrl.replace("pubType=cntm", "Classified Notice to Mariners");
    regexUrl = regexUrl.replace("&pubType=untm", "");
    regexUrl = regexUrl.replace("&includeRemovals=true", "");
    regexUrl = regexUrl.replace("&includeRemovals=false", "");
    regexUrl = regexUrl.replace(/portCode=\d*/, transformPorts(regexUrl));
    regexUrl = regexUrl.replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n");
    regexUrl = regexUrl.replace(/%20/ig, ' ');

    return regexUrl;
}

export {getTableHeaders, getResultHeaders, formatData};