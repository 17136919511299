import React, { Component } from "react";
import { Route } from 'react-router-dom';
import EmailSubScrb from './EmailSubScrb';
import EditSubscription from "./EditSubscription";

class ManageSubscription extends Component {

    render() {
        return (
            <div id="subscription" className="container">
                <div className="col-sm-12">
                    <h5>Manage Subscriptions</h5>
                    <div className="row">
                        <Route exact path={this.props.match.url} render={(props) => <EmailSubScrb {...props} />} />
                        <Route path={`${this.props.match.url}/:id`} render={(props) => <EditSubscription {...props} conf={this.props.conf} />} />
                    </div>
                </div>
            </div>
        )
    }
}


export default (ManageSubscription);