import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';

class NOAATT extends Component {
    render(){
        return(
            <div id="noaatt">
                  <PageContent divid="noaatt" />
                  <br />
                     <div id="pub-download">
                    <PubDownload category="NOAATT" list={this.props.list}pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default NOAATT;