import React, { Component } from "react";
const msiFetch = require("../../controllers/MsiFetch");
const formatDate = require("../../utils/FormatDate");

class BroadcastQueryPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navAreas: {
        "NAVAREA IV": "4",
        "NAVAREA XII": "12",
        HYDROLANT: "A",
        HYDROPAC: "P",
        HYDROARC: "C",
      },
      subregions: [],
      areaOptions: [],
    };
    this.validateFields = this.validateFields.bind(this);
    this.showNavAreas = this.showNavAreas.bind(this);
    this.updateAreas = this.updateAreas.bind(this);
  }

  componentDidMount() {
    const url = "publications/broadcast-warn/subregions";
    msiFetch
      .fetchData(url)
      .then((data) => {
        this.setState({ subregions: data, areaOptions: this.showNavAreas() });
      })
      .catch((error) => {
        this.setState({ subregions: [], areaOptions: this.showNavAreas() });
      });
  }

  showNavAreas() {
    var navTypes = Object.keys(this.state.navAreas);
    //iterate through each key to make an option value
    return navTypes.map((area) => (
      <option value={this.state.navAreas[area]} key={area}>
        {area}
      </option>
    ));
  }

  updateAreas() {
    //get the value of what the user would like to search by
    var searchType = document.getElementById("sel1").value;
    var geoLocLbl = document.getElementById("lblGeoLocator");

    if (searchType === "navArea") {
      geoLocLbl.className = "query-label no-display";
      //call method that shows nav areas. method extracted b/c its called on inital load as well
      this.setState({ areaOptions: this.showNavAreas() });
    } else if (searchType === "subregion") {
      geoLocLbl.className = "query-label";
      //return option values with the "Subregion " text in front of each value
      var options = this.state.subregions.map((region) => (
        <option value={region} key={region}>
          Subregion {region}
        </option>
      ));
      this.setState({ areaOptions: options });
    } else {
      geoLocLbl.className = "query-label no-display";
      this.setState({ areaOptions: [<option value="all">-</option>] });
    }
  }

  updateSearchFilter() {
    // variables are for input & select boxes"
    var filter = document.getElementById("sel2").value;
    var label1 = document.getElementById("label5");
    var msgNumberStartInput = document.getElementById("msgNumberStart");
    var msgNumberEndInput = document.getElementById("msgNumberEnd");
    var yearNumberStartInput = document.getElementById("yearNumberStart");
    var yearNumberEndInput = document.getElementById("yearNumberEnd");
    var issueDateStartInput = document.getElementById("issueDateStart");
    var issueDateEndInput = document.getElementById("issueDateEnd");
    var filterDiv = document.getElementById("bw-filters");
    var slash1 = document.getElementById("slash1");
    var slash2 = document.getElementById("slash2");
    var toText = document.getElementById("toText");

    // display classNames that we will be setting
    var display = "form-control";
    var nodisplay = "form-control no-display";
    var displayHalf = "form-control inline fourty-width";
    var displayTenth = "form-control inline tenth-width";
    var displayFilters = "col-sm-4";

    // depending on what user is filtering by, show or do not show certain inputs
    switch (filter) {
      case "allInforceNums":
      case "allActive":
        label1.innerHTML = "";
        msgNumberStartInput.className = nodisplay;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = nodisplay;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = "col-sm-4 no-display";
        slash1.className = nodisplay;
        slash2.className = nodisplay;
        toText.className = nodisplay;
        break;
      case "activeByDate":
        label1.innerHTML = "By Date Range (MM/DD/YYYY)";
        msgNumberStartInput.className = nodisplay;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = nodisplay;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = displayHalf;
        issueDateEndInput.className = displayHalf;
        filterDiv.className = displayFilters;
        slash1.className = nodisplay;
        slash2.className = nodisplay;
        toText.className = "";
        break;
      case "byNumber":
        label1.innerHTML = "By Specific Number (nnnn/YYYY)";
        msgNumberStartInput.className = displayHalf;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = displayHalf;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = displayFilters;
        slash1.className = "";
        slash2.className = nodisplay;
        toText.className = nodisplay;
        break;
      case "byNumberRange":
        label1.innerHTML = "By Number Range (nnnn/YYYY)";
        msgNumberStartInput.className = displayTenth;
        msgNumberEndInput.className = displayTenth;
        yearNumberStartInput.className = displayTenth;
        yearNumberEndInput.className = displayTenth;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = "col-sm-5";
        slash1.className = "";
        slash2.className = "";
        toText.className = "";
        break;
      case "allByYear":
        label1.innerHTML = "(YYYY)";
        msgNumberStartInput.className = nodisplay;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = display;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = displayFilters;
        slash1.className = nodisplay;
        slash2.className = nodisplay;
        toText.className = nodisplay;
        break;
      case "cancelledByYear":
        label1.innerHTML = "(YYYY)";
        msgNumberStartInput.className = nodisplay;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = display;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = displayFilters;
        slash1.className = nodisplay;
        slash2.className = nodisplay;
        toText.className = nodisplay;
        break;
      default:
        break;
    }
  }

  validateFields() {
    //check to make sure user filled out valid entries for the filtering options
    //grab all of the filtering values for user entry
    var filterType = document.getElementById("sel2").value;
    var msgNumberStart = document.getElementById("msgNumberStart").value;
    var msgNumberEnd = document.getElementById("msgNumberEnd").value;
    var msgYearStart = document.getElementById("yearNumberStart").value;
    var msgYearEnd = document.getElementById("yearNumberEnd").value;
    var issueDateStart = document.getElementById("issueDateStart").value;
    var issueDateEnd = document.getElementById("issueDateEnd").value;

    var alertMessage = "";
    //if user selected activeByDate
    if (filterType === "activeByDate") {
      if (issueDateStart === "" || issueDateEnd === "") {
        alertMessage =
          alertMessage + "You must fill in all fields for Issue Date. ";
      }
    }
    //if user selected byNumber
    else if (filterType === "byNumber") {
      if (msgNumberStart === "" || msgYearStart === "") {
        alertMessage =
          alertMessage + "You must fill in all fields for Warning Number. ";
      } else {
        if (msgNumberStart < 0) {
          alertMessage =
            alertMessage +
            "Invalid Entry: Warning Number must be greater than 0. ";
        }
        if (msgYearStart < 1000) {
          alertMessage =
            alertMessage + "Invalid Entry: Warning Year must be four digits. ";
        }
      }
    }
    //if user select byNumberRange
    else if (filterType === "byNumberRange") {
      if (msgNumberStart === "" || msgYearStart === "") {
        alertMessage =
          alertMessage +
          "You must fill in all fields for Starting Warning Number. ";
      } else if (msgNumberEnd === "" || msgYearEnd === "") {
        alertMessage =
          alertMessage +
          "You must fill in all fields for Ending Warning Number. ";
      } else {
        if (msgNumberStart < 0 || msgNumberEnd < 0) {
          alertMessage =
            alertMessage +
            "Invalid Entry: Warning Number must be greater than 0";
        }
        if (msgYearStart < 1000 || msgYearEnd < 1000) {
          alertMessage =
            alertMessage + "Invalid Entry: Warning Year must be four digits. ";
        }
      }
    }
    //if user selected allByYear
    else if (filterType === "allByYear") {
      if (msgYearStart === "") {
        alertMessage =
          alertMessage + "You must fill in all fields for Warning Year. ";
      } else if (msgYearStart < 1000) {
        alertMessage =
          alertMessage + "Invalid Entry: Warning Year must be four digits. ";
      }
    }
    //if user selected cancelledByYear
    else if (filterType === "cancelledByYear") {
      if (msgYearStart === "") {
        alertMessage =
          alertMessage + "You must fill in all fields for Warning Year. ";
      } else if (msgYearStart < 1000) {
        alertMessage =
          alertMessage + "Invalid Entry: Warning Year must be four digits. ";
      }
    }

    //if we have a message to show the user, show it
    if (alertMessage !== "") {
      window.alert(alertMessage);
    } else {
      this.getBroadcastWarn();
    }
  }

  getBroadcastWarn() {
    //variable instantiation
    this.url = "publications/broadcast-warn";
    //get area filter (1st column of query panel)
    var areaFilter = document.getElementById("sel1").value;
    var areaSelect = document.getElementById("sel4");
    var area = areaSelect.options[areaSelect.selectedIndex].value;

    //get filtering options (2nd column of query panel)
    var filterType = document.getElementById("sel2").value;
    var msgNumberStartInput = document.getElementById("msgNumberStart").value;
    var msgNumberEndInput = document.getElementById("msgNumberEnd").value;
    var yearNumberStartInput = document.getElementById("yearNumberStart").value;
    var yearNumberEndInput = document.getElementById("yearNumberEnd").value;
    var issueDateStartInput = document.getElementById("issueDateStart").value;
    var issueDateEndInput = document.getElementById("issueDateEnd").value;

    //get output option (3rd column of query panel)
    var output = document.getElementById("sel3").value;

    //if filtering all inforce numbers, add additional path to the url
    if (filterType === "allInforceNums") {
      this.url = this.url + "/inforce";
      area = "All";
    }

    //add in navarea or subregion
    if (areaFilter === "navArea") {
      this.url = this.url + "?navArea=" + area;
    } else if (areaFilter === "subregion") {
      this.url = this.url + "?subregion=" + area;
    } else {
      this.url = this.url + "?";
    }

    //add in filter options
    if (filterType === "allActive") {
      this.url = this.url + "&status=active";
    } else if (filterType === "activeByDate") {
      var issueDateStart = formatDate.convertDate(issueDateStartInput);
      var issueDateEnd = formatDate.convertDate(issueDateEndInput);
      this.url =
        this.url +
        "&issueDateStart=" +
        issueDateStart +
        "&issueDateEnd=" +
        issueDateEnd;
    } else if (filterType === "byNumber") {
      this.url =
        this.url +
        "&msgNumber=" +
        msgNumberStartInput +
        "&msgYear=" +
        yearNumberStartInput;
    } else if (filterType === "byNumberRange") {
      this.url =
        this.url +
        "&msgNumberStart=" +
        msgNumberStartInput +
        "&msgYearStart=" +
        yearNumberStartInput;
      this.url =
        this.url +
        "&msgNumberEnd=" +
        msgNumberEndInput +
        "&msgYearEnd=" +
        yearNumberEndInput;
    } else if (filterType === "allByYear") {
      this.url = this.url + "&status=all&msgYear=" + yearNumberStartInput;
    } else if (filterType === "cancelledByYear") {
      this.url = this.url + "&status=cancelled&msgYear=" + yearNumberStartInput;
    }

    //add in output value
    this.url = this.url + "&output=" + output;

    // open the query results window. send the api url as Request Params
    // so that we don't have to send data. Actual fetch will be done by QueryResults window
    window.open("/queryResults?" + this.url, "_blank");
  }

  openQueryDirections() {
    //open the window in a specific width/height
    window.open(
      "/queryDirections?queryType=navWarn",
      "_blank",
      "toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600"
    );
  }

  render() {
    return (
      <div className="row left-align container pb32">
        {/* <div className="col-sm-12 query-dir">
          <a onClick={this.openQueryDirections.bind(this)}>Query Directions</a>
        </div> */}
        <div className="col-sm-2" id="selector-col1">
          <label htmlFor="sel1" className="query-label">
            Area Type
          </label>
          <select
            className="form-control"
            id="sel1"
            defaultValue="navArea"
            onChange={this.updateAreas}
          >
            <option value="all">All Areas</option>
            <option value="navArea">By NAVAREA</option>
            <option value="subregion">By Charting Subregion</option>
          </select>
        </div>
        <div className="col-sm-2" id="selector-col1">
          <label htmlFor="sel1" className="query-label">
            Area
          </label>
          <select className="form-control" id="sel4">
            {this.state.areaOptions}
          </select>
          <label
            id="lblGeoLocator"
            htmlFor="sel1"
            className="query-label no-display"
          >
            <a
              target="_blank"
              href="/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=view"
            >
              (View Geographic Locator)
            </a>
          </label>
        </div>
        <div className="col-sm-3" id="selector-col2">
          <label htmlFor="sel2" className="query-label">
            Search
          </label>
          <select
            className="form-control"
            id="sel2"
            onChange={this.updateSearchFilter}
          >
            <option value="allActive">All In-Force Warnings</option>
            <option value="allInforceNums">All In-Force Warning Numbers</option>
            <option value="activeByDate">
              In-Force Warnings By Date Range
            </option>
            <option value="byNumber">By Warning Number</option>
            <option value="byNumberRange">By Warning Number Range</option>
            <option value="allByYear">All Warnings By Specific Year</option>
            <option value="cancelledByYear">
              Canceled Warnings By Specific Year
            </option>
          </select>
        </div>
        <div className="col-sm-5 no-display" id="bw-filters">
          <label
            id="label5"
            htmlFor="msgNumberStart"
            className="query-label"
          ></label>
          <br />
          <input
            maxLength={4}
            type="number"
            step="10"
            className="form-control no-display"
            id="msgNumberStart"
          />
          <strong id="slash1" className="no-display">
            &nbsp; / &nbsp;{" "}
          </strong>
          <input
            maxLength={4}
            type="number"
            step="10"
            className="form-control no-display"
            id="yearNumberStart"
          />
          <input
            type="date"
            className="form-control no-display"
            id="issueDateStart"
          />
          <strong id="toText" className="no-display">
            &nbsp; to &nbsp;{" "}
          </strong>
          <input
            maxLength={4}
            type="number"
            step="10"
            className="form-control no-display"
            id="msgNumberEnd"
          />
          <strong id="slash2" className="no-display">
            &nbsp; / &nbsp;{" "}
          </strong>
          <input
            maxLength={4}
            type="number"
            step="10"
            className="form-control no-display"
            id="yearNumberEnd"
          />
          <input
            type="date"
            className="form-control no-display"
            id="issueDateEnd"
          />
        </div>
        <div className="col-sm-3" id="selector-col3">
          <label htmlFor="sel3" className="query-label">
            Output Format
          </label>
          <br />
          <select className="form-control fourty-width float-left" id="sel3">
            <option value="html">HTML</option>
            <option value="xml">XML</option>
            <option value="csv">CSV (Delimited text)</option>
          </select>
          <a
            className="btn vol-btn quarter-width float-right"
            title="Search"
            id="btnApplyQuery"
            onClick={this.validateFields}
          >
            Search
          </a>
        </div>
      </div>
    );
  }
}

export default BroadcastQueryPanel;
