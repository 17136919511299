const LatLongConv = require('../../utils/LatLongConv');
//const USCGLLQueryBuilder = require('../Publications/USCGLL/USCGLLQueryBuilder');
const NGALOLQueryBuilder = require('../Publications/NGALOL/NGALOLQueryBuilder');
const cntmMultiplier = 60;

function getNoticeNumber(url){
    const noticeType = document.getElementById("selSearchNtms").value;
    const pubType = document.getElementById("pubTypeSel");
    switch(noticeType){
        case "bySpecificNTM":
            let wkNum = document.getElementById("endInputNTMWk").value;
            const yrNum = document.getElementById("endInputNTMYr").value;
            //add 60 to the week number if using classified ntms
            if(pubType && pubType.value === "cntm"){
                wkNum = parseInt(wkNum,10) + cntmMultiplier;
            }
            url += "noticeNumber=" + yrNum + wkNum + "&";
            break;
        case "byNTMRange":
            let startWkNum = document.getElementById("startInputNTMWk").value;
            const startYrNum = document.getElementById("startInputNTMYr").value;
            if(pubType && pubType.value === "cntm"){
                startWkNum = parseInt(startWkNum,10) + cntmMultiplier;
            }
            url += "minNoticeNumber=" + startYrNum + startWkNum + "&";
            let endWkNum = document.getElementById("endInputNTMWk").value;
            const endYrNum = document.getElementById("endInputNTMYr").value;
            if(pubType && pubType.value === "cntm"){
                endWkNum = parseInt(endWkNum,10) + cntmMultiplier;
            }
            url += "maxNoticeNumber=" + endYrNum + endWkNum + "&";
            break;
        default:
            //we dont need to do anything if searching all ntms
            break;
    }
    return url;
}

function getOutput(url){
    const output = document.getElementById("outputSel").value;
    url += "output=" + output;
    return url;
}

function getLatLongData(url){
    let leftLatDegree, leftLatMin, leftLatDir;
    let leftLonDegree, leftLonMin, leftLonDir;
    let rightLatDegree, rightLatMin, rightLatDir;
    let rightLonDegree, rightLonMin, rightLonDir;
    let leftCoords, rightCoords;

    leftLatDegree = parseFloat(document.getElementById("leftLatDegree").value);
    leftLatMin = parseFloat(document.getElementById("leftLatMinute").value);
    leftLatDir = document.getElementById("leftLatDirection").value;
    leftLonDegree = parseFloat(document.getElementById("leftLonDegree").value);
    leftLonMin = parseFloat(document.getElementById("leftLonMinute").value);
    leftLonDir = document.getElementById("leftLonDirection").value
    
    rightLatDegree = parseFloat(document.getElementById("rightLatDegree").value);
    rightLatMin = parseFloat(document.getElementById("rightLatMinute").value);
    rightLatDir = document.getElementById("rightLatDirection").value;
    rightLonDegree = parseFloat(document.getElementById("rightLonDegree").value);
    rightLonMin = parseFloat(document.getElementById("rightLonMinute").value);
    rightLonDir = document.getElementById("rightLonDirection").value;

    leftCoords = LatLongConv.convertToDec(leftLatDegree, leftLatMin, 0, leftLatDir, leftLonDegree, leftLonMin, 0, leftLonDir);
    rightCoords = LatLongConv.convertToDec(rightLatDegree, rightLatMin, 0, rightLatDir, rightLonDegree, rightLonMin, 0, rightLonDir);
    return url + '&latitudeLeft=' + leftCoords['latitude'] + '&longitudeLeft=' + leftCoords['longitude'] + '&latitudeRight=' + rightCoords['latitude'] + '&longitudeRight=' + rightCoords['longitude'];
}

function getChartsCorrAffFilters(urlString){
    //get filter type values
    const filterType = document.getElementById("selChartCorrFilters").value;
    if(filterType === "specifcCharts"){
        const chartList = document.getElementById("inputChartList").value;
        urlString += "chartNumber=" + chartList + "&";
    } else if (filterType === "byRegion"){
        const region = document.getElementById("selChartRegion").value;
        urlString += "region=" + region + "&";
    } else if (filterType === "bySubregion"){
        const subregion = document.getElementById("selChartSubregion").value;
        urlString += "subregion=" + subregion + "&";
    } else if (filterType === "withinPort" || filterType === "withinDNC"){
        let element = ""
        if(filterType === "withinPort"){
            element = "selChartPorts";
        } else { element = "selChartDNC"; }
        const portCode = document.getElementById(element).value;
        urlString += "portCode=" + portCode + "&";
    } else if(filterType === "byChartType"){
        const chartType = document.getElementById("selChartType").value;
        urlString += "chartType=" + chartType + "&";
    } else if(filterType === "geographicArea"){
        urlString = getLatLongData(urlString) + "&"
    } 

    return urlString;
}

function buildChartCorrQuery(){
    let urlString = "ntm/ntm-chart-corr?";
    const filterType = document.getElementById("selChartCorrFilters").value;

    if(filterType === "geographicArea"){
        urlString = "ntm/ntm-chart-corr/geo?"
    } 
    //get notice number(s)
    urlString = getNoticeNumber(urlString);
    //get additional filters
    urlString = getChartsCorrAffFilters(urlString);
    //get pubType (untm or cntm)
    const pubType = document.getElementById("pubTypeSel");
    if(pubType && pubType.value === "cntm"){
        urlString = urlString + "pubType=cntm&";
    }
    //get output type
    urlString = getOutput(urlString);

    return urlString;
}

function buildChartletsQuery(){
    let urlString = "ntm/ntm-graphics?";
    
    //get notice number(s)
    urlString = getNoticeNumber(urlString);

    //get filters
    const chartFilter = document.getElementById("selChartletFilters").value;
    if(chartFilter === "specifcCharts"){
        const chartList = document.getElementById("inputChartList").value;
        urlString += "chartNumber=" + chartList + "&";
    } else if (chartFilter === "subregion"){
        const subregion = document.getElementById("selChartSubregion").value;
        urlString += "subregion=" + parseInt(subregion,10) + "&";
    } else if(chartFilter === "chartType"){
        const chartType = document.getElementById("selChartType").value;
        urlString += "chartTypeDef=" + chartType + "&";
    }

    const dataTypeFilter = document.getElementById("selChartletGraphic").value;
    urlString += "graphicType=" + dataTypeFilter + "&";
    
    //get pubType (untm or cntm)
    const pubType = document.getElementById("pubTypeSel");
    if(pubType && pubType.value === "cntm"){
        urlString = urlString + "pubType=cntm&";
    }

    //get output type (default html)
    urlString += "output=html";

    return urlString;
}

function buildChartsAffQuery(){
    let urlString = "ntm/ntm-charts-affected?";
    //get notice number(s)
    urlString = getNoticeNumber(urlString);
    //get additional filters
    urlString = getChartsCorrAffFilters(urlString);
    //get pubType (untm or cntm)
    const pubType = document.getElementById("pubTypeSel");
    if(pubType && pubType.value === "cntm"){
        urlString = urlString + "pubType=cntm&";
    }
    //get output type
    urlString = getOutput(urlString);

    return urlString;
}

function buildHydroCorrQuery(){
    let urlString = "ntm/ntm-hydro-corr?";
    //get notice number(s)
    urlString = getNoticeNumber(urlString);
    //get additional filters
    const filterType = document.getElementById("selBWFilters").value;
    if(filterType !== "all"){
        urlString += "productType=" + filterType + "&";
    }
    if(filterType === "charts"){
        //untm filters by area (region & subregion)
        const areaFilter = document.getElementById("selBWAreaFilters");
        if(areaFilter){
            if (areaFilter.value === "byRegion"){
                const region = document.getElementById("selChartRegion").value;
                urlString += "region=" + region + "&";
            } else if (areaFilter.value === "bySubregion"){
                const subregion = document.getElementById("selChartSubregion").value;
                urlString += "subregion=" + subregion + "&";
            }
        } else {
            //cntm filters by chartType
            const chartTypeSel = document.getElementById("selChartType");
            if(chartTypeSel){
                if(chartTypeSel.value !== "none"){
                    urlString += "chartType=" + chartTypeSel.value + "&";
                }
            }
        }


    }
    //get pubType (untm or cntm)
    const pubType = document.getElementById("pubTypeSel");
    if(pubType && pubType.value === "cntm"){
        urlString = urlString + "pubType=cntm&";
    }
    //get output type
    urlString = getOutput(urlString);

    return urlString;
}

function buildNavPubCorrQuery(){
    let urlString = "ntm/ntm-nav-pub-corr?";
    //get notice number(s)
    urlString = getNoticeNumber(urlString);
    //get additional filters
    const pubGroupType = document.getElementById("selNavProduct").value;
    const smallSel = document.getElementById("selSmallNavPub").value;
    const largeSel = document.getElementById("selLargeNavPub").value;

    urlString += "publicationTypeGroup=" + pubGroupType + "&";

    if(pubGroupType === "other" && largeSel !== "all"){
        //handle the NOS CATALOGs that have to go by a diff field
        if(largeSel.substring(0, 3) === "CAT"){
            urlString += "pubOrVolumeNumber=" + largeSel + "&";
        } else {
            urlString += "publicationTypeSubGroup=" + largeSel + "&";
        }
    } else if(pubGroupType !== "other"){
        //find which selection is displayed (small or large)
        if(document.getElementById("smallOptionsList").className !== "no-display"){
            //small div is displayed, use its value
            if (smallSel !== "all"){
                urlString += "pubOrVolumeNumber=" + smallSel + "&";
            }
        } else {
            //large div is displayed, use its value
            if (largeSel !== "all" && pubGroupType.substring(0, 3) !== "Tid"){
                urlString += "pubOrVolumeNumber=" + largeSel + "&";
            } else if (largeSel !== "all"){
                //tide tables use subgroup
                urlString += "publicationTypeSubGroup=" + largeSel + "&";
            }
        }
    }

    //get pubType (untm or cntm)
    const pubType = document.getElementById("pubTypeSel");
    if(pubType && pubType.value === "cntm"){
        urlString = urlString + "pubType=cntm&";
    }

    //get output type (default html)
    urlString += "output=html";

    return urlString;
}

function buildPubsAffQuery(){
    let urlString = "ntm/ntm-pubs-affected?";
    //get publication group type
    urlString += "pubGroup=" + document.getElementById("selNavProduct").value + "&";
    //get output type
    urlString = getOutput(urlString);
    return urlString;
}

/* function buildUSCGLLQuery(){
    let urlString = "uscgll?";
    //get notice number(s)
    urlString = getNoticeNumber(urlString);
    //call queryBuilder method
    urlString = USCGLLQueryBuilder.getUSCGLL(urlString);
    return urlString;
} */

function buildNGALOLQuery(){
    let urlString = "ngalol/";
    const dataType = document.getElementById("ngalolReturn").value
    urlString += dataType + "?";
    //get notice number(s)
    urlString = getNoticeNumber(urlString);
    //call queryBuilder method
    urlString = NGALOLQueryBuilder.getNGALOL(urlString);
    return urlString;
}

export {buildChartCorrQuery, buildChartletsQuery, 
        buildChartsAffQuery, buildHydroCorrQuery,
        buildNavPubCorrQuery, buildPubsAffQuery,
        /*buildUSCGLLQuery, */buildNGALOLQuery };