import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const msiFetch = require('../../controllers/MsiFetch');

function createMarkup(text) {
    return { __html: text };
}

class EditSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resp: {},
            subscr: [],
            webmasterEmail: props.conf.webmasterEmail,
            pagecontent: "",
            network: "",
            code: ""
        }
        this.showNavselect = this.showNavselect.bind(this);
    }

    async componentDidMount() {
        const pgcontent = this.props.rootReducer.contentReducer.pageContent;
        const contentblock = pgcontent.find(obj => obj.divId === "subscribe");
        this.setState({
            pagecontent: contentblock.pageBody
        });

        var params = window.location.search.replace("?", '').split("&");
        var cc = params[0].replace("uid=", '');
        var id = params[1].replace("id=", '');
        this.setState({code: cc})
        //get user subscriptions
        const url = 'email-subscriber?code=' + cc + '&id=' + id;
        msiFetch.fetchData(url)
            .then(data => {
                this.setState({ subscr: data })
            })
            .catch(error => {
                this.setState({ subscr: "" });
                window.alert("Loading subscription information failed." + error);
            });
    }

    async componentDidUpdate(prevProps) {
        if (this.state.subscr !== "") {
            this.subscrItems();
            this.showNavselect();
        }
        if (this.props.conf.network != null && this.props.conf.network !== this.state.network) {
            this.setState({ network: this.props.conf.network });
            this.setState({ webmasterEmail: this.props.webmasterEmail });
        }
    }

    subscrItems() {
        //update the page with the users subscription values
        document.getElementById("ntmCheck").checked = this.state.subscr.subscrNTM ? true : false;
        if (this.state.network.substring(0, 7) === "unclass") {
            document.getElementById("navCheck").checked = this.state.subscr.subscrNavWarn ? true : false;
            document.getElementById("pubCheck").checked = this.state.subscr.subscrPubs ? true : false;
            if (this.state.subscr.subscrNavWarn) {
                var list = document.getElementById("BWtype");
                for (let i = 0, iLen = list.length; i < iLen; i++) {
                    if (list[i].value === "4" && this.state.subscr.navarea4) { list[i].selected = true; } else { list[i].selected = false; }
                    if (list[i].value === "lant" && this.state.subscr.hydrolant) { list[i].selected = true; }
                    if (list[i].value === "12" && this.state.subscr.navarea12) { list[i].selected = true; }
                    if (list[i].value === "pac" && this.state.subscr.hydropac) { list[i].selected = true; }
                    if (list[i].value === "arc" && this.state.subscr.hydroarc) { list[i].selected = true; }
                }
            }
        }
    }

    showNavselect() {
        if (this.state.network.substring(0, 7) === "unclass") {
            if (document.getElementById("navCheck").checked) {
                document.getElementById("BWselect").className = "";
            } else {
                document.getElementById("BWselect").className = "no-display";
                document.getElementById("BWtype").value = "null";
            }
        }
    }

    getSelectValues(select) {
        let result = [];
        const options = select && select.options;
        let opt;

        for (let i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    }

    async sendTransaction() {
        //update display
        document.getElementById("confirm").className = "";
        document.getElementById("subForm").className = "no-display";
        this.setState({ resp: { "message": "Please Wait..." } })
        //send updated subscriptions to the api
        const navChecked = (document.getElementById("navCheck") && document.getElementById("navCheck").checked ? true : false);
        const unsubscribe = document.getElementById("unsubscribe").checked;
        let warnValues = [];
        if (navChecked) {
            warnValues = this.getSelectValues(document.getElementById("BWtype"));
        }
        const formData = {
            "id": this.state.subscr.id,
            "email": this.state.subscr.email,
            "subscrNavWarn": navChecked,
            "subscrPubs": (document.getElementById("pubCheck") && document.getElementById("pubCheck").checked ? true : false),
            "subscrNTM": document.getElementById("ntmCheck").checked,
            "navarea4": (warnValues.indexOf("4") >= 0 ? true : false),
            "hydrolant": (warnValues.indexOf("lant") >= 0 ? true : false),
            "navarea12": (warnValues.indexOf("12") >= 0 ? true : false),
            "hydropac": (warnValues.indexOf("pac") >= 0 ? true : false),
            "hydroarc": (warnValues.indexOf("arc") >= 0 ? true : false),
            "isConfirmed": true,
        }

        if (unsubscribe) {
            const urlDel = "email-subscribers?uid=" + this.state.code;
            await msiFetch.deleteRawData(urlDel, null, 'application/json')
                .then(resp => {
                    this.setState({ resp })
                });
        }
        else {
            const url = "email-subscribers/manage-update";
            await msiFetch.postRawData(url, formData, 'application/json')
                .then(resp => {
                    this.setState({ resp })
                });
        }
    }

    async validateFields() {
        let alertMessage = "";

        if (document.getElementById("navCheck") && document.getElementById("navCheck").checked) {
            document.getElementById("BWselect").className = "";
            if (document.getElementById("BWtype").value === "null") {
                alertMessage += "Select Type for BW is required \n";
                document.getElementById("lblBWtype").className = "form-alert";
            } else {
                document.getElementById("lblBWtype").className = "";
            }
        }

        const navCheck = (document.getElementById("navCheck") && document.getElementById("navCheck").checked);
        const pubCheck = (document.getElementById("pubCheck") && document.getElementById("pubCheck").checked)

        if (!document.getElementById("ntmCheck").checked && !navCheck && !pubCheck && !document.getElementById("unsubscribe").checked) {
            alertMessage += "At least one subscription type or unsubscribe option must be selected \n";
            document.getElementById("lblDetails").className = "form-alert";
        } else {
            document.getElementById("lblDetails").className = "";
        }

        //if we have a message to show the user, show it
        if (alertMessage !== "") {
            window.alert(alertMessage);
        }
        else {
            //if everything is valid, move on to store data in the database
            //send some kind of confirmation that this is been submitted
            await this.sendTransaction();
            this.clearForm();
        }
    }

    clearForm() {
        this.unsubscribeAll();
        document.getElementById("unsubscribe").checked = false;
    }

    unsubscribeAll() {
        document.getElementById("ntmCheck").checked = false;
        if (document.getElementById("navCheck")) {
            document.getElementById("navCheck").checked = false;
            document.getElementById("BWtype").value = "null";
            document.getElementById("BWselect").className = "no-display";
        }
        if (document.getElementById("pubCheck")) {
            document.getElementById("pubCheck").checked = false;
        }
    }

    render() {
        if (this.state.subscr === "") {
            var mailto = "mailto:" + this.state.webmasterEmail;
            return (
                <div className="container left-align" id="subscriptions">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6 center-align">
                        We are unable to locate subscriber information on your email account.<br />
                    Please contact the Webmaster at for assistance at <a href={mailto}>{this.state.webmasterEmail}</a>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id="subscriptions">
                    <div id="subForm" className="container left-align">
                        <div dangerouslySetInnerHTML={createMarkup(this.state.pagecontent)} />
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <table className="table left-align">
                                <tbody>
                                    <tr>
                                        <td className="no-borders">
                                            <b><span id="email"><h4>{this.state.subscr.email}</h4></span></b>
                                    Please update your subscriptions below.
                               </td>
                                    </tr>
                                    <tr id="BWselect" className="no-display">
                                        <td className="no-borders"><span id="lblBWtype"> Select Type for BW* <br /></span>
                                            <span><i>To select multiple options hold down the control (ctrl) button (Windows) or command button (Mac). </i><br /></span>
                                            <select multiple className="form-control" id="BWtype">
                                                <option value="4">NAVAREA IV </option>
                                                <option value="lant">HYDROLANT</option>
                                                <option value="12">NAVAREA XII </option>
                                                <option value="pac">HYDROPAC</option>
                                                <option value="arc">HYDROARC</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="no-borders"><span id="lblDetails">Choose subscription(s): </span><br />
                                            <input type="checkbox" name="ntm" id="ntmCheck" value="NTM" />Notice to Mariners<br />
                                            {this.state.network.substring(0, 7) === "unclass" ? [
                                                <input type="checkbox" name="bw" id="navCheck" value="BW" key="BW" onClick={this.showNavselect} />,
                                                <span key="warnlabel">Navigational Warnings</span>,
                                                <br key="br1" />,
                                                <input type="checkbox" name="pubs" id="pubCheck" value="Pubs" key="Pub" />,
                                                <span key="publabel">Publication Updates</span>,
                                                <br key="br2" />
                                            ] : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="no-borders">
                                            <hr />
                                            <input type="checkbox" name="unsubscribe" id="unsubscribe" value="unsubscribe" onClick={this.unsubscribeAll.bind(this)} /> UNSUBSCRIBE ALL
                                (remove from all Maritime Safety subscriptions)<br />
                                            <hr />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="center-align no-borders"><input type="button" value="Submit" onClick={this.validateFields.bind(this)} />
                                &nbsp;
                                <input type="reset" value="Clear form" onClick={this.clearForm.bind(this)} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="confirm" className="no-display">
                        <div className="center-align">
                            {this.state.resp["message"]}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (rootReducer) => ({
    rootReducer
});

export default withRouter(connect(mapStateToProps)(EditSubscription));