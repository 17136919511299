export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const UPDATE_USERNAME = 'UPDATE_USERNAME';
export const UPDATE_USEREMAIL = 'UPDATE_USEREMAIL';
export const ADMIN_UPDATE = 'ADMIN_UPDATE';
export const CONTENT_UPDATE = 'CONTENT_UPDATE';
export const SEARCH_CONTENT_UPDATE = 'SEARCH_CONTENT_UPDATE';


/*
 * action creators
 */

//User auth successful
export const authSuccess = () => ({
    type: AUTH_SUCCESS
});

//user auth failed
export const authFail = () => ({
    type: AUTH_FAIL
});

//update the username once authenticated
export const updateUserName = (data,label) => ({
    type: UPDATE_USERNAME,
    data,
    label
});

//update the email once authenticated
export const updateUserEmail = (data,label) => ({
    type: UPDATE_USEREMAIL,
    data,
    label
});

export const adminUpdate = (data, label) => ({
    type: ADMIN_UPDATE,
    data,
    label
})

export const contentUpdate = (data, dateTime) => ({
    type: CONTENT_UPDATE,
    data,
    dateTime
})

export const searchContentUpdate = (data) => ({
    type: SEARCH_CONTENT_UPDATE,
    data
})