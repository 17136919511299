const d = new Date();
var formattedDate =
  d.getFullYear() +
  "-" +
  ("0" + (d.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + d.getDate()).slice(-2);
formattedDate =
  formattedDate +
  "T" +
  ("0" + d.getHours()).slice(-2) +
  ":" +
  ("0" + d.getMinutes()).slice(-2) +
  ":" +
  ("0" + d.getSeconds()).slice(-2);

function convertDate(date) {
  //convert to ISO date
  const d = new Date(date);
  var formattedDate =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2);
  formattedDate =
    formattedDate +
    "T" +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2);
  return formattedDate;
}

function deconvertDate(date) {
  if (date) {
    //take a date from the server (in utc) and convert it to mm/dd/yyyy
    var d = new Date(date);
    //javascript automatically putting the initial date as your local time, so add the offset
    var min = d.getTimezoneOffset();
    d = new Date(d.getTime() + min * 60000);
    return d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
  } else {
    return null;
  }
}

function deconvertTime(datetime) {
  if (datetime) {
    //take a datetime from the server (in utc) and deconvert it to a utc string
    var d = new Date(datetime);
    //javascript automatically putting the initial date as your local time, so remove the offset
    var min = d.getTimezoneOffset();

    d = new Date(d.getTime() - min * 60000);
    return d.toUTCString();
  } else {
    return null;
  }
}

export { formattedDate, convertDate, deconvertDate, deconvertTime };
