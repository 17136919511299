import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';

class SRTMar extends Component {
    render(){
        return(
            <div id="sight-red-tables-mar-nav">
                <div id="srtmar-descrip">
                    <PageContent divid="srtmar-descrip" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="SRTMar" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default SRTMar;