/*
  Author: Patrick McCallion
  This component will be the query results linked to the SMAPS online database for data post May, 2022.
*/

import React from "react";
import "../../css/queryResults.css";

function getTableHeaders(queryUrl) {
  return (
    <thead className="center-align">
      <tr>
        <td colSpan={42} className="bg-gray-lighter query-title">
          SMAPS Warning Messages
          <br />
          Query Results
        </td>
      </tr>
      <tr align="left">
        <td className="case-capitalize" colSpan={42} id="searchTerms">
          <b>Query Search Terms: </b>
          <br />
          {getQueryHeader(queryUrl)}
        </td>
      </tr>
    </thead>
  );
}

function getQueryHeader(queryUrl) {
  var regexUrl = queryUrl.replace(/.*?\?(.*?)&output=html/i, "$1");
  //prettier-ignore
  regexUrl = regexUrl.replace("output=html", "");
  regexUrl = regexUrl.replace(/.*?\?/, "");
  regexUrl = regexUrl.replace("navArea=4", "Message Type: NAVAREA IV ");
  regexUrl = regexUrl.replace("navArea=12", "Message Type: NAVAREA XII ");
  regexUrl = regexUrl.replace("navArea=A", "Message Type: HYDROLANT ");
  regexUrl = regexUrl.replace("navArea=P", "Message Type: HYDROPAC ");
  regexUrl = regexUrl.replace("navArea=C", "Message Type: HYDROARC ");
  regexUrl = regexUrl.replace("subregion=", "Subregion: ");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=201", "DNC Region: 1 - South Atlantic Ocean");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=202", "DNC Region: 2 - South Atlantic Ocean");
  regexUrl = regexUrl.replace("dncRegion=203", "DNC Region 3 - Indian Ocean");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=204", "DNC Region 4 - Western Australia");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=205", "DNC Region 5 - Eastern Australia");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=206", "DNC Region 6 - South Pacific Ocean");

  regexUrl = regexUrl.replace("dncRegion=207", "DNC Region 7 - South America");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=208", "DNC Region 8 - Southern Europe");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=209", "DNC Region 9 - Central Mediterranean");
  regexUrl = regexUrl.replace("dncRegion=210", "DNC Region 10 - Middle East");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=211", "DNC Region 11 - South China Sea");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=212", "DNC Region 12 - Japan/North Pacific");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=213", "DNC Region 13 - North America West");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=214", "DNC Region 14 - Central America/Caribbean Sea");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=215", "DNC Region 15 - Gulf of Mexico/Straits of Florida");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=216", "DNC Region 16 - Bahamas/Bermuda");
  //prettier-ignore
  regexUrl = regexUrl.replace( "dncRegion=217", "DNC Region 17 - Eastern United States");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=218", "DNC Region 18 - Northeastern United States/Canada");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=218", "DNC Region 19 - Greenland/Iceland/United Kingdom");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=219", "DNC Region 20 - United Kingdom");
  regexUrl = regexUrl.replace("dncRegion=220", "DNC Region 21 - Norway");
  regexUrl = regexUrl.replace("dncRegion=221", "DNC Region 22 - Barents Sea");
  regexUrl = regexUrl.replace("dncRegion=222", "DNC Region 23 - Northern Asia");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=223", "DNC Region 24 - Sea of Okhotsk");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=224", "DNC Region 25 - Gulf of Alaska");
  //prettier-ignore
  regexUrl = regexUrl.replace("dncRegion=225", "DNC Region 26 - Bristish Columbia");
  regexUrl = regexUrl.replace("dncRegion=226", "DNC Region 27 - Arctic Ocean");
  regexUrl = regexUrl.replace("dncRegion=227", "DNC Region 28 - Canada");
  regexUrl = regexUrl.replace("dncRegion=228", "DNC Region 29 - Antarctica");
  regexUrl = regexUrl.replace("geoArea=", "Geographic Area: ");
  regexUrl = regexUrl.replace("latitudeLeft=", "Latitude Left: ");
  regexUrl = regexUrl.replace("longitudeLeft=", "Longitude Left: ");
  regexUrl = regexUrl.replace("latitudeRight=", "Latitude Right: ");
  regexUrl = regexUrl.replace("longitudeRight=", "Longitude Right: ");
  regexUrl = regexUrl.replace("msgNumber=", "Warning Number: ");
  regexUrl = regexUrl.replace("msgYear=", "Warning Year: ");
  regexUrl = regexUrl.replace("msgNumberStart=", "Starting Warning Number: ");
  regexUrl = regexUrl.replace("msgNumberEnd=", "Ending Warning Number: ");
  regexUrl = regexUrl.replace("msgYearStart=", "Starting Year Number: ");
  regexUrl = regexUrl.replace("msgYearEnd=", "Ending Year Number: ");
  regexUrl = regexUrl.replace("issueDateStart=", "Starting Issue Date: ");
  regexUrl = regexUrl.replace("issueDateEnd=", "Ending Issue Date: ");
  regexUrl = regexUrl.replace("status=", "Status: ");
  regexUrl = regexUrl.replace("createdOn=", "Date Message Created On: ");

  //prettier-ignore 
  //starting with double digits, otherwise itll regex the double digits values twice
  regexUrl = regexUrl.replace("category=10", "Search Category: SAR");
  regexUrl = regexUrl.replace("category=11", "Search Category: Special Operations");
  regexUrl = regexUrl.replace("category=12", "Search Category: Tsunami");
  //prettier-ignore
  regexUrl = regexUrl.replace("category=13", "Search Category: Working Vessels");
  //regexUrl = regexUrl.replace("category=14", "Search Category: ALL Categories");
  regexUrl = regexUrl.replace("category=14", "");
  regexUrl = regexUrl.replace("category=1", "Search Category: Aids to Navigation");
  regexUrl = regexUrl.replace("category=2", "Search Category: Drifting Hazard");
  regexUrl = regexUrl.replace("category=3", "Search Category: Fixed Hazard");
  regexUrl = regexUrl.replace("category=4", "Search Category: General");
  regexUrl = regexUrl.replace("category=5", "Search Category: Ice");
  //prettier-ignore
  regexUrl = regexUrl.replace("category=6", "Search Category: In-Force Bulletins");
  regexUrl = regexUrl.replace("category=7", "Search Category: MODU");
  regexUrl = regexUrl.replace("category=8", "Search Category: Piracy");
  regexUrl = regexUrl.replace("category=9", "Search Category: Radio Services");
  regexUrl = regexUrl
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n");
  regexUrl = regexUrl.replace(/%20/gi, " ");
  return regexUrl;
}

function msgTextNormalizer(msgText) {
  var normalizedText = msgText.split("\\n").map((newLine) => <p>{newLine}</p>);
  return normalizedText;
}
//prettier-ignore
function cancelledText(text) {
  var cancelString = [];
  cancelString.push(text);
  var cancelledText = cancelString.map((cancelTxt) => 
    <span className="cancelled">
      <b>{cancelTxt}</b>
    </span>
  );
  return cancelledText[0];
}

function formatData(query, data) {
  //return empty list if its already empty
  if (data["smaps"]) {
    if (data["smaps"].length === 0) {
      return data["smaps"];
    }
    //else format the data
    else {
      var dataItems = [];
      //JG 20200511 customer request of functionality to show all items sorted by area, year, msgNumber
      if (
        //checking for presence of either NAVAREA, subregion, DNC Region, or Lat/Long
        query.indexOf("navArea") >= 0 ||
        query.indexOf("subRegion") >= 0 ||
        query.indexOf("dncRegion") >= 0 ||
        (query.indexOf("latitude") >= 0 && query.indexOf("longitude") >= 0)
      ) {
        //for each row that is returned... format it into a card
        for (var i = 0; i < data["smaps"].length; i++) {
          dataItems.push(
            <DataItem data={data["smaps"][i]} key={data["smaps"][i]} />
          );
        }
      } else {
        var area = "";
        for (var j = 0; j < data["smaps"].length; j++) {
          const dataItem = data["smaps"][j];
          if (dataItem.navArea !== area) {
            //add in a new row to display the nav area
            dataItems.push(
              <tr>
                <td
                  colSpan={42}
                  align="center"
                  className="bg-gray-lighter text-bold"
                >
                  {convertAreaName(dataItem.navArea)}
                </td>
              </tr>
            );
            area = dataItem.navArea;
          }
          dataItems.push(<DataItem data={dataItem} key={dataItem} />);
        }
      }
      return <tbody>{dataItems}</tbody>;
    }
  } else if (data["HYDROLANT"]) {
    //listing of all inforce warning numbers
    var inforceRows = [];
    //for each nav area ... format it into a table row
    inforceRows.push(
      <InforceRow
        data={data["NAVAREA IV"]}
        navarea={"NAVAREA IV"}
        key={"NAVAREA IV"}
      />
    );
    inforceRows.push(
      <InforceRow
        data={data["NAVAREA XII"]}
        navarea={"NAVAREA XII"}
        key={"NAVAREA XII"}
      />
    );
    inforceRows.push(
      <InforceRow
        data={data["HYDROARC"]}
        navarea={"HYDROARC"}
        key={"HYDROARC"}
      />
    );
    inforceRows.push(
      <InforceRow
        data={data["HYDROLANT"]}
        navarea={"HYDROLANT"}
        key={"HYDROLANT"}
      />
    );
    inforceRows.push(
      <InforceRow
        data={data["HYDROPAC"]}
        navarea={"HYDROPAC"}
        key={"HYDROPAC"}
      />
    );
    // inforceRows.push(<InforceRow data={data['SPECIAL WARN']} navarea={'SPECIAL WARN'} key={'SPECIAL WARN'} />);

    return <tbody>{inforceRows}</tbody>;
  } else return data;
}

function convertUTCtoLocalTime(utcTimeStamp) {
  const date = new Date(utcTimeStamp);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const InforceRow = (props) => {
  return (
    <tr id={props.navArea}>
      <td>{props.navArea}</td>
      <td>{props.data.join(", ")}</td>
    </tr>
  );
};

const DataItem = (props) => {
  var utcTimeStamp = props.data.createdOn.toString().substring(12, 16);
  const formattedDate = convertUTCtoLocalTime(utcTimeStamp);
  const createdOnYear = formattedDate;
  var cancelled = props.data.status === "CANCELED" ? true : false;
  var cancelData = "";
  if (cancelled) {
    cancelData =
      "(Cancelled on " +
      props.data.cancelledOn +
      ")";
  }

  return (
    <tr>
      <td>
        <pre className="smaps-data">
          {props.data.msgType} {props.data.msgSqncNumber}/{utcTimeStamp} (
          {props.data.subRegion})<br />
          <br />
          {cancelled ? cancelledText(cancelData) : null}
          <br />
          {msgTextNormalizer(props.data.msgText)}
          <br />
          {props.data.latitude}/{props.data.longitude}
        </pre>
      </td>
    </tr>
  );
};

function convertAreaName(navArea) {
  switch (navArea) {
    case "4":
      return "NAVAREA IV";
    case "12":
      return "NAVAREA XII";
    case "C":
      return "HYDROARC";
    case "A":
      return "HYDROLANT";
    case "P":
      return "HYDROPAC";
    case "S":
      return "Special Warnings";
    default:
      return "";
  }
}

export { getTableHeaders, formatData };
