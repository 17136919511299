import React, {Component} from 'react';
import { Link } from 'react-router-dom'

class Search extends Component {
    constructor(props){
        super(props);
        this.state = {
            resultsList: [],
        };
        this.searchText = "";
        this.articles = [];
        this.getArticles();
    }

    componentDidMount(){
        //persist what user searched in search box
        this.searchText = window.location.search.substring(3).replace(/%20/g, " ");
        document.getElementById("srch-term").value = this.searchText;

        this.manualSearch();
    }

    getArticles(){
        //get stored website articles
        const content = localStorage.getItem("reduxPersist:searchContentReducer");
        let arrList = [];
        if(Object.keys(content).length > 0){
            arrList = JSON.parse(content).searchContent;
            arrList = arrList.map(item => {
                item.pageBody = item.pageBody.replace(/<[^<>]*>/g, " ");
                return item;
            });
        }
        this.articles = arrList;
    }

    manualSearch(){
        //search... the hard way
        let results = [];
        for(let i=0; i<this.articles.length; i++){
            let indices = this.getIndicesOf(this.searchText, this.articles[i].pageBody, false);
            if(indices.length > 0){
                this.articles[i].indices = indices
                results.push(this.highlighter(this.articles[i]));
            }
        }
        this.setState({resultsList: results})
    }

    highlighter(matchItem){
        var text = matchItem.pageBody;
        var result = []
        var matches = [].concat(matchItem.indices); 
        var pair = matches.shift()
        
        for (var i = 0; i < text.length; i++) {
            var char = text.charAt(i)
            if (pair && i === pair[0]) {
            result.push('<span class="yellowHL"><b>')
            }
            result.push(char)
            if (pair && i === pair[1]) {
            result.push('</b></span>')
            pair = matches.shift()
            }
        }
        matchItem.highlight = result.join('');

        //set start and end of where values are highlighted
        matchItem.startChar = matchItem.highlight.indexOf("<");
        matchItem.endChar = matchItem.highlight.lastIndexOf(">") + 50;

        return matchItem
    };

    getIndicesOf(searchStr, str, caseSensitive) {
        let searchStrLen = searchStr.length;
        if (searchStrLen === 0) {
            return [];
        }
        let startIndex = 0, index, indices = [];
        if (!caseSensitive) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            let indexArr = [index, index + (searchStr.length-1)]
            indices.push(indexArr);
            startIndex = index + searchStrLen;
        }
        return indices;
    }

    render() {
        const count = this.state.resultsList.length;
        return (
            <div className="container left-align">
                <h5>Search Results</h5>
                <p>
                    Showing {count} results for "{this.searchText}"
                </p>
                <div id="results">
                    <table className="table table-striped">
                        <tbody>
                            {this.state.resultsList.map((result, index) => {
                                    return (<tr key={index}>
                                        <td width={20 + '%'}><Link to={result.relativeUrlPath}>{result.pageTitle}</Link></td>
                                        <td dangerouslySetInnerHTML={{__html: result.highlight.substring(result.startChar-20, result.endChar+50)}}></td>
                                    </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Search;