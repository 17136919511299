import React, { Component } from 'react';
import PageContent from '../PageContent';

class MaritimePartners extends Component {
    render() {
        return (
            <div id="maritimepartners">
                <PageContent divid="maritimepartners" />
            </div>
        );
    }
}
export default MaritimePartners;