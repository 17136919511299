import React, {Component} from 'react';
var TypeAhead = require('react-typeahead').Typeahead;

class AutoComplete extends Component {
    render(){
        const customClasses = {
            input: "form-control no-display auto-comp"
        }
        return(
            <TypeAhead
                options={this.props.data}
                maxVisible={5}
                defaultClassNames={false}
                customClasses={customClasses}
                ref="typeAhead"
            />
        );
    }
}

export default AutoComplete;