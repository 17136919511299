import React, {Component} from 'react';
import QueryPanel from '../QueryPanel';
import PageContent from '../PageContent';
const msiFetch = require('../../controllers/MsiFetch');
const downloadUrl = "/api/publications/download?key="

class Piracy extends Component {
    constructor(props){
        super(props);
        this.state = {
            recentOniPubs: [],
            pastOniPubs: [],
            asamPubs: [], 
            wtsPub: {},
            network: props.network
        }

        this.showPastReports = this.showPastReports.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.network !== prevProps.network) {
            this.setState({ network: this.props.network });
        }
    }

    async componentDidMount(){
        const asamList = await msiFetch.fetchData('media?type=ASAM');
        this.setState({asamPubs: asamList});
         
        //set recent oni pubs (breakout last 5)
        const oniPubs = await msiFetch.fetchData('media?type=ONI');
        if(oniPubs.length > 0){
            //checks for undefined
            let recentOniPubs = oniPubs.slice(-5);
            recentOniPubs = recentOniPubs.reverse();
    
            //set past oni pubs (as options for selectbox)
            let pastOniPubs = oniPubs.slice(0, oniPubs.length - 5);
            pastOniPubs = pastOniPubs.reverse();
            pastOniPubs = pastOniPubs.map(pub => {
                return <option key={pub.s3Key} value={downloadUrl + pub.s3Key + "&type=view"}>{pub.displayName}</option>
            })
            this.setState({recentOniPubs, pastOniPubs});
        } else { this.setState({oniPubs}); }
        

        //worldwide threats to shipping summary, just takes the FIRST pub it finds. Admins will need to remove old pubs...
        const wtsList = await msiFetch.fetchData('media?type=WW Threats Yearly');
        if(wtsList.length > 0){
            this.setState({wtsPub: wtsList[wtsList.length - 1]})
        } else { this.setState({wtsPub: {} }); }
    }

    showPastReports(event){
        //method opens a new window to show the ONI report (url retrieved from oni report selection box value)
        if(event.target.value){
            window.open(event.target.value, "_blank");
        }
    }

    render(){
        let fgmodURL = "";
        if(this.state.network.substring(0,7) === "unclass"){
            fgmodURL = "https://vcps.nga.mil/apps/ASAM";
        } else if(this.state.network === "secret"){
            fgmodURL = "https://fgmod.nga.smil.mil/apps/ASAM";
        } else {
            fgmodURL = "https://fgmod.nga.ic.gov/apps/ASAM";
        }
        return(
            <div id="piracy">
                <div id="oni" className="container">
                    <div className="row">
                        <div id="oni-descrip" className="left-align">
                            <PageContent  divid="oni-descrip" />                  
                        </div>
                        {/* <div className="col-sm-8 col-sm-offset-2 pub-download-bg border-around p10 mb10">      
                            <div className="col-sm-6"> 
                                <p className="section-title">Recent Messages:</p>
                                {this.state.recentOniPubs.length > 0 ? this.state.recentOniPubs.map((pub, index) => {
                                    return <p key={index} className="mb0"><a target="_blank" key={pub.s3Key} href={downloadUrl + pub.s3Key + "&type=view"} className="">{pub.displayName}</a></p>
                                }) : null}
                            </div>
                            <div className="col-sm-6">
                                <p className="section-title">Past Messages:</p>
                                <select className="form-control" id="oniReport" onChange={this.showPastReports} defaultValue="null">
                                    <option value="null" disabled>Select Past Message</option>
                                    {this.state.pastOniPubs}
                                </select>
                            </div>
                        </div>
                        <a href={downloadUrl + this.state.wtsPub.s3Key + "&type=view"} target="_blank" className="btn">Yearly Worldwide Threats to Shipping Summary</a> */}
                    </div>
                </div>
                <div id="divider" className="bg-secondary-dark">
                </div>
                <div id="asam" className="container">
                    <div className="row">
                        <div id="asam-descrip" className="left-align">
                            <PageContent divid="asam-descrip" />                  
                        </div>
                        <div className="col-sm-8 col-sm-offset-2 pub-download-bg border-around p10">              
                            <p className="section-title">Download ASAM Geospatial Files</p>
                            {this.state.asamPubs.length > 0 ? this.state.asamPubs.map(pub => { 
                                return <a key={pub.s3Key} href={downloadUrl + pub.s3Key + "&type=download"} target="_blank" className="btn btn-asam">{pub.displayName}</a>
                                }) : null}
		            {/* {this.state.network === "unclassified" ? null 
                                : <a href={fgmodURL} key="asamApp" target="_blank" rel="noopener noreferrer" className="btn btn-asam">Launch ASAM Activity Board</a>} */}
                        </div>
                    </div>
                </div>
                {/* <div id="ntm-query" className="container">
                    <h5 className="mb0">SEARCH ASAM DATABASE</h5>
                    <QueryPanel category="ASAM" />
                </div> */}
            </div>
        );
    }
}

export default Piracy;
