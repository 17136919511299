import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';


class SRTAir extends Component {
    render(){
        return(
            <div id="sight-red-tables-air-nav">
                <div id="srtair-descrip">
                    <PageContent divid="srtair-descrip" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="SRTAir" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default SRTAir;