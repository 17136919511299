import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';

class ICOS extends Component {
    render(){
        return(
            <div id="international-code-of-signals">
                <div id="icos-descrip">
                <PageContent divid="icos-descrip" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="ICOS" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default ICOS;