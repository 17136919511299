const LatLongConv = require('../../../utils/LatLongConv');

function getNGALOL(url){
    const volume = document.getElementById("sel4").value;
    const filterType = document.getElementById("sel5").value;
    const dataType = document.getElementById("ngalolReturn").value
    const output = document.getElementById("outputSel").value;
    const range = document.getElementById("range").value;
    const geoHDR = document.getElementById("geoHDR").value;
    const geoSHDR = document.getElementById("geoSHDR").value;
    const removal = (window.location.pathname === "/NTM" ? true : false);

    let number, featureNumberStart, featureNumberEnd;
    let leftLatDegree, leftLatMin, leftLatDir;
    let leftLonDegree, leftLonMin, leftLonDir;
    let rightLatDegree, rightLatMin, rightLatDir;
    let rightLonDegree, rightLonMin, rightLonDir;
    let leftCoords, rightCoords;
    
    //test for existance of selected volume number
    if(volume > 0) {
        url += 'volume=' + volume;

        //if user filtered by specific range
        if (dataType === 'lights-buoys' && range !== "" && range != null){
            url += '&range=' + range;
        }
        // if user filtered by specific aid number, get the aid number value
        if (filterType === "featureNumber"){
            number = document.getElementById("startRangeInput6").value
            url += '&featureNumber=' + number;
        }
        //if user filtered by aid number range, get the start and end values
        else if (filterType === "featureNumberRange"){
            featureNumberStart = document.getElementById("startRangeInput6").value
            featureNumberEnd = document.getElementById("endRangeInput6").value
            url += '&featureNumberStart=' + featureNumberStart + '&featureNumberEnd=' + featureNumberEnd;
        }
        // if user filtered by geographic header
        else if (filterType === "geoHDR"){
            if(geoSHDR !== "null"){
                url += '&geoHDRName=' + geoHDR + '&geoSHDRName=' + geoSHDR; 
            } else {
                url += '&geoHDRName=' + geoHDR;
            }
        }
        //else test if the user filtered by the bounding box values
        else if (filterType === "geographicArea") {
            leftLatDegree = parseFloat(document.getElementById("leftLatDegree").value);
            leftLatMin = parseFloat(document.getElementById("leftLatMinute").value);
            leftLatDir = document.getElementById("leftLatDirection").value;
            leftLonDegree = parseFloat(document.getElementById("leftLonDegree").value);
            leftLonMin = parseFloat(document.getElementById("leftLonMinute").value);
            leftLonDir = document.getElementById("leftLonDirection").value
            rightLatDegree = parseFloat(document.getElementById("rightLatDegree").value);
            rightLatMin = parseFloat(document.getElementById("rightLatMinute").value);
            rightLatDir = document.getElementById("rightLatDirection").value;
            rightLonDegree = parseFloat(document.getElementById("rightLonDegree").value);
            rightLonMin = parseFloat(document.getElementById("rightLonMinute").value);
            rightLonDir = document.getElementById("rightLonDirection").value;

            leftCoords = LatLongConv.convertToDec(leftLatDegree, leftLatMin, 0, leftLatDir, leftLonDegree, leftLonMin, 0, leftLonDir);
            rightCoords = LatLongConv.convertToDec(rightLatDegree, rightLatMin, 0, rightLatDir, rightLonDegree, rightLonMin, 0, rightLonDir);
            url += '&latitudeLeft=' + leftCoords['latitude'] + '&longitudeLeft=' + leftCoords['longitude'] + '&latitudeRight=' + rightCoords['latitude'] + '&longitudeRight=' + rightCoords['longitude'];
        }
    //else we want to query all of the volumes    
    } else {
        // if user filtered by specific aid number, get the aid number value
        if (filterType === "featureNumber"){
            number = document.getElementById("startRangeInput6").value;
            url += 'featureNumber=' + number;
        }
        //if user filtered by aid number range, get the start and end values
        else if (filterType === "featureNumberRange"){
            featureNumberStart = document.getElementById("startRangeInput6").value
            featureNumberEnd = document.getElementById("endRangeInput6").value
            url += 'featureNumberStart=' + featureNumberStart + '&featureNumberEnd=' + featureNumberEnd;
        }
        // if user filtered by geographic header
        else if (filterType === "geoHDR"){
            if(geoSHDR !== "null"){
                url += 'geoHDRName=' + geoHDR + '&geoSHDRName=' + geoSHDR; 
            } else {
                url += 'geoHDRName=' + geoHDR;
            }
        }
        //else test if the user filtered by the bounding box values
        else if (filterType === "geographicArea") {
            leftLatDegree = parseFloat(document.getElementById("leftLatDegree").value);
            leftLatMin = parseFloat(document.getElementById("leftLatMinute").value);
            leftLatDir = document.getElementById("leftLatDirection").value;
            leftLonDegree = parseFloat(document.getElementById("leftLonDegree").value);
            leftLonMin = parseFloat(document.getElementById("leftLonMinute").value);
            leftLonDir = document.getElementById("leftLonDirection").value
            rightLatDegree = parseFloat(document.getElementById("rightLatDegree").value);
            rightLatMin = parseFloat(document.getElementById("rightLatMinute").value);
            rightLatDir = document.getElementById("rightLatDirection").value;
            rightLonDegree = parseFloat(document.getElementById("rightLonDegree").value);
            rightLonMin = parseFloat(document.getElementById("rightLonMinute").value);
            rightLonDir = document.getElementById("rightLonDirection").value;

            leftCoords = LatLongConv.convertToDec(leftLatDegree, leftLatMin, 0, leftLatDir, leftLonDegree, leftLonMin, 0, leftLonDir);
            rightCoords = LatLongConv.convertToDec(rightLatDegree, rightLatMin, 0, rightLatDir, rightLonDegree, rightLonMin, 0, rightLonDir);
            url += 'latitudeLeft=' + leftCoords['latitude'] + '&longitudeLeft=' + leftCoords['longitude'] + '&latitudeRight=' + rightCoords['latitude'] + '&longitudeRight=' + rightCoords['longitude'];
        }

        if (filterType !== "null" && dataType === "lights-buoys") {
            //if user filtered by specific range
            //we can use &range here because we already built the url with a filter
            if (range !== "" && range != null){
                url += '&range=' + range;
            }
        } else if (dataType === "lights-buoys" && range !== "" && range != null) {
            //if user filtered by specific range
            //we must use ?range here because we haven't built the url with any filter yet
            url += 'range=' + range;
        }
    }

    if(url === 'publications/ngalol/'+dataType){
        //user didn't filter anything
        url += "includeRemovals=" + removal;
    } else {
        url += "&includeRemovals=" + removal;
    }

    url += "&output=" + output;
    return url;
}

export { getNGALOL };