import React, { Component } from "react";
import PageContent from "../PageContent";

const downloadUrl = require("../Publications/tmp/pubConstants").downloadUrl;

class UNTM extends Component {
  render() {
    return [
      <div className="row" key="title">
        <h5>Notice to Mariners</h5>
      </div>,
      <div className="row" key="descrip">
        <div className="col-sm-9" align="left">
          <div id="ntm-descrip">
            <PageContent divid="ntm-descrip" />
          </div>
        </div>
        <div className="col-sm-3">
          <div
            id="ntm-links"
            align="center"
            className="pub-download-bg pr15 pl15 p10 mb10"
          >
            <label htmlFor="selUNTM" className="view-ntm-label">
              View NTMs
            </label>
            <br />
            {this.props.ntm.state.ntmLinks}
            <select
              className="form-control"
              id="selUNTM"
              defaultValue="null"
              onChange={this.props.ntm.showModal.bind(
                this.props.ntm,
                "untm",
                null
              )}
            >
              <option value="null" disabled>
                Select NTM...
              </option>
              {this.props.ntm.state.ntmOptions}
            </select>
          </div>
          <a
            href={
              downloadUrl +
              "16920954/SFH00000/UNTM/Special_Notice.pdf&type=view"
            }
            target="_blank"
            className="btn btn-ntm special-para-btn"
          >
            NTM 1 SPECIAL PARAGRAPHS
          </a>
          <br />
          <a
            href={
              downloadUrl +
              "16920959/SFH00000/snc_cancellation_list.xlsx&type=view"
            }
            target="_blank"
            className="btn btn-ntm special-para-btn"
          >
            SNC CANCELLATION LIST
          </a>
        </div>
      </div>,
    ];
  }
}

export default UNTM;
