import React, {Component} from 'react';
import PageContent from '../PageContent';

class SHAR extends Component {
    render() {
      return (
            <div className="SHAR">
                <PageContent divid="shar-report" />
            </div>
        );
    }
}

export default SHAR;