import React, { Component } from 'react';

//MsiHeader component created in referrance to Jira ticket MRTMHYDRA-959. Ticket requested that specific styled links be added in a header banner.
//The "network" prop was used to to render enclave specific urls (UC, UC limdis, S, TS).

class MsiHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            network: props.network
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.network !== this.props.network) {
            this.setState({ network: this.props.network });
        }
    }
    render() {
        let dncURL = "";
        let esyncURL = "";
        let encURL = "";
        let librariesURL = "";
        let podsURL = "";
        let todURL = "";
        if (this.state.network === "unclassified") {
            dncURL = "https://dnc.nga.mil/";
        } else if (this.state.network === "unclassified//limdis") {
            dncURL = "https://dnc.geo.nga.mil/";
            librariesURL = "https://www.geointel.nga.mil/products/dnc/#update";
            esyncURL = "https://esync.gs.mil/client/";
            encURL = "https://dnc.geo.nga.mil/dncp/DNC/ENC.php";
            podsURL = "https://www.geo.nga.mil/products/print_on_demand/index.php";
            todURL = "https://www.geo.nga.mil/products/dnc/tod/index0.htm";
        } else if (this.state.network === "secret") {
            dncURL = "http://dnc.nga.smil.mil";
            librariesURL = "https://www.geoint.nga.smil.mil/products/dnc1/index.php";
            podsURL = "https://www.geoint.nga.smil.mil/products/print_on_demand/index.php";
            todURL = "https://www.geoint.nga.smil.mil/products/dnc1/tod/index.htm";
        } else if (this.state.network === "top secret") {
            dncURL = "https://dnc.nga.ic.gov";
            librariesURL = "https://www.geoint.nga.ic.gov/products/dnc1";
            podsURL = "https://www.geoint.nga.ic.gov/products/print_on_demand/index.php";
            todURL = "https://www.geoint.nga.ic.gov/products/dnc1/tod";
        }
        //Bootstrap library was used to construct a navbar. Ternary statements were used to render urls based on what network the user is on.
        return (
            <div>
                <nav className="navbar navbar-static-top navbar-inverse msi-header">
                    <div className="header-links">
                        <a href={dncURL} target="_blank" className="link-white">Digital Nautical Charts (DNC) VPF Database Updates (VDU)</a>
                    </div>
                    {this.state.network === "unclassified//limdis" ? (
                        <>
                            <div className='header-links'>
                                <a href={esyncURL} target="_blank" className="link-white">
                                    ESYNC
                                </a>
                            </div>
                            <div className="header-links">
                                <a href={encURL} target="_blank" className="link-white">
                                    Electronic Navigational Charts (ENC)
                                </a>
                            </div>
                        </>
                    ) : null}

                    {this.state.network === ("unclassified//limdis") || this.state.network === ("secret") || this.state.network === ("top secret") ?
                        (
                            <>
                                <div className="header-links">
                                    <a href={librariesURL} target="_blank" className="link-white">
                                        Digital Nautical Charts (DNC) Updated Libraries
                                    </a>
                                </div>

                                <div className="header-links">
                                    <a href={podsURL} target="_blank" className="link-white">
                                        Print On Demand (PODS)
                                    </a>
                                </div>
                                <div className="header-links">
                                    <a href={todURL} target="_blank" className="link-white">
                                        Tactical Ocean Data (TOD)
                                    </a>
                                </div>
                            </>
                        ) : null}
                </nav>
            </div>
        )
    }
}
export default MsiHeader;