import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';


class SDE extends Component {
    render(){
        
        return(
            <div id="sail-dir-enroute">
                <div id="sde-descrip">
                    <PageContent divid="sde-descrip" />
                </div>
                <br /><br /><br />
                <div id="pub-download">
                    <PubDownload category="SDE" pubTypeId={this.props.pubTypeId} network={this.props.network}  />
                </div>
            </div>
        );
    }
}

export default SDE;