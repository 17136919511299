import React, {Component} from 'react';
import PageContent from '../PageContent';

class MiscProducts extends Component {
    constructor(props){
        super(props);
        this.state = {
            pubs: []
        }
    }
    
    render(){
        return(
            <div className="miscProducts">
                <PageContent divid="miscProducts" />
            </div>
        );
    }
}

export default MiscProducts;
