import React, {Component} from 'react';
const msiFetch = require('../../controllers/MsiFetch');

class ASAMQueryPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            subregions: [],
            navareas: [],
            areaOptions: []
        }
        this.clearFilters = this.clearFilters.bind(this);
        this.showFilterOptions = this.showFilterOptions.bind(this);
        this.showFilterDates = this.showFilterDates.bind(this);
        this.getASAM = this.getASAM.bind(this);
        this.setNavAreaSubregionSelect = this.setNavAreaSubregionSelect.bind(this);
        this.validateFields = this.validateFields.bind(this);
    }

    async componentDidMount(){
        //api call to get the nav areas and subregions
        const url = "publications/asam/areas"
        await msiFetch.fetchData(url)
        .then(data => {
            this.setState({subregions: data["subregions"], navareas: data["navAreas"]});
        })
        .catch(error => {
            this.setState({subregions: [], navareas: []});
        });
    }

    showFilterOptions(){
        const filterType = document.getElementById("selFilter").value;
        const label = document.getElementById("filterLabel");
        const startReferenceYear = document.getElementById("startReferenceYear");
        const startReferenceNumber = document.getElementById("startReferenceNumber");
        const slash1 = document.getElementById("slash1");
        const selNavAreaSubregion = document.getElementById("selNavAreaSubregion");
        const endReference = document.getElementById("selector-col3");

        const displayRef = "form-control fourty-width float-left inline";
        const displaySel = "form-control";
        const noDisplay = "no-display";

        //clear out areaOptions state
        this.setState({areaOptions: []})

        switch(filterType) {
            case "reference":
                label.innerHTML = "YYYY-NNNN";
                startReferenceNumber.className = displayRef;
                startReferenceYear.className = displayRef;
                endReference.className = noDisplay;
                slash1.className = "inline float-left";
                selNavAreaSubregion.className = noDisplay;
                break;
            case "referenceRange":
                label.innerHTML = "YYYY-NNNN";
                startReferenceNumber.className = displayRef;
                startReferenceYear.className = displayRef;
                endReference.className = "col-sm-3";
                slash1.className = "inline float-left";
                selNavAreaSubregion.className = noDisplay;
                break;
            case "navArea":
                label.innerHTML = "";
                startReferenceNumber.className = noDisplay;
                startReferenceYear.className = noDisplay;
                endReference.className = noDisplay;
                slash1.className = noDisplay;

                //set area options
                this.setNavAreaSubregionSelect(filterType);

                selNavAreaSubregion.className = displaySel;
                break;
            case "subreg":
                label.innerHTML = "SELECT &nbsp;&nbsp;&nbsp;<a target='_blank' href='/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=veiw'>(View Geographic Locator)</a>";
                startReferenceNumber.className = noDisplay;
                startReferenceYear.className = noDisplay;
                endReference.className = noDisplay;
                slash1.className = noDisplay;

                //set area options
                this.setNavAreaSubregionSelect(filterType);

                selNavAreaSubregion.className = displaySel;
                break;
            default: //display nothing
                label.innerHTML = "";
                startReferenceNumber.className = noDisplay;
                startReferenceYear.className = noDisplay;
                endReference.className = noDisplay;
                slash1.className = noDisplay;
                selNavAreaSubregion.className = noDisplay;
                break;
        }
    }

    showFilterDates(){
        const startDate = document.getElementById("selector-col5");
        const endDate = document.getElementById("selector-col6");
        const year = document.getElementById("selector-col10");
        const filterType = document.getElementById("sel5").value;
        
        const display = "col-sm-3";
        const noDisplay = "no-display";

        switch(filterType){
            case "date":
                startDate.className = display;
                endDate.className = noDisplay;
                year.className = noDisplay;
                break;
            case "dateRange":
                startDate.className = display;
                endDate.className = display;
                year.className = noDisplay;
                break;
            case "year":
                startDate.className = noDisplay;
                endDate.className = noDisplay;
                year.className = display;
                break
            default:
                startDate.className = noDisplay;
                endDate.className = noDisplay;
                year.className = noDisplay;
                break;
        }
    }

    createOptions(data){
        //creates an <option> for each element of the list
        var list = [];

        for(var i=0; i<data.length;i++){
            if(typeof data[i] === "object"){
                data = data.sort((a, b) => (parseInt(a.int,10) > parseInt(b.int,10)) ? 1 : -1)
                list.push(<option key={data[i]['int']} value={data[i]['roman']}>{data[i]['roman']}</option>);
            } else {
                list.push(<option key={data[i]} value={data[i]}>{data[i]}</option>);
            }
        }
        return list;
    }

    setNavAreaSubregionSelect(dataType){
        let list = [];
        //add items to selectbox based on data type
        if(dataType === "navArea"){
            list = this.createOptions(this.state.navareas);
        } else {
            list = this.createOptions(this.state.subregions);
        }
        this.setState({areaOptions: list });
    }

    validateFields(){
        var filterType = document.getElementById("selFilter").value;
        var dateType = document.getElementById("sel5").value;
        var alertMessage = "";

        //check reference numbers
        if(filterType === "reference"){
            if(document.getElementById("startReferenceYear").value < 1000){
                alertMessage += "You must enter a 4-digit year.\n";
            }
            if(document.getElementById("startReferenceNumber").value < 1){
                alertMessage += "You must enter a valid ref number.";
            }
        }
        if(filterType === "referenceRange"){
            if(document.getElementById("startReferenceYear").value < 1000){
                alertMessage += "You must enter a 4-digit starting year.\n";
            }
            if(document.getElementById("startReferenceNumber").value < 1){
                alertMessage += "You must enter a valid starting ref number.\n";
            }
            if(document.getElementById("endReferenceYear").value < 1000){
                alertMessage += "You must enter a 4-digit ending year.\n";
            }
            if(document.getElementById("endReferenceNumber").value < 1){
                alertMessage += "You must enter a valid ending ref number.\n";
            }
        }

        //check dates
        if(dateType === "date"){
            if(document.getElementById("startDate").value === null || document.getElementById("startDate").value === ""){
                alertMessage += "You must enter/select a valid date."
            }
        }
        if(dateType === "dateRange"){
            if(document.getElementById("startDate").value === null || document.getElementById("startDate").value === ""){
                alertMessage += "You must enter/select a valid starting date.\n"
            }
            if(document.getElementById("endDate").value === null || document.getElementById("endDate").value === ""){
                alertMessage += "You must enter/select a valid ending date.\n"
            }
        }
        if(dateType === "year"){
            if(document.getElementById("year").value === null || document.getElementById("year").value === ""){
                alertMessage += "You must enter a valid year.\n"
            }
            else if(document.getElementById("year").value < 1000){
                alertMessage += "You must enter a valid 4 digit year.\n"
            }
        }

        if (alertMessage !== ""){
            window.alert(alertMessage);
        }
        else{
            //if everything is valid, move on to query
            this.getASAM();
        }
    }

    getASAM(){
        //query builder
        const filterType = document.getElementById("selFilter").value;
        const dateType = document.getElementById("sel5").value;
        const startReferenceYear = document.getElementById("startReferenceYear").value;
        const startReferenceNumber = document.getElementById("startReferenceNumber").value;
        const selNavAreaSubregion = document.getElementById("selNavAreaSubregion").value;
        const startDate = document.getElementById("startDate").value;
        const endDate = document.getElementById("endDate").value;
        const year = document.getElementById("year").value;
        const sort = document.getElementById("selSort").value;
        var url = "publications/asam?";

        //add the initial filter type to the url
        switch(filterType){
            case "reference":
                url += "reference=" + startReferenceYear + "-" + startReferenceNumber;
                break;
            case "navArea":
                url += "navArea=" + selNavAreaSubregion;
                break;
            case "subreg":
                url += "subreg=" + selNavAreaSubregion;
                break;
            default:
                url += "filter=none";
                break;
        }

        //add the date filter to the url
        switch(dateType){
            case "date":
                url += "&occurDate=" + startDate;
                break;
            case "dateRange":
                url += "&minOccurDate=" + startDate + "&maxOccurDate=" + endDate;
                break;
            case "year": 
                url += "&year=" + year;
                break;
            default:
                break;
        }

        //add sort and output to the url
        url += "&sort=" + sort;
        url += "&output=" + document.getElementById("selOutput").value;

        // open the query results window. send the api url as Request Params
        // so that we don't have to send data. Actual fetch will be done by QueryResults window
        window.open('/queryResults?'+url, '_blank');
    }

    clearFilters(){
        //all the elements that need to be set to empty
        const elementList = ["startDate", "endDate", "startReferenceYear",
            "startReferenceNumber", "endReferenceYear", "endReferenceNumber", "year"];

        //set selection boxes to null
        document.getElementById("selFilter").value = null;
        document.getElementById("sel5").value = null;

        //empty out that elementlist
        for(var i=0; i<elementList.length;i++){
            document.getElementById(elementList[i]).value = "";
        }

        //hide all the filtering boxes
        this.showFilterDates();
        this.showFilterOptions();

    }

    openQueryDirections(){
        //open the window in a specific width/height
        window.open('/queryDirections?queryType=asam', '_blank', 'toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600');
    }

    render(){
        return([
            <div className="row" key="1">
                <div className="col-sm-12 query-dir left-align">
                    <a onClick={this.openQueryDirections.bind(this)}>Query Directions</a>
                </div>
                <div className="col-sm-4" id="selector-col1" align="left">
                    <label htmlFor="selFilter" className="query-label">Retrieve</label>
                    <select className="form-control" id="selFilter" defaultValue="null" onChange={this.showFilterOptions}>
                        <option value="null">All</option>
                        <option value="subreg">by Subregion</option>
                        <option value="navArea">by NAVAREA</option>
                        <option value="reference">by Ref. Number</option>
                    </select>
                </div>
                <div className="col-sm-3" id="selector-col2" align="left">
                    <label htmlFor="startReferenceYear" className="query-label" id="filterLabel"></label><br/>
                    <input maxLength={4} type="number" className="no-display" id="startReferenceYear" /> 
                    <strong id="slash1" className="no-display">&nbsp; - &nbsp; </strong>
                    <input maxLength={4} type="number" step="10" className="no-display" id="startReferenceNumber" />
                    <select className="no-display" id="selNavAreaSubregion">
                        {this.state.areaOptions}
                    </select>
                </div>
                <div className="col-sm-3 no-display" id="selector-col3" align="left">
                    <label htmlFor="endReferenceYear" className="query-label">YYYY-NNNN</label><br/>
                    <input maxLength={4} type="number" className="form-control fourty-width float-left" id="endReferenceYear" />
                    <strong id="slash2" className="inline float-left">&nbsp; - &nbsp; </strong>
                    <input maxLength={4} type="number" step="10" className="form-control inline fourty-width float-left" id="endReferenceNumber" />
                </div>
            </div>,
            <div className="row" key="2">
                <div className="col-sm-4" id="selector-col4" align="left">
                    <label htmlFor="selDateFilter" className="query-label">Date Filter</label>
                    <select defaultValue='null' className="form-control" id="sel5" onChange={this.showFilterDates}>
                        <option value='null'>None</option>
                        <option value="date">Occurence Date</option>
                        <option value="dateRange">Occurence Date Range</option>
                        <option value="year">Year</option>

                    </select>
                </div>
                <div className="col-sm-3 no-display" id="selector-col5" align="left">
                    <label htmlFor="startDate" className="query-label" id="startDateLbl">Starting Date</label>
                    <input className="form-control" id="startDate" type="date" placeholder="YYYY-MM-DD" />
                </div>
                <div className="col-sm-3 no-display" id="selector-col6" align="left">
                    <label htmlFor="endDate" className="query-label" id="endDateLbl">Ending Date</label>
                    <input className="form-control" id="endDate" type="date" placeholder="YYYY-MM-DD" />
                </div>
                <div className="col-sm-3 no-display" id="selector-col10" align="left">
                    <label htmlFor="year" className="query-label" id="yearLbl">Year</label>
                    <input className="form-control" id="year" type="number" maxLength={4} />
                </div>
            </div>,
            <div className="row" key="3">
                <div className="col-sm-4" id="selector-col7" align="left">
                    <label htmlFor="selSort" className="query-label">Sort By</label>
                    <select defaultValue="date" className="form-control" id="selSort">
                        <option value="date">Occurrence Date</option>
                        <option value="ref">Reference Number</option>
                    </select>
                </div>
                <div className="col-sm-4" id="selector-col8" align="left">
                    <label htmlFor="selOutput" className="query-label">Output Format</label>
                    <select defaultValue="html" className="form-control" id="selOutput">
                        <option value="html">HTML</option>
                        <option value="xml">XML</option>
                        <option value="csv">CSV (Delimited text)</option>
                    </select>
                </div>
                <div className="col-sm-6 pb32" id="selector-col-9" align="left">
                    <div className="filter-buttons">
                        <a className="btn vol-btn float-left" title="Search" id="btnApplyQuery" onClick={this.validateFields}>Search</a>
                        <a className="btn vol-btn float-left" title="Clear Filters" id="btnClearQuery" onClick={this.clearFilters}>Clear</a>
                    </div>
                </div>
            </div>
        ]);
    }
}

export default ASAMQueryPanel;