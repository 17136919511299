import React from 'react';

function transformArray(array){
    if(array){
        //reduce the array into a map, creating objects using the geohdr as keys - with the value being a hash of count and geoSHDR;
        return array.reduce((total, amount) => {
            if (!total[amount[0]]){
                total[amount[0]] = [];
            }
            if(amount[1] != null){
                total[amount[0]].push(amount[1]);
            }
            return total;
        }, {});
    }
    else { return null; }
}

function numToPub(volumeNumber){
    var pub = "";
    switch(volumeNumber){
        case '110': 
            pub = "PUB 110";
            break;
        case '111':
            pub = "PUB 111";
            break;
        case '112':
            pub = "PUB 112";
            break;
        case '113':
            pub = "PUB 113";
            break;
        case '114':
            pub = "PUB 114";
            break;
        case '115': 
            pub = "PUB 115";
            break;
        case '116': 
            pub = "PUB 116";
            break;
        default:
            break;
    }
    return pub;
}

function volToRoman(volumeNumber){
    var roman = "";
    switch(volumeNumber){
        case '1': 
            roman = "VOLUME I";
            break;
        case '2':
            roman = "VOLUME II";
            break;
        case '3':
            roman = "VOLUME III";
            break;
        case '4':
            roman = "VOLUME IV";
            break;
        case '5': 
            roman = "VOLUME V";
            break;
        case '6': 
            roman = "VOLUME VI";
            break;
        case '7': 
            roman = "VOLUME VII";
            break;
        default:
            break;
    }
    return roman;
}

function latLong(){
    return (
    <section id="latLong" className="no-display p0">
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Lower Left Corner</th>
                    <th></th>
                    <th>Upper Right Corner</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Lat</td>
                    <td>
                        <input className="form-control quarter-width inline p0" id="leftLatDegree" maxLength={2}/><span className="big-char">°</span>&nbsp;
                        <input className="form-control quarter-width inline p0" id="leftLatMinute" maxLength={4} /><span className="big-char">′</span>&nbsp;
                        <select defaultValue="null" className="form-control quarter-width p0 inline" id="leftLatDirection">
                            <option value="null"></option>
                            <option value="N">N</option>
                            <option value="S">S</option>
                        </select>
                    </td>
                    <td>Lat:</td>
                    <td>
                        <input className="form-control quarter-width inline p0" id="rightLatDegree" maxLength={3} /><span className="big-char">°</span>&nbsp;
                        <input className="form-control quarter-width inline p0" id="rightLatMinute" maxLength={4} /><span className="big-char">′</span>&nbsp;
                        <select defaultValue="null" className="form-control quarter-width p0 inline" id="rightLatDirection">
                            <option value="null"></option>
                            <option value="N">N</option>
                            <option value="S">S</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Lon:</td>
                    <td>
                        <input className="form-control quarter-width inline p0" id="leftLonDegree" maxLength={3} /><span className="big-char">°</span>&nbsp;
                        <input className="form-control quarter-width inline p0" id="leftLonMinute" maxLength={4} /><span className="big-char">′</span>&nbsp;
                        <select defaultValue="null" className="form-control quarter-width p0 inline" id="leftLonDirection">
                            <option value="null"></option>
                            <option value="E">E</option>
                            <option value="W">W</option>
                        </select>
                    </td>
                    <td>Lon:</td>
                    <td>
                        <input className="form-control quarter-width inline p0" id="rightLonDegree" maxLength={3} /><span className="big-char">°</span>&nbsp;
                        <input className="form-control quarter-width inline p0" id="rightLonMinute" maxLength={4} /><span className="big-char">′</span>&nbsp;
                        <select defaultValue="null" className="form-control quarter-width p0 inline" id="rightLonDirection">
                            <option value="null"></option>
                            <option value="E">E</option>
                            <option value="W">W</option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
    );
}

function validateFields(){
    var alertMessage = "";

    var leftLatDegree = document.getElementById("leftLatDegree").value;
    var leftLatMin = document.getElementById("leftLatMinute").value;
    var leftLatDir = document.getElementById("leftLatDirection").value;
    var leftLonDegree = document.getElementById("leftLonDegree").value;
    var leftLonMin = document.getElementById("leftLonMinute").value;
    var leftLonDir = document.getElementById("leftLonDirection").value
    var rightLatDegree = document.getElementById("rightLatDegree").value;
    var rightLatMin = document.getElementById("rightLatMinute").value;
    var rightLatDir = document.getElementById("rightLatDirection").value;
    var rightLonDegree = document.getElementById("rightLonDegree").value;
    var rightLonMin = document.getElementById("rightLonMinute").value;
    var rightLonDir = document.getElementById("rightLonDirection").value;

    //check upper left bound
    //is Empty
    if(leftLatDegree === "" || leftLatMin === "" || leftLonDegree === "" || leftLonMin === ""){
        alertMessage = alertMessage + "You must fill in all fields for Lower Left Corner\n"
    }
    //is out of Range
    if (leftLatDegree < 0){ alertMessage = alertMessage + "Lower Left Latitude Degrees out of Range\n"; }
    if (leftLonDegree < 0){ alertMessage = alertMessage + "Lower Left Longitude Degrees out of Range\n"; }
    if (leftLatMin < 0) { alertMessage = alertMessage + "Lower Left Latitude Minutes out of Range\n"; }
    if (leftLonMin < 0) { alertMessage = alertMessage + "Lower Left Longitude Minutes out of Range\n"; }
    //has invalid hemisphere values
    if (leftLatDegree === "0" && leftLatMin === "0" && leftLatDir !== "null"){
        alertMessage = alertMessage + "Lower Left Latitude Should Not Have a Hemisphere\n";
    } else if ((leftLatDegree > "0" || leftLatMin > "0") && leftLatDir === "null"){
        alertMessage = alertMessage + "Specify a Hemisphere for Lower Left Latitude\n"
    }
    if (leftLonDegree === "0" && leftLonMin === "0" && leftLonDir !== "null"){
        alertMessage = alertMessage + "Lower Left Longitude Should Not Have a Hemisphere\n";
    } else if ((leftLonDegree > "0" || leftLonMin > "0") && leftLonDir === "null"){
        alertMessage = alertMessage + "Specify a Hemisphere for Lower Left Longitude\n"
    }
    
    //check upper right bound
    //is Empty
    if(rightLatDegree === "" || rightLatMin === "" || rightLonDegree === "" || rightLonMin === ""){
        alertMessage = alertMessage + "You must fill in all fields for Upper Right Corner\n"
    }
    //is out of Range
    if (rightLatDegree < 0){ alertMessage = alertMessage + "Upper Right Latitude Degrees out of Range\n"; }
    if (rightLonDegree < 0){ alertMessage = alertMessage + "Upper Right Longitude Degrees out of Range\n"; }
    if (rightLatMin < 0) { alertMessage = alertMessage + "Upper Right Latitude Minutes out of Range\n"; }
    if (rightLonMin < 0) { alertMessage = alertMessage + "Upper Right Longitude Minutes out of Range\n"; }
    //has invalid hemisphere value
    if (rightLatDegree === "0" && rightLatMin === "0" && rightLatDir !== "null"){
        alertMessage = alertMessage + "Upper Right Latitude Should Not Have a Hemisphere\n";
    } else if ((rightLatDegree > "0" || rightLatMin > "0") && rightLatDir === "null"){
        alertMessage = alertMessage + "Specify a Hemisphere for Upper Right Latitude\n"
    }
    if (rightLonDegree === "0" && rightLonMin === "0" && rightLonDir !== "null"){
        alertMessage = alertMessage + "Lower Right Longitude Should Not Have a Hemisphere\n";
    } else if ((rightLonDegree > "0" || rightLonMin > "0") && rightLonDir === "null"){
        alertMessage = alertMessage + "Specify a Hemisphere for Lower Right Longitude\n"
    }

    //Crossing the 180 degree line
    if (leftLonDir === "E" && rightLonDir === "W"){
        alertMessage = alertMessage + "MBRs crossing the 180 degree line are not permitted\n"
    }

    return alertMessage;
}

export {transformArray, numToPub, volToRoman, latLong, validateFields };