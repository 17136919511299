import React, {Component} from 'react';
const msiFetch = require('../../controllers/MsiFetch');

class EmbeddedHTML extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
        }
        this.url = window.location.search.substring(1);
        this.updatePage = this.updatePage.bind(this);
    }

    async componentDidMount(){
        //grab the data from the html file from the api (s3) using the S3 key for the file
        await msiFetch.fetchData(this.url)
            .then(data => {
                this.setState({data: data});
                this.updatePage();
            })
            .catch(error => {
                window.alert(error);
                this.setState({data: []});
            });
    }

    updatePage(){
        //update the div on the embedded html component with the parsed html
        var div = document.getElementById("embeddedHtml");
        div.innerHTML = this.state.data;
    }

    render(){
        return(
            <div id="embeddedHtml">
                {(this.state.data === null ? "Content Loading..." : "")}
            </div>
        );
    }
}

export default EmbeddedHTML;