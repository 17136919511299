function convertNavArea(navArea){
    //takes the nav_area and converts it to the text
    if(navArea === "4"){
        return "NAVAREA IV";
    } else if (navArea === "12"){
        return "NAVAREA XII";
    } else if (navArea === "C"){
        return "HYDROARC"
    } else if (navArea === "A"){
        return "HYDROLANT";
    } else if (navArea === "P"){
        return "HYDROPAC";
    } else { return false }
}

export {
    convertNavArea
}