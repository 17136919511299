import React, { Component } from 'react';
import PubDownload from '../PubDownload';
import PageContent from '../../PageContent';


class FleetGuides extends Component {
    render(){
        return(
            <div id="fleet-guides">
                <div id="fleet-guides-descrip">
                    <PageContent divid="fleet-guides-descrip" />    
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="FleetGd" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default FleetGuides