import React, {Component} from 'react';
import Config from '../utils/Config';
import { withRouter } from 'react-router-dom';

var USCGLLQueryResults = require('./Publications/USCGLL/USCGLLQueryResults');
var WPIQueryResults = require('./Publications/WPI/WPIQueryResults');
var NGALOLQueryResults = require('./Publications/NGALOL/NGALOLQueryResults');
const msiFetch = require('../controllers/MsiFetch');

class QueryBreakout extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            queryType: null,
            network: "",
            webmasterEmail:""
        }
        this.url = window.location.search.substring(1);
    }

    async componentDidMount(){
        var conf = new Config();
        await conf.setAppConfig();
        this.setState({network: conf.network});
        this.setState({webmasterEmail: conf.webmasterEmail});

        await msiFetch.fetchData(this.url)
            .then(data => this.setState({data: data}))
            .catch(error => {
                window.alert(error);
                this.setState({data: []});
            });
        this.setState({queryType: Object.keys(this.state.data)[0]});

        
    }

    generateTableHeaders(){
        //top of table, shows what you queried and any notes
        if(this.state.data !== null){
            var headerList = []
            var queryUrl = this.url;
            switch(this.state.queryType) {
                case "ports":
                    //pull headers for wpi
                    headerList = WPIQueryResults.getTableHeaders(queryUrl);  
                    break;
                case "uscg-ll":
                    //pull headers for uscgll
                    headerList = USCGLLQueryResults.getTableHeaders(queryUrl);
                    break;
                case "ngalol":
                    //grab type from url
                    var type = this.url.split('/')[2].split("?")[0];
                    //pull headers for ngalol
                    headerList = NGALOLQueryResults.getTableHeaders(type, queryUrl);
                    break;
                default:
                    break;
            }
            return headerList;
        }
    }

    generateResultsHeaders(){
        // reads in data from state
        // queryType is the first key that should be sent in the returned dataset
        if(this.state.data !== null){
            var headerList = []
            var tableHead = document.getElementById('resultsHeader');
            var volumesList;
            switch(this.state.queryType) {
                case "uscg-ll":
                    //return list of table headers for uscgll
                    volumesList = USCGLLQueryResults.getVolumes(this.state.data);
                    if (volumesList.length > 1) {
                        //we actually shouldn't get here... but just in case
                        break;
                    } else {
                        if(volumesList[0] === "VOLUME V") {
                            headerList = USCGLLQueryResults.Vol5tableHeaders;
                        } else {
                            headerList = USCGLLQueryResults.tableHeaders;
                        }
                    }
                    break;
                case "ngalol":
                    volumesList = NGALOLQueryResults.getVolumes(this.state.data);
                    if (volumesList.length > 1) {
                        break;
                    } else {
                        //grab type that was queried
                        var type = this.url.split('/')[2].split("?")[0];
                        // call function to get list of table headers for the chapter
                        headerList = NGALOLQueryResults.getResultHeaders(type);
                        break;
                    }
                default:
                    break;
            }
            //append table row element
            var row = document.createElement('tr');
            tableHead.appendChild(row);
            //iterate over list, appending to table row element
            headerList.forEach(element => {
                var child = document.createElement('th');
                child.innerHTML = element;
                row.appendChild(child);
            });
        }
    }

    generateResultsBody(){
        //reads data from state
        var sortedData = []
        if(this.state.data !== null){
            switch(this.state.queryType) {
                case "ports":
                    //Return a DIV to display the user port query
                    var queryResultsCount = this.state.data["ports"].length;
                    //Use results count to choose list of port names or display single result
                    if (queryResultsCount > 1){
                        sortedData = WPIQueryResults.getErrorMessage();
                    } else {
                        sortedData = WPIQueryResults.getIndivPortInfo(this.state.data);
                    }
                    break;
                case "uscg-ll":
                    //call function to format data for table
                    sortedData = USCGLLQueryResults.formatData(this.state.data);
                    break;
                case "ngalol":
                    //grab type of data queried
                    var type = this.url.split('/')[2].split("?")[0];
                    //call function to format the data for the table
                    sortedData = NGALOLQueryResults.formatData(type, this.state.data);
                    break;
                default:
                    break;
            }
            if (sortedData.length === 0 && this.state.queryType !== "0"){ return this.noResults() }
            else if (sortedData.length === 0 && this.state.queryType === "0") { return <pre>{this.state.data}</pre> }
            else { return sortedData; }
        }
        else {
            return <tbody><tr><td colSpan={20}>Loading... Please Wait</td></tr></tbody> 
        }
    }

    noResults(){
        return <tbody><tr><td colSpan={20}>The query returned no results.</td></tr></tbody>
    }

    emailLink() {
        var email = this.state.webmasterEmail;
        var mailto = "mailto:" + email;
        return  <div>Please direct any questions or comments pertaining to the site to <a href={mailto}>{email}</a></div>
    }

    goBack(){
        this.props.history.goBack();
    }

    render(){
        return(
            <div>
                <div id="return" className="mb10">
                    <a onClick={this.goBack.bind(this)}>Return to Results</a>
                    <br/>
                </div>
                <table className="table left-align">
                    {this.generateTableHeaders()}
                    <thead id='resultsHeader' className="bg-gray-light">
                        {this.generateResultsHeaders()}
                    </thead>
                        {this.generateResultsBody()}
                </table>
                        {this.emailLink()}
            </div>
        );
    }
}

export default withRouter(QueryBreakout);