function convertDMS(lat, lng) {
    var object = {};
    if (lat) {
        var latitude = toDegreesMinutesAndSeconds(lat);
        var latitudeCardinal = Math.sign(lat) >= 0 ? "N" : "S";
    }
    if (lng) {
        var longitude = toDegreesMinutesAndSeconds(lng);
        var longitudeCardinal = Math.sign(lng) >= 0 ? "E" : "W";
    }
    object["latitude"] = latitude + '"' + latitudeCardinal;
    object["longitude"] = longitude + '"' + longitudeCardinal;
    return object;
}

function parseDMS(input) {
    //for gps information in the following format: 36°57'9" N 110°4'21" W
    var object = {};
    var parts = input.split(/[^\d\w]+/);
    object["latitude"] = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
    object["longitude"] = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);
    return object;
}

function convertToDec(latDegree, latMin, latSec, latDir, lonDegree, lonMin, lonSec, lonDir) {
    //for gps information that comes in already split into separate fields.
    var object = {};
    object["latitude"] = ConvertDMSToDD(latDegree, latMin, latSec, latDir);
    object["longitude"] = ConvertDMSToDD(lonDegree, lonMin, lonSec, lonDir);
    return object;
}

function ConvertDMSToDD(degrees, minutes, seconds, direction) {
    var dd = parseFloat(degrees) + parseFloat(minutes/60) + parseFloat(seconds/(60*60));

    if (direction === "S" || direction === "W") {
        dd = dd * -1;
    } // Don't do anything for N or E
    return dd;
}

function toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.round(((minutesNotTruncated - minutes) * 60) * 10) / 10;
    
    if (seconds === 60){
        seconds = 0;
        minutes = minutes + 1;
    }
    
    //if single digit minutes or seconds, append leading zero
    if(minutes < 10){
        minutes = "0" + minutes;
    }

    if (seconds < 10){
        seconds = "0" + seconds;
    }

    return degrees + "°" + minutes + "'" + seconds;
}

export {convertDMS, parseDMS, convertToDec};
