import React, {Component} from 'react';
import PageContent from '../PageContent';

class BrowserSupport extends Component {
    render(){
        return(
  <div id="browser-support">
                <PageContent divid="browser-support" />
            </div>    

            
        );
    }
}

export default BrowserSupport;