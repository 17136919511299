import React, {Component} from 'react';
import QueryPanel from '../QueryPanel';
import PageContent from '../PageContent';
const msiFetch = require('../../controllers/MsiFetch');
const downloadUrl = "/api/publications/download?key="


class MODU extends Component {

    constructor(props){
        super(props);
        this.state = {
            moduPubs: [],
            network: props.network
        }
    }

    async componentDidMount(){
        const pubList = await msiFetch.fetchData('media?type=MODU');
        this.setState({moduPubs: pubList});
    }

    componentDidUpdate(prevProps) {
        if (this.props.network !== prevProps.network) {
            this.setState({ network: this.props.network });
        }
    }

    render(){
        let fgmodURL = "";
        if(this.state.network.substring(0,7) === "unclass"){
            fgmodURL = "https://vcps.nga.mil/apps/MODU";
        } else if(this.state.network === "secret"){
            fgmodURL = "https://fgmod.nga.smil.mil/apps/MODU";
        } else {
            fgmodURL = "https://fgmod.nga.ic.gov/apps/MODU";
        }
        return(
            <div id="modu">
                <div id="descrip" className="container">
                    <div id="modu-descrip" className="left-align">
                        <PageContent divid="modu-descrip" />
                    </div>
                    <br/>
                    <div className="col-sm-8 col-sm-offset-2 pub-download-bg p10 border-around">              
                        <p className="section-title">ACTIONS</p>
                        {this.state.network.substring(0,7) === "unclass" ? <a href="/submit-report/MODU-Report" className="mr10 mb0 btn btn-asam">Submit Movement Report</a> : null}
                        {this.state.moduPubs.length > 0 ? this.state.moduPubs.map(pub => {
                            return <a key={pub.s3Key} href={downloadUrl + pub.s3Key + "&type=download"} target="_blank" className="mr10 mb0 btn btn-asam">{pub.displayName}</a>
                            }) : null}
                        {this.state.network === "unclassified" ? null
                            : <a key="moduApp" href={fgmodURL} rel="noopener noreferrer" target="_blank" className="mr10 mb0 btn btn-asam">Launch MODU Viewing Application</a>}
                    </div>
                </div>
                <div id="ntm-query" className="container">
                    <h5 className="mb0">SEARCH MODU DATABASE</h5>
                    <QueryPanel category="MODU" />
                </div>
            </div>
        );
    }
}

export default MODU;
