import React from 'react';
import { convertDMS } from '../../utils/LatLongConv';

function getTableHeaders(queryUrl){
    return (
        <thead className="center-align">
            <tr>
                <td colSpan={42} className="bg-gray-lighter query-title">Mobile Offshore Drilling Units<br />(MODU)<br />Query Results</td>
            </tr>
            <tr align="left">
                    <td className="case-capitalize" colSpan={42} id="searchTerms"><b>Query Search Terms: </b><br/>{getQueryHeader(queryUrl)}</td>
                </tr>
        </thead>
    );
}

function getQueryHeader(queryUrl){
    var regexUrl = queryUrl.replace(/.*?\?(.*?)&output=html/i, '$1');
    regexUrl = regexUrl.replace("output=html", "");
    regexUrl = regexUrl.replace(/.*?\?/, '');
    regexUrl = regexUrl.replace("navArea=", "Nav Area: ");
    regexUrl = regexUrl.replace("subreg=", "Subregion: ");
    regexUrl = regexUrl.replace("navRegion=", "Region: ");
    regexUrl = regexUrl.replace("name=", "Name Matching: ");
    regexUrl = regexUrl.replace("sourceDate=", "Date: ");
    regexUrl = regexUrl.replace("minSourceDate=", "Dates Between: ");
    regexUrl = regexUrl.replace("&maxSourceDate=", " - ");
    regexUrl = regexUrl.replace("year=", "Year: ");
    regexUrl = regexUrl.replace("latitudeLeft=", "Lower Left Latitude: ");
    regexUrl = regexUrl.replace("longitudeLeft=", "Lower Left Longitude: ");
    regexUrl = regexUrl.replace("latitudeRight=", "Upper Right Latitude: ");
    regexUrl = regexUrl.replace("longitudeRight=", "Upper Right Longitude: ");
    regexUrl = regexUrl.replace("filter=none", "");
    regexUrl = regexUrl.replace("status=1", "Status: Active");
    regexUrl = regexUrl.replace("status=2", "Status: Inactive");

    regexUrl = regexUrl.replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n");
    regexUrl = regexUrl.replace(/%20/ig, ' ');
    return regexUrl;
}

function formatData(data){
    if ((data['modu']).length === 0) {
        return data['modu'];
    }
    else {
        return (
            <tbody>
                <tr>
                    <td colSpan={4}>{(data['modu']).length} matching record(s) found.</td>
                </tr>
                {data["modu"].map(rig => {
                    const position = convertDMS(rig.latitude, rig.longitude);
                    return ([
                            <tr>
                                <td width={20+"%"}><b>Rig Name:</b></td>
                                <td>{rig.name}</td>
                                <td><b>Navigational Area:</b></td>
                                <td>{rig.navArea}</td>
                            </tr>,
                            <tr>
                                <td className="no-border"><b>Entry Date:</b></td>
                                <td className="no-border">{rig.date}</td>
                                <td className="no-border"><b>Charting Subregion:</b></td>
                                <td className="no-border">{rig.subregion}</td>
                            </tr>,
                            <tr>
                                <td className="no-top-border"><b>Latitude:</b></td>
                                <td className="no-top-border">{position.latitude}</td>
                                <td className="no-border"><b>Special Status:</b></td>
                                <td className="no-border">{rig.specialStatus} {rig.specialStatus === "Specific Berth Requested" && rig.distance ? 'Distance: ' + rig.distance : ''}</td>
                            </tr>,
                            <tr>
                                <td className="no-top-border"><b>Longitude:</b></td>
                                <td className="no-top-border">{position.longitude}</td>
                                <td className="no-border"><b>Rig Status:</b></td>
                                <td className="no-border">{rig.rigStatus}</td>
                            </tr>
                    ]);
                })}
            </tbody>
        );
    }
}

export {
    getTableHeaders, formatData
}