import React, {Component} from 'react';
import PageContent from '../PageContent';

class Overview extends Component {
    render() {
        return (
            <PageContent divid="report-overview" />
        );
    }
}

export default Overview;