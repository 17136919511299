import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import LockOpen from '@mui/icons-material/LockOpen';

const gx = require('../../controllers/geoaxis');

function renderAlert(showAlert) {
    if (showAlert) {
            return ("An Error Occurred.");
    }
    return ('');
}

class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            user: null,
            showAlert: false,
            redirectUrl: ''
        };

        this.access_token = 'access_token';
        this.getRedirectUrl = this.getRedirectUrl.bind(this);
    }

    componentDidMount() {
        this.getRedirectUrl();
    }

    async getRedirectUrl() {
        const redirectUrl = await gx.getRedirectUrl();
        if (redirectUrl) this.setState({ redirectUrl });
        else this.setState({ showAlert: true });
    }
    
    render() {
        return(
            <ThemeProvider>
                <div>
                    <div>
                        {renderAlert(this.state.showAlert)}
                        <p align="left" style={{paddingLeft:20+'px'}}>
                        (U) You are accessing a U.S. Government (USG) Information System (IS) that is provided for USG-authorized use only.
                        <br/><br/>
                        (U) By using this IS (which includes any device attached to this IS), you consent to the following conditions:
                        <br/><br/>
                        - The USG routinely intercepts and monitors communications on this IS for purposes including, but not limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel misconduct (PM), law enforcement (LE), and counterintelligence (CI) investigations.
                        <br/><br/>
                        - At any time, the USG may inspect and seize data stored on this IS
                        <br/><br/>
                        - Communications using, or data stored on, this IS are not private, are subject to routine monitoring, interception and search, and may be disclosed or used for any USG-authorized purpose.
                        <br/><br/>
                        - This IS includes security measures (e.g., authentication and access controls) to protect USG interests -- not for your personal benefit or privacy.
                        <br/><br/>
                        - Notwithstanding the above, using this IS does not constitute consent to PM, LE or CI investigative searching or monitoring of the content of privileged communications, or work product, related to personal representation or services by attorneys, psychotherapists, or clergy, and theirs assistants. 
                        Such communications and work product are private and confidential. See User Agreement for details.
                        <br/><br/>
                        </p>
                        <Button
		            variant="contained"
                            label="Login with PKI" 
                            labelPosition="after"
		            size="large"
                            startIcon={<LockOpen />} 
                            href={this.state.redirectUrl} />
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

export default Login;
