import React, {Component} from 'react';
const downloadUrl = require("./tmp/pubConstants").downloadUrl;
const calcFileSize = require("../../utils/FileSize").calcFileSize;
const viewPrivateFile = require('../../utils/ViewPrivateFile').viewPrivateFile;
const msiFetch = require('../../controllers/MsiFetch');
const NOAATTid = require("./tmp/pubConstants").pubType.NOAATT;
const NOAATCTid = require("./tmp/pubConstants").pubType.NOAATCT;
const NP24 = require("./tmp/pubConstants").pubType.NP24;

const Volume = (props) => {
	
	// if it is a full publication (database specified) then return a table row with the volume information
	//regPubList = regular pubs that follow the normal publication pattern for a pageXOffset. 
	var link;
	const regPubList = ['rna', 'uscgll', 'chart1', 'apn', 'dbp', 'icos', 'rnmb', 'wpi', 'srtair', 'sdpg'];
	const irregPubList = ['apc', 'ngalol', 'srtmar'];
	const limPubList = ['fleetgd', 'noaatt'];
	if(regPubList.indexOf(props.category) > -1) {
		// if it has a files size of 0, do not show it
		if (props.pub['fileSize'] > 0 && props.pub['fullPubFlag'] === true ){
			link = downloadUrl + props.pub['s3Key'] + "&type=view";
			return (
					<tr>
						<td><a href={link} target="_blank">{props.pub['sectionDisplayName']}   ({calcFileSize(props.pub['fileSize'])}) </a></td>
					</tr>
			);
		}
		else { return null; }
	}
	else if (irregPubList.indexOf(props.category) > -1){
		//if it is listed as a full publication (won't have a file size or real s3 key)
		if (props.pub['fullPubFlag'] === true){
			
			link = downloadUrl + props.pub['s3Key'] + "&type=view";
			return (
				<option value={props.pub['s3Key']}>{props.pub['sectionDisplayName']}</option>
			);
		}
		else { 
			return (
				<option value={props.pub['pubDownloadId']}>{props.pub['pubDownloadDisplayName']}</option>
			);
			}
	}
	else if(limPubList.indexOf(props.category) > -1) {
		// if it has a files size of 0, do not show it
		if (props.pub['fileSize'] > 0 && props.pub['fullPubFlag'] === true){
			link = downloadUrl + props.pub['s3Key'] + "&type=view";
			return (
					<tr>
						<td>(U//LIMDIS)  <a onClick={() => {viewPrivateFile(link)}}>{props.pub['sectionDisplayName']}   ({calcFileSize(props.pub['fileSize'])}) </a></td>
					</tr>
			);
		}
		else { return null; }
	}
	else {
		return <tr></tr>
	}
}

const Chapter = (props) => {
	// if it has a files size of 0, do not show it
    // if it is NOT a full publication (database specified) then return a select option with the chapter information
	var link;
	const irregPubList = ['wpi'];
	if (props.pub['fileSize'] > 0 && props.pub['fullPubFlag'] === false){
        if(irregPubList.indexOf(props.category) > -1) {
            link = downloadUrl + props.pub['s3Key'] + "&type=view";
            return (
                <tr>
                    <td><a href={link} target="_blank">{props.pub['sectionDisplayName']}   ({calcFileSize(props.pub['fileSize'])}) </a></td>
                </tr>
            );
        }
        else {
    		link = downloadUrl + props.pub['s3Key'];
	    	return(
		    	<option value={link} title={window.location.hostname + link + "&output=view"}>{props.pub['sectionDisplayName']}    ({calcFileSize(props.pub['fileSize'])})</option>
            );
        }
	}
	else 
	{
		return false;
	}

}

const Month = (props) => {
	//Grabs the publication number from the dropdown to pull correct months / covers / etc.
	// if it has a files size of 0, do not show it
	var pubSelect = document.getElementById("pubSelect").value;
	if (props.pub['fileSize'] > 0 && props.pub['fullPubFlag'] === false
			&& String(props.pub['pubDownloadId']).includes(pubSelect)){
				
		var link = downloadUrl + props.pub['s3Key'];
		return(
			<option value={link} title={window.location.hostname + link + "&output=view"}>{props.pub['sectionDisplayName']}    ({calcFileSize(props.pub['fileSize'])})</option>
		);
	}
	else return false;
}

function getPubList(list, type, category){
	var cat = category.toString().toLowerCase();
	var pubdownloadid;
	//returns volumes or chapters for download
	if (list){
		return list.map(publication => {
			if (type === "Volume") { 
				if (publication.pubDownloadId !== pubdownloadid) {
					pubdownloadid = publication.pubDownloadId;
					return <Volume pub={publication} category={cat} key={publication.s3Key} /> 
				}
				else {
					return null;
				}
			}
			else if (type === "Month") { 
				return <Month pub={publication} category={cat} key={publication.s3Key}/> }
            else if (type === "Chapter"){ 
				return <Chapter pub={publication} category={cat} key={publication.s3Key}/> }
			else return <option value = '0'></option>
		});
	}
	else {
		return null;
	}
}

class PubDownload extends Component {
	constructor(props){
		super(props);
		this.state = {
				months: <option disabled value = '0'>There was a problem downloading the chapters.</option>,
				pubList: [],
				foreignPubList: [],
			};
		this.updateMonths = this.updateMonths.bind(this);
	}

	async componentDidMount(){
		let pubTypeId = this.props.pubTypeId;
		let url = "publications/stored-pubs?pubTypeId=" + pubTypeId;
		await msiFetch.fetchData(url)
        .then(data => {
			this.setState({pubList: data});
        })
        .catch(error => {
            this.setState({pubList: []});
		});

		//JG20190926 DO NOT PUT === HERE, THESE ARE NUMBERS NOT STRINGS
		if(pubTypeId === NOAATTid){
			url = "publications/stored-pubs?pubTypeId=" + NOAATCTid;
			msiFetch.fetchData(url).then(data => {
				let pubList = this.state.pubList;
				for(let i=data.length-1; i>-1; i--){
					//for each item, push into the publist
					pubList.unshift(data[i]);
				}
				this.setState({pubList});
			});
		}

		//query database for NP24 & AP3270 publications for the NIPR site
		if(window.classification.classification === "unclassified//limdis"){
			const urlNP24 = "publications/stored-pubs?pubTypeId=" + NP24;
			msiFetch.fetchData(urlNP24)
			.then(data => {			
				this.setState({ foreignPubList: data })
			});          
		}

        // we will hide Chapter/Additional Downloads part of the table if we don't want to show it for certain publications
		const noVolList = ['SDE'];
		const noChapterList = ['USCGLL', 'APN', 'WPI', 'DBP', 'SRTAir', 'SDPG', 'FleetGd', 'NOAATT'];
		const additionalFormatsList = ['WPI']; 
		//if this is a irregular pub (meaning there are NO CHAPTER FILES for download)
		if (noChapterList.indexOf(this.props.category) > -1){
            document.getElementById("chapter-downloads").className = "no-display";
		}
		//pubs that have an additional format
        if (additionalFormatsList.indexOf(this.props.category) > -1){
            document.getElementById("additional-downloads").className = "downloads-table";
		}
		//pubs that have no full volume selection
		if(noVolList.indexOf(this.props.category) > -1){
			document.getElementById("volume-downloads").className = "no-display";
		}
		
	}

	getVolumeLabel(){
		const pubNames = ['APC', 'NGALOL', 'SRTMar']
		const singleVol = ['DBP', 'ICOS', 'RNMB', 'RNA', 'WPI'];
		//APC has single "publications" instead of volumes
		if (pubNames.indexOf(this.props.category) > -1){
			return "Select Publication";
		}
		// single volume publications (only one file displayed)
		else if(singleVol.indexOf(this.props.category) > -1){
			return "Complete Volume";
		}
		//multiple volume publications (multiple files displayed)
		else {
			return "Complete Volume(s)";
		}
	}

	getChapterLabel(){
		const irregPubList = ['NGALOL', 'SDE'];
		if (this.props.category === "APC"){
			return "Download Publication Pages";
		}
		else if (irregPubList.indexOf(this.props.category) > -1){
			return "View/Download Publication"
		}
		else {
			return "Single Chapters Available for Download";
		}
	}

	getDisabledChapter(){
		const irregPubList = ['NGALOL', 'SDE'];
		if (this.props.category === "APC"){
			return "Select a Page to Download";
		}
		else if (irregPubList.indexOf(this.props.category) > -1){
			return "Select Publication"
		}
		else {
			return "Select a Chapter to Download";
		}
	}

    getFullVolumes(){
        //returns full volume publications based on database column "full volume"
        const irregPubList = ['APC', 'NGALOL', 'SRTMar'];
		//if this is a irregular pub (meaning the complete volume is a picklist NOT A link to a document)
		if (irregPubList.indexOf(this.props.category) > -1){
			return (
				<tr><td>
					<select defaultValue="null" className="form-control" id="pubSelect" onChange={this.updateMonths}>
						<option disabled value='null'>Select a Publication</option>
						{getPubList(this.state.pubList, "Volume", this.props.category)};
					</select>
				</td></tr>
			);
        }
        else { 
			return getPubList(this.state.pubList, "Volume", this.props.category);
		}
    }

    getChapters(){
        //returns chapters for download for developer specific publications 
		const irregPubList = ['APC', 'NGALOL', 'SRTMar'];
		//if this is a irregular pub (meaning the complete volume is a picklist NOT A link to a document)
		if (irregPubList.indexOf(this.props.category) > -1){
			return this.state.months;
		}
		else { 
			return getPubList(this.state.pubList, "Chapter", this.props.category); 
		}
	}
	
	openPub(){
		var select = document.getElementById("chapterSelect");
		var link = select.options[select.selectedIndex].value + "&type=view";
		if(select.value !== "null"){
			if(window.classification.classification === "unclassified//limdis" && link.indexOf("PubNP24") > 0){
				//link for NIPR NP24 file selected
				viewPrivateFile(link);
			} else {
				window.open(link, "_blank");
			}
		}
	}
	downloadPub(){
		var select = document.getElementById("chapterSelect");
		var link = select.options[select.selectedIndex].value + "&type=download";
		if(select.value !== "null"){
			if(window.classification.classification === "unclassified//limdis" && link.indexOf("PubNP24") > 0){
				//link for NIPR NP24 file selected
				viewPrivateFile(link);
			} else {
				window.open(link, "_blank");
			}
		}
	}

	updateMonths(){
		const irregPubList = ['APC', 'NGALOL', 'SRTMar'];
		//if this is a irregular pub (meaning the complete volume is a picklist NOT A link to a document)
		if (irregPubList.indexOf(this.props.category) > -1){
			//update the months 
			var months = getPubList(this.state.pubList, "Month", this.props.category);
			this.setState({months: months});
		}
	}

	updateControls(){
		document.getElementById("buttonsTable").removeAttribute("hidden");
	}

	getTempSRTAirFix(){
		// Volume 1 - Selected Stars Epoch 2020 - AP3270
		// add SRT Air volume 1 to the list. avoids it being in the database.
		const link = downloadUrl + "16980309/SFH00000/ap3270_v1_2020_CD01.pdf&type=view";		
		if(this.props.category === "SRTAir" && window.classification.classification === "unclassified//limdis"){
			const pub = this.state.foreignPubList[1];
			if(pub){
				return (
					<tr>
						<td><a onClick={() => {viewPrivateFile(link)}}>{pub["sectionDisplayName"]}  ({calcFileSize(pub['fileSize'])})</a></td>
					</tr>
				);
			} else return null;
		} else return null;
		
	}

	getTempBlackSeaFix(){
		// Pub. NP24  - British Admiralty - Black Sea and Sea of Azov Pilot, 6th Ed 2019
		// add Black Sea Pilot file to list. avoids it being in the database.
		const link = downloadUrl + "16980309/SFH00000/PubNP24bk.pdf";
		if(this.props.category === "SDE" && window.classification.classification === "unclassified//limdis"){
			const pub = this.state.foreignPubList[0];
			if(pub){
				return <option value={link} title={window.location.hostname + link + "&output=view"}>{pub["sectionDisplayName"]}  ({calcFileSize(pub['fileSize'])})</option>
			} else { return null; }
		}
	}

    render(){
        return(
            <table className="table center-align table70">
                <thead className="bg-gray">
                    <tr>
                        <th className="center-align">Download Publication</th>
                    </tr>
                </thead>
                <tbody className="downloads-table" id="volume-downloads">
                    <tr className="bg-gray-lighter">
                        <td id="volume-label">{this.getVolumeLabel()}</td>
                    </tr>
					{this.getTempSRTAirFix()}
                    {this.getFullVolumes()}
                </tbody>
                <tbody className="no-display" id="additional-downloads">
                    <tr className="bg-gray-lighter">
                        <td>Additional Formats</td>
                    </tr>
                    {this.getChapters()}
                </tbody>
                <tbody className="downloads-table" id="chapter-downloads">
                    <tr className="bg-gray-lighter">
                        <td id="chapter-label">{this.getChapterLabel()}</td>
                    </tr>
                    <tr>
                        <td>
                            <select defaultValue='null' className="form-control" id="chapterSelect">
                                <option disabled value='null'>{this.getDisabledChapter()}</option>
								{this.getChapters()}
								{this.getTempBlackSeaFix()}
                            </select>
                            <a className="btn btn-pub" title="View" onClick={this.openPub}>View</a>
                            <a className="btn btn-pub" title="Download" onClick={this.downloadPub}>Download</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default PubDownload;