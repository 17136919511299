import React, { Component } from "react";
import Config from "../utils/Config";
const RNAQueryResults = require("./Publications/RNA/RNAQueryResults");
//const USCGLLQueryResults = require('./Publications/USCGLL/USCGLLQueryResults');
const WPIQueryResults = require("./Publications/WPI/WPIQueryResults");
const NGALOLQueryResults = require("./Publications/NGALOL/NGALOLQueryResults");
const BroadcastQueryResults = require("./BroadcastWarnings/BroadcastQueryResults");
const NTMQueryResults = require("./NoticeToMariners/NTMQueryResults");
const ASAMQueryResults = require("./Piracy/ASAMQueryResults");
const MODUQueryResults = require("./MODU/MODUQueryResults");
const ProdCatQueryResults = require("./Products/ProdCatQueryResults");
const SmapsQueryResults = require("./BroadcastWarnings/SmapsQueryResults");
const msiFetch = require("../controllers/MsiFetch");

class QueryResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      queryType: null,
      network: "",
      webmasterEmail: "",
      noticeNum: "",
    };
    this.url = window.location.search.substring(1);
  }

  async componentDidMount() {
    let conf = new Config();
    await conf.setAppConfig();
    this.setState({ network: conf.network });
    this.setState({ webmasterEmail: conf.webmasterEmail });

    await msiFetch
      .fetchData(this.url)
      .then((data) => {
        if (this.url.split("output=")[1] === "html") {
          console.log(data);
          this.setState({ data, queryType: Object.keys(data)[0] });
          const noticeNumIncluded =
            this.url.indexOf("noticeNum") > -1 ? true : false;
          const ntmQuery = this.url.indexOf("/ntm/") > -1 ? true : false;
          if (
            this.url.split("output=")[1] === "html" &&
            ntmQuery &&
            !noticeNumIncluded
          ) {
            let url = "publications/ntm/ls-notice-numbers";
            const cntmIncluded =
              this.url.indexOf("pubType=cntm") > -1 ? true : false;
            if (cntmIncluded) {
              url = "publications/ntm/hs-notice-numbers";
            }
            msiFetch
              .fetchData(url)
              .then((response) =>
                this.setState({ noticeNum: response["notice_numbers"][0] })
              );
          }
        } else {
          this.setState({
            data,
            queryType: Object.keys(data.substring(0, 250))[0],
          });
        }
      })
      .catch((error) => {
        this.setState({ data: [], queryType: [] });
      });

    if (this.state.queryType === "radio-navaids") {
      const chapter = this.url.split("/")[2].split("?")[0];
      if (chapter === "dfrs") {
        RNAQueryResults.getAreaNotes(this.state.data);
      }
    }
  }

  generateTableHeaders() {
    //top of table, shows what you queried and any notes
    if (this.state.data !== null) {
      let headerList = [];
      const queryUrl = this.url;
      switch (this.state.queryType) {
        case "radio-navaids":
          //grab chapter that was queried
          const chapter = this.url.split("/")[2].split("?")[0];
          // call function to get list of table headers for the chapter
          headerList = RNAQueryResults.getTableHeaders(chapter);
          break;
        /* case "uscg-ll":
                case "uscg-llV5":
                    //pull headers for uscgll
                    headerList = USCGLLQueryResults.getTableHeaders(queryUrl);
                    break; */
        case "ngalol":
          //grab type from url
          const type = this.url.split("/")[2].split("?")[0];
          //pull headers for ngalol
          headerList = NGALOLQueryResults.getTableHeaders(type, queryUrl);
          break;
        case "ports":
          //pull headers for wpi
          headerList = WPIQueryResults.getTableHeaders(queryUrl);
          break;
        case "HYDROLANT":
        case "broadcast-warn":
          //pull headers for Broadcast Warn
          headerList = BroadcastQueryResults.getTableHeaders(queryUrl);
          break;
        case "asam":
          //pull headers for ASAM
          headerList = ASAMQueryResults.getTableHeaders(queryUrl);
          break;
        case "modu":
          headerList = MODUQueryResults.getTableHeaders(queryUrl);
          break;
        //case "ntm-uscg-ll":
        //case "ntm-uscg-llV5":
        case "pubsAff":
        case "ntm-ngalol":
        case "noticeNumbers":
        case "navPubCorr":
        case "hydroCorr":
        case "chartsAff":
        case "ntmGraphics":
        case "chartCorr":
          //pull headers for NTM based on queryType
          headerList = NTMQueryResults.getTableHeaders(
            queryUrl,
            this.state.queryType,
            this.addNoticeNumber.bind(this)
          );
          break;
        case "prodcat":
          const productType = this.url.split("/")[2].split("?")[0];
          headerList = ProdCatQueryResults.getTableHeaders(productType);
          break;
        case "smaps":
          //pull headers for SMAPS
          console.log("THIS IS THE QUERY URL: " + queryUrl);
          headerList = SmapsQueryResults.getTableHeaders(queryUrl);
          break;
        default:
          break;
      }
      return headerList;
    }
  }

  generateResultsHeaders() {
    // reads in data from state
    // queryType is the first key that should be sent in the returned dataset
    if (this.state.data !== null) {
      let centerFirst = false;
      let headerList = [];
      let tableHead = document.getElementById("resultsHeader");
      let volumesList;
      let type;
      switch (this.state.queryType) {
        case "radio-navaids":
          //grab chapter that was queried
          const chapter = this.url.split("/")[2].split("?")[0];
          // call function to get list of table headers for the chapter
          headerList = RNAQueryResults.getResultHeaders(chapter);
          break;
        /* case "uscg-ll":
                case "uscg-llV5":
                    //return list of table headers for uscgll
                    volumesList = USCGLLQueryResults.getVolumes(this.state.data);
                    if (volumesList.length > 1) {
                        break;
                    } else {
                        if (volumesList[0] === "VOLUME V") {
                            headerList = USCGLLQueryResults.Vol5tableHeaders;
                        } else {
                            headerList = USCGLLQueryResults.tableHeaders;
                        }
                    }
                    break; */
        case "ngalol":
          volumesList = NGALOLQueryResults.getVolumes(this.state.data);
          if (volumesList.length > 1) {
            break;
          } else {
            //grab type that was queried
            type = this.url.split("/")[2].split("?")[0];
            // call function to get list of table headers for the chapter
            headerList = NGALOLQueryResults.getResultHeaders(type);
            break;
          }
        case "modu":
        case "asam":
        case "HYDROLANT":
        case "broadcast-warn":
          headerList = ["", "", "", ""];
          break;
        case "ntm-ngalol":
          type = this.url.split("/")[2].split("?")[0];
          // call function to get list of table headers for the chapter
          headerList = NTMQueryResults.getResultHeaders(type);
          break;
        //case "ntm-uscg-ll":
        //case "ntm-uscg-llV5":
        case "pubsAff":
        case "navPubCorr":
        case "hydroCorr":
        case "chartsAff":
        case "ntmGraphics":
        case "chartCorr":
          headerList = NTMQueryResults.getResultHeaders(this.state.queryType);
          break;
        case "noticeNumbers":
          headerList = NTMQueryResults.getResultHeaders(this.state.queryType);
          centerFirst = true;
          break;
        case "prodcat":
          type = this.url.split("/")[2].split("?")[0];
          headerList = ProdCatQueryResults.getResultHeaders(type);
          break;
        case "smaps":
          headerList = ["", "", "", ""];
          break;
        default:
          break;
      }
      //append table row element
      let row = document.createElement("tr");
      if (!tableHead.hasChildNodes()) {
        tableHead.appendChild(row);
      }
      //iterate over list, appending to table row element
      headerList.forEach((element) => {
        let child = document.createElement("th");
        child.innerHTML = element;
        row.appendChild(child);
      });

      if (centerFirst) {
        row.firstChild.className = "center-align";
      }
    }
  }

  /*eslint no-fallthrough: ["error", { "commentPattern": "break[\\s\\w]*omitted" }]*/
  generateResultsBody() {
    //reads data from state
    let sortedData = [];
    let type;
    if (this.state.data !== null) {
      switch (this.state.queryType) {
        case "radio-navaids":
          //grab chapter that was queried
          const chapter = this.url.split("/")[2].split("?")[0];
          //call function to format data for table
          sortedData = RNAQueryResults.formatData(chapter, this.state.data);
          break;
        /* case "uscg-ll":
                case "uscg-llV5":
                    //call function to format data for table
                    sortedData = USCGLLQueryResults.formatData(this.state.data);
                    break; */
        case "ngalol":
          //grab type of data queried
          type = this.url.split("/")[2].split("?")[0];
          //call function to format the data for the table
          sortedData = NGALOLQueryResults.formatData(type, this.state.data);
          break;
        case "ports":
          //Return a DIV to display the user port query
          const queryResultsCount = this.state.data["ports"].length;
          //Use results count to choose list of port names or display single result
          if (queryResultsCount > 1) {
            sortedData = WPIQueryResults.getPortNameLinks(this.state.data);
          } else {
            sortedData = WPIQueryResults.getIndivPortInfo(this.state.data);
          }
          break;
        case "HYDROLANT":
        case "broadcast-warn":
          const query = this.url.split("/")[1].split("?")[1];
          sortedData = BroadcastQueryResults.formatData(this.state.data, query);
          break;
        case "asam":
          sortedData = ASAMQueryResults.formatData(this.state.data);
          break;
        case "modu":
          sortedData = MODUQueryResults.formatData(this.state.data);
          break;
        case "ntm-ngalol":
          type = this.url.split("/")[2].split("?")[0];
          sortedData = NTMQueryResults.formatData(type, this.state.data);
          break;
        //case "ntm-uscg-ll":
        //case "ntm-uscg-llV5":
        case "pubsAff":
        case "noticeNumbers":
        case "navPubCorr":
        case "hydroCorr":
        case "chartsAff":
        case "ntmGraphics":
        case "chartCorr":
          sortedData = NTMQueryResults.formatData(
            this.state.queryType,
            this.state.data
          );
          break;
        case "prodcat":
          type = this.url.split("/")[2].split("?")[0];
          sortedData = ProdCatQueryResults.formatData(type, this.state.data);
          break;
        case "smaps":
          const smapsQuery = this.url.split("/")[1].split("?")[1];
          sortedData = SmapsQueryResults.formatData(
            smapsQuery,
            this.state.data
          );
          break;

        default:
          break;
      }
      if (sortedData.length === 0 && this.state.queryType !== "0") {
        return this.noResults();
      } else if (sortedData.length === 0 && this.state.queryType === "0") {
        if (this.url.split("output=")[1] === "xml") {
          return this.downloadXML();
        } else if (this.url.split("output=")[1] === "csv") {
          return this.downloadCSV();
        } else {
          return <pre>{this.state.data}</pre>;
        }
      } else {
        if (this.url.split("output=")[1] === "xml") {
          return this.downloadXML();
        } else if (this.url.split("output=")[1] === "csv") {
          return this.downloadCSV();
        } else {
          return sortedData;
        }
      }
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={20}>Loading, Please Wait...</td>
          </tr>
        </tbody>
      );
    }
  }

  downloadXML() {
    const dataBlob = new Blob([this.state.data], { type: "application/xml" });
    const href = URL.createObjectURL(dataBlob);
    let link;
    const onclick = () => {
      window.navigator.msSaveBlob(dataBlob, "file.xml");
    };
    if (window.navigator && window.navigator.msSaveBlob) {
      //IE -- force download
      link = (
        <a onClick={onclick} id="linkDownload" className="btn">
          Download XML
        </a>
      );
    } else {
      link = (
        <a href={href} download="file.xml" id="linkDownload" className="btn">
          Download XML
        </a>
      );
    }
    return (
      <tbody>
        <tr>
          <td align="center">
            <br />
            <br />
            {link}
          </td>
        </tr>
      </tbody>
    );
  }

  downloadCSV() {
    const dataBlob = new Blob(["\ufeff", this.state.data], {
      type: "application/csv",
    });
    const href = URL.createObjectURL(dataBlob);
    let link;
    const onclick = () => {
      window.navigator.msSaveBlob(dataBlob, "file.csv");
    };
    if (window.navigator && window.navigator.msSaveBlob) {
      //IE -- force download
      link = (
        <a onClick={onclick} id="linkDownload" className="btn">
          Download CSV
        </a>
      );
    } else {
      link = (
        <a href={href} download="file.csv" id="linkDownload" className="btn">
          Download CSV
        </a>
      );
    }
    return (
      <tbody>
        <tr>
          <td align="center">
            <br />
            <br />
            {link}
            <br />
            <b>Note:</b>{" "}
            <i>
              When opening the CSV file in spreadsheet software, please ensure
              that the rows are expanded to display data properly.
            </i>
            <br />
          </td>
        </tr>
      </tbody>
    );
  }

  noResults() {
    return (
      <tbody>
        <tr>
          <td colSpan={20}>The query returned no results.</td>
        </tr>
      </tbody>
    );
  }

  emailLink() {
    const email = this.state.webmasterEmail;
    const mailto = "mailto:" + email;
    return (
      <div>
        Please direct any questions or comments pertaining to the site to{" "}
        <a href={mailto}>{email}</a>
      </div>
    );
  }

  addNoticeNumber() {
    //if no noticeNumber was included (searching all ntms, include the last notice number) available
    const noticeNumIncluded = this.url.indexOf("noticeNum") > -1 ? true : false;
    const ntmQuery = this.url.indexOf("/ntm/") > -1 ? true : false;
    const cntmIncluded = this.url.indexOf("pubType=cntm") > -1 ? true : false;
    if (
      this.url.split("output=")[1] === "html" &&
      ntmQuery &&
      !noticeNumIncluded
    ) {
      return (
        <tr align="left">
          <td className="case-capitalize" colSpan={42} id="noticeNum">
            {"Corrected through " +
              (cntmIncluded ? "Classified " : "") +
              " NTM " +
              this.state.noticeNum}
          </td>
        </tr>
      );
    } else return null;
  }

  render() {
    return (
      <div>
        <table className="table left-align" id="rootTable">
          {this.generateTableHeaders()}
          <thead id="resultsHeader" className="bg-gray-light">
            {this.generateResultsHeaders()}
          </thead>
          {this.generateResultsBody()}
        </table>
        <div>{this.emailLink()}</div>
      </div>
    );
  }
}

export default QueryResults;
