import React, { Component } from "react";
import PageContent from "../PageContent";
const msiFetch = require("../../controllers/MsiFetch");

class didyouknow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DYKArray: [],
      DYKComponents: [],
      params: "",
    };
    this.scrollRef = React.createRef();
  }

  async componentDidMount() {
    //api call to get the Did You Know information from the database
    const url = "info/did-you-know";
    await msiFetch
      .fetchData(url)
      .then((data) => {
        this.setState({ DYKArray: data });
        this.buildcards(data);
      })
      .catch((error) => {
        this.setState({ DYKArray: [] });
      });
    if (this.state.params) {
      this.scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  buildcards(DYKArray) {
    // var myParam = window.location.search.split("=")[1];
    var param = new URLSearchParams(window.location.search);
    var myParam = param.get("data_target");
    if (myParam) {
      this.setState({ params: myParam });
    }
    // console.log("The params state is:", this.state.params);
    var mappedCards = DYKArray.map((DYKcontent) => (
      <div className="card" key={DYKcontent["id"]}>
        <div className="card-header">
          <div className="text-left">
            <button
              className="btn btn-xs btn-new btn-block"
              type="button"
              data-toggle="collapse"
              data-target={"#" + DYKcontent["dataTarget"]}
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <b>{DYKcontent["title"]}</b>
            </button>
          </div>
        </div>
        <div
          id={DYKcontent["dataTarget"]}
          className={
            myParam == DYKcontent["dataTarget"] ? "collapse in" : "collapse"
          }
          aria-expanded={myParam == DYKcontent["dataTarget"] ? "true" : "false"}
          data-parent="#dykQuestions"
          ref={myParam == DYKcontent["dataTarget"] ? this.scrollRef : null}
        >
          <div className="text-left">
            <div
              className="card-body"
              dangerouslySetInnerHTML={{ __html: DYKcontent["body"] }}
            ></div>
          </div>
        </div>
      </div>
    ));
    this.setState({ DYKComponents: mappedCards });
  }

  render() {
    return (
      <div className="Didyouknow">
        <header className="didyouknow-header container">
          <PageContent divid="did-you-know" />
          <div className="accordion" id="dykQuestions">
            {this.state.DYKComponents}
          </div>
        </header>
      </div>
    );
  }
}

export default didyouknow;
