import React, { Component } from 'react';
import { SmModal } from '../../utils/SmModal';
import Recaptcha from '../Actions/Recaptcha';
import Config from '../../utils/Config';


const msiFetch = require('../../controllers/MsiFetch');

class EmailSubScrb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            captchaDone: false,
            renderCaptcha: true,
            captchaToken: '',
            resp: {},
            network: props.network
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.network !== this.props.network) {
            this.setState({ network: this.props.network });
        }
    }

    showModal() {
        //when called, change state to show the modal
        this.setState({ showModal: true });
    }

    hideModal() {
        //when called, change the state to hide the modal
        this.setState({ showModal: false });
    }

    async componentDidMount(){
        var conf = new Config();
        await conf.setAppConfig();
        if (conf.network !== "unclassified") {
            this.setState({ captchaDone: true, renderCaptcha: false })
        };
    }

    verifyCaptcha(response) {
        this.setState({ captchaDone: true, captchaToken: response });
    }

    expiredCaptcha() {
        this.setState({ captchaDone: false, captchaToken: '' });
    }

    async sendTransaction() {

        //send the email address to the api
        const email = document.getElementById("email").value;
        const formData = {
            "email": email
        }
        const url = "email-subscribers/manage?email=" + email;
        await msiFetch.postRawData(url, formData, 'application/json')
            .then(resp => { this.setState({ resp }) });
        this.showModal();
        //reset
        document.getElementById("email").value = "";
    }

    async validateFields() {
        let alertMessage = "";
        const email = document.getElementById("email").value;

        if (email === null || email === "") {
            alertMessage += "Email Address is required\n";
            document.getElementById("lblEmail").className = "form-alert";
        } else {
            document.getElementById("lblEmail").className = "";
        }

        //validate email address characters
        var letters = /[^a-zA-Z0-9@._%+-]/g;
        if (email != null) {
            if (email.match(letters)) {
                alertMessage += "Invalid email address format.\n";
                document.getElementById("lblEmail").className = "form-alert";
            }
        }
        if (!this.state.captchaDone) {
            alertMessage += "Please complete the Captcha Verification.";
        }
        //if we have a message to show the user, show it
        if (alertMessage !== "") {
            window.alert(alertMessage);
        }
        else {
            //if everything is valid, move on to send the data to the api
            //send some kind of confirmation that this is been submitted
            await this.sendTransaction();
        }
    }

    render() {
        return (
            <div className="container" id="subscriptions">
                <p>To manage your subscription with the Maritime Safety Office, enter your registered email address and
                        click the 'Submit' button. <br />If you are a registered subscriber, you will receive an email with instructions to manage your subscription. </p>
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                    <table className="table left-align">
                        <tbody>
                            <tr>
                                <td className="no-borders"><span id="lblEmail">Email Address* &nbsp;</span>
                                    <input type="text" id="email" className="form-control" /></td>
                            </tr>
                            <br />
                            <tr>
                                <td className="no-borders">
                                    {this.state.renderCaptcha ? <Recaptcha id="recaptcha"
                                        verifyCallback={this.verifyCaptcha.bind(this)} expiredCallback={this.expiredCaptcha.bind(this)}
                                    /> : null}
                                </td>
                            </tr>
                            <br />
                            <tr>
                                <td className="center-align no-borders"><input type="button" value="Submit" onClick={this.validateFields.bind(this)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <SmModal show={this.state.showModal} handleClose={this.hideModal} title="Submit Confirmation">
                        {this.state.resp["message"]}
                    </SmModal>
                </div>
            </div>
        );
    }
}

export default EmailSubScrb;
