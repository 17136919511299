import React from 'react';
import groupBy from 'lodash/groupBy';
import '../../../css/queryResults.css';

const dgpstableHeaders = ["No", "Name & Location", "Position", "Station ID", "Range", "Frequency", "Transfer Rate", "Remarks"];
const rbcntableHeaders = ["No", "Name & Location", "Position", "Characteristic", "Range", "Sequence", "Frequency", "Remarks"];
const lightstableHeaders = ["No", "Name & Location", "Position", "Characteristic", "Height", "Range", "Structure", "Remarks"];

function getTableHeaders(type, queryUrl){
    return (
        <thead className="center-align">
            <tr>
                <td colSpan={42} className="bg-gray-lighter query-title">NGA List of Lights<br />Query Results</td>
            </tr>
            <tr align="left">
                    <td className="case-capitalize" colSpan={42} id="searchTerms"><b>Query Search Terms: </b><br/>{getQueryHeader(type, queryUrl)}</td>
                </tr>
        </thead>
    );
}

//Return subheader to display the customer query
function getQueryHeader(type, queryUrl){
    let regexUrl = queryUrl.replace(/.*?\?(.*?)&output=html/i, '$1');
    regexUrl = regexUrl.replace("output=html", '');
    regexUrl = regexUrl.replace(/.*?\?/, '');
    regexUrl = regexUrl.replace("volume=", "Publication: ");
    regexUrl = regexUrl.replace("featureNumber=", "Aid Number: ");
    regexUrl = regexUrl.replace("featureNumberStart=", "Aid Number Start: ");
    regexUrl = regexUrl.replace("featureNumberEnd=", "Aid Number End: ");
    regexUrl = regexUrl.replace("geoHDRName=", "Geographic Header: ");
    regexUrl = regexUrl.replace("geoSHDRName=", "Geographic Sub Header: ");
    regexUrl = regexUrl.replace("range=", "Range Exceeding: ");
    regexUrl = regexUrl.replace("latitudeLeft=", "Lower Left Latitude: ");
    regexUrl = regexUrl.replace("longitudeLeft=", "Lower Left Longitude: ");
    regexUrl = regexUrl.replace("latitudeRight=", "Upper Right Latitude: ");
    regexUrl = regexUrl.replace("longitudeRight=", "Upper Right Longitude: ");
    regexUrl = regexUrl.replace("&includeRemovals=true", "");
    regexUrl = regexUrl.replace("&includeRemovals=false", "");
    
    //Add Type of Result
    if(type === 'lights-buoys'){
       regexUrl = regexUrl + "&Type of Feature: Lighted Aids"
    } else if(type=== 'dgpsstations'){
        regexUrl = regexUrl + "&Type of Feature: Differential GPS Stations"
    } else if(type === 'radiobeacons'){
        regexUrl = regexUrl + "&Type of Feature: Radiobeacons"
    }

    regexUrl = regexUrl.replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n");
    regexUrl = regexUrl.replace(/%20/ig, ' ');
    return regexUrl;
}

function getResultHeaders(type){
    //depending on the type of data, return a specific header list
    switch(type) {
        case "dgpsstations":
            return dgpstableHeaders;
        case "radiobeacons":
            return rbcntableHeaders;
        case "lights-buoys":
            return lightstableHeaders;
        default:
            break;
    }
}

const DisplayGeoHDR = (props) => {
    return (
        <tr id={props.areaName}>
            <td colSpan={42} align="center" className="bg-gray-lighter text-bold">{props.areaName}</td>
        </tr>
    );
}

const DisplayGeoSHDR = (props) => {
    return (
        <tr id={props.areaName}>
            <td colSpan={42} align="left" className="text-bold" id="sub-area">{props.areaName}</td>
        </tr>
    );
}

const DisplayGeoUHDR = (props) => {
    return (
        <tr id={props.areaName}>
            <td colSpan={42} align="left" className="text-bold" id="sub-u-area">{props.areaName}</td>
        </tr>
    );
}

const DisplayGeoBHDR = (props) => {
    return (
        <tr id={props.areaName}>
            <td colSpan={42} align="left" className="text-bold" id="sub-b-area">{props.areaName}</td>
        </tr>
    );
}

const DisplayNote = (props) => {
    if(props.type === "preNote"){
        return (
            <tr>
                <td colSpan={42} align="left" id="preceedingNote">{props.note}</td>
            </tr>
        );
    }
    else {
        return (
            <tr>
                <td></td><td colSpan={42} align="left" id="postNote">{props.note}</td>
            </tr>
        );
    }
}

function getVolumes(data){
    if ((data['ngalol']).length === 0) {
        return data['ngalol'];
    }
    else {
        //group data by volumes {creates object with volumes as keys}
        const listByVolume = groupBy(data['ngalol'], 'volumeNumber');
        const volumes = Object.keys(listByVolume);
        return volumes;
    }
}

function formatData(queryType, data){
    let queryData;
    let type;
    if(data['ntm-ngalol']){
        queryData = data['ntm-ngalol'];
        type = "ntm"
    } else { 
        queryData = data['ngalol']; 
        type = "reg"
    }

    if (queryData.length === 0) {
        return queryData;
    }
    else {
        let formattedData = {}
        let key, array = null
        //group data by volume {creates object with volumes as keys}
        const listByVolume = groupBy(queryData, 'volumeNumber');
        const volumes = Object.keys(listByVolume);
        if (volumes.length > 1) {
            //user queried data that returned more than 1 volume. show results as linked list
            for (key in listByVolume) {
                if (key !== null){
                    array = listByVolume[key]
                    formattedData[key] = showGeoAreasofVolume(array)
                }
            }
            return Object.keys(formattedData).map(volume => { return <LinkedList volume={volume} key={volume} data={formattedData[volume]} /> });
        } else {
            //results list only concerns one volume, display data directly
            if (queryType === "dgpsstations"){
                formattedData = concatDupesDGPS(queryData, type);
            } else if(queryType === "radiobeacons") {
                formattedData = concatDupesRBCNS(queryData, type);
            } else {
                formattedData = concatDupesLights(queryData, type);
            }
            
            return <tbody><VolumeData data={formattedData} /></tbody>          
        }
    }
}

function showGeoAreasofVolume(array){
    const listByGeoArea = groupBy(array, 'geopolitcalHeading');
    return listByGeoArea;
}

function concatDupesRBCNS(array, type){
    let formattedArray = []
    let object;
    //iterate over list backwards to concatenate values that are duplicated
    for (let i = array.length-1; i>-1; i--){
        object = {}
        if(array[i].precedingNote){
            object.precedingNote = array[i].precedingNote.replace(/#|!|\$/g,''); // get rid of # or ! /
        }
        if(array[i].postNote){
            object.postNote = array[i].postNote.replace(/#|!|\$/g,''); // get rid of # or ! /
        }
        object.aidNumber = array[i].featureNumber;
        object.name = (array[i].name ? textReplacements(array[i].name) : '');
        //concatenate Lat & Long
        object.latlong = array[i].position;
        //field 4 characteristic
        object.transmissionText = (array[i].characteristic ? textReplacements(array[i].characteristic) : '');
        //field 5 range
        object.range = array[i].range
        //field 6 sequence
        object.sequenceText = (array[i].sequenceText ? array[i].sequenceText : '');
        //field 7 frequency
        object.frequency = array[i].frequency;
        //field 8 concatenate characteristic remarks
        object.characteristicRemarks = (array[i].stationRemark ? array[i].stationRemark : '');

        //set geo names
        if (!array[i].regionHeading){ array[i].regionHeading = '' }
        if (!array[i].subregionHeading){ array[i].subregionHeading = '' }
        if (!array[i].localHeading){ array[i].localHeading = '' }
        object.geoHDRName = array[i].geopoliticalHeading;
        object.geoSHDRName = array[i].regionHeading;
        object.geoUHDRName = array[i].subregionHeading;
        object.geoBHDRName = array[i].localHeading;

        if(type === "ntm"){
            object.noticeNumber = array[i].noticeWeek + "/" + array[i].noticeYear;
        }
        //save to new array *unshift adds to beginning of array*
        formattedArray.unshift(object);
    }
    return formattedArray;
}

function concatDupesDGPS(array, type){
    let formattedArray = []
    let object;
    //iterate over list backwards to concatenate values that are duplicated
    for (let i = array.length-1; i>-1; i--){
        object = {}
        if(array[i].precedingNote){
            object.precedingNote = array[i].precedingNote.replace(/#|!|\$/g,''); // get rid of # or ! /
        }
        if(array[i].postNote){
            object.postNote = array[i].postNote.replace(/#|!|\$/g,''); // get rid of # or ! /
        }
        object.aidNumber = array[i].featureNumber;
        object.name = (array[i].name ? textReplacements(array[i].name) : '');
        //concatenate Lat & Long
        object.latlong = array[i].position;
        //field 4 transmit_station_id + ref_station_ids
        object.transmitStation = (array[i].stationID ? array[i].stationID.replace(/null/g, '') : '');
        //field 5 range
        object.range = (array[i].range ? array[i].range : '');
        //field 6 frequency
        object.frequency = (array[i].frequency ? array[i].frequency : '');
        //field 7 transfer rate
        object.transferRate = (array[i].transferRate ? array[i].transferRate : '');
        //field 8 characteristic remarks
        object.characteristicRemarks = textReplacements(array[i].remarks);

        if (!array[i].regionHeading){ array[i].regionHeading = '' }
        if (!array[i].subregionHeading){ array[i].subregionHeading = '' }
        if (!array[i].localHeading){ array[i].localHeading = '' }
        object.geoHDRName = array[i].geopoliticalHeading;
        object.geoSHDRName = array[i].regionHeading;
        object.geoUHDRName = array[i].subregionHeading;
        object.geoBHDRName = array[i].localHeading;

        if(type === "ntm"){
            object.noticeNumber = array[i].noticeWeek + "/" + array[i].noticeYear;
        }
        //save to new array *unshift adds to beginning of array*
        formattedArray.unshift(object);
    }
    return formattedArray;
}

function concatDupesLights(array, type){
    let formattedArray = []
    let object;

    //iterate over list backwards to concatenate values that are duplicated
    for (let i = array.length-1; i>0; i--){
        
        if (array[i].featureNumber === array[i-1].featureNumber && (array[i].name !== array[i-1].name || array[i].charNo !== array[i-1].charNo)){
            //we are on a secondary item for the same feature number
            //only show characteristic name, characteristic text, perperra, range, remarks, structure
            object = {}
            object.aidNumber = ''
            object.name = (array[i].name ? textReplacements(array[i].name) : '');
            object.latlong = '';
            object.characteristicText = (array[i].characteristic ? textReplacements(array[i].characteristic) : '');
            object.height = array[i].heightFeetMeters;
            object.range = (array[i].range ? array[i].range : '');
            object.structureText = (array[i].structure ? array[i].structure.replace(/#|!|\$/g,'') : '');
            object.characteristicRemarks = (array[i].remarks ? textReplacements(array[i].remarks) : '');

            if (!array[i].regionHeading){ array[i].regionHeading = '' }
            if (!array[i].subregionHeading){ array[i].subregionHeading = '' }
            if (!array[i].localHeading){ array[i].localHeading = '' }
            object.geoHDRName = array[i].geopoliticalHeading;
            object.geoSHDRName = array[i].regionHeading;
            object.geoUHDRName = array[i].subregionHeading;
            object.geoBHDRName = array[i].localHeading;

            if(type === "ntm"){
                object.noticeNumber = null;
            }
            //save to new array *unshift adds to beginning of array*
            formattedArray.unshift(object);
        }
        else {
            object = {}
            //we are on the first row for this station
            if(array[i].precedingNote){
                object.precedingNote = array[i].precedingNote.replace(/#|!|\$/g,''); // get rid of # or ! /
            }
            if(array[i].postNote){
                object.postNote = array[i].postNote.replace(/#|!|\$/g,''); // get rid of # or ! /
            }
            object.aidNumber = array[i].featureNumber;
            object.name = (array[i].name ? textReplacements(array[i].name) : '');
            object.latlong = array[i].position;
            object.characteristicText = (array[i].characteristic ? textReplacements(array[i].characteristic) : '');
            object.height = array[i].heightFeetMeters;
            object.range = (array[i].range ? array[i].range : '');
            object.structureTextLL = (array[i].structure ? array[i].structure.replace(/#|!|\$/g,'') : '');
            object.characteristicRemarks = (array[i].remarks ? textReplacements(array[i].remarks) : '');
            
            if (!array[i].regionHeading){ array[i].regionHeading = '' }
            if (!array[i].subregionHeading){ array[i].subregionHeading = '' }
            if (!array[i].localHeading){ array[i].localHeading = '' }
            object.geoHDRName = array[i].geopoliticalHeading;
            object.geoSHDRName = array[i].regionHeading;
            object.geoUHDRName = array[i].subregionHeading;
            object.geoBHDRName = array[i].localHeading;

            if(type === "ntm"){
                object.noticeNumber = array[i].noticeWeek + "/" + array[i].noticeYear;
            }
            //save to new array *unshift adds to beginning of array*
            formattedArray.unshift(object);
        }
    }
    //first record in the array
    object = {}
    //we are on the first row for this station
    if(array[0].precedingNote){
        object.precedingNote = array[0].precedingNote.replace(/#|!|\$/g,''); // get rid of # or ! /
    }
    if(array[0].postNote){
        object.postNote = array[0].postNote.replace(/#|!|\$/g,''); // get rid of # or ! /
    }
    object.aidNumber = array[0].featureNumber;
    object.name = (array[0].name ? textReplacements(array[0].name) : '');
    //concatenate Lat & Long
    object.latlong = array[0].position;
    object.characteristicText = (array[0].characteristic ? textReplacements(array[0].characteristic) : '');
    object.height = array[0].heightFeetMeters;
    object.range = (array[0].range ? array[0].range : '');
    object.structureTextLL = (array[0].structure ? array[0].structure.replace(/#|!|\$/g,'') : '');
    object.characteristicRemarks = (array[0].remarks ? textReplacements(array[0].remarks) : '');
    
    if (!array[0].regionHeading){ array[0].regionHeading = '' }
    if (!array[0].subregionHeading){ array[0].subregionHeading = '' }
    if (!array[0].localHeading){ array[0].localHeading = '' }
    object.geoHDRName = array[0].geopoliticalHeading;
    object.geoSHDRName = array[0].regionHeading;
    object.geoUHDRName = array[0].subregionHeading;
    object.geoBHDRName = array[0].localHeading;

    if(type === "ntm"){
        object.noticeNumber = array[0].noticeWeek + "/" + array[0].noticeYear;
    }
    //save to new array *unshift adds to beginning of array*
    formattedArray.unshift(object);
    return formattedArray;
}

function textReplacements(text){
    //replaces common chars in text
    if(text && (typeof text === 'string' || text instanceof String)){
        return text.replace(/#|!|\$/g,'').replace(/\^/g,'°').replace(/@/g, '• ').replace(/_/g, '- ').replace(/`/g, "'").replace('null', '');
    } else { return text }
}

function volumeToInt(volumeNumberString){
    let volNumInt = null;
    switch(volumeNumberString){
        case "PUB 110":
            volNumInt = 110;
            break;
        case "PUB 111":
            volNumInt = 111;
            break;
        case "PUB 112":
            volNumInt = 112;
            break;
        case "PUB 113":
            volNumInt = 113;
            break;
        case "PUB 114":
            volNumInt = 114;
            break
        case "PUB 115":
            volNumInt = 115;
            break;
        case "PUB 116":
            volNumInt = 116;
            break;
        default:
            break;
    }
    return volNumInt;
}

const LinkedList = (props) => {
    //List of links that will show the GeoHDR value when multiple volumes are returned
    //DOM XSS Fix
    let url = encodeURIComponent('/queryBreakout?' + window.location.search.substring(1));
    url.replace("/[^a-zA-Z0-9]+/", "");
    //END DOM XSS Fix
    const volNum = volumeToInt(props.volume);
    return (
        <tbody id={props.volume}>
            <tr>
                <td colSpan={42} align="center" className="bg-gray-lighter text-bold">{props.volume}</td>
            </tr>
            {Object.keys(props.data).sort().map(area => <tr><td><a href={url+'&volume='+volNum+'&geoHDRName='+area} target='_blank'>{area}</a></td></tr>)}
        </tbody>
    );
}

const TableData = (props) => {
    //Returns the actual table rows for each row in the table.
    //Excludes certain elements (that will be taken care of via another method (like pre/post note))
    return (
        <tr>
            {Object.keys(props.data).map(function(element) { 
                let dataValue = props.data[element];
                // list of elements that should NOT be displayed the normal way
                const nonDisplay = ['featureId', 'geoHDRName', 'geoSHDRName', 'geoUHDRName', 'geoBHDRName', 'precedingNote', 'postNote', 'structure', 'structureUp', 'structureDown'];
                if(nonDisplay.indexOf(element) < 0){
                    return <td key={props.data["aidNumber"] + element} id={element} className="valignTop">{dataValue}</td>
                } 
                else if(element === "structureUp"){
                    //volume V structure Up, Down & Text
                    return <td key={props.data["aidNumber"] + element} id={element} className="valignTop"><span className="uscgll">{dataValue}</span><span className="uscgll">{props.data["structureDown"]}</span><br/>{props.data["structure"]}</td>
                }
                else { return false }
            })}
        </tr>
    );
}

const VolumeData = (props) => {
    let tableData = [];
    let displayGeoBHDR, displayGeoSHDR, displayGeoHDR, displayGeoUHDR, displayPreNote, displayPostNote;

    let geoHDR = '';
    let geoSHDR = '';
    let geoUHDR = '';
    let geoBHDR = '';

    for(let i=0; i<props.data.length; i++){
        //test display of area header names
        displayGeoHDR = (props.data[i].geoHDRName === geoHDR ? false : true);
        displayGeoSHDR = (displayGeoHDR ? (props.data[i].geoSHDRName !== '' ? true : false) : (props.data[i].geoSHDRName === geoSHDR ? false : (props.data[i].geoSHDRName !== '' ? true : false)));
        displayGeoUHDR = (props.data[i].geoUHDRName === geoUHDR ? false : (props.data[i].geoUHDRName !== '' ? true : false));
        displayGeoBHDR = (props.data[i].geoBHDRName === geoBHDR ? false : (props.data[i].geoBHDRName !== '' ? true : false));

        //test the display of the prenote and postnote
        displayPreNote = (props.data[i].precedingNote ? true : false);
        displayPostNote = (props.data[i].postNote ? true : false)

        //we created an array of reactjs components that will display the table rows
        //for each header, if true (meaning display it) add it to the array
        if (displayGeoHDR){ tableData.push(<DisplayGeoHDR areaName={props.data[i].geoHDRName} key={props.data[i].geoHDRName} />) }
        if (displayGeoSHDR){ tableData.push(<DisplayGeoSHDR areaName={props.data[i].geoSHDRName} key={props.data[i].geoSHDRName} />) }
        if (displayGeoUHDR){ tableData.push(<DisplayGeoUHDR areaName={props.data[i].geoUHDRName} key={props.data[i].geoUHDRName} />) }
        if (displayGeoBHDR){ tableData.push(<DisplayGeoBHDR areaName={props.data[i].geoBHDRName} key={props.data[i].geoBHDRName} />) }

        //display prenote (if exists), table data (always), then postnote (if exists)
        if (displayPreNote){ tableData.push(<DisplayNote note={props.data[i].precedingNote} key={i+"PreNote"} type="preNote" />) }
        tableData.push(<TableData data={props.data[i]} key={i} />);
        if (displayPostNote){ tableData.push(<DisplayNote note={props.data[i].postNote} key={i+"PostNote"} type="postNote" />) }
            
        //update vars if necessary
        if (props.data[i].geoHDRName !== geoHDR) { geoHDR = props.data[i].geoHDRName }
        if (props.data[i].geoSHDRName !== geoSHDR) { geoSHDR = props.data[i].geoSHDRName }
        if (props.data[i].geoUHDRName !== geoUHDR) { geoUHDR = props.data[i].geoUHDRName }
        if (props.data[i].geoBHDRName !== geoBHDR) { geoBHDR = props.data[i].geoBHDRName }
    }
    
    return tableData;
}

export {getTableHeaders, getResultHeaders, formatData, getVolumes};
