import React, {Component} from 'react';
import { Link } from 'react-router-dom'
const icon = "../icons/searchIcon.png";

function navbkg(){
    let loc = window.location.pathname;
    if (loc !== "/")
    {
        return "nav-panel-bkg";
    }
    else{
        return "nav-panel";
    }
};

class NavigationPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoggedIn: props.isLoggedIn           
        }
    }
    

    renderName(){
        if (this.state.isLoggedIn && this.props.isLoggedIn){
            //return the user's name
            return (
                <div className="float-right">
                    <p align="right" className="login"><b>{this.props.data["email"]}</b></p>
                </div>
            );
        }
    }

    search(){
        //validate more than 3 characters
        let params = document.getElementById("srch-term").value;
        if(params.length <= 3){
            window.alert("Plese enter a few more characters for a more accurate search.");
        } else {
            //open new tab with search results
            window.open('/search?q='+params, '_blank');
        }
        
    }

    render() {
        const wrapper = this.state.isLoggedIn && this.props.isLoggedIn ? "col-md-7 col-md-offset-5" : "col-sm-4 col-sm-offset-8";
        const inputsize = this.state.isLoggedIn && this.props.isLoggedIn ? "input-group col-md-7" : "input-group";
        return (
            <section className={navbkg()}>
                <div className={wrapper +" pb10"}>
                    <div className={inputsize}>
                        <input className="form-control" placeholder="Search" name="srch-term" id="srch-term" type="text" />
                        <div className="input-group-btn">
                            <button onClick={this.search}><img src={icon} alt="search" height="26" width="25" /></button>
                        </div>
                    </div>
                    {this.renderName()}
                </div>
                <h3 className="page-title">Maritime Safety Information</h3>
                <div className="container">
                    <Link className="btn btn-xs btn-default thin" to="/home">Home</Link>
                    <Link className="btn btn-xs btn-default thin" to="/NTM">Notice to Mariners</Link>
                    <Link className="btn btn-xs btn-default thin" to="/Publications">Publications</Link>
                    <Link className="btn btn-xs btn-default thin" to="/NavWarnings">Navigational Warnings</Link>
                    <Link className="btn btn-xs btn-default thin" to="/Piracy">Piracy</Link>
                    <Link className="btn btn-xs btn-default thin" to="/MODU">Drill Rigs</Link>
                    <Link className="btn btn-xs btn-default thin" to="/Products">Product Catalog</Link>
                    <Link className="btn btn-xs btn-default thin" to="/MiscProducts">Misc. Products</Link>
                    <Link className="btn btn-xs btn-default thin" to="/Calc">Nautical Calculators</Link>
                </div>
            </section>
        );
    }
}

export default NavigationPanel;