import React, { Component } from 'react';
const msiFetch = require('../../controllers/MsiFetch');

class Faq extends Component {
    constructor(props){
        super(props);
        this.state = {
            FAQArray: []
        }
    }

    async componentDidMount(){
        //api call to get the faq information from the database
        const url = "info/faq"
        await msiFetch.fetchData(url)
        .then(data => {
            this.setState({FAQArray: data});
        })
        .catch(error => {
            this.setState({FAQArray: []});
        });
    }  

    buildcards(FAQArray) {
        return FAQArray.map(FAQcontent=>
            <div className="card" key={FAQcontent["id"]}>
                <div className="card-header">
                    <div className="text-left">
                        <button className="btn btn-xs btn-block btn-new" type="button" data-toggle="collapse" data-target={"#" + FAQcontent["dataTarget"]} aria-expanded="true" aria-controls="collapseOne">   
                            <b>{FAQcontent["title"].replace(/''/ig,"'")}</b>
                        </button>
                    </div>
                </div>

                <div id={FAQcontent["dataTarget"]} className="collapse"  data-parent="#faqquestions">
                    <div className="text-left">
                        <div className="card-body" dangerouslySetInnerHTML={{ __html: FAQcontent["body"].replace(/''/ig,"'")}}>
                        </div>
                    </div>
                </div>  
            </div>
        );
    }

    render() {
        return (
            <div className="Faq">
                <header className="Faq-header container">
                    <h5>Frequently Asked Questions</h5>
                    <br></br>

                    <div className="accordion" id="faqQuestions" >
                        {this.buildcards(this.state.FAQArray)}
                    </div>  
                </header>
            </div>
        );
    }
}
  
export default Faq;
  