import groupBy from 'lodash/groupBy';

const lightsUtils = require('../Publications/LightsUtils');
const MsiFetch = require('../../controllers/MsiFetch');

function yrVal(yrNum, minYr){
    if(yrNum < minYr) {
        return "Please enter a valid 4 digit year beginning at " + minYr + '.\n';
    }
    else 
    {
        return "";
    }
}

function validateNTMRange(pubType){
    //check week number between 1-53
    //check year is less than or equaled to current year
    //Chartlest/Depth Tab check year is greater than or equaled to CNTM1981, NTM1977; ChartAffected/Chart Corr (CNTM1960, NTM1918)
    let alert = '';
    const noticeType = document.getElementById("selSearchNtms").value;
    const queryType = document.getElementById("queryType").value;
    switch(noticeType){
        case "bySpecificNTM":
            const wkNum = document.getElementById("endInputNTMWk").value;
            const yrNum = document.getElementById("endInputNTMYr").value;
            if(pubType === "untm"){
                if(wkNum > 53 || wkNum < 1) {
                    alert += "You must enter a valid week number for Specific NtM search (1 - 53). "
                }
            } else {
                if(wkNum > 12 || wkNum < 1) {
                    alert += "You must enter a valid month number for Specific NtM search (1 - 12). "
                }
            } 
            switch(queryType) {
                case "chartletsDepthtabsNotes":
                    if(pubType==="untm") {
                        alert += yrVal(yrNum, 1977);
                    }
                    else {
                        alert += yrVal(yrNum, 1981);
                    }
                    break;
                case "chartsAffected":
                case "chartCorr":
                    if(pubType==="untm") {
                        alert += yrVal(yrNum, 1918);
                    }
                    else {
                        alert += yrVal(yrNum, 1960);
                    }
                    break;
                default:
                    alert += yrVal(yrNum, 1999);
                    break;
            }
           
            if(wkNum < 10 && wkNum.length < 2){
                document.getElementById("endInputNTMWk").value = "0"+wkNum;
            }
            break;
        case "byNTMRange":
            const startWkNum = document.getElementById("startInputNTMWk").value;
            const startYrNum = document.getElementById("startInputNTMYr").value;
            if(pubType === "untm"){
                if(startWkNum > 53 || startWkNum < 1) {
                    alert += "You must enter a valid week number for Specific NtM search (1 - 53). "
                }
            } else {
                if(startWkNum > 12 || startWkNum < 1) {
                    alert += "You must enter a valid month number for Specific NtM search (1 - 12). "
                }
            }
           
            if(startWkNum < 10  && startWkNum.length < 2){
                document.getElementById("startInputNTMWk").value = "0"+startWkNum;
            }         
            const endWkNum = document.getElementById("endInputNTMWk").value;
            const endYrNum = document.getElementById("endInputNTMYr").value;
            if(pubType === "untm"){
                if(endWkNum > 53 || endWkNum < 1) {
                    alert += "You must enter a valid week number for Specific NtM search (1 - 53). "
                }
            } else {
                if(endWkNum > 12 || endWkNum < 1) {
                    alert += "You must enter a valid month number for Specific NtM search (1 - 12). "
                }
            }
           
            if(endWkNum < 10  && endWkNum.length < 2){
                document.getElementById("endInputNTMWk").value = "0"+endWkNum;
            }   
            
            switch(queryType) {
                case "chartletsDepthtabsNotes":
                    if(pubType==="untm") {
                        alert += yrVal(startYrNum, 1977);
                        alert += yrVal(endYrNum, 1977);
                    }
                    else {
                        alert += yrVal(startYrNum, 1981);
                        alert += yrVal(endYrNum, 1981);
                    }
                    break;
                case "chartsAffected":
                case "chartCorr":
                    if(pubType==="untm") {
                        alert += yrVal(startYrNum, 1918);
                        alert += yrVal(endYrNum, 1918);
                    }
                    else {
                        alert += yrVal(startYrNum, 1960);
                        alert +=  yrVal(endYrNum, 1960);
                    }
                    break;
                default:
                    alert += yrVal(startYrNum, 1999);
                    alert +=  yrVal(endYrNum, 1999);
                    break;
            }
            break;
        default:
            //we dont need to do anything if searching all ntms
            break;
    }
    if(alert !== ''){
        window.alert(alert);
        return 1;
    }
    return 0;
}

function validateChartCorr(){
    //check specific charts is comma separated list,
    //check geographic area is filled in (call utils class for this)
    var letters = /[^a-zA-Z0-9,-\s]/g;
    let alert = '';
    const filterType = document.getElementById("selChartCorrFilters").value;
    if(filterType === "specifcCharts"){
        const chartList = document.getElementById("inputChartList").value;
        
        if(chartList.match(letters)) {
            alert += "Invalid characters entered in List of Charts field. Valid characters are letters, numbers, hyphens, and commas."
        }
        if(chartList == null || chartList === ""){
            alert += "You must enter a list of chart numbers for Specific Charts search."
        }
    } else if(filterType === "geographicArea"){
        alert += lightsUtils.validateFields();
    } else if(filterType === "all"){
        //if user selected all, they can only query 1 years worth of ntms
        const noticeType = document.getElementById("selSearchNtms").value;
        if(noticeType === "byNTMRange"){
            const startWkNum = document.getElementById("startInputNTMWk").value;
            const startYrNum = document.getElementById("startInputNTMYr").value;        
            const endWkNum = document.getElementById("endInputNTMWk").value;
            const endYrNum = document.getElementById("endInputNTMYr").value;
            if((endYrNum - 1) > startYrNum){
                alert += "When querying for All Charts, you are restricted to a NTM range of one year.";
            } else if(((endYrNum -1) === (startYrNum-0)) && (endWkNum > startWkNum)){
                alert += "When querying for All Charts, you are restricted to a NTM range of one year.";
            }
        } else if (noticeType === "all"){
            alert += "When querying for All Charts, must enter a specific NTM number or range."
        }
    }

    if(alert !== ''){
        window.alert(alert);
        return 1;
    }
    return 0;
}

function validateChartlets(){
    //check specific charts is comma separated list,
    var letters = /[^a-zA-Z0-9,-\s]/g;
    let alert = '';
    const chartFilter = document.getElementById("selChartletFilters").value;
    if(chartFilter === "specifcCharts"){
        const chartList = document.getElementById("inputChartList").value;
        if(chartList.match(letters)) {
            alert += "Invalid characters entered in List of Charts field. Valid characters are letters, numbers, hyphens, and commas."
        }
        if(chartList == null || chartList === ""){
            alert += "You must enter a list of chart numbers for Specific Charts search."
        }
    }

    if(alert !== ''){
        window.alert(alert);
        return 1;
    }
    return 0;
}

function validateChartsAffected(){
    //do the same thing as chart corrections (no need to duplicate code)
    return validateChartCorr();
}

function validateHydrographic(){
    return 0;
}

function validateNavPubCorr(){
    //check that a product is selected
    let alert = "";
    const pubType = document.getElementById("selNavProduct").value;
    if(pubType === "none"){
        alert += "Please select a Product.";
        window.alert(alert);
        return 1;
    }
    return 0;
}

async function validateUscgLLCorr(pubId){
    let alert = '';
    //check geographic area
    const filterType = document.getElementById("sel5").value;
    if(filterType === "geographicArea"){
        alert += lightsUtils.validateFields();
    }
    
    //check noticeNumber is within available ntm corrections range
    const startWkNum = document.getElementById("startInputNTMWk").value;
    const startYrNum = document.getElementById("startInputNTMYr").value;           
    const endWkNum = document.getElementById("endInputNTMWk").value;
    const endYrNum = document.getElementById("endInputNTMYr").value;
    let volume = document.getElementById("sel4").value; //1,2,3...
    const url = "publications/ntm/ntm-available-corr?pubTypeId=" + pubId;

    //convert noticeNumbers
    const noticeNumberStart = "" + startYrNum + startWkNum;
    const noticeNumberEnd = "" + endYrNum + endWkNum;

    //if using uscgll, convert selected volume (1,2,3...) to roman number (I,II,III...)
    if(!(volume.length > 2)){
        volume = await lightsUtils.volToRoman(volume).split(" ")[1]; 
    }
    //api call to get available notice numbers
    const dataList = await request(url);
    const groupedList = groupBy(dataList, "volumeNumber");
    //returns {"I": [], "II": [], "III": []...}

    //grab list that matches our volume number
    if(groupedList[volume]){
        let data = groupedList[volume][0];
        //check if noticeNumber is lower than the last notice
        if(noticeNumberStart !== ""){
            if(parseInt(noticeNumberStart, 10) < data['dbLastNotice']){
                alert = "Invalid NtM Number. Please check the List of Available NtM Corrections."
            }
        }
        if(noticeNumberEnd !== ""){
            if(parseInt(noticeNumberEnd, 10) < data['dbLastNotice']){
                alert = "Invalid NtM Number. Please check the List of Available NtM Corrections."
            }
        }
    }

    if(alert !== ''){
        window.alert(alert);
        return 1;
    }
    return 0;
}

async function validateNgaLolCorr(pubId){
    //do the same thing as USCG LL (no need to duplicate code)

    return await validateUscgLLCorr(pubId);
}

const request = async (url) => {
    const response = await MsiFetch.fetchData(url).then(data => data['availCorr']);
    return response
}

export {validateChartCorr, validateNTMRange,
        validateChartlets, validateChartsAffected,
        validateHydrographic, validateNavPubCorr,
        validateUscgLLCorr, validateNgaLolCorr};