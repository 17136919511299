import React from 'react';
import groupBy from 'lodash/groupBy';
import '../../../css/queryResults.css';
const msiFetch = require('../../../controllers/MsiFetch');

const dfrsTableHeaders = ["No", "Name", "Type", "Rx", "Tx", "Frequency", "Range", "Procedure", "Remarks"];

const tsTableHeaders = ["No", "Name", "Hours of Transmission", "System", "Frequency"];

const warnTableHeaders = ["No", "Names", "Frequency", "Times", "Nature of Broadcast"];

function getTableHeaders(chapter){
    var chapterNo;
    switch(chapter){
        case "dfrs":
            chapterNo = 1;
            break;
        case "ts":
            chapterNo = 2;
            break;
        case "warn":
            chapterNo = 3;
            break;
        default:
            chapterNo = 1;
    }
    
    return (
        <thead className="center-align">
            <tr>
                <td colSpan={42} className="bg-gray-lighter query-title">Radio Navigation Aids Chapter {chapterNo}<br />Query Results</td>
            </tr>
        </thead>
    );
}

function getResultHeaders(chapter){
    switch(chapter) {
        case "dfrs":
            return dfrsTableHeaders;
        case "ts":
            return tsTableHeaders;
        case "warn":
            return warnTableHeaders;
        default:
            break;
    }
}

const TableSection = (props) => {
    return (
        <tbody id={props.area}>
            <tr>
                <td colSpan={42} align="center" className="bg-gray-lighter text-bold">{props.area}</td>
            </tr>
            <tr>
                <td colSpan={42} id="area-notes"></td>
            </tr>
            {props.data[props.area].map(station => { return <TableData data={station} key={station} /> })}
        </tbody>
    );
}

const TableData = (props) => {
    return ([
            <tr>
            {Object.keys(props.data).map(function(element) { 
                var dataValue = props.data[element];
                if(element !== "areaName" && element !== "fNote" && element !== "pnote" && element !== "notes"){
                    if (element === "stationNo" || element === "stationName"){
                        return <td id={element} className="text-bold">{dataValue}</td>
                    } else {
                        return <td id={element}>{dataValue}</td>
                    }
                }
                else { return false }
            })}
            </tr>
            ,
            <tr>
            {Object.keys(props.data).map(element => {
                var dataValue = props.data[element];
                if (element === "notes" || element === "fNote"){
                    return <TableNotes element={element} data={dataValue} key={dataValue} /> 
                }
                else return false;
            })}
            </tr>
    ]);
}

const TableNotes = (props) => {
    return [<td className="no-border"></td>,<td className="no-border" id={props.element} colSpan={42}>{props.data}</td>]
}

function formatData(chapter, data){
    //if there were no results returned from the database, return empty array
    if ((data['radio-navaids']).length === 0) {
        return data['radio-navaids'];
    }
    else {
        var formattedData = {}
        //group data by areaName {creates object with areanames as keys}
        var list = groupBy(data['radio-navaids'], 'areaName');
        //for each area, iterate the array to null out the duplicate values
        for (var key in list){
            var array = list[key]
            if (chapter === "dfrs"){
                formattedData[key] = formatDFRSData(array);
            }
            else if (chapter === "ts"){
                formattedData[key] = formatTSData(array);
            }
            else if (chapter === "warn"){
                formattedData[key] = formatWarnData(array);
            }
        }
        return Object.keys(formattedData).map(area => { return <TableSection area={area} data={formattedData} chapter={chapter} key={area} /> });

    }
}

function formatDFRSData(array){
    var formattedArray = array;
    formattedArray.sort(function(a, b) {
        var x = parseFloat(a['stationNo']),
            y = parseFloat(b['stationNo']);

        return 1 * ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    return formattedArray;
}

function formatTSData(array){
    //iterate list backwards and null out values that are duplicated
    for (var i = array.length-1; i > 0; i--){
        if (array[i].stationName === array[i-1].stationName){
            //null out duplicate values so they don't show on table display
            array[i].stationNo = null;
            array[i].stationName = null;
            array[i].system = null;
            //fnote shows on last row of the duplicated station 
            array[i-1].fnote = null;
        }
    }
    return array
}

function formatWarnData(array){
    var formattedArray = []
    //iterate over list backwards to concatenate values that are duplicated
    for (var i = array.length-1; i>0; i--){
        if (array[i].stationName === array[i-1].stationName){
            //concatenate frequency with prior row
            if (array[i].frequency){
                array[i-1].frequency = array[i-1].frequency + " \n" + array[i].frequency;
            }
            if (array[i].times){
                array[i-1].times = array[i-1].times + " \n" + array[i].times;
            }
            if (array[i].broadcastNature){
                array[i-1].broadcastNature = array[i-1].broadcastNature + " \n" + array[i].broadcastNature;
            }
        }
        else {
            formattedArray.unshift(array[i]);
        }
    }
    formattedArray.unshift(array[0]);
    return formattedArray;
}

async function getAreaNotes(data){
    const areaNotes = await msiFetch.fetchData('publications/radio-navaids/dfrs/areas');

    if ((data['radio-navaids']).length > 0) {
        //group data by areaName {creates object with areanames as keys}
        var list = groupBy(data['radio-navaids'], 'areaName');
        var areas = areaNotes['areas'];
        //for each area, iterate the notes array to pull out area notes
        for (var key in list){
            //start with empty notes list
            var notes = [];
            //for each area in our areaNotes list, see if it the area that we are 
            // looking at has existing notes
            for (var i = 0; i < areas.length; i++){
                if (areas[i][0] === key && areas[i][2] !== null){
                    //if notes exist, add them to the array
                    notes[0] = areas[i][2] + "<br/>";
                    //if frequencies exist, add them to the array
                    if (areas[i][4]) { notes.push(areas[i][4] + "<br/>") }
                }
            }
            //update the DOM with the area notes
            if(notes.length > 0){
                //select the part of the table with the Area name
                var areaNotesDOM = document.getElementById(key).childNodes[1].childNodes[0];
                //set the notes to be in the table
                areaNotesDOM.innerHTML = notes.join("");
            }
        }
    }
}

export {getTableHeaders, getResultHeaders, formatData, getAreaNotes};
