import React, {Component} from 'react';
import PageContent from '../PageContent';

class VisitReport extends Component {
    render() {
        return (
            <div className="VisitReport">
                <PageContent divid="visit-report" />
            </div>
        );
    }
}

export default VisitReport;