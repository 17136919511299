import React from 'react';
import '../../css/queryResults.css';

const publicationTableHeaders = ["Series Stock Number (National Stock Number)", "Title", "Edition Number", "Edition Date", "Price Category", "Announced in NtM"];
const todTableHeaders = ["Stock No. (National Stk. No.)", "Title", "Edition Number", "Edition Date", "Price Category", "Announced in NtM"];
const dncTableHeaders = ["Stock No. (National Stk. No.)", "Title", "Edition Number", "Edition Date", "Price Category", "Announced in NtM"];
const ngaNosTableHeaders = ["Stock No. (National Stk. No.)", "Title", "", "Scale = 1:", "Edition Number", "Edition Date", "Price Category", "Announced in NtM"];

function getTableHeaders(productType){
    let pubType = "";
    switch(productType){
        case "publications":
            pubType = "Publication";
            break;
        case "tod":
            pubType = "TOD Chart";
            break;
        case "dnc":
            pubType =  "DNC Chart";
            break;
        case "nganos":
            pubType = "Catalog NGA / NOS Chart";
            break;
        default:
            break;
    }

    return (
        <thead className="center-align">
            <tr>
               <td colSpan={42} className="bg-gray-lighter query-title">Product Catalog {pubType}<br />Query Results</td>
            </tr>
            <tr><td colSpan={42}>
               <a onClick={()=>{window.open('/PriceCategories.html', '_blank', 'width=650,height=450'); return false;}}>View Price Category Information</a></td>
            </tr>
        </thead>
    );
}

function getResultHeaders(productType){
    switch(productType) {
        case "publications":
            return publicationTableHeaders;
        case "tod":
            return todTableHeaders;
        case "dnc":
            return dncTableHeaders;
        case "nganos":
            return ngaNosTableHeaders;
        default:
            break;
    }
}

function formatData(productType, data){
    //if there were no results returned from the database, return empty array
    const apiData = data['prodcat'];
    let formattedData = [];

    if (apiData.length === 0) {
        return apiData;
    }
    else {
        const copy = [...apiData];
        if (productType === 'publications'){
            formattedData = formatPubData(copy);
        } else if (productType === 'nganos') {
            formattedData = formatNgaNosData(copy);
        } else formattedData = copy;
    }
    return (
        <tbody>
            {formattedData.map((product,index) => { return <TableData data={product} key={index} /> })}
        </tbody>
    );

}

function formatPubData(array){
    for (var i = array.length-1; i >= 0; i--){
        //blank out volumeId so that it is not rendered
        array[i].volumeId = null;
    }
    return array;
}

function formatNgaNosData(inputArray){
    let array = [...inputArray];
    let outputArr = [];

    //for each, iterate backwards and combine dupes, blank out lat/long
    for (var i = array.length-1; i > 0; i--){
        let object = {};
        if(array[i].seriesAndNationalStockNumber === array[i-1].seriesAndNationalStockNumber){
            //we are on a secondary (or more) item for this product
            //add or update inset list
            if(!array[i-1].insetList){
                //add new list
                array[i-1].insetList = (array[i].insetList ? array[i].insetList : []);
            }
            //copy current object and add 
            object.plan = array[i].plan + ":";
            object.insetTitle = array[i].insetTitle;
            object.insetScale = array[i].insetScale;
            array[i-1].insetList.unshift(object);

        } else {
            //we are on the first (or only) item for this product
            if(array[i].insetTitle.toLowerCase() !== array[i].chartTitle.toLowerCase()){
                //inset has a different title, add it to inset list
                object.plan = array[i].plan + ":";
                object.insetTitle = array[i].insetTitle;
                object.insetScale = array[i].insetScale;
                array[i].insetScale = null;
                //if this is the only item, there may not be an insetList existing and we should add it
                if(!array[i].insetList){
                    array[i].insetList = [];
                }
                array[i].insetList.unshift(object);
            }
            array[i].eminY = null;
            array[i].eminX = null;
            array[i].emaxY = null;
            array[i].emaxX = null;
            array[i].insetId = undefined;
            //add to final array
            outputArr.unshift(array[i])
            
        } 
    }

    //update the first entry in the list (which we couldn't iterate through)
    if(array[0].insetTitle.toLowerCase() !== array[0].chartTitle.toLowerCase()){
        //inset has a different title, add it to inset list
        let object = {};
        object.plan = array[0].plan + ":";
        object.insetTitle = array[0].insetTitle
        object.insetScale = array[0].insetScale
        array[0].insetScale = null;
        array[0].insetList.unshift(object)
    }
    array[0].eminY = null;
    array[0].eminX = null;
    array[0].emaxY = null;
    array[0].emaxX = null;
    array[0].insetId = undefined;
    //add to final array
    outputArr.unshift(array[0])
    return outputArr;
}

const TableData = (props) => {
    let insetList = [];
    if(props.data.insetList){
        insetList = props.data.insetList.map((inset,index) => (<tr key={index}>
            <td className="no-border"></td>
            <td className="no-border">{inset.plan}</td>
            <td className="no-border">{inset.insetTitle}</td>
            <td className="no-border">{inset.insetScale}</td>
            <td className="no-border" colSpan={5}></td>
        </tr>));        
    }
    const noshow = ["chartNumber", "chartEditionStatus", "region", "subregion",
        "plan", "insetList", "insetTitle", "catalogNotes",
        "eminY", "eminX", "emaxY", "emaxX", "insetId"];
   let datarow = [
        <tr key={props.data.chartTitle}>
            {Object.keys(props.data).map((element, index) => { 
                let dataValue;
                if(element === "chartTitle"){
                    dataValue = props.data[element];
                    return <td colSpan={2} id={element} key={index}>{dataValue}</td>
                } else if(noshow.indexOf(element) < 0){
                    dataValue = props.data[element];
                    return <td id={element} key={index}>{dataValue}</td>
                } else return null;
            })}
        </tr>
    ];
    
    //append insets to data row
    if(insetList.length > 0){
        for(let i=0; i<insetList.length; i++){
            datarow.push(insetList[i]);
        }
    }

    if(props.data.catalogNotes){
        datarow.push(<tr key={props.data.seriesAndNationalStockNumber}><td className="no-border"></td><td className="no-border" colSpan={42}>{props.data.catalogNotes}</td></tr>)
    }
    
    return datarow;
}
export {getTableHeaders, getResultHeaders, formatData}