import React, { Component } from "react";
import { Link } from "react-router-dom";

class HomeTC extends Component {
  render() {
    const styles = {
      width: "85%",
    };
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="gray-light-box">
              <h3>Maritime Safety Office</h3>
              <br />
              <h6 className="text-normal">
                <b>Our Mission</b>
              </h6>
              <p className="pl10 pr10">
                Provide global maritime geospatial intelligence in support of
                national security objectives, including safety of navigation,
                international obligations, and joint military operations.
              </p>
              <div>
                <b>Customer Outreach</b>
                <br />
                <br />
                <div className="col-sm-4 col-sm-offset-2">
                  <Link to="/whats-new" className="btn btn-home" style={styles}>
                    What's New...
                  </Link>
                </div>
                <div className="col-sm-4">
                  <Link
                    to="/did-you-know"
                    className="btn btn-home"
                    style={styles}
                  >
                    Did You Know...
                  </Link>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="row">
          <br />
        </div>
        <div className="col-md-8 col-md-offset-2">
          <div className="gray-light-box">
            <div className="col-sm-6">
              <Link to="/browser-support">Browser Support</Link>
            </div>
            <div className="col-sm-6">
              <Link to="/legal-sec">Legal Disclaimer & Security Policy</Link>
            </div>
          </div>
        </div>
        <div className="row">
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default HomeTC;