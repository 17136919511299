import React, {Component} from 'react';
import AutoComplete from '../../../utils/AutoComplete';
import { RNAStations } from '../tmp/pubConstants';
const MsiFetch = require('../../../controllers/MsiFetch');

const OptionValue = (props) => {
    //option items for country filter
    return <option value={props.data[1]}>{props.data[0]}</option>
}

class RNAQueryPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            areas: [],
            chapter: "dfrs",
            stations: RNAStations["dfrs"]
        }
        this.clearFilters = this.clearFilters.bind(this);
    }

    async componentDidMount(){
        //get list of countries for each chapter
        const areas = await MsiFetch.fetchData('publications/radio-navaids/areas');
        this.setState({areas});
    }

    updateChapter(){
        //when user changes chapter, update state
        var chapter = document.getElementById("sel4").value;
        this.setState({chapter, stations: RNAStations[chapter]});
    }

    getRadioNavAids(){
        // variables include the select boxes & input fields
        var filter = null;
        var chapter = document.getElementById("sel4").value;
        var filterType = document.getElementById("sel5").value;
        var output = document.getElementById("sel7").value
        // if user filtered by stationName, get the stationName value
        if (filterType === "stationName"){
            filter = document.getElementsByClassName("auto-comp")[0].value;
        }
        else {
            //get the areaId value
            filter = document.getElementById("sel6").value;
        }
        //build the api url to fetch with the radio-navaid chapter & filter
        var url = "publications/radio-navaids/";
        if (filterType !== "null"){
            url = url + chapter + '?' + filterType + "=" + filter + "&output=" + output;
        }
        else{
            url = url + chapter + '?output=' + output;
        }

        // open the query results window. send the api url as Request Params
        // so that we don't have to send data. Actual fetch will be done by QueryResults window
        window.open('/queryResults?'+url, '_blank');
    }

    getGeographicAreas(){
        // per the chapter you selected, get the <option> values for the countries
        var optionsList = [];
        var options = this.state.areas[this.state.chapter];
        //if statement because options could be undefined (async fetch has not run yet)
        if (options) {
            optionsList = options.map(area => {return <OptionValue data={area} key={area}/>});
        }
        return optionsList;
    }

    updateFinalFilter(){
        // variables are for input & select boxes related to radio-nav aids"
        var value = document.getElementById("sel5").value;
        var label = document.getElementById("label6");
        var inputStationName = document.getElementsByClassName("auto-comp")[0];
        var areaIdSelect = document.getElementById("sel6");
        
        // display classNames that we will be setting
        var display = "form-control";
        var nodisplay = "form-control no-display"
        var display_autocomp = "form-control auto-comp"
        var nodisplay_autocomp = "form-control no-display auto-comp"

        // depending on what user is filtering by, show or do not show certain inputs
        switch(value) {
            case "stationName":
                label.innerHTML = "Enter Station Name";
                inputStationName.className = display_autocomp;
                areaIdSelect.className = nodisplay;
                areaIdSelect.value = "null";
                break;
            case "areaId":
                label.innerHTML = "Country";
                inputStationName.className = nodisplay_autocomp;
                areaIdSelect.className = display;
                this.refs["stationRef"].refs["typeAhead"].setEntryText('');
                break;
            default:
                label.innerHTML = "Enter Station Name";
                inputStationName.className = display_autocomp;
                areaIdSelect.className = nodisplay;
                areaIdSelect.value = "null";
        }
    }

    clearFilters(){
        var chapter = document.getElementById("sel4")
        var value = document.getElementById("sel5");
        var label = document.getElementById("label6");
        var inputStationName = document.getElementsByClassName("auto-comp")[0];
        var areaIdSelect = document.getElementById("sel6");

        chapter.value = "dfrs";
        value.value = "null";
        label.innerHTML = "";
        inputStationName.className = "auto-comp form-control no-display"
        areaIdSelect.className = "form-control no-display";
        this.refs["stationRef"].refs["typeAhead"].setEntryText('');
    }

    openQueryDirections(){
        //open the window in a specific width/height
        window.open('/queryDirections?queryType=radio-navaids', '_blank', 'toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600');
    }

    render(){
        return(
            <div className="row">
                <div className="col-sm-12 query-dir">
                    <a onClick={this.openQueryDirections.bind(this)}>Query Directions</a>
                </div>
                <div className="col-sm-6" id="selector-col4">
                    <label htmlFor="sel4" className="query-label">Select Chapter</label>
                    <select className="form-control" id="sel4" onChange={this.updateChapter.bind(this)}>
                        <option value="dfrs">Chapter 1: Radio Direction Finder &amp; Radar Stations</option>
                        <option value="ts">Chapter 2: Radio Time Signals</option>
                        <option value="warn">Chapter 3: Radio Navigational Warnings</option>
                    </select>
                </div>
                <div className="col-sm-6" id="selector-col5">
                    <label htmlFor="sel5" className="query-label">Search Stations</label>
                    <select defaultValue='null' className="form-control" id="sel5" onChange={this.updateFinalFilter.bind(this)}>
                        <option disabled value='null'>Select a Filter</option>
                        <option value="stationName">By Station Name</option>
                        <option value="areaId">By Country</option>
                    </select>
                </div>
                <div className="col-sm-6" id="selector-col6">
                    <label htmlFor="sel6" className="query-label" id="label6"></label>
                    <AutoComplete ref="stationRef" data={this.state.stations}/>
                    <select defaultValue='null' className="form-control no-display" id="sel6">
                        <option disabled value='null'>Select a Country Name</option>
                        {this.getGeographicAreas()}
                    </select>
                </div>
                <div className="col-sm-6">
                    <label htmlFor="sel7" className="query-label" id="label7">Output Format</label>
                    <select defaultValue="html" className="form-control" id="sel7">
                        <option value="html">HTML</option>
                        <option value="xml">XML</option>
                        <option value="csv">CSV (Delimited text)</option>
                    </select>
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                    <div className="filter-buttons">
                        <a className="btn vol-btn" title="Search" id="btnApplyQuery" onClick={this.getRadioNavAids}>Search</a>
                        <a className="btn vol-btn" title="Clear Filters" id="btnClearQuery" onClick={this.clearFilters}>Clear</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default RNAQueryPanel;
