import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';


class APC extends Component {
    render(){
        return(
            <div id="atlas-pilot-charts-aids">
                <div id="apc-descrip">
                 <PageContent divid="apc-descrip" /> 
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="APC" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default APC;