import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
import 'babel-polyfill';
//importing polyfills for IE<11

import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import './css/bootstrap.min.css'
import App from './components/App';
const gx = require('./controllers/geoaxis')
//import registerServiceWorker from './registerServiceWorker';

// Index.js is the initial file for entry into our application. 
async function renderApp() {
    const gxurl = (window.location.pathname.match(/\//g).length > 1 ? "../api/application/gx-enabled" : "api/application/gx-enabled");
    const gxEnabled = await fetch(gxurl).then(response => response.json()).then(json => json.gxEnabled);
    const bannerUrl = (window.location.pathname.match(/\//g).length > 1 ? "../api/application/banner" : "api/application/banner");
    const banner = await fetch(bannerUrl).then(response => response.json()).then(json => json.banner);
    const networkurl = (window.location.pathname.match(/\//g).length > 1 ? "../api/application/classification" : "api/application/classification");
    const network = await fetch(networkurl).then(response => response.json());

    if(network.classification === "unclassified"){
        ReactDOM.render(<App loggedIn={false} user={null} gxEnabled={true} banner={banner} />, document.getElementById('root'));
    } else if (gxEnabled){
        const user = await gx.getUserInfo();
        ReactDOM.render(<App loggedIn={true} user={user} gxEnabled={true} banner={banner} />, document.getElementById('root'));
    }
    else {
        const user = {
            email: ""
        }
        ReactDOM.render(<App loggedIn={true} user={user} gxEnabled={false} banner={banner} />, document.getElementById('root'));
    }
}
renderApp();
//registerServiceWorker();