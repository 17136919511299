import React, {Component} from 'react';
import groupBy from 'lodash/groupBy';
import UNTM from './UNTM';
import CNTM from './CNTM';
import QueryPanel from '../QueryPanel';
import { SmModal } from '../../utils/SmModal';

const msiFetch = require('../../controllers/MsiFetch');
const pubConstants = require('../Publications/tmp/pubConstants');
const calcFileSize = require("../../utils/FileSize").calcFileSize;
const downloadUrl = require("../Publications/tmp/pubConstants").downloadUrl;

class NTM extends Component {
    constructor(props){
        super(props);
        this.state = {
            showNtm: false,
            showArchives: false,
            noticeNumbers: [], //from database
            ntmLinks: [], //links that appear for the last 4 ntm numbers
            ntmOptions: [],  //option tags to build dropdown
            cntmLinks: [], //links that appear in the last 4 cntm numbers
            cntmOptions: [], // option tags to build dropdown
            ntmPubs: [], //pubs updated when user selects ntm number    
            currrentUnclassNotice: "00/0000",
            currentClassNotice: "00/0000",
            network: props.network,
            pubList: [],
            ntm235: []
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.getNtmPubs = this.getNtmPubs.bind(this);
    }

    async componentDidMount(){
        //grab the notice numbers to populate the drop down
        const url = "publications/ntm/ls-notice-numbers";
        msiFetch.fetchData(url)
        .then(data => {
            this.setState({
                noticeNumbers: data, 
                ntmOptions: this.buildNoticeNumbers(data["notice_numbers"]), 
                currentUnclassNotice: data["notice_numbers"][0],
                ntmLinks: this.buildNtmLinks(data["notice_numbers"], "untm")
            });
        })
        .catch(() => {
            this.setState({noticeNumbers: [], ntmOptions: [], currentUnclassNotice: "00/0000", ntmLinks: []});
        });

        this.checkCNTM();
     
        let pubTypeId = pubConstants.pubType.NTMSummary;
        const pubUrl = "publications/stored-pubs?pubTypeId=" + pubTypeId;
		msiFetch.fetchData(pubUrl)
        .then(data => {
            this.setState({pubList: data});
        })
        .catch(error => {
            this.setState({pubList:[]});
		});
      
    }

    componentDidUpdate(prevProps) {
        if (this.props.network !== prevProps.network) {
            this.setState({ network: this.props.network });
            this.checkCNTM();
        }
    }

    checkCNTM(){
        if(this.props.network.substring(0, 7) !== "unclass" && this.props.network !== ""){
            //query database for class ntm numbers
            const hsurl = "publications/ntm/hs-notice-numbers";
            msiFetch.fetchData(hsurl)
            .then(data => {
                this.setState({
                    cntmOptions: this.buildNoticeNumbers(data["notice_numbers"]), 
                    currentClassNotice: data["notice_numbers"][0],
                    cntmLinks: this.buildNtmLinks(data["notice_numbers"], "cntm")
                });
            })
            .catch(() => {
                this.setState({noticeNumbers: [], ntmOptions: [], currentUnclassNotice: "00/0000", ntmLinks: []});
            });
        }

    }
   
    buildNoticeNumbers(noticeNumberArray) {
        //iterate through each key to make an option value
        let list  = [];
        if(noticeNumberArray){
            const slice = noticeNumberArray.slice(4, noticeNumberArray.length);
            list.push(slice.map(noticeNumber => <option value={noticeNumber} key={noticeNumber}>{noticeNumber}</option>));
        }
        return list;
    }

    buildNtmLinks(noticeNumberArray, type) {
        //slice list to get first 4
        let list = [];
        if(noticeNumberArray){
            const slice = noticeNumberArray.slice(0,4);
        list.push(slice.map((noticeNumber, index) => { return [<a key={noticeNumber} onClick={this.showModal.bind(this, type, noticeNumber)}>{noticeNumber}</a>,<br key={index}/>]}));
        }
        return list;
    }

    showArchives(){
        const selectedValue = document.getElementById("selSumCorrections").value;
        if(selectedValue === "archives"){
            //open modal for archives
            this.showModal("archives", null);
        } else {
            const link = pubConstants.downloadUrl + selectedValue;
            const title = window.location.hostname + link + "&output=";
            document.getElementById("btnDownloadSummaryCorr").title = title + "download";
            document.getElementById("btnViewSummaryCorr").title = title + "view";
            
        }
    }

    showSummaryCorr(type){
        const selectedValue = document.getElementById("selSumCorrections").value;
        if(selectedValue != null && selectedValue !== "null") {
            //open page with summary of correction
            window.open(downloadUrl+selectedValue+"&type=" + type);
        }
    }

    buildSummCorrections(){
        var list = this.state.pubList;
        const currYear = new Date().getFullYear().toString();
        const lastYear = (new Date().getFullYear()-1).toString();
        var hasyear=false;
        
        if (list.length > 0){
            return list.map(pub => {   
                if(!pub.s3Key){
                    return null;
                }
                //get the publication's year value. for this to work, the year of the pub should be made the internal path.
                var pubYear = pub.s3Key.substring(18,22);
                if (pubYear === currYear){
                    hasyear=true;
                    //place the current year's pubs in the drop down.
                    const link = pubConstants.downloadUrl + pub.s3Key;
                    const title = window.location.hostname + link + "&output=view";
                    return <option title={title} value={pub.s3Key} key={pub.pubsecId}>{pub.sectionDisplayName}  ({calcFileSize(pub.fileSize)})</option>;
                }
                else{
                    if (hasyear===false){
                        //place last year's pubs in the drop down b/c this year has not been released yet
                        if (pubYear === lastYear){
                            return <option value={pub.s3Key} key={pub.pubsecId}>{pub.sectionDisplayName}  ({calcFileSize(pub.fileSize)})</option>;
                        }
                    }
                }                   
                return null;
            });
        }
        else {
            return null;
        }
    }

    buildSummCorrectionsButtons(){
        let list = [];
        //build summary of corrections modal consisting of buttons for each pub per year
        if(this.state.pubList.length > 0){
            //add year to each object so that we can later group by year
            const updatedList = this.state.pubList.map(pub => {
                if(pub.s3Key){
                    pub.year = pub.s3Key.substring(18, 22);
                    pub.volume = pub.s3Key.substring(36, 37);
                    return pub;
                }
                return null;
            })

            const groupedCorr = groupBy(updatedList, "year");
            let yearGroupedCorr;
            let volGroupedCorr;
            let className;
            let href;
            //for each year (going back to 2007), push the year title
            for(let i=new Date().getFullYear(); i>2006; i--){
                yearGroupedCorr = groupedCorr[i];
                if(yearGroupedCorr){
                    //push year title
                    list.push(<p key={i}>{i} Editions</p>);                
                    /* push buttons... iterate over 5-6 because there are 5 volumes per year for unclass & 1 addtnl for class networks.\
                    if the volume exists make it active */
                    volGroupedCorr = groupBy(yearGroupedCorr, "volume");
                    for(let j=1; j<=5; j++){
                        if(volGroupedCorr[j]) {
                            className = "btn btn-ntm-pub";
                            href = downloadUrl + volGroupedCorr[j][0]["s3Key"] + "&type=view";
                        }
                        else {
                            className = "btn btn-ntm-pub disabled";
                            href = null;
                        }
                        list.push(<a key={""+i+j} className={className} href={href} target="_blank" >Volume {j}</a>);
                    }
                    //push in class summary of corrections if on high side networks
                    if(this.state.network.substring(0,7) !== "unclass"){
                        //if the publication exists for this year, add it
                        if(volGroupedCorr[""]){
                            //b/c its grouped by volume number, this will have any empty volume #
                            href = downloadUrl + volGroupedCorr[""][0]["s3Key"] + "&type=view";
                            list.push(<a key={i+"CSOC"} className="btn btn-ntm-pub" href={href} target="_blank" >{i} CSOC</a>);
                        } else {
                            //disabled csoc button
                            list.push(<a key={i+"CSOC"} className="btn btn-ntm-pub disabled" href={null} target="_blank" >{i} CSOC</a>);
                        }
                    }
                }
            }
        }
        return list; 
    }

    getNtmPubs(noticeNumber){
        //slice and dice to get number in correct format
        const number = noticeNumber.slice(3, noticeNumber.length) + noticeNumber.slice(0,2);
        const url = "publications/ntm/pubs?output=html&noticeNumber=" + number;
        msiFetch.fetchData(url)
            .then(data => { 
                const ntmPubs =  groupBy(data["pubs"], "sectionOrder");
                let ntmEntirePubs = [];
                //untm using section number 235
                if(ntmPubs["235"]){
                    //untm exists
                    ntmEntirePubs = ntmPubs["235"];
                }
                //cntm is using a different section number "375"
                if(ntmPubs["375"]){
                    //cntm exists
                    ntmEntirePubs = ntmPubs["375"];
                }
                this.setState({ntmPubs, 
                    ntm235: Array.from(ntmEntirePubs).sort((a, b) => a.fileExtension.localeCompare(b.fileExtension))});
            });
    }

    openNtmSection(event){
        const s3Key = event.target.value;
        if(s3Key !== "null"){
            const href = downloadUrl + s3Key + "&type=view";
            window.open(href, '_blank');
        }
    }

    showModal(modalType, noticeNumber){
        if (modalType === "archives") {
            this.setState({showArchives: true});
        } else {
            if(modalType === "untm"){
                if(!noticeNumber){
                    //pull it from the select box
                    noticeNumber = document.getElementById("selUNTM").value;
                }
                this.getNtmPubs(noticeNumber);
            } else if(modalType === "cntm"){
                if(!noticeNumber){
                    //pull it from the select box
                    noticeNumber = document.getElementById("selCNTM").value;
                }
                const alteredNum = parseInt(noticeNumber.substring(0,2), 10) + 60;
                const year = noticeNumber.substring(2);
                this.getNtmPubs(alteredNum + year);
            }
            //TODO if noticeNumber in top 4, use cached data?
            this.setState({showNtm: true, selectedNtM: noticeNumber});
        }
    }

    hideModal(){
        //when called, change the state to hide the modal
        this.setState({showNtm: false, showArchives: false, ntm235: [], ntmPubs: []});
        document.getElementById("selSumCorrections").value = "null"
    }

    renderNTMPageContent(){
        if(this.state.network.substring(0, 7) === "unclass" && this.state.network !== ""){
            return <UNTM ntm={this} />
        } else { return <CNTM ntm={this} /> }
    }

    render(){
        return(
            <div id="ntm" className="pb32">
                <div id="descrip" className="container">
                    {this.renderNTMPageContent()}
                    <div>
                        <SmModal show={this.state.showNtm} handleClose={this.hideModal} title="View/Download Publication">
                            <div className="col-xs-10 col-xs-offset-1 left-align">
                                <p className="section-title center-align">U.S. Notice to Mariners Number {this.state.selectedNtM}</p>
                                <label htmlFor="ntmBtm" className="query-label">Complete Publication</label><br/>
                                    {this.state.ntm235 ? this.state.ntm235.map(ntm => {
                                        const href = downloadUrl + ntm.odsKey + "&type=view";
                                        return <a target="_blank" href={href} className="btn btn-ntm-pub mb0" key={ntm.odsEntryId}>{ntm.fileExtension === "zip" ? "NTM w/ Associated Graphic Files" : "NTM Only"}   ({calcFileSize(ntm.fileSize)})</a>
                                    }) : null}
                                <hr />
                                <label htmlFor="selNTMSection" className="query-label">Sections</label><br/>
                                <select className="form-control" id="selNTMSection" defaultValue="null" onChange={this.openNtmSection.bind(this)}>
                                    <option value="null">Select Section...</option>
                                    {Object.keys(this.state.ntmPubs).map(sectionNum => { 
                                        const sectionObj = this.state.ntmPubs[sectionNum];
                                        return sectionObj[0].isFullPublication === true ? null: <option key={sectionObj[0].odsEntryId} value={sectionObj[0].odsKey}>{sectionObj[0].title}    ({calcFileSize(sectionObj[0].fileSize)})</option>
                                    })}
                                </select>
                            </div>
                        </SmModal>
                        <SmModal show={this.state.showArchives} handleClose={this.hideModal} title="Summary of Corrections - Publication Archive">
                            <div>
                                {this.buildSummCorrectionsButtons()}
                            </div>
                        </SmModal>
                        <br />
                    </div>
                    <br/>
                </div>
                <div id="ntm-query" className="container mb10 pb10 pub-download-bg">
                    <h5>SEARCH ONLINE DATABASE</h5>
                    <QueryPanel category="NTM" currentUnclassNotice={this.state.currentUnclassNotice} currentClassNotice={this.state.currentClassNotice} network={this.state.network} />
                </div>
                <div id="ntm-summary">
                    <div className="container">
                        <h5>Summary of Corrections</h5>
                        <div className="row col-sm-12">
                            <div className="col-sm-8" align="left">
                                Summary of Corrections Publications, separated into five regional Volumes, contain a summary of Notice to Mariners corrections issued from the date of
                                each chart or publication product up to the "Corrected through NTM" number of the volume.
                            </div>
                            <div className="col-sm-4">
                                <select className="form-control mb10" id="selSumCorrections" defaultValue="null" onChange={this.showArchives.bind(this)}>
                                    <option value="null" disabled>View Publications ...</option>
                                    {this.buildSummCorrections()}
                                    <option value="archives">View Archives</option>
                                </select>
                                <a className="btn btn-pub summ-corr-btn float-right" id="btnDownloadSummaryCorr" onClick={this.showSummaryCorr.bind(this, "download")}>Download</a>
                                <a className="btn btn-pub summ-corr-btn float-right" id="btnViewSummaryCorr" title="View" onClick={this.showSummaryCorr.bind(this, "view")}>View</a>
                            </div>
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
        );
    }
}

export default NTM;