import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Overview from './Overview';
// import ASAM from './ASAM';
import MIReport from './Oberserver';
import MODU from './MODU';
import VisitReport from './VisitReport';
import SHAR from './SHAR';

const Report = (props) => {
    // if (props.match.params.rpt === "ASAM-Report") { return <ASAM /> }
    if (props.match.params.rpt === "Observ-Report") { return <MIReport /> }
    if (props.match.params.rpt === "MODU-Report") { return <MODU /> }
    if (props.match.params.rpt === "Visit-Report") { return <VisitReport /> }
    if (props.match.params.rpt === "SHAR-Report") { return <SHAR /> }
}
class SubmitReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            disabled: false
        }
    }

    componentDidMount(){
        this.disableOnSecret();
    }

    componentDidUpdate(prevProps) {
        if (this.props.network !== prevProps.network) {
            this.disableOnSecret();
        }
    }

    viewReport() {
        var pagevalue = document.getElementById("reportSelect").value;
        window.location.href = "/submit-report" + pagevalue; 
    }

    disableOnSecret(){
        if(this.props.network === "secret"){
            this.setState({disabled: true});
        }
    }

    render() {
        return (
        <div className="SubmitReport">
            <header className="Submitreport-header">
                <h5>Submit Informational Report to NGA</h5>
            </header>
            <div className="container">
                <div className="row pb24">
                    <div className="col-sm-4 col-sm-offset-4">
                        <select id="reportSelect" className="form-control" onChange={this.viewReport} defaultValue="null"> 
                            <option value="null" disabled>Select...</option>                   
                            {/* <option value="/ASAM-Report" disabled={this.state.disabled}>Anti-Shipping Activity Message (ASAM) Report </option> */}
                            <option value="/Observ-Report">Observer Report </option>
                            <option value="/MODU-Report"disabled={this.state.disabled}>Mobile Offshore Drilling Units (MODU) Movement Report </option>
                            <option value="/Visit-Report">US Navy Port Visit Report </option>
                            <option value="/SHAR-Report">Ship Hostile Action Report (SHAR) Instructions </option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <Route path={`${this.props.match.url}/:rpt`} render={(props) => <Report match={props.match} />} />
                    <Route
                        exact
                        path={this.props.match.url}
                        render={() => <Overview />}
                    />
                </div>
            </div>
       </div>

    );
  }
}

export default SubmitReport;