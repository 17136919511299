import React, {Component} from 'react';
import PageContent from '../PageContent';

class Overview extends Component {
    render(){
        return(
            <div id="overview">
                <PageContent divid="Publications" />
           </div>
        );
    }
}

export default Overview;