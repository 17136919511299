/* Controller will do all of our fetches to the database and return the values where called */

async function fetchData(url){
    //get data from msi api
    //we used to just return json, but now we have to handle xml and csv data returns
    url = "/api/" + url;

    //fetch with GET method
    var response = await fetch(url, 
        { 
            method: 'GET'
        })
        .then(response => {
            if(response.ok) {
                return response.text();
            } else {
                return "{}";
            }
        })
        .then(text => {
            //try to parse the json, if fails... return it as text
            try {
                return JSON.parse(text);
            }
            catch(err) {
                return text;
            }
        })
        .catch(error => window.alert(error));
    return response;
}

async function postFormData(url, formData){
    //post data to the msi api
    url = "/api/" + url;

    //fetch with POST method for multipart form-data content type
    var response = await fetch(url, 
        {
            //Don't add a content type header, Spring will throw an exception.
            method: 'POST',
            body: formData
        }).then(response => { 
            if (response.ok) 
                { 
                    return response.json(); }
            else {
               return {'message': response.statusText};
            }
        }).catch(error => window.alert(error));
    return response;
}

async function postRawData(url, rawData, contentType){
    //post data to the msi api
    url = "/api/" + url;

    //fetch with POST for RAW content types
    var response = await fetch(url, 
        {
            //Don't add a content type header, Spring will throw an exception.
            method: 'POST',
            headers: new Headers({ 'content-type': contentType }),
            body: JSON.stringify(rawData)
        }).then(response => { 
            if (response.ok) 
                { 
                    return response.json(); }
            else {
               return {'message': response.statusText};
            }
        }).catch(error => window.alert(error));
    return response;
}

async function deleteRawData(url, rawData, contentType){
    //post data to the msi api
    url = "/api/" + url;

    //fetch with POST for RAW content types
    var response = await fetch(url, 
        {
            //Don't add a content type header, Spring will throw an exception.
            method: 'DELETE',
            headers: new Headers({ 'content-type': contentType }),
            body: JSON.stringify(rawData)
        }).then(response => { 
            if (response.ok) 
                { 
                    return response.json(); }
            else {
               return {'message': response.statusText};
            }
        }).catch(error => window.alert(error));
    return response;
}

export {
    fetchData,
    postFormData,
    postRawData,
    deleteRawData
};
