import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import '../css/App.css';
import Config from '../utils/Config';
import { contentUpdate, searchContentUpdate } from '../utils/ReduxActions';
import { SmModal } from '../utils/SmModal';
import store, { history } from '../utils/Store';
import SubmitReport from './Actions/SubmitReport';
import BroadcastWarnings from './BroadcastWarnings/BroadcastWarnings';
import MsiFooter from './Header-Footer/MsiFooter';
import MsiHeader from './Header-Footer/MsiHeader';
import Home from './Home/Home';
import BrowserSupport from './InfoPgs/BrowserSupport';
import CommercialUse from './InfoPgs/CommercialUse';
import ContactInfo from './InfoPgs/ContactInfo';
import DYKnow from './InfoPgs/DYKnow';
import FAQ from './InfoPgs/FAQ';
import LegalSec from './InfoPgs/LegalSec';
import MaritimePartners from './InfoPgs/MaritimePartners';
import NauticalCalc from './InfoPgs/NauticalCalc';
import WhatsNew from './InfoPgs/WhatsNew';
import MiscProducts from './MiscProducts/MiscProducts';
import MODU from './MODU/MODU';
import NavigationPanel from './Navigation/NavigationPanel';
import NotFound from './NotFound';
import AvailableCorr from './NoticeToMariners/AvailableCorr';
import NTM from './NoticeToMariners/NTM';
import Piracy from './Piracy/Piracy';
import ProdCat from './Products/ProdCat';
import Publications from './Publications/Publications';
import QueryBreakout from './QueryBreakout';
import QueryDirections from './QueryDirections';
import QueryResults from './QueryResults';
import Search from './Search';
import ManageSubscription from './Subscriptions/ManageSubscription';
import Subscribe from './Subscriptions/Subscribe';
import Login from './User/Login';
import ViewFile from './ViewFile';

const msiFetch = require('../controllers/MsiFetch');

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: props.loggedIn,
            userData: props.user,
            gxEnabled: props.gxEnabled,
            banner: props.banner,
            showAlert: true,
            classification: ""
        };
        this.changeLoginStatus = this.changeLoginStatus.bind(this);
        this.appConfig = new Config();
        this.checkStore();
    }

    async checkStore() {
        //check the data store to see if we already have the page content from rehydration
        //or if stored page content is over a week old and expired
        let storedData = store.getState().contentReducer.pageContent;
        let storedDateTime = new Date(Date.parse(store.getState().contentReducer.dateTime));

        var expDate = new Date();
        expDate.setDate(expDate.getDate() - 3);

        if (storedData.length < 1 || storedDateTime < expDate) {
            //do api to get data and store it inside the store
            let url = "pageContent";
            await msiFetch.fetchData(url)
                .then(data => { store.dispatch(contentUpdate(data, new Date())); })
                .catch(() => { return false; });

            //get all content for use later
            url = "allContent";
            await msiFetch.fetchData(url)
                .then(data => { store.dispatch(searchContentUpdate(data)); })
                .catch(() => { return false; })
        }
    }

    changeLoginStatus(bool) {
        this.setState({
            loggedIn: bool
        });
    }

    componentWillMount() {
        if (this.state.showAlert) {
            //do not render the alert if it is blank
            if (this.state.banner === "") {
                //do not open
                this.setState({ showAlert: false });
            }

            //do not render on /view or /queryresults pages (same as navbar)
            const noRenderList = ['/queryResults', '/queryBreakout', '/queryDirections', '/view/HTML', '/availableCorrections'];
            if (noRenderList.indexOf(window.location.pathname) > -1) {
                //do not open
                this.setState({ showAlert: false });
            }
        }
    }

    async componentDidMount() {
        await this.appConfig.setAppConfig();
        this.setState({ classification: this.appConfig.network });
    }

    renderHeader() {
        // Do not render the Header on the queryResults page
        // render login only if gx is enabled
        const noRenderList = ['/queryResults', '/queryBreakout', '/queryDirections', '/view/HTML', '/availableCorrections'];
        if (noRenderList.indexOf(window.location.pathname) < 0) {
            // if the value is NOT in the norenderlist...
            return <MsiHeader isLoggedIn={this.state.loggedIn} data={this.state.userData} gxEnabled={this.state.gxEnabled} network={this.state.classification} />
        }
    }

    renderNav() {
        // Do not render the nav panel on the queryResults page
        // render login only if gx is enabled
        const noRenderList = ['/queryResults', '/queryBreakout', '/queryDirections', '/view/HTML', '/availableCorrections'];
        if (noRenderList.indexOf(window.location.pathname) < 0) {
            // if the value is NOT in the norenderlist...
            return <NavigationPanel isLoggedIn={this.state.loggedIn} data={this.state.userData} gxEnabled={this.state.gxEnabled} network={this.state.classification} />
        }
    }

    renderFooter() {
        const noRenderList = ['/queryDirections'];
        if (noRenderList.indexOf(window.location.pathname) < 0) {
            // if the value is NOT in the norenderlist...
            return <MsiFooter network={this.state.classification} />
        }
    }

    hideModal() {
        //when called, change the state to hide the modal
        this.setState({ showAlert: false });
    }

    render() {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <div className="app">
                        {this.renderHeader()}
                        {this.renderNav()}
                        <Switch>
                            <Route path="/" exact render={() => <Home isLoggedIn={this.state.loggedIn} data={this.state.userData}
                                changeLoginStatus={this.changeLoginStatus} gxEnabled={this.state.gxEnabled} classification={this.state.classification} />} />
                            <Route path="/home" exact render={() => <Home isLoggedIn={this.state.loggedIn} data={this.state.userData}
                                changeLoginStatus={this.changeLoginStatus} gxEnabled={this.state.gxEnabled} classification={this.state.classification} />} />
                            <Route path="/NGAPortal" render={(props) => <Home isLoggedIn={this.state.loggedIn} data={this.state.userData}
                                changeLoginStatus={this.changeLoginStatus} gxEnabled={this.state.gxEnabled} classification={this.state.classification} />} />
                            <Route path="/NTM" render={(props) => <NTM {...props} loggedIn={this.state.loggedIn} network={this.state.classification} />} />
                            <Route path="/NavWarnings" exact render={(props) => <BroadcastWarnings {...props} network={this.state.classification} />} />
                            <Route path="/Piracy" exact render={(props) => <Piracy {...props} network={this.state.classification} />} />
                            <Route path="/MODU" exact render={(props) => <MODU {...props} network={this.state.classification} />} />
                            <Route path="/Publications" render={(props) => <Publications {...props} loggedIn={this.state.loggedIn} network={this.state.classification} />} />
                            <Route path="/MiscProducts" exact component={MiscProducts} />
                            <Route path="/Products" exact render={(props) => <ProdCat {...props} network={this.state.classification} />} />
                            <Route path="/Calc" exact component={NauticalCalc} />
                            <Route path="/faq" component={FAQ} />
                            <Route path="/did-you-know" component={DYKnow} />
                            <Route path="/maritime-partners" exact component={MaritimePartners} />
                            <Route path="/contact-info" exact component={ContactInfo} />
                            <Route path="/legal-sec" exact component={LegalSec} />
                            <Route path="/whats-new" exact component={WhatsNew} />
                            <Route path="/commercial-use" exact component={CommercialUse} />
                            <Route path="/submit-report" render={(props) => <SubmitReport {...props} network={this.state.classification} />} />
                            <Route path="/browser-support" exact component={BrowserSupport} />
                            <Route path="/queryResults" exact component={QueryResults} />
                            <Route path="/queryBreakout" exact component={QueryBreakout} />
                            <Route path="/queryDirections" exact component={QueryDirections} />
                            <Route path="/availableCorrections" exact component={AvailableCorr} />
                            <Route path="/subscribe" exact render={(props) => <Subscribe {...props} network={this.state.classification} />} />
                            <Route path="/unsubscribe" render={(props) => <ManageSubscription {...props} conf={this.appConfig} network={this.state.classification} />} />
                            <Route path="/manage-subscription" render={(props) => <ManageSubscription {...props} conf={this.appConfig} network={this.state.classification} />} />
                            <Route path="/home/manage-subscription" render={(props) => <ManageSubscription {...props} conf={this.appConfig} network={this.state.classification} />} />
                            <Route path="/search" exact component={Search} />
                            <Route path="/login" exact component={Login} />
                            <Route path="/view" render={(props) => <ViewFile {...props} />} />
                            <Route component={NotFound} />
                        </Switch>
                        <SmModal show={this.state.showAlert} handleClose={this.hideModal.bind(this)} title="Alert!">
                            {this.state.banner}
                        </SmModal>
                        {this.renderFooter()}
                    </div>
                </ConnectedRouter>
            </Provider>
        );
    }
}

export default App;
