import React, { Component } from 'react';
import { SmModal } from '../../utils/SmModal';
import PageContent from '../PageContent';
import Recaptcha from '../Actions/Recaptcha';
import Config from '../../utils/Config';

const msiFetch = require('../../controllers/MsiFetch');

class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            captchaDone: false,
            renderCaptcha: true,
            captchaToken: '',
            resp: {},
            network: props.network
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showNavselect = this.showNavselect.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.network !== this.props.network) {
            this.setState({ network: this.props.network });
        }
    }

    showModal() {
        // when called, change state to show the modal
        this.setState({ showModal: true });

    }

    hideModal() {
        // when called, change the state to hide the modal
        this.setState({ showModal: false });
    }

    async componentDidMount() {
        const querySearch = window.location.search;
        // ?type=ntmUpdates
        const type = querySearch.substring(querySearch.indexOf("=") + 1);

        switch (type) {
            case "ntmUpdates":
                document.getElementById("ntmCheck").checked = true;
                break;
            case "navWarnings":
                document.getElementById("navCheck").checked = true;
                this.showNavselect();
                break;
            case "pubUpdates":
                document.getElementById("pubCheck").checked = true;
                break;
            default:
                break;
        }

        var conf = new Config();
        await conf.setAppConfig();
        if (conf.network !== "unclassified") {
            this.setState({ captchaDone: true, renderCaptcha: false })
        };
    }

    verifyCaptcha(response) {
        this.setState({ captchaDone: true, captchaToken: response });
    }

    expiredCaptcha() {
        this.setState({ captchaDone: false, captchaToken: '' });
    }

    showNavselect() {
        if (document.getElementById("navCheck").checked) {
            document.getElementById("BWselect").className = "";
        } else {
            document.getElementById("BWselect").className = "no-display";
        }
    }

    getSelectValues(select) {
        let result = [];
        const options = select && select.options;
        let opt;

        for (let i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    }

    async sendTransaction() {
        const navChecked = (document.getElementById("navCheck") && document.getElementById("navCheck").checked ? true : false);
        let warnValues = [];
        if (navChecked) {
            warnValues = this.getSelectValues(document.getElementById("BWtype"));
        }
        const formData = {
            "email": document.getElementById("email").value,
            "subscrNavWarn": navChecked,
            "subscrPubs": (document.getElementById("pubCheck") && document.getElementById("pubCheck").checked ? true : false),
            "subscrNTM": document.getElementById("ntmCheck").checked,
            "navarea4": (warnValues.indexOf("4") >= 0 ? true : false),
            "hydrolant": (warnValues.indexOf("lant") >= 0 ? true : false),
            "navarea12": (warnValues.indexOf("12") >= 0 ? true : false),
            "hydropac": (warnValues.indexOf("pac") >= 0 ? true : false),
            "hydroarc": (warnValues.indexOf("arc") >= 0 ? true : false),
            "isConfirmed": false,
            "isBlacklisted": false,
            "recaptchaToken": this.state.captchaToken
        }
        const url = "email-subscribers";

        await msiFetch.postRawData(url, formData, 'application/json')
            .then(resp => { this.setState({ resp }) });
        this.showModal();
    }

    async validateFields() {
        let alertMessage = "";
        const email = document.getElementById("email").value;
        const confirmemail = document.getElementById("confirmemail").value;

        if (email === null || email === "") {
            alertMessage += "Email Address is required\n";
            document.getElementById("lblEmail").className = "form-alert";
        } else {
            document.getElementById("lblEmail").className = "";
        }
        if (confirmemail === null || confirmemail === "") {
            alertMessage += "Confirm Email Address is required \n";
            document.getElementById("lblConfirm").className = "form-alert";
        } else {
            document.getElementById("lblConfirm").className = "";
        }

        if (email !== confirmemail) {
            alertMessage += "Email doesn't match \n";
            document.getElementById("lblConfirm").className = "form-alert";
            document.getElementById("lblEmail").className = "form-alert";
        }

        // validate email address characters
        var letters = /[^a-zA-Z0-9@._%+-]/g;
        if (email != null && email.match(letters)) {
            alertMessage += "Invalid email address \n";
            document.getElementById("lblEmail").className = "form-alert";
        }

        if (document.getElementById("navCheck") && document.getElementById("navCheck").checked) {
            if (document.getElementById("BWtype").value === "null") {
                alertMessage += "Select Type for BW is required \n";
                document.getElementById("lblBWtype").className = "form-alert";
            } else {
                document.getElementById("lblBWtype").className = "";
            }
        }

        const navCheck = (document.getElementById("navCheck") && document.getElementById("navCheck").checked);
        const pubCheck = (document.getElementById("pubCheck") && document.getElementById("pubCheck").checked);

        if (!document.getElementById("ntmCheck").checked && !navCheck && !pubCheck) {
            alertMessage += "At least one subscription type must be selected \n";
            document.getElementById("lblDetails").className = "form-alert";
        } else {
            document.getElementById("lblDetails").className = "";
        }

        if (!this.state.captchaDone) {
            alertMessage += "Please complete the Captcha Verification.";
        }

        // if we have a message to show the user, show it
        if (alertMessage !== "") {
            window.alert(alertMessage);
        }
        else {
            // if everything is valid, move on to store data in the database
            // send some kind of confirmation that this is been submitted
            // this.sendTransaction();
            await this.sendTransaction();
            this.clearForm();
        }
    }

    clearForm() {
        // all the elements that need to be set to empty
        let elementList = ["email", "confirmemail"];
        document.getElementById("ntmCheck").checked = false;

        if (document.getElementById("navCheck")) {
            document.getElementById("navCheck").checked = false;
            document.getElementById("BWtype").value = "null";
            document.getElementById("BWselect").className = "no-display";
        }

        if (document.getElementById("pubCheck")) {
            document.getElementById("pubCheck").checked = false;
        }



        // empty out that elementlist
        for (var i = 0; i < elementList.length; i++) {
            document.getElementById(elementList[i]).value = "";
        }

        elementList = ["lblEmail", "lblConfirm", "lblBWtype", "lblDetails"];

        // change font color back to default
        for (var j = 0; j < elementList.length; j++) {
            document.getElementById(elementList[j]).className = "";
        }
    }
    render() {
        return (
            <div className="container left-align" id="subscriptions">
                <PageContent divid="subscribe" />
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                    <table className="table left-align">
                        <thead>

                        </thead>
                        <tbody>
                            <tr>
                                <td className="no-borders"><span id="lblEmail">Email Address* &nbsp;</span>
                                    <input type="text" id="email" className="form-control" /></td>
                            </tr>
                            <tr>
                                <td className="no-borders"><span id="lblConfirm">Confirm Email Address*</span><br />
                                    <input type="text" id="confirmemail" className="form-control" /> </td>
                            </tr>
                            <tr id="BWselect" className="no-display">
                                <td className="no-borders"><span id="lblBWtype"> Select Type for BW* <br /></span>
                                    <span><i>To select multiple options hold down the control (ctrl) button (Windows) or command button (Mac). </i><br /></span>
                                    <select multiple className="form-control" id="BWtype">
                                        <option value="4">NAVAREA IV </option>
                                        <option value="lant">HYDROLANT</option>
                                        <option value="12">NAVAREA XII </option>
                                        <option value="pac">HYDROPAC</option>
                                        <option value="arc">HYDROARC</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="no-borders"><span id="lblDetails">Choose subscription(s): </span><br />
                                    <input type="checkbox" name="ntm" id="ntmCheck" value="NTM" />Notice to Mariners<br />
                                    {this.state.network.substring(0, 7) === "unclass" ? [
                                        <input type="checkbox" name="bw" id="navCheck" value="BW" key="BW" onClick={this.showNavselect} />,
                                        <span key="warnlabel">Navigational Warnings</span>,
                                        <br key="br1" />,
                                        <input type="checkbox" name="pubs" id="pubCheck" value="Pubs" key="Pub" />,
                                        <span key="publabel">Publication Updates</span>,
                                        <br key="br2" />
                                    ] : null}
                                </td>
                            </tr>
                            <tr>
                                <td className="no-borders">
                                    {this.state.renderCaptcha ? <Recaptcha id="recaptcha"
                                        verifyCallback={this.verifyCaptcha.bind(this)} expiredCallback={this.expiredCaptcha.bind(this)}
                                    /> : null}
                                </td>
                            </tr>
                            <tr>
                                <td className="center-align no-borders"><input type="button" value="Submit" onClick={this.validateFields.bind(this)} />
                                    <input type="reset" value="Clear form" onClick={this.clearForm.bind(this)} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <SmModal show={this.state.showModal} handleClose={this.hideModal} title="Submit Confirmation">
                        {this.state.resp["message"]}
                    </SmModal>
                </div>
            </div>
        );
    }
}

export default Subscribe;