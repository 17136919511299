import React, { Component } from 'react';
import PageContent from '../PageContent';

class ContactInfo extends Component {
    render() {
        return (
            <div id="contactInfo">
                <PageContent divid="contactInfo" />
            </div>   

        );
    }
}

export default ContactInfo;
