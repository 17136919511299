import React, { Component } from 'react';
import PageContent from '../PageContent';

class LegalSec extends Component {
	render() {
		return (
			<div id="legalsec">
				<PageContent divid="legalsec" />
			</div>
		);
    }
}

export default LegalSec;