import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

function createMarkup(text) {
    return {__html: text };
}
 

class PageContent extends Component {
    constructor(props){
        super(props)
        this.state = {
            divid: "",
            location: window.location.pathname,   
            pagecontent: []     
        }
    }
    
    componentDidMount() {   
        const pgcontent = this.props.rootReducer.contentReducer.pageContent;
        const loc = window.location.pathname;
        
        this.setState({
            divid: this.props.divid, 
            location: loc,
            pagecontent: pgcontent
        });
    }

    componentDidUpdate(prevProps) {
        const pgcontent = this.props.rootReducer.contentReducer.pageContent;
        if (pgcontent !== prevProps.rootReducer.contentReducer.pageContent) {
            this.setState({ pagecontent: pgcontent });
        }

        if(this.props.divid !== prevProps.divid){
            this.setState({divid: this.props.divid});
        }
    }
    
    render() {
        const pagecontent = this.state.pagecontent;        
        const contentblock =  pagecontent.find(obj => obj.divId === this.state.divid && obj.relativeUrlPath === this.state.location);
        const noRenderHdr = ["ntm-descrip", 'product-descrip', "cntm-descrip"];
        if (contentblock){
            return(
                 <div id="content">
                    {(noRenderHdr.indexOf(this.state.divid) > -1 ? "" : <h5>{contentblock.pageTitle}</h5>)}
                    <div dangerouslySetInnerHTML={createMarkup(contentblock.pageBody)} />           
                </div>
            )
        }
      
        return (
            <div>                
                {contentblock}          
            </div>
        );
    }
}

const mapStateToProps = (rootReducer) => ({
    rootReducer
});

//export default PageContent;
export default withRouter(connect(mapStateToProps)(PageContent));