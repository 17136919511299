/*
  Author: Jacob Helton
  This component will be the query panel linked to the SMAPS online database for data post May, 2022.
*/

import React, { Component } from "react";
const msiFetch = require("../../controllers/MsiFetch");
const lightUtils = require("../Publications/LightsUtils");
// const search = require("./SearchBy");
const formatDate = require("../../utils/FormatDate");
const LatLongConv = require("../../utils/LatLongConv");

class SmapsQueryPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navAreas: {
        "NAVAREA IV": "4",
        "NAVAREA XII": "12",
        HYDROLANT: "A",
        HYDROPAC: "P",
        HYDROARC: "C",
      },
      subregions: [],
      areaOptions: [],
      dncRegions: [],
     // categories: [],
      label2: "",
    };
    this.validateSmapsFields = this.validateSmapsFields.bind(this);
    this.showNavAreas = this.showNavAreas.bind(this);
    this.updateAreas = this.updateAreas.bind(this);
  }

  componentDidMount() {
    const url = "publications/broadcast-warn/subregions";
    msiFetch
      .fetchData(url)
      .then((data) => {
        this.setState({
          subregions: data,
          areaOptions: this.showNavAreas(),
          dncRegions: this.chartCorrDNC(),
       //   categories: this.setCategories(),
          label2: "Message Type",
        });
      })
      .catch((error) => {
        this.setState({ subregions: [], areaOptions: this.showNavAreas() });
        console.log("There was an error fetching subregions: ", error);
      });
  }

  showNavAreas() {
    var navTypes = Object.keys(this.state.navAreas);
    //iterate through each key to make an option value
    return navTypes.map((area) => (
      <option value={this.state.navAreas[area]} key={area}>
        {area}
      </option>
    ));
  }

  updateAreas() {
    // getting the area type value that the user is searching by
    var searchType = document.getElementById("smaps-sel1").value;
    var geoLocLbl = document.getElementById("lblGeoLocater");
    var geoLocLblDnc = document.getElementById("lblGeoLocaterDnc");


    // replacing form sections dependant on the first selection
    switch (searchType) {
      case "messageType":
        geoLocLbl.className = "query-label no-display";
        geoLocLblDnc.className= "query-label no-display";
        document
          .getElementById("smaps-selector-col2")
          .classList.remove("no-display");
        document.getElementById("latLongDiv").classList.add("no-display");
        document.getElementById("latLong").classList.add("no-display");
        this.setState({
          areaOptions: this.showNavAreas(),
          label2: "Message Type",
        });
        break;

      case "dncRegion":
        geoLocLbl.className = "query-label no-display";
        geoLocLblDnc.className= "query-label";
        document
          .getElementById("smaps-selector-col2")
          .classList.remove("no-display");
        document.getElementById("latLongDiv").classList.add("no-display");
        document.getElementById("latLong").classList.add("no-display");
        var options = this.state.dncRegions.map((region) => (
          <option value={region.value} key={region.value}>
            {region.text}
          </option>
        ));
        this.setState({ areaOptions: options, label2: "DNC Region" });
        break;

      case "subregion":
        geoLocLbl.className = "query-label";
        geoLocLblDnc.className= "query-label no-display";
        document
          .getElementById("smaps-selector-col2")
          .classList.remove("no-display");
        document.getElementById("latLongDiv").classList.add("no-display");
        document.getElementById("latLong").classList.add("no-display");
        var options = this.state.subregions.map((region) => (
          <option value={region} key={region}>
            Subregion {region}
          </option>
        ));
        this.setState({ areaOptions: options, label2: "Subregion" });
        break;

      case "geoArea":
        geoLocLbl.className = "query-label no-display";
        geoLocLblDnc.className= "query-label no-display";
        document.getElementById("latLongDiv").classList.remove("no-display");
        document.getElementById("latLong").classList.remove("no-display");
        document
          .getElementById("smaps-selector-col2")
          .classList.add("no-display");
        this.setState({ label2: "" });
        break;

      default:
        break;
    }
  }

  chartCorrDNC() {
    return [
      { value: "201", text: "DNC 1 - South Atlantic Ocean" },
      { value: "202", text: "DNC 2 - South Atlantic Ocean" },
      { value: "203", text: "DNC 3 - Indian Ocean" },
      { value: "204", text: "DNC 4 - Western Australia" },
      { value: "205", text: "DNC 5 - Eastern Australia" },
      { value: "206", text: "DNC 6 - South Pacific Ocean" },
      { value: "207", text: "DNC 7 - South America" },
      { value: "208", text: "DNC 8 - Southern Europe" },
      { value: "209", text: "DNC 9 - Central Mediterranean" },
      { value: "210", text: "DNC 10 - Middle East" },
      { value: "211", text: "DNC 11 - South China Sea" },
      { value: "212", text: "DNC 12 - Japan/North Pacific" },
      { value: "213", text: "DNC 13 - North America West" },
      { value: "214", text: "DNC 14 - Central America/Caribbean Sea" },
      { value: "215", text: "DNC 15 - Gulf of Mexico/Straits of Florida" },
      { value: "216", text: "DNC 16 - Bahamas/Bermuda" },
      { value: "217", text: "DNC 17 - Eastern United States" },
      { value: "218", text: "DNC 18 - Northeastern United States/Canada" },
      { value: "219", text: "DNC 19 - Greenland/Iceland/United Kingdom" },
      { value: "220", text: "DNC 20 - United Kingdom" },
      { value: "221", text: "DNC 21 - Norway" },
      { value: "222", text: "DNC 22 - Barents Sea" },
      { value: "223", text: "DNC 23 - Northern Asia" },
      { value: "224", text: "DNC 24 - Sea of Okhotsk" },
      { value: "225", text: "DNC 25 - Gulf of Alaska" },
      { value: "226", text: "DNC 26 - Bristish Columbia" },
      { value: "227", text: "DNC 27 - Arctic Ocean" },
      { value: "228", text: "DNC 28 - Canada" },
      { value: "229", text: "DNC 29 - Antarctica" },
    ];
  }

  setCategories() {
    const categories = [
      { value: "14", text: "All Categories" }, //added this into the front to have all categories
      { value: "1", text: "Aids to Navigation" },
      { value: "2", text: "Drifting Hazard" },
      { value: "3", text: "Fixed Hazard" },
      { value: "4", text: "General" },
      { value: "5", text: "Ice" },
      { value: "6", text: "In-Force Bulletins" },
      { value: "7", text: "MODU" },
      { value: "8", text: "Piracy" },
      { value: "9", text: "Radio Services" },
      { value: "10", text: "SAR" },
      { value: "11", text: "Special Operations" },
      { value: "12", text: "Tsunami" },
      { value: "13", text: "Working Vessels" }
    ];

    var options = categories.map((category) => (
      <option value={category.value} key={category.value}>
        {category.text}
      </option>
    ));

    return options;
  }

  latLongSearch() {
    return (
      <div id="latLongDiv" className="no-display">
        <label htmlFor="latLong" className="query-label" id="label6">
          Enter Corner Coordinates of Search Area
        </label>
        {lightUtils.latLong()}
      </div>
    );
  }

  updateSearchFilter() {
    // variables are for input & select boxes"
    var filter = document.getElementById("smaps-sel4").value;
    var label1 = document.getElementById("smaps-label5");
    var msgNumberStartInput = document.getElementById("smaps-msgNumberStart");
    var msgNumberEndInput = document.getElementById("smaps-msgNumberEnd");
    var yearNumberStartInput = document.getElementById("smaps-yearNumberStart");
    var yearNumberEndInput = document.getElementById("smaps-yearNumberEnd");
    var issueDateStartInput = document.getElementById("smaps-issueDateStart");
    var issueDateEndInput = document.getElementById("smaps-issueDateEnd");
    var filterDiv = document.getElementById("smaps-bw-filters");
    var slash1 = document.getElementById("smaps-slash1");
    var slash2 = document.getElementById("smaps-slash2");
    var toText = document.getElementById("smaps-toText");

    // display classNames that we will be setting
    var display = "form-control";
    var nodisplay = "form-control no-display";
    var displayHalf = "form-control inline fourty-width";
    var displayTenth = "form-control inline tenth-width";
    var displayFilters = "col-sm-4";

    // depending on what user is filtering by, show or do not show certain inputs
    switch (filter) {
      case "allInforceNums":
      case "allActive":
        label1.innerHTML = "";
        msgNumberStartInput.className = nodisplay;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = nodisplay;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = "col-sm-4 no-display";
        slash1.className = nodisplay;
        slash2.className = nodisplay;
        toText.className = nodisplay;
        break;
      case "activeByDate":
        label1.innerHTML = "By Date Range (MM/DD/YYYY)";
        msgNumberStartInput.className = nodisplay;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = nodisplay;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = displayHalf;
        issueDateEndInput.className = displayHalf;
        filterDiv.className = displayFilters;
        slash1.className = nodisplay;
        slash2.className = nodisplay;
        toText.className = "";
        break;
      case "byNumber":
        label1.innerHTML = "By Specific Number (nnnn/YYYY)";
        msgNumberStartInput.className = displayHalf;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = displayHalf;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = displayFilters;
        slash1.className = "";
        slash2.className = nodisplay;
        toText.className = nodisplay;
        break;
      case "byNumberRange":
        label1.innerHTML = "By Number Range (nnnn/YYYY)";
        msgNumberStartInput.className = displayTenth;
        msgNumberEndInput.className = displayTenth;
        yearNumberStartInput.className = displayTenth;
        yearNumberEndInput.className = displayTenth;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = "col-sm-5";
        slash1.className = "";
        slash2.className = "";
        toText.className = "";
        break;
      case "allByYear":
        label1.innerHTML = "(YYYY)";
        msgNumberStartInput.className = nodisplay;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = display;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = displayFilters;
        slash1.className = nodisplay;
        slash2.className = nodisplay;
        toText.className = nodisplay;
        break;
      case "cancelledByYear":
        label1.innerHTML = "(YYYY)";
        msgNumberStartInput.className = nodisplay;
        msgNumberEndInput.className = nodisplay;
        yearNumberStartInput.className = display;
        yearNumberEndInput.className = nodisplay;
        issueDateStartInput.className = nodisplay;
        issueDateEndInput.className = nodisplay;
        filterDiv.className = displayFilters;
        slash1.className = nodisplay;
        slash2.className = nodisplay;
        toText.className = nodisplay;
        break;
      default:
        break;
    }
  }

  validateSmapsFields() {
    // checking to make sure the user filled out the form with valid entries
    var areaFilter = document.getElementById("smaps-sel1").value;
    var filterType = document.getElementById("smaps-sel4").value;
    var msgNumberStart = document.getElementById("smaps-msgNumberStart").value;
    var msgNumberEnd = document.getElementById("smaps-msgNumberEnd").value;
    var msgYearStart = document.getElementById("smaps-yearNumberStart").value;
    var msgYearEnd = document.getElementById("smaps-yearNumberEnd").value;
    var issueDateStart = document.getElementById("smaps-issueDateStart").value;
    var issueDateEnd = document.getElementById("smaps-issueDateEnd").value;

    var alertMessage = "";

    if (areaFilter === "geoArea") {
      alertMessage += lightUtils.validateFields();
    }

    switch (filterType) {
      // if user selected activeByDate
      case "activeByDate":
        if (issueDateStart === "" || issueDateEnd === "") {
          alertMessage += "You must fill in all fields for Issue Date. ";
        }
        break;

      // if user selected byNumber
      case "byNumber":
        if (msgNumberStart === "" || msgYearStart === "") {
          alertMessage += "You must fill in all fields for Warning Number. ";
        } else {
          if (msgNumberStart < 0) {
            alertMessage +=
              "Invalid Entry: Warning Number must be greater than 0. ";
          }
          if (msgYearStart.length !== 4) {
            alertMessage += "Invalid Entry: Warning Year must be four digits. ";
          }
        }
        break;

      // if user selected byNumberRange
      case "byNumberRange":
        if (msgNumberStart === "" || msgYearStart === "") {
          alertMessage +=
            "You must fill in all fields for Starting Warning Number. ";
        } else if (msgNumberEnd === "" || msgYearEnd === "") {
          alertMessage +=
            "You must fill in all fields for Ending Warning Number. ";
        } else {
          if (msgNumberStart < 0 || msgNumberEnd < 0) {
            alertMessage +=
              "Invalid Entry: Warning Number must be greater than 0. ";
          }
          if (msgYearStart.length !== 4 || msgYearEnd.length !== 4) {
            alertMessage += "Invalid Entry: Warning Year must be four digits. ";
          }
        }
        break;

      // if user selected allByYear
      case "allByYear":
        if (msgYearStart === "") {
          alertMessage += "You must fill in all fields for Warning Year. ";
        } else if (msgYearStart.length !== 4) {
          alertMessage += "Invalid Entry: Warning Year must be four digits. ";
        }
        break;

      //if user selected cancelledByYear
      case "cancelledByYear":
        if (msgYearStart === "") {
          alertMessage += "You must fill in all fields for Warning Year. ";
        } else if (msgYearStart.length !== 4) {
          alertMessage += "Invalid Entry: Warning Year must be four digits. ";
        }
        break;

      default:
        break;
    }

    // if we have a message to show the user, show it
    if (alertMessage !== "") {
      window.alert(alertMessage);
    } else {
      this.getSmapsWarn();
    }
  }

  getSmapsWarn() {
    this.url = "publications/smaps";

    // get area filter
    var areaFilter = document.getElementById("smaps-sel1").value;
    var areaSelect = document.getElementById("smaps-sel2");
    var area = areaSelect.options[areaSelect.selectedIndex].value;

    // get filtering options
    var filterType = document.getElementById("smaps-sel4").value;
    var msgNumberStartInput = document.getElementById(
      "smaps-msgNumberStart"
    ).value;
    var msgNumberEndInput = document.getElementById("smaps-msgNumberEnd").value;
    var yearNumberStartInput = document.getElementById(
      "smaps-yearNumberStart"
    ).value;
    var yearNumberEndInput = document.getElementById(
      "smaps-yearNumberEnd"
    ).value;
    var issueDateStartInput = document.getElementById(
      "smaps-issueDateStart"
    ).value;
    var issueDateEndInput = document.getElementById("smaps-issueDateEnd").value;
    var output = document.getElementById("smaps-sel5").value;

    // get category
    //var category = document.getElementById("smaps-sel3").value;
    var category = 14; //until we fix the new category additions in smapsApi well utilize ALL CATEGORIES

    if (filterType === "allInforceNums") {
      this.url += `/inforce`;
      area = `All`;
    }

    switch (areaFilter) {
      case "messageType":
        this.url += `?navArea=${area}`;
        break;

      case "subregion":
        this.url += `?subRegion=${area}`;
        break;

      case "dncRegion":
        this.url += `?dncRegion=${area}`;
        break;

      case "geoArea":
        if (filterType === "allInforceNums") {
          this.url += `?geoArea=${area}`;
        } else {
          let leftLatDegree, leftLatMin, leftLatDir;
          let leftLonDegree, leftLonMin, leftLonDir;
          let rightLatDegree, rightLatMin, rightLatDir;
          let rightLonDegree, rightLonMin, rightLonDir;
          let leftCoords, rightCoords;

          leftLatDegree = parseFloat(
            document.getElementById("leftLatDegree").value
          );
          leftLatMin = parseFloat(
            document.getElementById("leftLatMinute").value
          );
          leftLatDir = document.getElementById("leftLatDirection").value;
          leftLonDegree = parseFloat(
            document.getElementById("leftLonDegree").value
          );
          leftLonMin = parseFloat(
            document.getElementById("leftLonMinute").value
          );
          leftLonDir = document.getElementById("leftLonDirection").value;
          rightLatDegree = parseFloat(
            document.getElementById("rightLatDegree").value
          );
          rightLatMin = parseFloat(
            document.getElementById("rightLatMinute").value
          );
          rightLatDir = document.getElementById("rightLatDirection").value;
          rightLonDegree = parseFloat(
            document.getElementById("rightLonDegree").value
          );
          rightLonMin = parseFloat(
            document.getElementById("rightLonMinute").value
          );
          rightLonDir = document.getElementById("rightLonDirection").value;

          leftCoords = LatLongConv.convertToDec(
            leftLatDegree,
            leftLatMin,
            0,
            leftLatDir,
            leftLonDegree,
            leftLonMin,
            0,
            leftLonDir
          );
          rightCoords = LatLongConv.convertToDec(
            rightLatDegree,
            rightLatMin,
            0,
            rightLatDir,
            rightLonDegree,
            rightLonMin,
            0,
            rightLonDir
          );
          this.url += `?latitudeLeft=${leftCoords["latitude"]}&longitudeLeft=${leftCoords["longitude"]}&latitudeRight=${rightCoords["latitude"]}&longitudeRight=${rightCoords["longitude"]}`;
          this.url += `&category=${category}`;
        }
        break;
    }

    switch (filterType) {
      case "allActive":
        this.url += "&status=active";
        this.url += `&category=${category}`;
        break;

      case "activeByDate":
        var issueDateStart = formatDate.convertDate(issueDateStartInput);
        var issueDateEnd = formatDate.convertDate(issueDateEndInput);
        this.url += `&issueDateStart=${issueDateStart}&issueDateEnd=${issueDateEnd}`;
        this.url += `&category=${category}`;
        break;

      case "byNumber":
        this.url += `&msgSqncNumber=${msgNumberStartInput}&createdOn=${yearNumberStartInput}`;
        this.url += `&category=${category}`;
        break;

      case "byNumberRange":
        this.url += `&msgSqncStart=${msgNumberStartInput}&startDate=${yearNumberStartInput}`;
        this.url += `&msgSqncEnd=${msgNumberEndInput}&endDate=${yearNumberEndInput}`;
        this.url += `&category=${category}`;
        break;

      case "allByYear":
        this.url += `&status=all&createdOn=${yearNumberStartInput}`;
        this.url += `&category=${category}`;
        break;

      case "cancelledByYear":
        this.url += `&status=cancelled&cancelledOn=${yearNumberStartInput}`;
        this.url += `&category=${category}`;
        break;
    }

    this.url += `&output=${output}`;

    console.log("this.url: ", this.url);
    window.open("/queryResults?" + this.url, "_blank");
  }

  // Console logging for testing
  openSmapsQueryDirections() {
    window.open(
      "/queryDirections?queryType=smaps",
      "_blank",
      "toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600"
    );
  }

  render() {
    return (
      <div className="row left-align container pb32">
        <div className="col-sm-12 query-dir">
          <a onClick={this.openSmapsQueryDirections.bind(this)}>
            Query Directions
          </a>
        </div>
        <div className="col-sm-2" id="smaps-selector-col1">
          <label htmlFor="smaps-sel1" className="query-label">
            Area Type
          </label>
          <select
            id="smaps-sel1"
            className="form-control"
            defaultValue="messageType"
            onChange={this.updateAreas}
          >
            <option value="messageType">Message Type</option>
            <option value="subregion">Subregion</option>
            <option value="dncRegion">DNC Regions</option>
        {/*    <option value="geoArea">within Geographic Area</option> */}
          </select>
          <label
            id="lblGeoLocater"
            htmlFor="smaps-sel1"
            className="query-label no-display"
          >
            <a
              target="_blank"
              href="/api/publications/download?key=16920956/SFH00000/geographic_locator.jpe&type=view"
            >
              (View Geographic Locator)
            </a>
          </label>
          <label
            id="lblGeoLocaterDnc"
            htmlFor="smaps-sel1"
            className="query-label no-display"
          >
            <a
              target="_blank"
              href="/api/publications/download?key=16920956/SFH00000/world_dnc.jpg&type=view"
            >
              (View Geographic Locator)
            </a>
          </label>
        </div>
        <div className="col-sm-2" id="smaps-selector-col2">
          <label htmlFor="smaps-sel2" className="query-label">
            {this.state.label2}
          </label>
          <select id="smaps-sel2" className="form-control">
            {this.state.areaOptions}
          </select>
        </div>
        {this.latLongSearch()}
        {/* <div className="col-sm-2" id="smaps-selector-col3">
          <label htmlFor="smaps-sel3" className="query-label">
            Categories
          </label>
          <select id="smaps-sel3" className="form-control">
            {this.state.categories}
          </select>
        </div> */}
        <div className="col-sm-3" id="smaps-selector-col4">
          <label htmlFor="smaps-sel4" className="query-label">
            Search
          </label>
          <select
            className="form-control"
            id="smaps-sel4"
            onChange={this.updateSearchFilter}
          >
            <option value="allActive">All In-Force Warnings</option>
          {/*  <option value="allInforceNums">All In-Force Warning Numbers</option> */}
            <option value="activeByDate">
              In-Force Warnings By Date Range
            </option>
            <option value="byNumber">By Warning Number</option>
            <option value="byNumberRange">By Warning Number Range</option>
            <option value="allByYear">All Warnings By Specific Year</option>
            <option value="cancelledByYear">
              Canceled Warnings By Specific Year
            </option>
          </select>
        </div>
        <div className="col-sm-5 no-display" id="smaps-bw-filters">
          <label
            id="smaps-label5"
            htmlFor="smaps-msgNumberStart"
            className="query-label"
          ></label>
          <br />
          <input
            maxLength={4}
            type="number"
            step="10"
            className="form-control no-display"
            id="smaps-msgNumberStart"
          />
          <strong id="smaps-slash1" className="no-display">
            &nbsp; / &nbsp;{" "}
          </strong>
          <input
            maxLength={4}
            type="number"
            className="form-control no-display"
            id="smaps-yearNumberStart"
          />
          <input
            type="date"
            className="form-control no-display"
            id="smaps-issueDateStart"
          />
          <strong id="smaps-toText" className="no-display">
            &nbsp; to &nbsp;{" "}
          </strong>
          <input
            maxLength={4}
            type="number"
            className="form-control no-display"
            id="smaps-msgNumberEnd"
          />
          <strong id="smaps-slash2" className="no-display">
            &nbsp; / &nbsp;{" "}
          </strong>
          <input
            maxLength={4}
            type="number"
            className="form-control no-display"
            id="smaps-yearNumberEnd"
          />
          <input
            type="date"
            className="form-control no-display"
            id="smaps-issueDateEnd"
          />
        </div>
        <div className="col-sm-3" id="smaps-selector-col5">
          <label htmlFor="sel5" className="query-label">
            Output Format
          </label>
          <br />
          <select
            className="form-control fourty-width float-left"
            id="smaps-sel5"
          >
            <option value="html">HTML</option>
            <option value="xml">XML</option>
            <option value="csv">CSV (Delimited text)</option>
          </select>
          <a
            className="btn vol-btn quarter-width float-right"
            title="Search"
            id="smaps-btnApplyQuery"
            onClick={this.validateSmapsFields}
          >
            Search
          </a>
        </div>
      </div>
    );
  }
}

export default SmapsQueryPanel;
