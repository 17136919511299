import React from 'react';
import { convertDMS } from '../../utils/LatLongConv';
const deconvert = require('../../utils/FormatDate').deconvertDate;

function getTableHeaders(queryUrl){
    return (
        <thead className="center-align">
            <tr>
                <td colSpan={42} className="bg-gray-lighter query-title">Anti Shipping Activity Messages<br />Query Results</td>
            </tr>
            <tr align="left">
                    <td className="case-capitalize" colSpan={42} id="searchTerms"><b>Query Search Terms: </b><br/>{getQueryHeader(queryUrl)}</td>
                </tr>
        </thead>
    );
}

function getQueryHeader(queryUrl){
    var regexUrl = queryUrl.replace(/.*?\?(.*?)&output=html/i, '$1');
    regexUrl = regexUrl.replace("output=html", "");
    regexUrl = regexUrl.replace(/.*?\?/, '');
    regexUrl = regexUrl.replace("reference=", "Reference Number: ");
    regexUrl = regexUrl.replace("startReference=", "Reference Numbers Between: ");
    regexUrl = regexUrl.replace("&endReference=", "- ");
    regexUrl = regexUrl.replace("navArea=", "Nav Area: ");
    regexUrl = regexUrl.replace("subreg=", "Subregion: ");
    regexUrl = regexUrl.replace("victim=", "Victim Matching: ");
    regexUrl = regexUrl.replace("occurDate=", "Date: ");
    regexUrl = regexUrl.replace("minOccurDate=", "Dates Between: ");
    regexUrl = regexUrl.replace("&maxOccurDate=", " - ");
    regexUrl = regexUrl.replace("year=", "Occurence Year: ");
    regexUrl = regexUrl.replace("filter=none", "");
    regexUrl = regexUrl.replace("sort=", "Sort: ");


    regexUrl = regexUrl.replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n").replace("&", "\n");
    regexUrl = regexUrl.replace(/%20/ig, ' ');
    return regexUrl;
}

function formatData(data){
    if ((data['asam']).length === 0) {
        return data['asam'];
    }
    else {
        return data["asam"].map(message => {
            const position = convertDMS(message.latitude, message.longitude);
            return (
                <tbody>
                    <tr>
                        <td width={20+"%"}><b>Reference Number:</b></td>
                        <td>{message.reference}</td>
                        <td><b>Geographical Subregion:</b></td>
                        <td>{message.subreg}</td>
                    </tr>
                    <tr>
                        <td className="no-border"><b>Date of Occurence:</b></td>
                        <td className="no-border">{deconvert(message.date)}</td>
                        <td className="no-border"><b>Aggressor:</b></td>
                        <td className="no-border">{message.hostility}</td>
                    </tr>
                    <tr>
                        <td className="no-border"><b>Geographical Location:</b></td>
                        <td className="no-border">{position.latitude}<br/>{position.longitude}</td>
                        <td className="no-border"><b>Victim:</b></td>
                        <td className="no-border">{message.victim}</td>
                    </tr>
                    <tr>
                        <td className="no-top-border"><b>Description:</b></td>
                        <td  className="no-top-border" colSpan={3}>{message.description}</td>
                    </tr>
                </tbody>
            );
        })
    }
}

export {
    getTableHeaders, formatData
}