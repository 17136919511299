import React from 'react';
const search = require('./SearchBy');
const lightUtils = require('../Publications/LightsUtils');

function showChartCorrFilters(that){
    const pubType = that.state.pubType;
    let cntm = false;
    if(pubType === "cntm"){
        cntm = true
    }
    return (
        <div className="row left-align" id="chartQuery" key={4}>
            {/*BEGIN OF QUERY FOR CHARTS CORRECTIONS FILTER ROW*/}
            <div className="col-sm-4">
                <label htmlFor="selChartCorrFilters" className="query-label">QUERY FOR CHARTS</label>
                <select defaultValue="specificCharts" className="form-control" id="selChartCorrFilters" onChange={search.searchChartCorrBy}>
                    <option value="specifcCharts">Specific Charts</option>
                    {cntm ? null : <option value="byRegion">within Region</option> }
                    {cntm ? null : <option value="bySubregion">within Subregion</option> }
                    {cntm ? null : <option value="withinPort">within Port of Call</option> }
                    {cntm ? null : <option value="withinDNC">within DNC</option> }
                    {cntm ? <option value="byChartType">by Chart Type</option> : null }
                    <option value="geographicArea">within Geographic Area</option>
                    <option value="all">All</option>
                </select>
            </div>
            <div className="col-sm-6" id="chartList">
                <label htmlFor="inputChartList" className="query-label">Enter Comma Separated Chart Numbers</label>
                <textarea className="form-control" id="inputChartList" />
            </div>
            <div className="col-sm-4 no-display" id="chartAreaFilters">
                <label id="lblChartRegion" htmlFor="selChartRegion" className="query-label"></label>
                <select defaultValue="1" className="form-control" id="selChartRegion">
                    <option value={1}>Region 1</option>
                    <option value={2}>Region 2</option>
                    <option value={3}>Region 3</option>
                    <option value={4}>Region 4</option>
                    <option value={5}>Region 5</option>
                    <option value={6}>Region 6</option>
                    <option value={7}>Region 7</option>
                    <option value={8}>Region 8</option>
                    <option value={9}>Region 9</option>
                    <option value={0}>Other</option>
                </select>
                <select className="form-control no-display" id="selChartSubregion">
                    {buildChartSubregionOptions()}
                </select>
                <select className="form-control no-display" id="selChartPorts">
                    {buildChartPortsOptions()}
                </select>
                <select className="form-control no-display" id="selChartDNC">
                    {buildChartDNCOptions()}
                </select>
                <select className="form-control no-display" id="selChartType">
	    	    {/*<option>Bottom Contour</option>*/}
                    <option>Combat/Littoral</option>
                    <option>Standard Nautical</option>
	    	    {/*<option>Bathymetric Navigation Planning</option>
                    <option>Hull Integrity Test Site</option>
                    <option>Bathymetric Navigation</option>*/}
                </select>
            </div>
            {latLongSearch()}
            <div className="col-sm-6 no-display" id="chartAllNote">
                <label className="query-label"></label>
                <p className="has-warning">Notice: Please be aware that by selecting the "All" Query for Chart option you will not be able to
                use the "All" option for "Search NTM's" and will be restricted to a range of one year for the
                "Search NTM's" filter option above.</p>
            </div>
            {/*END OF QUERY FOR CHARTS ROW*/}
        </div>  
    );
}

function latLongSearch(){
    return (
        <div id="latLongDiv" className="no-display">
            <label htmlFor="latLong" className="query-label" id="label6">Enter Corner Coordinates of Search Area</label>
            {lightUtils.latLong()}
        </div>
        );
}

function buildChartSubregionOptions(){
    return(
        search.chartCorrSubregions().map(region => <option key={region} value={region}>Subregion {region}</option>)
    );
}

function buildChartPortsOptions(){
    return(
        search.chartCorrPorts().map(port => <option key={port.value} value={port.value}>{port.text}</option>)
    );
}

function buildChartDNCOptions(){
    return(
        search.chartCorrDNC().map(dnc => <option key={dnc.value} value={dnc.value}>&gt; {dnc.text}</option>)
    );
}

function showChartletDepthTabFilters(that){
    const pubType = that.state.pubType;
    let cntm = false;
    if(pubType === "cntm"){
        cntm = true
    }
    return([
        <div className="row left-align" id="chartQuery" key={5}>
            {/*BEGIN OF QUERY FOR CHARTLETS DEPTHTABS NOTES FILTER ROW*/}
            <div className="col-sm-4">
                <label htmlFor="selChartletFilters" className="query-label">QUERY FOR CHARTS</label>
                <select defaultValue="specificCharts" className="form-control" id="selChartletFilters" onChange={search.searchChartletsBy}>
                    <option value="specifcCharts">Specific Charts</option>
                    {cntm ? null : <option value="subregion">by Subregion</option> }
                    {cntm ? <option value="chartType">by Chart Type</option> : null }
                    <option value="All">All</option>
                </select>
            </div>
            <div className="col-sm-6" id="chartList">
                <label htmlFor="inputChartList" className="query-label">Enter Comma Separated Chart Numbers</label>
                <textarea className="form-control" id="inputChartList" />
            </div>
            <div className="no-display" id="chartletSubreg">
                <label htmlFor="selChartSubregion" className="query-label"> </label>
                <select className="form-control" id="selChartSubregion">
                    {buildChartSubregionOptions()}
                </select>
            </div>
            <div className="no-display" id="chartletChartType">
                <label htmlFor="selChartType" className="query-label">SELECT </label>
                <select className="form-control" id="selChartType">
	    	    {/*<option>Bottom Contour</option>*/}
                    <option>Combat/Littoral</option>
                    <option>Standard Nautical</option>
	    	    {/*<option>Bathymetric Navigation Planning</option>
                    <option>Hull Integrity Test Site</option>
                    <option>Bathymetric Navigation</option>*/}
                </select>
            </div>
            {/*END OF QUERY FOR CHARTLETS DEPTHTABS NOTES FILTER ROW */}
        </div>,
        <div className="row left-align" id="chartletAddtnlFilters" key={6}>
            <div className="col-sm-4">
                <label htmlFor="selChartletGraphic" className="query-label">GRAPHIC TYPE</label>
                <select defaultValue="All" className="form-control" id="selChartletGraphic">
                    <option value="All">All</option>
                    <option value="Chartlet">Chartlets</option>
                    <option value="Depth Tab">Depth Tabulations</option>
                    <option value="Note">Notes</option>
                </select>
            </div>
        </div>
    ]);
}

function showHydrographicFilters(that){
    const pubType = that.state.pubType;
    let cntm = false;
    if(pubType === "cntm"){
        cntm = true
    }
    return([
        <div className="row left-align" id="chartQuery" key={7}>
            <div className="col-sm-4">
                <label htmlFor="selBWFilters" className="query-label">PRODUCT TO SEARCH</label>
                <select defaultValue="charts" className="form-control" id="selBWFilters" onChange={search.searchBWBy}>
                    <option value="charts">Charts</option>
                    {cntm ? null : <option value="dnc">Digital Nautical Charts (DNC®)</option> }
                    <option value="tod">Tactical Ocean Data (TOD™)</option>
                    <option value="navpubs">Navigation Publications</option>
                    <option value="all">All Products</option>
                </select>
            </div>
            <div className="col-sm-6">
                <label className="query-label"></label>
                <input className="invisible-box disabled form-control"></input>
            </div>
        </div>,
        <div className="row left-align" id="BWAdditionalFilters" key={8}>
            {cntm ? null : <div className="col-sm-4">
                <label htmlFor="selBWAreaFilters" className="query-label">AREA FILTER</label>
                <select defaultValue="none" className="form-control" id="selBWAreaFilters" onChange={search.searchBWAreaBy}>
                    <option value="none">None</option>
                    <option value="byRegion">within Region</option>
                    <option value="bySubregion">within Subregion</option>
                </select>
            </div>}
            {cntm ? <div className="col-sm-4">
                <label htmlFor="selChartType" className="query-label">Chart Type</label>
                <select defaultValue="none" className="form-control" id="selChartType" >
                    <option value="none">All</option>
		    {/*<option>Bottom Contour</option>*/}
                    <option>Combat/Littoral</option>
                    <option>Standard Nautical</option>
		    {/*<option>Bathymetric Navigation Planning</option>
                    <option>Hull Integrity Test Site</option>
                    <option>Bathymetric Navigation</option>*/}
                </select>
            </div> : null}
            <div className="col-sm-4" id="chartAreaFilters">
                <label htmlFor="selChartRegion" className="query-label" id="regionlabel"></label>
                <select defaultValue="1" className="form-control no-display" id="selChartRegion">
                    <option value={1}>Region 1</option>
                    <option value={2}>Region 2</option>
                    <option value={3}>Region 3</option>
                    <option value={4}>Region 4</option>
                    <option value={5}>Region 5</option>
                    <option value={6}>Region 6</option>
                    <option value={7}>Region 7</option>
                    <option value={8}>Region 8</option>
                    <option value={9}>Region 9</option>
                    <option value={0}>Other</option>
                </select>
                <select className="form-control no-display" id="selChartSubregion">
                    {buildChartSubregionOptions()}
                </select>
            </div>
        </div>
    ]);
}

function showNavPubFilters(){
    return(
        <div className="row left-align" id="BWAdditionalFilters" key={9}>
            <div className="col-sm-4">
                <label htmlFor="selNavProduct" className="query-label">WITHIN PRODUCT</label>
                <select defaultValue="none" className="form-control" id="selNavProduct" onChange={search.searchNavPubBy}>
                    <option value="none" disabled>Select a Product...</option>
                    <option value="Sailing Directions - Enroute">Sailing Directions Enroute</option>
                    <option value="Sailing Directions - Planning Guides">Sailing Directions Planning Guides</option>
                    <option value="NGA List of Lights">NGA List of Lights</option>
                    <option value="USCG Light List">USCG Light Lists</option>
                    <option value="Fleet Guide">Fleet Guides</option>
                    <option value="Sight Reduction Tables for Air Navigation">Sight Reduction Tables (Air)</option>
                    <option value="Sight Reduction Tables for Marine Navigation">Sight Reduction Tables (Marine)</option>
                    <option value="Coast Pilot">Coast Pilots</option>
                    <option value="Tide Tables">Tide Tables</option>
                    <option value="Tidal Current Tables">Tidal Current Tables</option>
                    <option value="Atlas of Pilot Charts">Atlas of Pilot Charts</option>
                    <option value="other">Other Publications</option>
                </select>
            </div>
            <div className="col-sm-4 no-display" id="smallOptionsList">
                <label htmlFor="selSmallNavPub" className="query-label">Publication</label>
                <select defaultValue="all" className="form-control" id="selSmallNavPub">
                    {/*build small options list*/}
                </select>
            </div>
            <div className="col-sm-8 no-display" id="largeOptionsList">
                <label htmlFor="selLargeNavPub" className="query-label">Publication</label>
                <select defaultValue="all" className="form-control" id="selLargeNavPub">
                    {/*build large options list*/}
                </select>
            </div>
        </div>
    );
}

function showPubsAffFilters(){
    return(
        <div className="row left-align" id="BWAdditionalFilters" key={9}>
            <div className="col-sm-4">
                <label htmlFor="selNavProduct" className="query-label">WITHIN PRODUCT</label>
                <select defaultValue="all" className="form-control" id="selNavProduct">
                    <option value="all">All Products</option>
                    <option value="NGA Hydrographic Products">NGA/DLIS Catalogs</option>
                    <option value="NGA List of Lights">NGA List of Lights</option>
                    <option value="Sailing Directions">Sailing Directions</option>
                    <option value="USCG Light List">USCG Light List</option>
                    <option value="Digital Nautical Publications">Digital Pubs - Quarterly</option>
                    <option value="Fleet Guide">Fleet Guides</option>
                    <option value="NOS Misc Pubs">NOS Miscellaneous Publications</option>
                    <option value="Almanacs">Almanacs</option>
                    <option value="Coast Pilot">Coast Pilot</option>
                    <option value="Radio Navigational Aids">Radio Navigational Aids</option>
                    <option value="American Practical Navigator">American Practical Navigator</option>
                    <option value="International Code of Signals">International Code of Signals</option>
                    <option value="World Port Index">World Port Index</option>
                    <option value="Distances Between Ports">Distances Between Ports</option>
                    <option value="Radar Navigation and Maneuvering Board Manual">Radar Navigation and Maneuvering Board Manual</option>
                    <option value="Sight Reduction Tables for Marine Navigation">Sight Reduction Tables (Marine)</option>
                    <option value="Sight Reduction Tables for Air Navigation">Sight Reduction Tables (Air)</option>
                    <option value="Chart No. 1">Chart No. 1</option>
                    <option value="Atlas of Pilot Charts">Atlas of Pilot Charts</option>
                    <option value="USCG Navigation Rules">USCG Navigation Rules</option>
                    <option value="NOS Tide Tables">NOS Tide Tables</option>
                    <option value="Tidal Current Tables">Tidal Current Tables</option>
                </select>
            </div>
        </div>
    );
}


function showUscgLLFilters(that){
    return ([
        <div className="row left-align" id="uscgllQuery" key={10}>
            <div className="col-sm-4" id="selector-col4">
                <label htmlFor="sel4" className="query-label">Select Volume</label>
                <select defaultValue={1} className="form-control" id="sel4" onChange={that.getHDRAreas.bind(that)}>
                    <option value={1}>Volume I</option>
                    <option value={2}>Volume II</option>
                    <option value={3}>Volume III</option>
                    <option value={4}>Volume IV</option>
                    <option value={5}>Volume V</option>
                    <option value={6}>Volume VI</option>
                    <option value={7}>Volume VII</option>
                </select>
            </div>
        </div>,
        <div className="row left-align" id="uscgllAddtnlFilters" key={19}>
            {listLightsAdditionalFilters(that)}
            {latLongSearch()}
        </div>
    ]);
}

function showNgaLolFilters(that){
    return ([
        <div className="row left-align" id="uscgllQuery" key={11}>
            <div className="col-sm-4" id="selector-col4">
                <label htmlFor="sel4" className="query-label">Select Publication</label>
                <select defaultValue={110} className="form-control" id="sel4" onChange={that.getHDRAreas.bind(that)}>
                    <option value={110}>Publication 110</option>
                    <option value={111}>Publication 111</option>
                    <option value={112}>Publication 112</option>
                    <option value={113}>Publication 113</option>
                    <option value={114}>Publication 114</option>
                    <option value={115}>Publication 115</option>
                    <option value={116}>Publication 116</option>
                </select>
            </div>
        </div>,
        <div className="row left-align" id="uscgllAddtnlFilters" key={12}>
            {listLightsAdditionalFilters(that)}
            {latLongSearch()}
        </div>,
        <div className="row left-align" id="ngalolReturnFilter" key={13}>
            <div className="col-sm-4">
                <label htmlFor="ngalolReturn" className="query-label">Return Type</label>
                <select defaultValue="lights-buoys" className="form-control" id="ngalolReturn" onChange={hideRangeFilter}>
                    <option value="lights-buoys">Lighted Aids</option>
                    <option value="radiobeacons">Radiobeacons</option>
                    <option value="dgpsstations">DGPS Stations</option>
                </select>
            </div>
            <div className="col-sm-6" id="lightListRange" key={18}>
                <label htmlFor="chk1" className="query-label" id="label8">Optional</label><br />
                <p className="mb0">Limit results to lights with a range exceeding &nbsp;
                    <input className="form-control width70 inline p0" id="range" maxLength={2} />
                    &nbsp; nautical miles.
                </p> 
            </div>
        </div>
    ]);
}

function listLightsAdditionalFilters(that){
    return([
        <div className="col-sm-4" id="selector-col5" key={14}>
            <label htmlFor="sel5" className="query-label">Search Aids</label>
            <select defaultValue='all' className="form-control" id="sel5" onChange={updateFinalFilter}>
                <option value="all">All Aids</option>
                <option value="featureNumber">By Specific Aid Number</option>
                <option value="featureNumberRange">By Aid Number Range</option>
                <option value="geoHDR">By Geographic Header</option>
                <option value="geographicArea">By Geographic Area</option>
            </select>
        </div>,
        <div className="col-sm-2 no-display" id="beginningInput" key={15}>
            <label htmlFor="startRangeInput6" className="query-label" id="label6"></label>
            <input className="form-control col-sm-3" id="startRangeInput6" />
            <input className="form-control col-sm-3 no-display" id="endRangeInput6" placeholder="Ending Number" />
        </div>,
        <div className="col-sm-6 no-display" id="geoHdrDiv" key={17}>
            <label htmlFor="geoHDR" className="query-label">Geographic Header</label>
            <select defaultValue="null" className="form-control" id="geoHDR" onChange={that.updateSHDRAreas.bind(that)}>
                <option disabled value="null">Select a Geographic Header</option>
                {that.state.geoHDROptions}
            </select>
            <label htmlFor="geoSHDR" className="query-label" id="labelSHDR">Geographic Subheader</label>
            <select defaultValue="null" className="form-control" id="geoSHDR">
                <option disabled value="null">Optional: Select a Geographic Sub-header</option>
                {that.state.geoSHDROptions}
            </select>
        </div>
    ]);
}

function hideRangeFilter(){
    var dataType = document.getElementById("ngalolReturn").value;
    //if datatype is lights-buoys, show the range filter
    //if not, hide it
    if(dataType !== 'lights-buoys'){ document.getElementById("lightListRange").className = "no-display"; }
    else { document.getElementById("lightListRange").className = "col-sm-6"; }
}

function updateFinalFilter(){
    // variables are for input & select boxes related to uscgll"
    var filter = document.getElementById("sel5").value;
    var label = document.getElementById("label6");
    var aidNumberStartDiv = document.getElementById("beginningInput");
    var aidNumberStartInput = document.getElementById("startRangeInput6");
    //var aidNumberEndDiv = document.getElementById("endingInput");
    var aidNumberEndDiv = document.getElementById("endRangeInput6");
    var geoHDR = document.getElementById("geoHdrDiv");
    var latLong = document.getElementById("latLong");
    var latLongDiv = document.getElementById("latLongDiv");
    
    // display classNames that we will be setting
    var display3 = "col-sm-3";
    var display4 = "col-sm-6";
    var display8 = "col-sm-8";
    var nodisplay = "no-display";

    // depending on what user is filtering by, show or do not show certain inputs
    switch(filter) {
        case "featureNumber":
            label.innerHTML = "Enter Aid Number";
            aidNumberStartInput.placeholder = "";
            aidNumberStartDiv.className = display3;
            aidNumberEndDiv.className = nodisplay;
            geoHDR.className = nodisplay;
            latLong.className = nodisplay;
            latLongDiv.className = nodisplay;
            break;
        case "featureNumberRange":
            label.innerHTML = "Enter Aid Number Range"
            aidNumberStartInput.placeholder = "Starting Number";
            aidNumberStartDiv.className = display3;
            aidNumberEndDiv.className = "form-control col-sm-3";
            geoHDR.className = nodisplay;
            latLong.className = nodisplay;
            latLongDiv.className = nodisplay;
            break;
        case "geoHDR":
            aidNumberStartDiv.className = nodisplay;
            aidNumberEndDiv.className = nodisplay;
            geoHDR.className = display4;
            latLong.className = nodisplay;
            latLongDiv.className = nodisplay;
            break;
        case "geographicArea":
            aidNumberStartDiv.className = nodisplay;
            aidNumberEndDiv.className = nodisplay;
            geoHDR.className = nodisplay;
            latLong.className = display8;
            latLongDiv.className = "";
            break;
        default:
            //display none
            label.innerHTML = "";
            aidNumberStartDiv.className = nodisplay;
            aidNumberEndDiv.className = nodisplay;
            geoHDR.className = nodisplay;
            latLong.className = nodisplay;
            latLongDiv.className = nodisplay;
            break;
    }
}

export { showChartCorrFilters, showChartletDepthTabFilters, 
         showHydrographicFilters, showNavPubFilters,
         showUscgLLFilters, showNgaLolFilters, showPubsAffFilters }
