import React, { Component } from 'react';
const msiFetch = require('../../controllers/MsiFetch');


function createMarkup(text) {
    return {__html: text };
  }

class WhatsNew extends Component {
    constructor(props) {
        super(props);    
        this.state = {
            dataArr: []
        };
      }
    
    
async componentDidMount() {   
    const url = "pageContent/getByTitle?pageTitle=What's New in Maritime Safety";
    await msiFetch.fetchData(url)
    .then(data => {
        this.setState({dataArr: data});
    })
    .catch(error => {
        this.setState({dataArr: []});
    });
    
}
    render() {
        const dataArr = this.state.dataArr;
      
        return (
            <div>
                <h5>{dataArr.pageTitle}</h5>
                <div dangerouslySetInnerHTML={createMarkup(dataArr.pageBody)} />      
            </div>
        )
       
    }
}

export default WhatsNew;