import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';

class APN extends Component {
    render(){
        return(
            
            <div id="amer-practical-nav">
                <div id="apn-descrip">
                <PageContent divid="apn-descrip" />
                </div>
                <br />
                <div id="pub-download">
                    <PubDownload category="APN" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default APN;