import React, {Component} from 'react';
import PubDownload from './../PubDownload';
import PageContent from '../../PageContent';

class SDPG extends Component {
    render(){
        
        return(
            <div id="sail-dir-plan-guides">
                <div id="sdpg-descrip">
                    <PageContent  divid="sdpg-descrip" />
                </div>
                <br /><br /><br />
                <div id="pub-download">
                    <PubDownload category="SDPG" pubTypeId={this.props.pubTypeId} />
                </div>
            </div>
        );
    }
}

export default SDPG;