import React from "react";
import "../../css/queryResults.css";

function getTableHeaders(queryUrl) {
  return (
    <thead className="center-align">
      <tr>
        <td colSpan={42} className="bg-gray-lighter query-title">
          Broadcast Warning Messages
          <br />
          Query Results
        </td>
      </tr>
      <tr align="left">
        <td className="case-capitalize" colSpan={42} id="searchTerms">
          <b>Query Search Terms: </b>
          <br />
          {getQueryHeader(queryUrl)}
        </td>
      </tr>
    </thead>
  );
}

//Return subheader to display the customer query
function getQueryHeader(queryUrl) {
  var regexUrl = queryUrl.replace(/.*?\?(.*?)&output=html/i, "$1");
  regexUrl = regexUrl.replace("output=html", "");
  regexUrl = regexUrl.replace(/.*?\?/, "");
  regexUrl = regexUrl.replace("navArea=", "Nav Area: ");
  regexUrl = regexUrl.replace("subregion=", "Subregion: ");
  regexUrl = regexUrl.replace("msgNumber=", "Warning Number: ");
  regexUrl = regexUrl.replace("msgYear=", "Warning Year: ");
  regexUrl = regexUrl.replace("msgNumberStart=", "Starting Warning Number: ");
  regexUrl = regexUrl.replace("msgNumberEnd=", "Ending Warning Number: ");
  regexUrl = regexUrl.replace("msgYearStart=", "Starting Year Number: ");
  regexUrl = regexUrl.replace("msgYearEnd=", "Ending Year Number: ");
  regexUrl = regexUrl.replace("issueDateStart=", "Starting Issue Date: ");
  regexUrl = regexUrl.replace("issueDateEnd=", "Ending Issue Date: ");
  regexUrl = regexUrl.replace("status=", "Status: ");

  regexUrl = regexUrl
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n")
    .replace("&", "\n");
  regexUrl = regexUrl.replace(/%20/gi, " ");
  return regexUrl;
}

function formatData(data, query) {
  //return empty list if its already empty
  if (data["broadcast-warn"]) {
    if (data["broadcast-warn"].length === 0) {
      return data["broadcast-warn"];
    }
    //else format the data
    else {
      var dataItems = [];
      console.log(data, query);
      //JG 20200511 customer request of functionality to show all items sorted by area, year, msgNumber
      if (query.indexOf("navArea") >= 0 || query.indexOf("subregion") >= 0) {
        //for each row that is returned... format it into a card
        for (var i = 0; i < data["broadcast-warn"].length; i++) {
          dataItems.push(
            <DataItem
              data={data["broadcast-warn"][i]}
              key={data["broadcast-warn"][i]}
            />
          );
        }
      } else {
        var area = "";
        for (var j = 0; j < data["broadcast-warn"].length; j++) {
          const dataItem = data["broadcast-warn"][j];
          if (dataItem.navArea !== area) {
            //add in a new row to display the nav area
            dataItems.push(
              <tr>
                <td
                  colSpan={42}
                  align="center"
                  className="bg-gray-lighter text-bold"
                >
                  {convertAreaName(dataItem.navArea)}
                </td>
              </tr>
            );
            area = dataItem.navArea;
          }
          dataItems.push(<DataItem data={dataItem} key={dataItem} />);
        }
      }
      return <tbody>{dataItems}</tbody>;
    }
  } else if (data["HYDROLANT"]) {
    //listing of all inforce warning numbers
    var inforceRows = [];
    //for each nav area ... format it into a table row
    inforceRows.push(
      <InforceRow
        data={data["NAVAREA IV"]}
        navarea={"NAVAREA IV"}
        key={"NAVAREA IV"}
      />
    );
    inforceRows.push(
      <InforceRow
        data={data["NAVAREA XII"]}
        navarea={"NAVAREA XII"}
        key={"NAVAREA XII"}
      />
    );
    inforceRows.push(
      <InforceRow
        data={data["HYDROARC"]}
        navarea={"HYDROARC"}
        key={"HYDROARC"}
      />
    );
    inforceRows.push(
      <InforceRow
        data={data["HYDROLANT"]}
        navarea={"HYDROLANT"}
        key={"HYDROLANT"}
      />
    );
    inforceRows.push(
      <InforceRow
        data={data["HYDROPAC"]}
        navarea={"HYDROPAC"}
        key={"HYDROPAC"}
      />
    );
    // inforceRows.push(<InforceRow data={data['SPECIAL WARN']} navarea={'SPECIAL WARN'} key={'SPECIAL WARN'} />);

    return <tbody>{inforceRows}</tbody>;
  } else return data;
}

const InforceRow = (props) => {
  return (
    <tr id={props.navarea}>
      <td>{props.navarea}</td>
      <td>{props.data.join(", ")}</td>
    </tr>
  );
};

const DataItem = (props) => {
  var navArea = convertAreaName(props.data.navArea);
  var cancelled = props.data.status === "C" ? true : false;
  var cancelData = "";
  if (cancelled) {
    cancelData =
      "(Cancelled by " +
      convertAreaName(props.data.cancelNavArea) +
      " " +
      props.data.cancelMsgNumber +
      "/" +
      props.data.cancelMsgYear +
      ")\n\n";
  }

  return (
    <tr>
      <td>
        <pre className="bwarn-data">
          {navArea} {props.data.msgNumber}/{props.data.msgYear} (
          {props.data.subregion})<br />
          <br />
          {cancelled ? cancelData : null}
          {props.data.text}
          <br />
          {props.data.issueDate}{" "}
          {props.data.authority ? props.data.authority : null}
        </pre>
      </td>
    </tr>
  );
};

function convertAreaName(navArea) {
  switch (navArea) {
    case "4":
      return "NAVAREA IV";
    case "12":
      return "NAVAREA XII";
    case "C":
      return "HYDROARC";
    case "A":
      return "HYDROLANT";
    case "P":
      return "HYDROPAC";
    case "S":
      return "Special Warnings";
    default:
      return "";
  }
}

export { getTableHeaders, formatData };
