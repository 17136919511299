import React, { Component } from 'react';
import QueryPanel from '../QueryPanel';
import PageContent from '../PageContent';


class ProdCat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            network: props.network,
            divId: ""
        }
    }

    componentDidMount(){
        this.renderArticle();
    }

    renderArticle(){
        if(this.props.network === "unclassified"){
            this.setState({ divId: "www-product-descrip"});
        } else {
            this.setState({ divId: "product-descrip"});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.network !== prevProps.network) {
            this.renderArticle();
            this.setState({network: this.props.network})
        }
    }

    render() {
        return (
            <div id="prodcat">
                <div id="prodcat-descrip" className="container">
                    <div align="center" className="row">
                        <h5>Search Catalog of Hydrographic Products</h5>
                        <div className="col-md-12" align="center">
                            Use the Catalog Search to retrieve specific product information as listed in the NGA Catalog of Hydrographic Products.
                        <br /><br />
                            Select a type of product from the options below to display its interactive Database Query form.
                        </div>
                        <div id="prodcat-query" className="container">
                            <QueryPanel className="col-md-12" category="ProdCat" network={this.state.network} />
                        </div>
                    </div>
                    <PageContent divid={this.state.divId}/>
                </div>
            </div>
        );
    }
}
export default ProdCat;