import React, { Component } from 'react';
import PageContent from '../PageContent';

class CommercialUse extends Component {
    render() {
        return (
            <div id="commercialuse">
                <PageContent divid="commercialuse" />
            </div>      
        );
    }
}

export default CommercialUse;