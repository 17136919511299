import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUserEmail, updateUserName } from '../../utils/ReduxActions';
import HomeUC from './HomeUC';
import HomeTC from './HomeTC';
import HomeSC from './HomeSC';

const msiFetch = require('../../controllers/MsiFetch');

class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoggedIn: props.isLoggedIn,          
            classification: props.classification
        }
    }
    
    componentDidMount(){
        if(this.props.gxEnabled && this.props.classification !== "unclassified"){
            this.updateUserInfo();
        } 

    }

    componentDidUpdate(prevProps) {
        if (this.props.classification !== prevProps.classification) {
            this.setState({classification: this.props.classification });
        }
    }

    updateUserInfo(){
        if (this.state.isLoggedIn){
            this.id = sessionStorage.getItem("userId");
            this.props.updateUserName(this.props.data["givenName"], "userName");
            this.props.updateUserEmail(this.props.data["email"], "userEmail");
            this.sendTransaction()
        }
    }

    async sendTransaction(){
        const formData = {
            "userName": this.id,
            "auditAction": "user-login",
        }
        const url = "transactions/add-transaction";
        await msiFetch.postRawData(url, formData, 'application/json');
    }  

    renderHomePage(){
        switch(this.state.classification){
            case "unclassified//limdis":
            case "unclassified":
                return <HomeUC network={this.state.classification} />
            case "secret": 
                return <HomeSC />
            case "top secret": 
                return <HomeTC />
            default:
                return null;
        }
    }
      
    render(){
        return(
            <div id="Home" >
                <section className="homebkg">
                    {this.renderHomePage()}
                </section>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateUserName: (data, label) => { dispatch(updateUserName(data, label)) },
    updateUserEmail: (data, label) => { dispatch(updateUserEmail(data, label)) },
});

const mapStateToProps = (rootReducer) => ({
    rootReducer
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));