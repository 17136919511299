//import remoteConf from './config.json';

class Config {
    constructor() {
        this.appconfig = null;
        this.network = null;
        this.webmasterEmail = null;
        // Set service endpoint us .io for localhost (DEV) and
        const getUrl = window.location;
        this.redirecturi = "https://" + getUrl.hostname;
        this.scope = 'openid user_attributes'

        var res = getUrl.hostname.split(".");
        this.env = res[res.length-1];

        //set up api baseurl rebuilding it from the current hostname
        this.baseurl = "https://msi"
        for (var i=1; i<res.length; i++) {
            this.baseurl = this.baseurl + "." + res[i]
        }
        
        //localhost specific
        if(getUrl.hostname === 'localhost') {
            this.baseurl = "http://localhost:8080";
            this.redirecturi = "http://localhost:3000/";
            this.env = "local";
        } 
        
    }

    async setAppConfig(){
        this.appconfig =  await fetch("/api/application/config").then(response => response.json());
        this.network = this.appconfig.network;
        this.webmasterEmail = this.appconfig.webmasterEmail;
    }
}

export default Config;
