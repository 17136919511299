import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { AUTH_SUCCESS,AUTH_FAIL,UPDATE_USERNAME, UPDATE_USEREMAIL, 
    ADMIN_UPDATE, CONTENT_UPDATE, SEARCH_CONTENT_UPDATE } from '../utils/ReduxActions';

const authInitialState = {
    isAuthenticated: false,
}

const initialState = {
    userName: '',
    userEmail: '', 
    isAdmin: false, 
}

const contentInitialState = {
    pageContent: [],
    dateTime: '',
}

const searchContentInitialState = {
    searchContent: [],
}

const authReducer = (state = authInitialState , action) => {
  switch (action.type) {
        case AUTH_SUCCESS:
            return {...state, 
                isAuthenticated: true
            };
        case AUTH_FAIL:
            return {...state, 
                isAuthenticated: false
            };
        case 'persist/REHYDRATE': 
            //console.log(action.payload);
            if (action.payload['authReducer']){
                return {state, 
                    isAuthenticated: action.payload['authReducer']['isAuthenticated']
                };
            } else {
                return { ...state }
            }
        default: 
            return state;
    }
}

const userReducer = (state = initialState, action) => {
    switch (action.type){
        case UPDATE_USERNAME:
            return { state, 
                [action.label]: action.data
            };
        case UPDATE_USEREMAIL:
            return { state, 
                [action.label]: action.data
            };
        case ADMIN_UPDATE:
            return { state, 
                [action.label]: action.data
            };
        case 'persist/REHYDRATE':
            if (action.payload['authReducer']){
                return { state, 
                    userName: action.payload['userReducer']['userName'],
                    userEmail: action.payload['userReducer']['userEmail'],
                }
            } else {
                return { ...state }
            }
        default: 
            return state;
    }
}

const contentReducer = (state = contentInitialState, action) => {
    switch (action.type){
        case CONTENT_UPDATE:
            return {...state, 
                pageContent: action.data,
                dateTime: action.dateTime
            }
        case 'persist/REHYDRATE':
            if (action.payload['contentReducer']){
                return {...state, 
                    pageContent: action.payload['contentReducer']['pageContent'],
                    dateTime: action.payload['contentReducer']['dateTime']
                }
            } else {
                return { ...state }
            }
        default:
            return state;
    }
}

const searchContentReducer = (state = searchContentInitialState, action) => {
    switch (action.type){
        case SEARCH_CONTENT_UPDATE:
            return {...state, 
                searchContent: action.data
            }
        case 'persist/REHYDRATE':
            if (action.payload['searchContentReducer']){
                return { ...state, 
                    searchContent: action.payload['searchContentReducer']['searchContent'],
                }
            } else {
                return { ...state }
            }
        default: 
            return state;
    }
}


export default combineReducers({
    routing: routerReducer,
    authReducer,
    userReducer,
    contentReducer,
    searchContentReducer
})
