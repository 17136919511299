async function viewPrivateFile(url){
    //get data from msi api
    //we used to just return json, but now we have to handle xml and csv data returns

    //get auth token
    const token = sessionStorage.getItem('access_token');

    //fetch with GET method
    await fetch(url, 
        { 
            method: 'GET',
            headers: { Authorization: "Bearer " + token }
        })
        .then(response => {
           if(response.ok){
               return response.blob();
           }
        }).then(blob => showFile(blob))
        .catch(error => window.alert(error));
}

function showFile(blob){
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], {type: "application/pdf"})
  
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    } 
  
    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.setAttribute("type", "hidden");
    link.href = data;
    link.target = '_blank';
    //link.download="file.pdf";
    document.body.appendChild(link);
    link.click();
    link.remove();
    setTimeout(function(){
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data); }
    , 300000)
  }


export {viewPrivateFile}
