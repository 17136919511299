import React, { Component } from 'react';
import AutoComplete from '../../../utils/AutoComplete';

const MsiFetch = require('../../../controllers/MsiFetch');

const OptionValue = (props) => {
    //option items for country filter
    return <option value={props.data}>{props.data}</option>
}

class WPIQueryPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            ports: [],
            regions: []
        }
        this.clearFilters = this.clearFilters.bind(this);
    }

    async componentDidMount() {
        //get list of country, port, and region names
        const wpiPrcNames = await MsiFetch.fetchData('publications/world-port-index/wpi-prc-names');
        //get list of countries
        this.setState({ countries: wpiPrcNames['countries'] });
        //get list of Ports
        this.setState({ ports: wpiPrcNames['ports'] });
        //get list of Regions
        this.setState({ regions: wpiPrcNames['regions'] });
        this.updateFinalFilter();
    }

    getWorldPortIndex() {
        // variables include the select boxes & input fields
        var filter = null;
        var filterType = document.getElementById("sel4").value;
        var countryName = document.getElementById("sel5").value;
        var harborSize = document.getElementById("sel6").value;
        var output = document.getElementById("sel7").value;
        var harborSizeUrl = "";
        // if user filtered by portName, get the portName value
        if (filterType === "portName") {
            filter = document.getElementsByClassName("auto-comp")[0].value;
        }
        else if (filterType === "regionName") {
            filter = document.getElementsByClassName("auto-comp")[1].value;
        }
        else {
            //get the country value
            filter = countryName;
        }
        if (harborSize !== "null") {
            if (harborSize === "V") {
                harborSizeUrl = "&harborSize=V&harborSize=S&harborSize=M&harborSize=L";
            } else if (harborSize === "S") {
                harborSizeUrl = "&harborSize=S&harborSize=M&harborSize=L";
            } else if (harborSize === "M") {
                harborSizeUrl = "&harborSize=M&harborSize=L";
            } else {
                harborSizeUrl = "&harborSize=L";
            }
        }
        //build the api url to fetch with the world port index
        var url = "publications/world-port-index";
        if (filterType !== "null") {
            if (harborSize !== "null") {
                url = url + '?' + filterType + "=" + filter + harborSizeUrl + "&output=" + output;
            } else {
                url = url + '?' + filterType + "=" + filter + "&output=" + output;
            }
        }
        else {
            url = url + '?output=' + output;
        }

        // open the query results window. send the api url as Request Params
        // so that we don't have to send data. Actual fetch will be done by QueryResults window
        window.open('/queryResults?' + url, '_blank');
    }

    getCountryNames() {
        // show all the country names
        var optionsList = [];
        var options = this.state.countries.sort();
        //if statement because options could be undefined (async fetch has not run yet)
        if (options) {
            optionsList = options.map(country => { return <OptionValue data={country} key={country} /> });
        }
        return optionsList;
    }

    updateFinalFilter() {
        // variables are for input & select boxes related to port queries
        var filterTypeValue = document.getElementById("sel4").value;
        var filterTypeLabel = document.getElementById("label5");
        var countryName = document.getElementById("sel5");
        var harborSizeLabel = document.getElementById("label6");
        var harborSize = document.getElementById("sel6");
        var portName = document.getElementsByClassName("auto-comp")[0];
        var regionName = document.getElementsByClassName("auto-comp")[1];

        // display classNames that we will be setting
        var display = "form-control";
        var nodisplay = "form-control no-display"
        var display_autocomp = "form-control auto-comp"
        var nodisplay_autocomp = "form-control no-display auto-comp"

        // depending on what user is filtering by, show or do not show certain inputs
        switch (filterTypeValue) {
            case "portName":
                filterTypeLabel.innerHTML = "Enter Port Name";
                portName.className = display_autocomp;
                regionName.className = nodisplay_autocomp;
                countryName.className = nodisplay;
                harborSizeLabel.innerHTML = "";
                harborSize.className = nodisplay;
                countryName.value = "null";
                this.refs["regionNameRef"].refs["typeAhead"].setEntryText('');
                break;
            case "regionName":
                filterTypeLabel.innerHTML = "Enter Region Name";
                portName.className = nodisplay_autocomp;
                regionName.className = display_autocomp;
                countryName.className = nodisplay;
                harborSizeLabel.innerHTML = "OPTIONAL: MINIMUM HARBOR SIZE";
                harborSize.className = display;
                countryName.value = "null";
                this.refs["portNameRef"].refs["typeAhead"].setEntryText('');
                break;
            case "countryName":
                filterTypeLabel.innerHTML = "Country";
                portName.className = nodisplay_autocomp;
                regionName.className = nodisplay_autocomp;
                countryName.className = display;
                harborSizeLabel.innerHTML = "OPTIONAL: MINIMUM HARBOR SIZE";
                harborSize.className = display;
                this.refs["portNameRef"].refs["typeAhead"].setEntryText('');
                this.refs["regionNameRef"].refs["typeAhead"].setEntryText('');
                break;
            default:
                filterTypeLabel.innerHTML = "Enter Port Name";
                portName.className = display_autocomp;
                regionName.className = nodisplay_autocomp;
                countryName.className = nodisplay;
                harborSizeLabel.innerHTML = "";
                harborSize.className = nodisplay;
                countryName.value = "null";
                this.refs["regionNameRef"].refs["typeAhead"].setEntryText('');
        }
    }

    clearFilters() {
        var filterType = document.getElementById("sel4");
        var countryName = document.getElementById("sel5");
        var harborSizeLabel = document.getElementById("label6");
        var harborSize = document.getElementById("sel6");
        var portName = document.getElementsByClassName("auto-comp")[0];
        var regionName = document.getElementsByClassName("auto-comp")[1];

        //Nulls are for selects, innerHtml for labels, no-display for hiding
        filterType.value = "portName";
        this.updateFinalFilter();
        countryName.value = "null";
        harborSizeLabel.innerHTML = "";
        harborSize.value = "null";
        portName.value = "";
        regionName.value = "";
        this.refs["portNameRef"].refs["typeAhead"].setEntryText('');
        this.refs["regionNameRef"].refs["typeAhead"].setEntryText('');
    }

    openQueryDirections() {
        //open the window in a specific width/height
        window.open('/queryDirections?queryType=world-port-index', '_blank', 'toolbar=no,status=no,location=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=600');
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-12 query-dir">
                    <a onClick={this.openQueryDirections.bind(this)}>Query Directions</a>
                </div>
                <div className="col-sm-6" id="selector-col4">
                    <label htmlFor="sel4" className="query-label">Search Ports</label>
                    <select defaultValue="portName" className="form-control" id="sel4" onChange={this.updateFinalFilter.bind(this)}>
                        <option value="portName">by Port Name</option>
                        <option value="regionName">by Region Name</option>
                        <option value="countryName">by Country Name</option>
                    </select>
                </div>
                <div className="col-sm-6" id="selector-col5">
                    <label htmlFor="sel5" className="query-label" id="label5"></label>
                    <AutoComplete ref="portNameRef" data={this.state.ports} id="auto-port-name" />
                    <AutoComplete ref="regionNameRef" data={this.state.regions} id="auto-region" />
                    <select defaultValue='null' className="form-control no-display" id="sel5">
                        <option disabled value='null'>Select a Country Name:</option>
                        {this.getCountryNames()}
                    </select>
                </div>
                <div className="col-sm-6" id="selector-col6">
                    <label htmlFor="sel6" className="query-label" id="label6"></label>
                    <select defaultValue='null' className="form-control no-display" id="sel6">
                        <option disabled value='null'>Returns ports at or exceeding size selection </option>
                        <option value="L">L - Large</option>
                        <option value="M">M - Medium</option>
                        <option value="S">S - Small</option>
                        <option value="V">V - Very Small</option>
                    </select>
                </div>
                <div className="col-sm-6">
                    <label htmlFor="sel7" className="query-label" id="label7">Output Format</label>
                    <select defaultValue="html" className="form-control" id="sel7">
                        <option value="html">HTML</option>
                        <option value="xml">XML</option>
                        <option value="csv">CSV (Delimited text)</option>
                    </select>
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                    <div className="filter-buttons">
                        <a className="btn vol-btn" title="Search" id="btnApplyQuery" onClick={this.getWorldPortIndex}>Search</a>
                        <a className="btn vol-btn" title="Clear Filters" id="btnClearQuery" onClick={this.clearFilters}>Clear</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default WPIQueryPanel;
